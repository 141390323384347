import React from 'react'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Flex, { FlexItem } from 'styled-flex-component';
import { H1, H2, P, U, Strong, Img, Button, HexagonGrid } from 'src/components';
import { BreakLine, GrayBreakLine, useIsDeviceWidth, useLanguage } from 'src/components/utils';
import { mainTheme } from "src/GlobalStyle"
import useContents from "src/data"


const CareerRoot = styled.section`
    ${props => props.theme.css.flexCenter()}

    padding: 300px 20px 0;
    margin-bottom: 250px;

    ${breakpoint("desktop", "big desktop")`
        margin-bottom: 100px;
    `}
    ${breakpoint("mobile", "desktop")`
        padding: 100px 20px;
        margin-bottom: 0;
    `}
 
`

const ThreeSlogan = styled(Flex)`
    ${breakpoint("desktop", "big desktop")`
        margin-top: 100px;
    `} 
`

export default function Career() {
    const { images, 컨텐츠 } = useContents("Career")
    const language = useLanguage()
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800})


    // header
    const upperBigText = isSmallDevice
        ? <H1 size={24}>
            <Strong><U>{`Career`}</U></Strong>
        </H1>
        : <H1 size={50} weight={100}>
            {컨텐츠[0][0]}<Strong><U>{컨텐츠[0][1]}</U></Strong>{컨텐츠[0][2]}
        </H1>

    const upperSmallText = <P size={14}  marginTop={20}>{컨텐츠[0][3]}</P>

    const button = isSmallDevice
        ? null
        : <Button
        href={`https://deargen.oopy.io/d090bbbd-2cd3-4f17-8fda-41e770d6c22c`}
        text={language==="ko" ? "지원하기" : "Apply"}
        customCss={`margin-top: 20px;`} />

    const breakline = isSmallDevice 
        ? <GrayBreakLine paddingTop={30} paddingBottom={30}/>
        : <BreakLine paddingTop={60} paddingBottom={60} /> 

    const lowerBigText = <H2
    size={isSmallDevice ? 18 : 30}
    weight={300}>
        {컨텐츠[0][5]}<Strong>{컨텐츠[0][6]}</Strong> ?
    </H2>

    const lowerSmallText = isSmallDevice
        ? null
        : <P size={14} marginTop={24}>
            {컨텐츠[0][7]}
        </P>
    
    const headerRender = <Flex full center>
        <FlexItem>
            {upperBigText}
            {upperSmallText}
            {button}
            {breakline}
            {lowerBigText}        
            {lowerSmallText}
        </FlexItem>
    </Flex>


    // three slogans
    const hexagonGridConfig = {
        size: 110,
        gap: 10,
        gridColumn: 2,
        buttonsProps: [
            {
                color: mainTheme.secondary,
                config: {
                    componentNotHovered: <>
                        <Img gatsbyImageSrc={images.balloon} />
                    </>,
                    componentHovered: <>
                        <P size={20} align="center" weight="bold" color="secondary">{컨텐츠[1][0]}</P>
                        <P size={12} align="center" color="white">{컨텐츠[1][1]}</P>
                    </>,
                    backgroundColorNotHovered: "white",
                    backgroundColorHovered: mainTheme.primary,
                }
                
            },
            {
                color: mainTheme.secondary,
                config: {
                    componentNotHovered: <>
                        <Img gatsbyImageSrc={images.school} />
                    </>,
                    componentHovered: <>
                        <P size={20} align="center" weight="bold" color="secondary">{컨텐츠[2][0]}</P>
                        <P size={12} align="center" color="white">{컨텐츠[2][1]}</P>
                    </>,
                    backgroundColorNotHovered: "white",
                    backgroundColorHovered: mainTheme.primary,
                },
            },
            null,
            {
                color: mainTheme.secondary,
                config: {
                    componentNotHovered: <>
                        <Img gatsbyImageSrc={images.heart} />
                    </>,
                    componentHovered: <>
                        <P size={20} align="center" weight="bold" color="secondary">{컨텐츠[3][0]}</P>
                        <P size={12} align="center" color="white">{컨텐츠[3][1]}</P>
                    </>,
                    backgroundColorNotHovered: "white",
                    backgroundColorHovered: mainTheme.primary,
                }
            },
            null
        ]
    }

    const desktopThreeSloganRender =<HexagonGrid {...hexagonGridConfig} />

    const mobileThreeSlogan = <Flex full center style={{marginTop: 30 }} column>
        <FlexItem>
            <Flex center>
                <Img gatsbyImageSrc={images.balloon_mobile} />
                <FlexItem style={{marginLeft: 20, width: 180}}>
                    <P size={18} weight="bold">{컨텐츠[1][0]}</P>
                    <P size={12}>{컨텐츠[1][1]}</P>
                </FlexItem>
            </Flex>
        </FlexItem>
        
        <FlexItem style={{marginTop: 50}}>
            <Flex center>
                <Img gatsbyImageSrc={images.school_mobile} />
                <FlexItem style={{marginLeft: 20, width: 180}}>
                    <P size={18} weight="bold">{컨텐츠[2][0]}</P>
                    <P size={12}>{컨텐츠[2][1]}</P>
                </FlexItem>
            </Flex>
        </FlexItem>
        
        <FlexItem style={{marginTop: 50}}>
            <Flex center>
                <Img gatsbyImageSrc={images.heart_mobile} />
                <FlexItem style={{marginLeft: 20, width: 180}}>
                    <P size={18} weight="bold">{컨텐츠[3][0]}</P>
                    <P size={12}>{컨텐츠[3][1]}</P>
                </FlexItem>
            </Flex>
        </FlexItem>
    </Flex>

    const threeSlogansRender = <ThreeSlogan full center>
        {isSmallDevice 
            ? mobileThreeSlogan 
            : desktopThreeSloganRender
        }
    </ThreeSlogan>


    const shouldDirectionColumn = useIsDeviceWidth({ from: 0, to: 1200 })

    
    return <CareerRoot>
        <Flex
        center
        column={shouldDirectionColumn}
        style={{width: 1200}}>
            {headerRender}
            {threeSlogansRender}
        </Flex>
    </CareerRoot>
}
