import React, {  } from 'react'
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import Flex from "styled-flex-component"
import { useLanguage, DeargenLogo } from "src/components/utils"

const MainLogoCss = css`
    ${props => props.theme.css.flexCenter()}
`

export default function MainLogo() {
    const language = useLanguage()

    return <Link to={`/${language}/`} css={MainLogoCss}>
        <DeargenLogo />
    </Link> 
}
