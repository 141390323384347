import React, {  } from 'react'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Flex, { FlexItem } from 'styled-flex-component';
import { H1, P, Img } from 'src/components';
import { useIsDeviceWidth, useLanguage } from 'src/components/utils';
import {  } from "src/GlobalStyle"
import useContents from "src/data"

const RecruitBoardRoot = styled.section`
    ${props => props.theme.css.flexCenter("column")}

    position: relative;
    margin-top: 100px;

    ${breakpoint("mobile", "desktop")`
        margin: 0;
    `}
`

export default function Career() {
    const { images, 컨텐츠 } = useContents("CareerDetail")
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })
    const language = useLanguage()

    const mobileRender = <>
        <Img gatsbyImageSrc={images.recruit_board_0} />   
    </>

    const desktopRender =  <>
        <Img gatsbyImageSrc={images.recruit_board_0} />   
    </>


    // render
    const render = isSmallDevice
        ? mobileRender
        : desktopRender


    return <RecruitBoardRoot>
        <Flex full center style={{ marginTop: 70, marginBottom: isSmallDevice ? 60 : 150 }}>
            <Flex style={{ width: 800 }} column>
                {render}
            </Flex>
        </Flex>
    </RecruitBoardRoot>
}
