import React, {  } from 'react'
import _ from "lodash"
import {  } from "src/components"
import { useIsDeviceWidth } from 'src/components/utils'

// sections
// TODO : currently `TragetDiscovery` and `DrugDesign` is for mobile. `DesktopRender` is for desktop. this is temporary and will be modified soon.
import TargetDiscovery from "./TargetDiscovery"
import DesktopRender from "./DesktopRender"


export default function TechnologyIndex({ location }) {
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })
    

    // render
    const render = isSmallDevice
        ? <TargetDiscovery location={location} />
        : <DesktopRender location={location} />


    return <>
        {render}
        {/* <ArrowButton position="lefttop" direction="left" onClick={e => history.push(`/${language}#drug`)} /> */}
    </>
}