import React, {  } from 'react'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Flex, { FlexItem } from "styled-flex-component"
import { CgCopy } from "react-icons/cg"
import { BsCircleFill } from "react-icons/bs"
import { FaAngleRight } from "react-icons/fa"
import _ from "lodash"
import { H1, P, U, Img, A } from "src/components"
import { BreakLine, useIsDeviceWidth, CapsuleText } from 'src/components/utils'
import {  } from 'src/GlobalStyle'
import useContents from "src/data"

const MainSloganRoot = styled(Flex)`
    // height: 100vh;
    padding-top: 50px;
    margin-bottom: 40px;

    ${breakpoint("mobile", "desktop")`
        box-sizing: border-box;
        padding: 60px 20px 50px;
    `}
`

const Background = styled.div`
    position: absolute;
    z-index: -1;
    bottom: 0;
    
    figure {
        width: 100vw;
        height: 55vh;
        object-fit: cover;
        opacity: 0.1;
        mix-blend-mode: luminosity;
    }

`
const LogoImage = styled(FlexItem)`
    width: 350px;
    
    ${breakpoint("mobile", "desktop")`
        width: 240px;
        min-width: 100px;
        margin: 80px auto;
    `}
`
const Text = styled(FlexItem)`
    width: 100%;
    ${H1} {
        margin-top: 2rem;
        font-size: 4rem;
        font-weight: bold;

        ${breakpoint("mobile", "desktop")`
            font-size: 26px;
        `}
    }
    ${P} {
        margin-top: 10px;
        font-size: 15px;
    }
`
const Blur = styled(Flex)`
    background: rgba(230, 241, 255, 0.5);
    padding: 20px 0;
    width: 100vw;
    backdrop-filter: blur(16px);
`
const LatestNews = styled(Flex)`
    width: 100%;
    padding: 20px;
`
const NewsItem = styled(Flex)`    
    width: 100%;
    margin-top: 20px;
    ${P} {
        text-align: left;
        font-weight: bold;
        text-overflow: elipsis;
        width: 90%;
    }
    ${A} {
        white-space: pre;
    }
`
export default function MainSlogan() {
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })

    // contents
    const { images, 컨텐츠 } = useContents("Home_Slogan")
    const { posts } = useContents("Updates")

    // background
    const backgroundRender = isSmallDevice
        ? null
        : <Background>
            <BreakLine width={700} />
            <Img gatsbyImageSrc={images.laboratory} />
        </Background>


    // image
    const imageRender = <LogoImage>
        <Img gatsbyImageSrc={images.home_slogan_logo} /> 
    </LogoImage>


    // text
    const textRender = <Text>
        <H1>
            {컨텐츠[0][0]}
        </H1>
        <P>{컨텐츠[0][1]}</P>
    </Text>

    // blur
    const blurRender = <Blur>
        <LatestNews column>
            <P size={24}><CgCopy /> LATEST NEWS</P>
            {posts.slice(0,3).map((post, i) => <NewsItem alignCenter justifyBetween>
                <A to={post.to} href={post.href}>
                    <P key={i} lineLimit={1}>
                        <BsCircleFill size={8} />
                        {"  "}{_.truncate(post.title, { length: 50 })}
                    </P>
                </A>
                <FaAngleRight size={16} />
            </NewsItem>)}
        </LatestNews>
    </Blur>


    // title in mobile
    const mobileTitleRender = isSmallDevice
        ? <CapsuleText customCss={`margin-top: 40px;`} />
        : null


    return <MainSloganRoot full column center>
        {mobileTitleRender}
        {backgroundRender}
        {textRender} 
        {imageRender}
        {blurRender}   
    </MainSloganRoot>
}
