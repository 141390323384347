import { useStaticQuery, graphql } from "gatsby"
import { useLanguage } from "src/components/utils"

const text = {   
    ko: [
        // paper
        [
            [
                `ALS`,
                `ALS(루게릭), 근본적 치료에 대한 도전`,
                `현재 미국 FDA 승인을 받고 사용중인 ALS 치료제는 루게릭의 증상을 완화시키고 진행을 늦추는 수준입니다. 디어젠은 90%이상의 루게릭 환자에서 관찰 되는 TDP-43 단백질응집을 감소시킴으로서, ALS의 근복적인 치료제개발에 도전하고 있습니다.`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
            [
                `대장암`,
                `대장암, MERTK, 그리고 저분자 화합물`,
                `디어젠은 대장암을 대상으로 연구한 결과, 암세포 면역반응 회피기작에 MERTK (TYRO3/AXL/MER, TAM receptor kinase family)가 중요한 타깃으로 결과가 도출되었으며, 이에 대한 저분자 화합물 저해제에 집중하여 연구를 진행하고 있습니다.`,
                `https://deargen.blog/2019/08/05/%eb%8c%80%ec%9e%a5%ec%95%94-mertk-%ea%b7%b8%eb%a6%ac%ea%b3%a0-%ec%a0%80%eb%b6%84%ec%9e%90-%ed%99%94%ed%95%a9%eb%ac%bc/`,
        
            ],
            [
                `AI-PROTAC`,
                `Undruggable to Degradable with AI`,
                `PROTAC은 기존 바이오 의약품으로 공략하기 어려웠던 질병 치료제에 대한 새로운 플랫폼으로 알려져 있습니다. 디어젠은 분해대상 단백질에 결합할 수 있는 저분자 화합물을 보다 빠르고 정확하게 탐색함으로서 PROTAC을 통한 신약개발을 가속화하고 있습니다.`,
                `https://deargen.blog/2019/08/05/undrugable-to-degradable-with-ai/`,
            ],
            [
                `사코페니아`,
                `저분자 화합물을 이용한 사코페니아(근감소증) 치료`,
                `사코페니아는 2016년 10월부터 새롭게 질병으로 분류된 신종 노인성 질환입니다. 노화가 진행됨에 따라 골격근이 감소하는 질병으로 현재까지 미국 FDA에 허가받은 노인성 근감소증 치료제는 전무한 실정입니다. 이에 디어젠은 저분자 화합물을 이용한 근감소증 치료제 개발에 도전하고 있습니다.`,
                `https://deargen.blog/2019/08/05/%ec%a0%80%eb%b6%84%ec%9e%90-%ed%99%94%ed%95%a9%eb%ac%bc%ec%9d%84-%ec%9d%b4%ec%9a%a9%ed%95%9c-%ec%82%ac%ec%bd%94%ed%8e%98%eb%8b%88%ec%95%84%ea%b7%bc%ea%b0%90%ec%86%8c%ec%a6%9d-%ec%b9%98%eb%a3%8c/`,
            ],
        ],
        // header
        [
            "Integration, Innovation,",
            " In medication",
            `디어젠은 신약개발 분야의 전문가들과의 협업네트워크를 통해,\nAI 기반의 신약 개발 프로세스를 구축해 가고 있습니다.\n우리의 혁신적인 신약 파이프라인은 치료가 어려웠던 질병들의 근원적 치료를 가능케 할 것입니다.`,
        ],
        // old version header
        [
            "Power of Integration",
            `디어젠은 신약개발 분야의 전문가들과의 협업네트워크를 통해,\nAI 기반의 신약 개발 프로세스를 구축해 가고 있습니다.\n우리의 혁신적인 신약 파이프라인은 치료가 어려웠던 질병들의 근원적 치료를 가능케 할 것입니다.`,
        ],
    ],
    en: [
        [
            [
                `ALS`,
                `ALS (Lou Gehrig), a Challenge to Radical Cure`,
                `ALS treatments that currently are being used under FDA approval have a level that relieves symptoms and slows the progression of Lou Gehrig. Deargen is challenging the development of new ALS treatments by reducing TDP-43 protein aggregation observed in more than 90% of patients with Lou Gehrig.`,
                `https://deargen.blog/2019/08/05/als-lou-gehrig-a-challenge-to-radical-cure/`,
        
            ],
            [
                `Colorectal Cancer`,
                `Colorectal Cancer, MERTK, and Small molecular`,
                `The study of colorectal cancer has shown that MERTK (TYRO3 / AXL / MER, TAM receptor kinase family) is an important target for cancer cell immune response evasion mechanism. Thus, Deargen is researching by focusing on small molecule inhibitors.`,
                `https://deargen.blog/2019/08/05/colorectal-cancer-mertk-and-small-molecular/`,
        
            ],
            [
                `AI-PROTAC`,
                `Undruggable to Degradable with AI`,
                `PROTAC is known as a new platform for disease treatment that has been difficult to reach with existing biopharmaceuticals. Deargen is accelerating the development of drugs through PROTAC by rapidly and precisely searching for small molecules that can bind to the target protein.`,
                `https://deargen.blog/2019/08/05/undruggable-to-degradable-with-ai/`,
            ],
            [
                `Sarcopenia`,
                `Treatment of Sarcopenia using Small Molecules`,
                `Sarcopenia is a new type of age-related disease that has been newly classified as a disease since October 2016. It is defined as a disease with a reduction in skeletal muscle mass as aging progresses and there are no treatments for age-related sarcopenia that have been approved by the FDA so far. In this regard, Deargen is challenging the development of treatments for sarcopenia using small molecules.`,
                `https://deargen.blog/2019/08/05/treatment-of-sarcopenia-using-small-molecules/`,
            ],
        ],
        // header
        [
            "Integration, Innovation,",
            " In medication",
            `DEARGEN has long been establishing an AI-based new drug development process\nthrough operating a cooperative network with experts in new drug development. 
            Our innovative new drug pipeline will allow the radical cure of diseases that have remained difficult to treat.`,
        ],
        // old version header
        [
            "Power of Integration",
            "DEARGEN has long been establishing an AI-based new drug development process\nthrough operating a cooperative network with experts in new drug development.\nOur innovative new drug pipeline will allow the radical cure of diseases that have remained difficult to treat.",
        ],
    ]
}


export default function useContent() {
    const language = useLanguage()
    const 컨텐츠 = text[language]

    const images = useStaticQuery(graphql`
    query {
        ALS: file(relativePath: { eq: "Home_OurWork/ALS.png" }) {
            childImageSharp {
                fixed(width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        CC: file(relativePath: { eq: "Home_OurWork/CC.png" }) {
            childImageSharp {
                fixed(width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        PROTAC: file(relativePath: { eq: "Home_OurWork/PROTAC.png" }) {
            childImageSharp {
                fixed(width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        Sarcopenia: file(relativePath: { eq: "Home_OurWork/Sarcopenia.png" }) {
            childImageSharp {
                fixed(width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }

        # TODO: delete. this is for oldOurWork
        ALS_oldOurWork: file(relativePath: { eq: "Home_OurWork/ALS.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`)

    return { images, 컨텐츠 }
}