import React, {  } from 'react'
import { navigate } from "gatsby"
// import { useLocation, Redirect } from 'react-router-dom'
import _ from "lodash"
import {  } from "src/components"
import { useIsDeviceWidth } from 'src/components/utils'
import HomeDesktop from "./HomeDesktop"
import HomeMobile from "./HomeMobile"


export default function Home({ location }) {
    // const location = useLocation()
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })


    // render
    const render = isSmallDevice
        ? <HomeMobile />
        : <HomeDesktop location={location} />


    // redirect if worng url
    if (!["", "#technology", "#pipeline", "#publication", "#team", "#partners", "#contact"].includes(location.hash)) navigate(location.pathname)
    
    
    // return render
    return <>
        {render}
    </>
}

