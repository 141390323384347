import React from 'react'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { H1, H2, P, Button } from 'src/components';
import { blur } from 'src/components/utils';

const TechnologySectionRoot = styled.div`
    ${props => props.customCss};
`

// layout
const Layout = styled.div`
    box-sizing: border-box;
    max-width: 600px;
    min-width: 400px;
    padding: ${props => props.paddingTop}px 50px 0;
    margin-top: ${props => props.marginTop}px;
`

//body
const Body = styled.div`
    display: flex;
    flex-direction: column;

    ${H2} {
        font-size: 1.3em;
        font-weight: 700;
        color: #055f96;
    }

    ${P} {
        color: #06374f;
        font-size: 0.9em;
        font-weight: 500;
        margin: 1rem 0;
    }
`
const BodyItem = styled.div`
    margin-bottom: 3rem;
    animation: ${props => blur({ speed: 1, delay: props.delay })}
`
// for hash url anchor link
const AnchorLink = styled.a(({ id, ...props }) => <a id={id} {...props}></a>)
   
export default function TechnologySection({ header, body, id, customCss }) {
    // render
    const headerRender = React.isValidElement(header)
        ? header
        : <>
            <H1 size={37} weight="bold">{header[0]}</H1>
            <P size={15} marginTop={20}>{header[1]}</P>
            {header[2] 
                ? <Button {...header[2]} customCss={`margin-top: 20px`}/> 
                : null
            }
    </>


    const bodyRender = React.isValidElement(body)
        ? body
        : <Body>     
            {body.map(([title, content, buttonProps], index) => <BodyItem key={title} delay={index*0.2}>
                <H2>{title}</H2>
                <P>{content}</P>
                {buttonProps ? <Button {...buttonProps} /> : null}
            </BodyItem>)}
        </Body>


    return <TechnologySectionRoot customCss={customCss}>
        <AnchorLink id={id} />

        <Layout paddingTop={100} marginTop={150}>
            {headerRender}    
        </Layout>

        <Layout paddingTop={70}>
            {bodyRender}
        </Layout>
    </TechnologySectionRoot>
}