import styled, {  } from "styled-components"


export const Section = styled.section`
    position: relative;
    display: block;
    // if not define overfloww:hidden, critical error is occurred in mobile. don't know reason why
    overflow: hidden;
    ${props => props.customCss};
    
`