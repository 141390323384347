import React, { useState } from "react"
import styled, {  } from "styled-components"
import { useInView } from "react-intersection-observer"
import {  } from "src/components"
import { fadeIn, createAnimation,  } from "src/components/utils"
import {  } from "src/GlobalStyle"

// Arise
// reference: https://codepen.io/jscottsmith/pen/GMYdKg
const AriseItem = styled.div`
    ${props => props.theme.css.full}
`
const AriseRoot = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;    
    height: 100%;
    ${AriseItem} {
        animation: ${props => props.shouldPlayAnimation 
            ? createAnimation({
                from: { transform: "translateY(150%)" },
                to: { transform: "translateY(0)" },
                speed: props.speed,
                delay: props.delay,
                callback: props.animationCallback
             }) 
            : "" };
    }

`
export const Arise = ({ play=true, speed=1, delay=0, children}) => {
    const [played, setPlayed] = useState(false);   
    const shouldPlayAnimation = play && !played

    return <AriseRoot
    play={play}
    speed={speed}
    delay={delay}
    animationCallback={() => setPlayed(true)}
    shouldPlayAnimation={shouldPlayAnimation}>
        <AriseItem>
            {children}
        </AriseItem>
    </AriseRoot>
}


// Fade-In
const FadeInViewRoot = styled.div`
    opacity: ${props => props.inView ? 1 : 0};
    animation: ${props => props.inView && fadeIn({ mode: "fromLeft", withBlur: true, speed: 0.8 }) }};
    ${props => props.customCss};
`
export const FadeInView = ({ threshold=1, triggerOnce=true, customCss, children }) => {
    // animation
    const [ref, inView] = useInView({ threshold, triggerOnce })

    return <FadeInViewRoot
    ref={ref}
    // animationPlayed={animationPlayed}
    inView={inView}
    // animationCallback={() => setAnimationPlayed(true)} 
    customCss={customCss} >
        {children}
    </FadeInViewRoot> 
}