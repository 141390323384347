import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const P = styled(
    ({ size, color, weight, align, marginTop, whiteSpace, lineHeight, hoverColor, lineLimit, to, href, children, ...props }) => <p {...props}>{children}</p>
)`

    font-size: ${props => props.size 
        ? Number.isInteger(props.size)
            ? `${props.size}px`
            : props.size
        : `0.9em`
    };

    color: ${props => { 
        if (props.color==="primary") return  props.theme.primary
        if (props.color==="secondary") return  props.theme.secondary

        const defaultColor = props.theme.primary
        return props.color 
            ? props.color
            : defaultColor
    }};

    font-weight: ${props => props.weight 
        ? props.weight 
        : "inherit"
    };

    text-align: ${props => props.align 
        ? props.align 
        : "inherit"
    };

    margin-top: ${props => props.marginTop
        ? props.marginTop 
        : 0
    }px;

    white-space: ${props => props.whiteSpace
        ? props.whiteSpace
        : "pre-wrap"
    };

    line-height: ${props => props.lineHeight
        ? props.lineHeight
        : "1.5"
    };
    
    transition: all 0.2s;
    &:hover {
        color: ${props => {
            if (props.hoverColor==="primary") return  props.theme.primary
            if (props.hoverColor==="secondary") return  props.theme.secondary

            const defaultHoverColor = props.color
            return props.hoverColor 
                ? props.hoverColor
                : defaultHoverColor
        }}
    };

    ${props => {
        return props.lineLimit 
            ? `
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: ${props.lineLimit};
                overflow: hidden;
            `
            : ""
    }};

    ${props => props.customCss};
`

export default P