import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import styled, {  } from "styled-components"
import Flex, {  } from "styled-flex-component"
import breakpoint from "styled-components-breakpoint"
import {  } from "react-icons/fa"
import { P, Button, ArrowButton } from 'src/components';
import { useLanguage, useIsDeviceWidth, BreakLine } from 'src/components/utils';
import { mainTheme } from "src/GlobalStyle"
import Card from "./_TeamCard"
import RightModal from "./_RightModal"
import useContents from "src/data"


const TeamRoot = styled.section`
    max-width: 1200px;
    margin: 0 auto 200px;
    padding: 150px 20px 0;
    overflow: hidden;

    ${breakpoint("mobile", "desktop")`
        padding-top: 100px;
        margin-bottom: 100px;
    `}
`
const MenuItem = styled(P)`
    margin-left: 20px;
    opacity: 0.4;
    cursor: pointer;
    ${props => props.active && `
        color: ${props.theme.secondary};
        font-weight: bold;
        opacity: 1;
    `}
`
const CardWrapper = styled.div`
    width: 33%;
    padding: 10px;
    box-sizing: border-box;
    
    ${breakpoint("desktop", "big desktop")`
        width: 50%;
    `}

    ${breakpoint("mobile", "desktop")`
        width: 100%;
        padding: 0;
        margin-bottom: 16px;
    `}
`
const PeopleCardsRoot = styled.div`
    transition: 0.2s;
    opacity: ${props => props.show ? 1 : 0};
`


export default function TeamDetail({ location }) {
    const language = useLanguage()
    const { 컨텐츠 } = useContents("Team")
    const { images: images_Home_Team, 컨텐츠: 컨텐츠_Home_Team } = useContents("Home_Team")
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })

    // header
    const headerRender = <>
        <P size={20} weight={300} marginTop={20}>{컨텐츠[0][0]}</P>
        <P size={28} weight={900}>
            {컨텐츠_Home_Team[1][0][0]}
            <span css={`color: ${mainTheme.secondary}`}>{컨텐츠_Home_Team[1][0][1]}</span>
            {컨텐츠_Home_Team[1][0][2]}
        </P>
        <P size={14} marginTop={10}>
            {컨텐츠_Home_Team[1][1]}
        </P>

        <Button
        marginTop={20}
        text={컨텐츠[0][2]}
        to={`/${language}/career_detail/`} 
        customCss={`margin-top: 20px;`} />

        {!isSmallDevice && <BreakLine paddingTop={100} />}
    </>

    // choose which people to show
    useEffect(() => {
        if (location.hash==="#deargen") setPeople("deargen")
        if (location.hash==="#advisors") setPeople("advisors")
    }, [location])
    const [people, setPeople] = useState(location.hash.slice(1) || "deargen")
    const [show, setShow] = useState(true)
    function onClick(people) {
        setShow(false)
        setTimeout(() =>{
            setPeople(people)
            setShow(true)
        }, 200)
    }
    const menuRender = <Flex direction="row" center style={{marginTop: 40}}>
        <MenuItem active={people==="deargen"} onClick={() => { onClick("deargen"); navigate(`${location.pathname}#deargen`) }}>
            Members
        </MenuItem>
    </Flex>


    // people cards
    const [selected, setSelected] = useState(null)
    const peopleCardsRender = <PeopleCardsRoot show={show}>
        <Flex wrap style={{marginTop: 30}}>
            {컨텐츠_Home_Team[0]
            .filter(profile => people==="advisors"
                ? profile.isAdvisor
                : !profile.isAdvisor
            )
            .map((profile, index) => (
                <CardWrapper onClick={e => setSelected(index)} key={index}>
                    <Card
                    {...profile}
                    gatsbyImageSrc={images_Home_Team[`${people==="deargen" ? "member" : "advisor"}_${index}`]} />
                </CardWrapper>
            ))}
        </Flex>

        {!isSmallDevice && <BreakLine paddingTop={40} paddingBottom={40} />}
    </PeopleCardsRoot>

    
    // right modal
    const selectedProfile = 컨텐츠_Home_Team[0]
        .filter(profile => people==="advisors"
            ? profile.isAdvisor
            : !profile.isAdvisor
        )[selected]
    const selectedMemberGatsbyImageSrc = images_Home_Team[`${people==="deargen" ? "member" : "advisor"}_${selected}`]
    const rightModalRender = <RightModal profile={selectedProfile} gatsbyImageSrc={selectedMemberGatsbyImageSrc} show={selected!==null} onClose={() => setSelected(null)} />


    // arrow button
    const arrowButtonRender = <>
        <ArrowButton position={{right: 20, bottom: 70}} direction="up" onClick={e => window.scroll({ top: 0, behavior: "smooth" })} />
        <ArrowButton position={{right: 20, bottom: 20}} direction="top" onClick={e => window.scroll({ top: document.body.clientHeight, behavior: "smooth" })} />
    </>


    return <TeamRoot>
        {headerRender}
        {menuRender}
        {peopleCardsRender}
        {rightModalRender}
        {isSmallDevice && arrowButtonRender}
    </TeamRoot>   
}