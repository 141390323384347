import { useLanguage } from "src/components/utils"

const text = {   
    ko: [
        // desktop
        [
            "Seoul Office",
            "Deargen, 15th floor, Dongwon F&B, 60, Mabang-ro, \nSeocho-gu, Seoul, Republic of Korea",
            "+82 70 4335 7236",
            "Daejeon Office",
            "R1846, 18F, 136, Cheongsa-ro, Seo-gu, \nDaejeon, Republic of Korea",
            "U.S. Office",
            "4185 SILVER PEAK PKWY, SUITE A, SUWANEE, GA, 30024, USA",
            "iDear Center",
            "(18469) 경기도 화성시 동탄첨단산업1로 27, 금강펜테리움 IX타워 A동 11층",
            `contact@deargen.me`,
        ],
        // mobile
        [
            "contact@deargen.me",
            "+82 70 4335 7236",
            "서울 연구소",
            "서울특별시 서초구 마방로 60, 동원F&B 15층",
            "대전 본사",
            "대전광역시 서구 청사로 136, 대전무역회관 18층 R1846호(월평동)",
            "미국 지사",
            "4185 SILVER PEAK PKWY, SUITE A, SUWANEE, GA, 30024, USA",
            "iDear Center",
            "(18469) 경기도 화성시 동탄첨단산업1로 27, 금강펜테리움 IX타워 A동 11층",
            "ⓒ 2021 Deargen Inc.",
        ],
    ],
    en: [
        // desktop
        [
            "Seoul Office",
            "Deargen, 15th floor, Dongwon F&B, 60, Mabang-ro, \nSeocho-gu, Seoul, Republic of Korea",
            "+82 70 4335 7236",
            "Daejeon Office",
            "R1846, 18F, 136, Cheongsa-ro, Seo-gu, \nDaejeon, Republic of Korea",
            "U.S. Office",
            "4185 SILVER PEAK PKWY, SUITE A, SUWANEE, GA, 30024, USA",
            `contact@deargen.me`,
            "iDear Center",
            "11F, 27, Dongtancheomdansaneop 1-ro, Hwaseong-si,\nGyeonggi-do, Republic of Korea",
        ],
        // mobile
        [
            "contact@deargen.me",
            "+82 70 4335 7236",
            "Seoul Office \n(Research Institute)",
            "Deargen, 15th floor, Dongwon F&B, 60, Mabang-ro, \nSeocho-gu, Seoul, Republic of Korea",
            "Daejeon Office",
            "R1846, 18F, 136, Cheongsa-ro, Seo-gu, \nDaejeon, Republic of Korea",
            "U.S. Office",
            "4185 SILVER PEAK PKWY, SUITE A, SUWANEE, GA, 30024, USA",
            "iDear Center",
            "11F, 27, Dongtancheomdansaneop 1-ro, Hwaseong-si,\nGyeonggi-do, Republic of Korea",
            "ⓒ 2021 Deargen Inc.",
        ],
    ],
}

export default function useContent() {
    const language = useLanguage()
    const 컨텐츠 = text[language]

    return { 컨텐츠 }
}