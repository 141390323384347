import _ from "lodash"
import fp from "lodash/fp"
import { useStaticQuery, graphql } from "gatsby"
import striptags from "striptags"
import { useLanguage } from "src/components/utils"


const UPDATES_CATEGORY = ["NEWS", "PROJECTS", "TECH REVIEW"]

export default function useContent() {
    const language = useLanguage()

    const { allWordpressCategory, updatesPosts } = useStaticQuery(graphql`
        query getUpdatesPosts {
            allWordpressCategory {
                edges {
                    node {
                        name
                    }
                }
            }
            updatesPosts: allWordpressPost(filter: {tags: {elemMatch: {name: {eq: "@Updates"}}}}, sort: {fields: date, order: DESC}) {
                edges {
                    previous {
                        slug
                    }
                    node {
                        ...article
                    }
                    next {
                        slug
                    }
                }
            }
        }
        fragment article on wordpress__POST {
            slug
            title
            excerpt
            date(formatString: "YYYY.MM.DD")
            category: categories {
                name
                description
            }
            tags {
                name
            }
            imgSrc: jetpack_featured_media_url
        }

    `)

    const categories = fp.flow(
        fp.map("node.name"),
        fp.filter(category => UPDATES_CATEGORY.includes(category))
    )(allWordpressCategory.edges)
    categories.unshift("ALL")
        

    const languageTag = `language:${language==="ko" ? "korean" : "english"}`
    const posts = fp.flow(
        fp.map("node"),
        // flatten tags
        fp.map(post => ({ 
            ...post,
            tags: post.tags.map(tag => tag.name)
        })),
        // filter by language
        fp.filter(post => post.tags.some(fp.includes(languageTag))),
        // strip HTML tag in excerpt
        fp.map(post => ({
            ...post,
            excerpt: striptags(post.excerpt)
        })),
        // flatten category
        fp.map(post => ({ 
            ...post,
            category: post.category[0].name
        })),
        // add `to` property using slug
        fp.map(post => ({
            ...post,
            to: `/${language}/updates/${post.slug}/`
        })),
        // if category has `href:https://example.com` tag, replace href with it
        fp.map(post => {
            if (post.tags.some(tag => tag.includes("href:"))) {
                const href = post.tags.find(tag => tag.includes("href:")).split(":").slice(1).join(":")
                return { 
                    ...post,
                    href,
                    // disable `to` prop if `href` exists
                    to:undefined
                }
            }
            return post
        })
    )(updatesPosts.edges)

    return { categories, posts }
}