import { useStaticQuery, graphql } from "gatsby"
import { useLanguage } from "src/components/utils"

const text = {   
    ko: [
        // left text
        [
            // big
            "What ",
            // big text underline
            "we can do is...",
            // small text
            "디어젠의 AI 기술은 다양한 신약개발 치료전략에 적용되고 있습니다."
        ],
        // right cards
        [
            [
                `PROTAC`,
                `프로탁 (PROTAC-Proteolysis targeting chimera)은 Ubiquitin-Proteasome System(UPS)를 유도하여 타겟 단백질을 분해 하는 기술입니다. 목표로 하는 단백질(Protein of Interest, POI)이 E1, E2, E3의 ligase에 의해 유비퀴틴(ubiquitin) 화 되면 그 부분을 인지한 프로테아좀(proteasome)에 의해 단백질 분해(Protein degradation)가 일어나는 원리입니다. 프로탁은 목적 단백질과 E3 ligase에 결합하는 ligand 그리고 이 둘을 연결하는 linker로 구성되어 있습니다. 프로탁은 기능을 억제 하는 것이 주요 목적이었던 기존 표적치료제가 갖는 약물 저항성 및 “Undruggable target” 들에 대한 문제점들을 극복하는 신약 개발의 차세대 플랫폼으로 대두되고 있습니다.\n디어젠은 고도의 AI solution을 이용해 최적화된 ligand와 linker의 구조를 발굴하고 PROTAC 분야의 선도적인 기업과의 협업을 통해 항암 표적치료제에 대한 연구를 진행하고 있습니다.`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
            [
                `ADC`,
                `ADC(Antibody-drug conjugate)는 기존의 항체보다 효능을 향상시킨 차세대 항체-약물 접합체로 항체의 특성인 타겟 특이성과 약물의 특성인 세포독성(cytotoxicity) 두 요소의 장점을 조합한 약물입니다. 구체적으로는, 암세포 특이적 항원에 결합할 수 있는 항체를 사용하여 세포독성을 야기하는 약물을 종양 세포에 전달함으로써 암세포를 타겟팅 하여 죽이는데 초점을 맞춘 테크놀로지라고 할 수 있습니다. 저희 디어젠에서 개발한 인공지능 알고리즘은 타겟 단백질과 약물 간의 친화도를 예측함으로써 타겟 단백질을 제어할 수 있는 후보 물질들을 도출해 낼 수 있습니다. 이렇게 AI solution을 통해 발굴된 물질과 종양 세포 특이적 항체와의 결합은 single agent 치료 대비 더욱 향상된 치료 효과를 제공할 수 있을 것이라 기대합니다.`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
            [
                `EPITOPE`,
                `항원결정기 (Epitope)이란 항원 (antigen) 단백질의 면역원성을 제공하는 8 – 15개 내외의 아미노산 서열로 이루어진 항원 단백질의 일부입니다. T세포, B세포, 대식세포를 포함하는 다양한 면역세포들의 TCR, BCR, MHC 등의 면역 수용체 (receptor)들이 epitope을 인식하여 다양한 면역반응으로 이어지게 됩니다. 간략한 예시로 세균 (bacteria)가 체내로 침투하게 되면, B세포가 세균의 항원에서 다양한 epiptope을 인식하고 그에 대한 항체 (antibody)를 형성하게 됩니다. Epitope을 생물학적으로 찾는 과정은 epitope scanning이라는 방식으로 antigen의 전체 아미노산 서열을 15개 내외의 단위로 잘라가며 찾는 방식입니다. 이러한 방법은 고전적이고 노동 집약적이라는 문제점을 가지고 있습니다. 디어젠은 파트너사 옵티팜과 함께 epitope을 예측하는 인공지능 모델을 만들어 epitope scanning을 in silico로 구현하였으며, 현재 암 치료 백신, epitope library 구축 등의 연구개발을 진행하고 있습니다.`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
            [
                `AGONIST`,
                `Agonist란 특정한 타깃 단백질을 활성화 (activation)하는 물질들을 의미합니다. 쉽게는 inhibitor와 반대되는 개념으로 볼 수 있습니다. 지금까지의 디어젠의 drug-target interaction (DTI) 는 물질과 타깃 단백질의 결합 친화도 (affinity)를 기반으로 예측했기에 inhibition과 activation activity를 구분하기에는 어려운 부분이 있었습니다. 또한, 신약 개발 분야에서 agonist hit discovery에 대한 수요도 늘어가는 추세에 있습니다. 이러한 unmet needs를 해소하기 위해 디어젠은 물질과 타깃 단백질의 activation을 예측할 수 있는 DearDTE (Drug-Target Efficacy)를 개발하였습니다. DearDTE는 현재 SK Chemical, iN Therapeutics, Amyloid Solution, 포항공대 등 다양한 파트너와의 협력에 활용하고 있습니다.`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
            [
                `INHIBITOR`,
                `질병의 원인이 되는 단백질의 기능을 저해 혹은 억제 하기 위해서 많은 제약사들은 inhibitor(저해제)라고 하는 약물을 개발합니다. 인체에 존재하는 2만여 개의 단백질들은 제각각 고유의 기능들을 가지고 있습니다. 단백질의 기능에 따라 효소 단백질, 이온 채널 단백질, 운반 단백질, 방어 단백질, 구조 단백질 등으로 다양하게 구분을 지어 볼 수 있습니다. 이러한 단백질들의 기능에 문제가 생기면 질병을 야기하게 됩니다. 이때 우리는 약물로써 단백질의 기능을 제어합니다. 약물이 타겟 단백질을 직접적으로 제어하기 위해서는 우선적으로 약물과 단백질의 결합이 이루어져야 합니다. 디어젠의 인공지능 solution은 세상에 존재하는 수십, 수백만 개의 약물과 바이러스부터 인간에 이르기까지 모든 개체를 아우르는 단백질 사이의 결합력을 예측할 수 있습니다. 이러한 예측 결과를 통해 타겟 단백질에 결합하여 직접적으로 영향을 많이 미칠 수 있는 약물들을 선별해낼 수 있고, 추후 검증을 통해 저해제 발굴의 속도를 가속화 할 수 있습니다.`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
        ],
        // right cards learn more
        "Learn\nMore\nOur\nPipeline"
    ],
    en: [
        // left text
        [
            // big
            "What ",
            // big text underline
            "we can do is...",
            // small text
            "Deargen’s A.I technology is being applied to\nvarious new drug development treatment strategies."
        ],
        // right cards
        [
            [
                `PROTAC`,
                `PROTAC-Proteolysis targeting chimera is a technology that induces the Ubiquitin-Proteasome System (UPS) to degrade the target protein. When the target protein (Protein of Interest (POI)) is ubiquitinated by the ligase of E1, E2, E3, the proteasome recognizes that part of the protein and degrades the protein. PROTAC is composed of a protein of interest, a ligand that binds to the E3 ligase, and a linker that connects the two. PROTAC is emerging as a next-generation platform for new drug development that overcomes the problems of drug resistance and “Undruggable targets” of existing target therapies whose main purpose was to inhibit the function.\nDeargen discovers the structure of an optimized ligand and linker using a highly advanced AI solution, and is conducting research on anticancer target treatments through collaboration with leading companies in the PROTAC field.`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
            [
                `ADC`,
                `ADC (Antibody-drug conjugate) is a next-generation antibody-drug conjugate that has improved efficacy over existing antibodies.  This drug combines the advantages of two factors: target specificity, which is a characteristic of an antibody, and cytotoxicity, which is a characteristic of a drug. Specifically, this is a technology focused on targeting and killing cancer cells by delivering drugs that cause cytotoxicity to tumor cells using antibodies that can bind to cancer cell-specific antigens. The Artificial Intelligence algorithm developed by Deargen can derive candidate substances that can control the target protein by predicting the affinity between the target protein and the drug. It is expected that the binding of the substance discovered through the AI ​​solution to the tumor cell-specific antibody will provide more improved therapeutic effect compared to single agent treatment.`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
            [
                `EPITOPE`,
                `Epitope is a part of an antigenic protein consisting of a sequence of 8 to 15 amino acids that provide the immunogenicity of an antigen protein. Immune receptors such as TCR, BCR, and MHC of various immune cells including T cells, B cells, and macrophages recognize epitopes and lead to various immune responses. As a simple example, when a bacteria enters the body, B cells recognize various epiptopes from the bacterial antigen and form antibodies against them. The process of biologically searching for epitopes is a method called epitope scanning, which cuts and searches the entire amino acid sequence of antigen into units of about 15 or more. This method has the problem of being classic and labor intensive. Deargen, together with its partner Optipharm, created an Artificial Intelligence model that predicts epitope and implemented epitope scanning in silico, and is currently conducting research and development such as cancer treatment vaccine and epitope library construction.`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
            [
                `AGONIST`,
                `Agonist refers to substances that activate a specific target protein. It can easily be viewed as the opposite of inhibitor. Until now, Deargen's drug-target interaction (DTI) was predicted based on the binding affinity between the substance and the target protein, which causes barrier to distinguish between the inhibition and activation activity. In addition, the demand for agonist hit discovery is on the rise in the field of new drug development. To address these unmet needs, Deargen developed DearDTE (Drug-Target Efficacy), which can predict the activation of substances and target proteins. DearDTE is currently being used in cooperation with various partners such as SK Chemical, IN Therapeutics, Amyloid Solution, and Pohang University of Science and Technology`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
            [
                `INHIBITOR`,
                `To hinder or inhibit the function of proteins that cause disease, many pharmaceutical companies develop drugs called inhibitors. Each of the 20,000 proteins in the human body has its own unique functions. Depending on the function of the protein, it can be classified into enzyme proteins, ion channel proteins, transport proteins, defense proteins, and structural proteins. Problems with the functioning of these proteins can lead to disease. This function of protein can be controlled by of a drug. In order for a drug to directly control a target protein, the drug and protein must first be bound. Deargen's Artificial Intelligence solution is able eto predict the binding affinity between dozens and millions of drugs in the world and proteins that span all organisms, from viruses to humans. Through these prediction results, Deargen help to select the drugs that has direct effect on target protein, and in-depth validation can aid the discovery of inhibitors.`,
                `https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/`,
        
            ],
        ],
        // right cards learn more
        "Learn\nMore\nOur\nPipeline"
    ],
}


export default function useContent() {
    const language = useLanguage()
    const 컨텐츠 = text[language]

    const images = useStaticQuery(graphql`
        query {
            pipeline_0: file(relativePath: { eq: "Home_Pipeline/PROTAC.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 120) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            pipeline_1: file(relativePath: { eq: "Home_Pipeline/adc_payload.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 120) {
                        ...GatsbyImageSharpFixed
                    }
                }
                
            }
            pipeline_2: file(relativePath: { eq: "Home_Pipeline/epitope.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 120) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            pipeline_3: file(relativePath: { eq: "Home_Pipeline/agonist.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 120) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            pipeline_4: file(relativePath: { eq: "Home_Pipeline/inhibitor.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 120) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return { images, 컨텐츠 }
}