import { useState, useEffect } from 'react'
import _ from "lodash"
export { useInView } from "react-intersection-observer"


export function useDeviceName() {
    const [deviceName, setDeviceName] = useState("")

    useEffect(() => {
        const getDeviceName = (width) => {
            if (!typeof window === `undefined`) return

            if (width < 576) return "mobile"
            else if (width < 800) return "tablet"
            else if (width < 1200) return "desktop"
            else if (width < 1600) return "big desktop"
            else return "big big desktop"
        }
        setDeviceName(getDeviceName(window.innerWidth));
        const handler = e => { 
            setDeviceName(getDeviceName(window.innerWidth))
        }

        window.addEventListener("resize", handler);
        return () => { window.removeEventListener(window, handler); }
    })

    return deviceName
}

export function useIsDeviceWidth({ from=0, to=1920 }) {
    const { innerWidth } = useWindowSize()

    return _.inRange(innerWidth, from, to)
}

export function useWindowSize() {
    const [innerHeight, setInnerHeight] = useState(0)
    const [innerWidth, setInnerWidth] = useState(0)
    const [offsetHeight, setOffsetHeight] = useState(0)

    useEffect(() => {
        if (!typeof window === `undefined`) return
        
        const handler = e => { 
            setInnerHeight(window.innerHeight)
            setInnerWidth(window.innerWidth)
            setOffsetHeight(window.document.body.offsetHeight)
        }

        window.addEventListener("resize", handler);
        return () => { window.removeEventListener(window, handler); }
    })

    return { innerHeight, innerWidth, offsetHeight }
}

export function useMousePosition() { 
    const [ref, setRef] = useState(null)
    const calculatePosition = (element, mouseEvent) => {
        const { clientX, clientY } = mouseEvent;
        const { left, top, width, height } = element.getBoundingClientRect();
        const 
        x = clientX - left,
        y = clientY - top
        return {
            x,
            y,
            ratioX: x / width,
            ratioY: y / height,
        }
    }
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0, ratioX: 0, ratioY: 0});
    useEffect(() => {
        if (!ref) return
        
        const handleMouseMove = _.throttle(e => {
            setMousePosition(calculatePosition(ref, e));
        }, 50);
        ref.addEventListener("mousemove", handleMouseMove);
        return () => {
            ref.removeEventListener("mousemove", handleMouseMove)
        }
    }, [ref]);
    return [setRef, mousePosition]
}

// see https://github.com/streamich/react-use/blob/master/src/useWindowScroll.ts
export function useWindowScroll() {
    const [state, setState] = useState({ x: 0, y: 0 })

    useEffect(() => {
        if (!window) return
        
        const handler = () => {
            setState({
                x: window.pageXOffset,
                y: window.pageYOffset,
            });
        }

        window.addEventListener("keydown", handler);
        return () => { window.removeEventListener(window, handler); }
    }, [typeof window !== `undefined` && window])

    return state
}

// original git repository is here https://github.com/alvarotrigo/fullPage.js/blob/master/src/fullpage.js
// WARNING: this code is NOT free license. don't expose below code to outside.
let prevTime = new Date().getTime();
let canScroll = true;
let scrollings = [];

export function useMouseWheelHandler(props) {
    useEffect(() => {
        const {onWheelUp, onWheelDown, wait=800} = props

        // see https://github.com/alvarotrigo/fullPage.js/blob/master/src/fullpage.js#L1481
        function getAverage(elements, number){
            var sum = 0;

            //taking `number` elements from the end to make the average, if there are not enought, 1
            var lastElements = elements.slice(Math.max(elements.length - number, 1));

            for(var i = 0; i < lastElements.length; i++){
                sum = sum + lastElements[i];
            }

            return Math.ceil(sum/number);
        }
        // see https://github.com/alvarotrigo/fullPage.js/blob/master/src/fullpage.js#L1500
        function MouseWheelHandler(e) {
            // do not trigger when this is pinch-zoom. https://stackoverflow.com/questions/29929411/disable-pinch-zoom-in-webkit-or-electron
            if (e.ctrlKey) return 

            let curTime = new Date().getTime();

            // normalize delta. 1 means scrolling-up, -1 means scrolling-down.
            let scrollDirection = Math.max(-1, Math.min(1, -e.deltaY));

            //Limiting the array to 150 (lets not waste memory!)
            if (scrollings.length >= 150) {
                scrollings.shift();
            }

            //keeping record of the previous scrollings
            scrollings.push(Math.abs(e.deltaY));

            //time difference between the last scroll and the current one
            let timeDiff = curTime-prevTime;
            prevTime = curTime;
            
            //haven't they scrolled in a while?
            //(enough to be consider a different scrolling action to scroll another section)
            if (timeDiff > 200) {
                //emptying the array, we dont care about old scrollings for our averages
                scrollings = [];
            }

            if (canScroll) {
                let averageEnd = getAverage(scrollings, 10);
                let averageMiddle = getAverage(scrollings, 70);
                let isAccelerating = averageEnd >= averageMiddle;

                //to avoid double swipes...
                if (isAccelerating) {
                    canScroll = false
                    setTimeout(() => {
                        canScroll = true;
                    }, wait)
                    
                    //scrolling down?
                    if (scrollDirection < 0) {
                        onWheelDown();

                    //scrolling up?
                    } else {
                        onWheelUp();
                    }
                }
            }
            return false;
        }

        // see https://github.com/alvarotrigo/fullPage.js/blob/master/src/fullpage.js#L3052
        function addMouseWheelHandler() {
            var prefix = '';
            var _addEventListener;

            if (window && window.addEventListener){
                _addEventListener = "addEventListener";
            }else{
                _addEventListener = "attachEvent";
                prefix = 'on';
            }

            // detect available wheel event
            var support = 'onwheel' in document.createElement('div') ? 'wheel' : // Modern browsers support "wheel"
                document.onmousewheel !== undefined ? 'mousewheel' : // Webkit and IE support at least "mousewheel"
                'DOMMouseScroll'; // let's assume that remaining browsers are older Firefox

            if(support == 'DOMMouseScroll'){
                document[ _addEventListener ](prefix + 'MozMousePixelScroll', MouseWheelHandler, {passive: false });
            }

            //handle MozMousePixelScroll in older Firefox
            else {
                document[ _addEventListener ](prefix + support, MouseWheelHandler, {passive: false });
            }
        }

        // see https://github.com/alvarotrigo/fullPage.js/blob/master/src/fullpage.js#L3037
        function removeMouseWheelHandler() {
            if (document.addEventListener) {
                document.removeEventListener('mousewheel', MouseWheelHandler, false); //IE9, Chrome, Safari, Oper
                document.removeEventListener('wheel', MouseWheelHandler, false); //Firefox
                document.removeEventListener('MozMousePixelScroll', MouseWheelHandler, false); //old Firefox
            } else {
                document.detachEvent('onmousewheel', MouseWheelHandler); //IE 6/7/8
            }
        }

        addMouseWheelHandler()
        return removeMouseWheelHandler
    }, [props])
}

export function useKeypress({keycode, callback}) {
    useEffect(() =>{
        if (!typeof window === `undefined`) return
        
        const handler = (event) => { 
            if (event.code===keycode) callback(); 
        }

        window.addEventListener("keydown", handler);
        return () => { window.removeEventListener("keydown", handler); }
    }, [window])
}

export function useLanguage() {
    const [state, setState] = useState("en")

    useEffect(() => {
        if (!typeof window === `undefined`) return
        
        const { href, host } = window.location
        if (href.includes(`${host}/ko`)) setState("ko")
        if (href.includes(`${host}/en`)) setState("en")
    })

    return state
}
