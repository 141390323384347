import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import { HexagonGrid, Button, H1, P, Strong } from "src/components";
import { useKeypress, BreakLine, useLanguage } from "src/components/utils";
import {} from "styled-components-breakpoint";
import DetailHexagon from "./_DetailHexagon";
import useContents from "src/data";

const TeamRoot = styled.div`
    ${(props) => props.theme.css.flexCenter("column")}
    ${(props) => props.theme.css.full}
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 0.4s;
    padding: 50px 2em 0;
`;
const LayoutContainer = styled.div`
  position: relative;
  height: 800px;
  width: 100%;
  max-width: 1400px;
`;
const Header = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);

  ${H1} {
    font-weight: 300;
    font-size: 4rem;
  }
  ${Strong} {
    font-weight: 600;
  }
`;
const Grid = styled.div`
  position: absolute;
  right: 0;
  top: 110px;
`;
const Detail = styled.div`
  ${(props) => props.theme.css.full}
  position: absolute;
  top: -100px;
  transition: transform 0.2s;
  transform: scale(${(props) => (props.active ? 1 : 0)});
`;
const Fade = styled.div`
  transition: opacity 0.2s;
  opacity: ${(props) => (props.fade ? 0.1 : 1)};
`;

export default function Team({ slidePhase }) {
  const [show, setShow] = useState(false);
  const { images, 컨텐츠 } = useContents("Home_Team");
  const language = useLanguage();

  // hexagon grid config
  const dummyHexagonConfig = {
    dummy: true,
    config: {
      backgroundColorHovered: "lightgray",
      backgroundColorNotHovered: "lightgray",
      border: "1px solid transparent",
    },
  };
  const hexagonGridConfig = {
    size: 80,
    gridColumn: 4,
    gap: 9,
    buttonsProps: [
      null,
      ..._.range(0, 3).map((i) => ({ gatsbyImageSrc: images[`member_${i}`] })),
      null,
      ..._.range(3, 6).map((i) => ({ gatsbyImageSrc: images[`member_${i}`] })),
      ..._.range(6, 8).map((i) => ({ gatsbyImageSrc: images[`member_${i}`] })),
    ],
  };

  // header render
  const headerRender = (
    <Header>
      <H1>
        {컨텐츠[1][0][0]}
        <Strong>{컨텐츠[1][0][1]}</Strong>
        {컨텐츠[1][0][2]}
      </H1>
      <P>{컨텐츠[1][1]}</P>
      <BreakLine paddingBottom={30} paddingTop={30} />
      <Button text={컨텐츠[1][2]} to={`/${language}/team/`} />
    </Header>
  );

  // choose which profile to show in detail hexagon
  const [selected, setSelected] = useState(null);
  let index = 0;
  hexagonGridConfig.buttonsProps.map((buttonProps) => {
    if (!buttonProps) return;
    if (buttonProps.dummy) return;

    const i = index++;
    buttonProps.onClick = () => {
      setSelected(i);
    };
  });
  useKeypress({
    keycode: "Escape",
    callback() {
      setSelected(null);
    },
  });

  // detail hexagon render
  const profiles = 컨텐츠[0];
  const size = 350;
  const gatsbyImageSrc = images[`member_${selected}`];
  const detailRender = (
    <Detail active={selected !== null} onClick={(e) => setSelected(null)}>
      <DetailHexagon
        gatsbyImageSrc={gatsbyImageSrc}
        size={size}
        {...profiles[selected]}
      />
    </Detail>
  );

  // hexagon grid
  const hexagonGridRender = (
    <Grid>
      <Fade fade={selected !== null}>
        <HexagonGrid {...hexagonGridConfig} />
      </Fade>
      {detailRender}
    </Grid>
  );

  // response to slide change
  useEffect(() => {
    switch (slidePhase) {
      case "movingIn":
        setTimeout(() => {
          setShow(true);
        }, 800);

        break;
      case "movingOut":
        setTimeout(() => {
          setShow(false);
        }, 400);
        break;
    }
  }, [slidePhase]);

  return (
    <TeamRoot show={show}>
      <LayoutContainer>
        {headerRender}
        {hexagonGridRender}
      </LayoutContainer>
    </TeamRoot>
  );
}
