import React from "react"
import styled from "styled-components"

const Strong = styled(
    ({ size, color, weight, align, marginTop, whiteSpace, lineHeight, hoverColor, lineLimit, children, ...props }) => <strong {...props}>{children}</strong>
)`
    font-size: ${props => props.size 
        ? typeof props.size === "number"
            ? `${props.size}px`
            : props.size
        : "inherit"
    };

    color: ${props =>{ 
        if (props.color==="primary") return  props.theme.primary
        if (props.color==="secondary") return  props.theme.secondary

        return props.color 
            ? props.color
            : "inherit"
    }};

    font-weight: ${props => props.weight 
        ? props.weight 
        : 900
    };

    text-align: ${props => props.align 
        ? props.align 
        : "inherit"
    };

    margin-top: ${props => props.marginTop 
        ? props.marginTop 
        : 0
    }px;

    white-space: ${props => props.whiteSpace
        ? props.whiteSpace
        : "pre-wrap"
    };

    line-height: ${props => props.lineHeight
        ? props.lineHeight
        : "1.5"
    };

    transition: all 0.2s;
    &:hover {
        color: ${props => {
            if (props.hoverColor==="primary") return  props.theme.primary
            if (props.hoverColor==="secondary") return  props.theme.secondary

            const defaultHoverColor = props.color
            return props.hoverColor 
                ? props.hoverColor
                : defaultHoverColor
        }}
    };

    ${props => props.customCss};
`

export default Strong