import React from 'react'
import { useIsDeviceWidth } from 'src/components/utils'
import Footer from './Footer'
import MobileFooter from './MobileFooter'

export default function({ location }) {
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })
    const isHome = ["/", "/en", "/ko", "/en/", "/ko/"].includes(location.pathname)

    return isSmallDevice
        ? <MobileFooter location={location} />
        : isHome
            ? null 
            : <Footer />
}
