import { useStaticQuery, graphql } from "gatsby"
import { useLanguage } from "src/components/utils"

const text = {   
    ko: [
        [
            `\nAI-First Pharmatech`,
            `\nTransforming the future of treating the\nundruggables with AI`,
        ]
    ],
    en: [
        [
            `\nAI-First Pharmatech`,
            `\nTransforming the future of treating the\nundruggables with AI`,
        ]
    ]
}


export default function useContent() {
    const language = useLanguage()
    const 컨텐츠 = text[language]

    const images = useStaticQuery(graphql`
        query {
            home_slogan_logo: file(relativePath: { eq: "Home_Slogan/home_slogan_logo.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            big_capsule: file(relativePath: { eq: "Home_Slogan/big_capsule.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            gene: file(relativePath: { eq: "Home_Slogan/gene.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return { images, 컨텐츠 }
}