import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from "styled-components"
import Flex, {  } from "styled-flex-component"
import breakpoint from "styled-components-breakpoint"
import { P, Strong } from "src/components"
import { fadeIn } from "src/components/utils"
import GoogleMap from "./_GoogleMap"
import ContactFooter from "./_ContactFooter"
import useContents from "src/data"

// TODO: Apply styled-component-flex to this component
const ContactRoot = styled.div`
    ${props => props.theme.css.flexCenter()}
    ${props => props.theme.css.full}
`

const LayoutContainer = styled(Flex)`
    position: absolute;
    top: 20%;
    box-sizing: border-box;
    padding: 0 1rem;

    height: 100%;
    max-height: 400px;
    width: 100%;
    max-width: 800px;
`

// description
const DescriptionRoot = styled.div`
    flex: 1;
    ${props => props.theme.css.full};
    ${props => props.theme.css.flexCenter("row")};
    ${props => props.customCss};
    justify-content: start;
    opacity: 0;

    ${Strong} {
        display: block;
        padding 0.5rem 0;
    }

    ${breakpoint("mobile", "desktop")`
        font-size: 1rem;
        text-align: center;
        justify-content: center;
    `}
`

const Description = ({ where, show }) => {
    let name, address, introAnimation

    // get contents
    const { 컨텐츠 } = useContents("Home_Contact")

    if (where==="kaist") {
        name = 컨텐츠[0][0]
        address = 컨텐츠[0][1]
        introAnimation = show 
            ? fadeIn({ mode: "fromLeft" }) 
            : "none"
    }
    if (where==="seoulOffice") {
        name = 컨텐츠[0][2]
        address = 컨텐츠[0][3]
        introAnimation = show 
            ? fadeIn({ mode: "fromLeft" })
            : "none"
    }
    if (where==="iDearCenter") {
        name = 컨텐츠[0][4]
        address = 컨텐츠[0][5]
        introAnimation = show 
            ? fadeIn({ mode: "fromLeft" })
            : "none"
    }
    if (where==="usOffice") {
        name = 컨텐츠[0][6]
        address = 컨텐츠[0][7]
        introAnimation = show 
            ? fadeIn({ mode: "fromLeft" })
            : "none"
    }

    return <DescriptionRoot customCss={css`animation: ${introAnimation}`}>
        <P>
            <Strong>{name}</Strong>      
            <P customCss={`color: gray;`}>
                {address}
            </P>
        </P>
    </DescriptionRoot>
}

const MapAndDescription = ({mapRender, descriptionRender}) => {
    return <Flex full column>
        {mapRender}
        {descriptionRender}
    </Flex>
}

const Gutter = styled.div`width: 40px;`


export default function Contact({ slidePhase }) {
    const [show, setShow] = useState(false)

    // refs
    const kaistGoogleMapRef = useRef()
    const seoulOfficeGoogleMapRef = useRef()
    const iDearCenterGoogleMapRef = useRef()
    const usOfficeGoogleMapRef = useRef()

    // map
    const kaistMap = <GoogleMap where="kaist" ref={kaistGoogleMapRef} />
    const seoulOfficeMap = <GoogleMap where="seoulOffice" ref={seoulOfficeGoogleMapRef} />
    const iDearCenterMap = <GoogleMap where="iDearCenter" ref={iDearCenterGoogleMapRef} />
    const usOfficeMap = <GoogleMap where="usOffice" ref={usOfficeGoogleMapRef} />

    // description
    const kaistDescription = <Description where="kaist" show={show} />
    const seoulOfficeDescription = <Description where="seoulOffice" show={show} />
    const iDearCenterDescription = <Description where="iDearCenter" show={show} />
    const usOfficeDescription = <Description where="usOffice" show={show} />

    // map and description
    const kaist = <MapAndDescription mapRender={kaistMap} descriptionRender={kaistDescription} />
    const seoulOffice = <MapAndDescription mapRender={seoulOfficeMap} descriptionRender={seoulOfficeDescription} />
    const iDearCenter = <MapAndDescription mapRender={iDearCenterMap} descriptionRender={iDearCenterDescription} />
    const usOffice = <MapAndDescription mapRender={usOfficeMap} descriptionRender={usOfficeDescription} />

    // render
    const render = <>
        {kaist}
        <Gutter />
        {seoulOffice}
        <Gutter />
        {iDearCenter}
        <Gutter />
        {usOffice}
    </>


    // response to slide change
    useEffect(() => {
        switch (slidePhase) {

            case "movingIn":
                setTimeout(() => {
                    setShow(true)
                }, 1000)

                setTimeout(() => {
                    if (!kaistGoogleMapRef.current || !seoulOfficeGoogleMapRef.current || !iDearCenterGoogleMapRef.current || !usOfficeGoogleMapRef.current) return
                    kaistGoogleMapRef.current.attachMarker()
                    seoulOfficeGoogleMapRef.current.attachMarker()
                    iDearCenterGoogleMapRef.current.attachMarker()
                    usOfficeGoogleMapRef.current.attachMarker()
                }, 1400)
                break

            case "movingOut":
                setTimeout(() => {
                    if (!kaistGoogleMapRef.current || !seoulOfficeGoogleMapRef.current || !iDearCenterGoogleMapRef.current || !usOfficeGoogleMapRef.current) return
                    kaistGoogleMapRef.current.clearMarker()
                    seoulOfficeGoogleMapRef.current.clearMarker()
                    iDearCenterGoogleMapRef.current.clearMarker()
                    usOfficeGoogleMapRef.current.clearMarker()
                    setShow(false)
                }, 400)
            break
            
        }
    }, [slidePhase])


    return <ContactRoot>
        <LayoutContainer show={show}>
            {render}
        </LayoutContainer>
        <ContactFooter />
    </ContactRoot>
}