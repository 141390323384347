import React from 'react'
import styled from "styled-components"
import Flex from "styled-flex-component"
import { GrClose } from "react-icons/gr"
import breakpoint from "styled-components-breakpoint"
import { P, Img } from "src/components"
import { useIsDeviceWidth } from "src/components/utils"  



const CardRoot = styled.div`
    // when some card is selected, non-selected cards's z-index is 0, blur background is 1, selected card is 2
    z-index: ${props => props.active ? 2 : 0};
    left: ${props => props.left}px;
    top: ${props => props.top}px;
    height: 200px;
    background: ${props => props.active ? "#e6f1ff" : "#e6f1ff" };
    width: ${props => props.active ? "200px" : "calc(33% - 10px)"};
    // 2*CardHeight + 4*CardMargin
    height: ${props => props.active ? "calc(400px + 20px)" : "200px"};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: 0.2s;
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 5px;
    box-sizing: border-box;

    &:hover {
        border: 1px solid ${props => props.active ? "transparent" : props.theme.secondary};
        cursor: pointer;
    }
    
    ${breakpoint("mobile", "desktop")`
        height: 180px;
        width: ${props => props.active ? "100%" : "calc(50% - 10px)"};
    `}
`
const ImageWrapper = styled(Flex)`
    margin-bottom: 40px;
`
const Text = styled(P)`
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    bottom: 20px;
`
const CloseIcon = styled.div`
    opacity: ${props => props.show ? 1 : 0};
`

export default function _Card({ text, gatsbyImageSrc, onClick, active, hide, left, top }) {
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800})

    // 모바일에서는 어짜피 아무데나 클릭하면 active = false 되기때문에 onClick 없이 그냥 버튼만 갖다놓는다
    const CloseIconRender = isSmallDevice && <CloseIcon show={active}>
        <GrClose color="black" size={30} style={{position:"absolute", top: 20, right: 20, cursor: "pointer" }}/>
    </CloseIcon>

    return (
        <CardRoot active={active} onClick={onClick} hide={hide} left={left} top={top}>
            {CloseIconRender}
            <ImageWrapper center>
                <Img gatsbyImageSrc={gatsbyImageSrc}/>
            </ImageWrapper>
            <Text>{text}</Text>
        </CardRoot>
    )
}
