import React, {  } from 'react'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Flex, {  } from 'styled-flex-component';
import { FaAngleRight } from "react-icons/fa"
import { H1, P, Img, HexagonButton } from 'src/components';
import { useIsDeviceWidth, useLanguage } from 'src/components/utils';
import { mainTheme } from "src/GlobalStyle"
import useContents from 'src/data';


const RecruitProcessRoot = styled.section`
    ${props => props.theme.css.flexCenter("column")}

    position: relative;
    margin-top: 100px;

    ${breakpoint("mobile", "desktop")`
        margin: 0;
    `}
`
const Background = styled(Flex)`
    position: relative;
    padding-top: ${props => props.paddingTop}px;
    padding-bottom: ${props => props.paddingBottom}px;
    background: ${props => props.color};
`
const Content = styled(Flex)`
    width: 800px;

    ${breakpoint("mobile", "desktop")`
        padding: 0 20px;
    `}
`
const FiveProcessItem = styled(Flex)`
    flex: 1;
    position: relative;
    margin-top: 40px;
    justify-content: start;

    ${breakpoint("mobile", "desktop")`
        flex: none;
        width: 33%;
    `}
`


export default function Career() {
    const { images, 컨텐츠 } = useContents("CareerDetail")
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })
    const language = useLanguage()


    // header
    const headerRender = <H1 
    color={isSmallDevice ? "lightgray" : "white"}
    size={isSmallDevice ? 22 : 30} 
    weight={isSmallDevice ? 100 : 600}
    customCss={`width: 100%;`}>
        {language==="ko" ? `채용절차` : "How to join"}
    </H1>


    // five process
    const pictureRender = <Flex full wrap justifyBetween={!isSmallDevice}>
        {컨텐츠.RecruitProcess[0].map((text, index) => {
            const last = index === 컨텐츠.RecruitProcess[0].length - 1

            return <FiveProcessItem
            key={index}
            column
            center>
                <Img gatsbyImageSrc={images[`recruit_process_${index}`]} />
                <P weight={700} marginTop={10} color="secondary" align="center">
                    {text}
                </P>
                {!last && <FaAngleRight color="#517f9c" style={{position: "absolute", right: -10, top: "40%"}} />}
            </FiveProcessItem>
        })}
    </Flex>


    // upper render
    const upperRender = <>
        {headerRender}
        {pictureRender}
    </>


    // lower render
    const warnningIcon = isSmallDevice
        ? <Img gatsbyImageSrc={images.recruit_process_notice} customCss={`position: absolute; left: 30px; top: -20px;`} />
        : <Flex center>
            <Img gatsbyImageSrc={images.recruit_process_notice} />
        </Flex>

    const warnningText = <P
        size={11}
        color="#c5d8e4"
        style={{
            marginLeft: isSmallDevice ? 0 : 50,
            lineHeight: 2,
        }}>
            {컨텐츠.RecruitProcess[1][0]}
        </P>

    const lowerRender = <Flex column={isSmallDevice}>
        {warnningIcon}
        {warnningText}
    </Flex>


    // Hexagons
    const hexagonsRender = isSmallDevice
        ? null  
        : <>
            {/* left bottom small */}
            <HexagonButton
            size={40}
            color={mainTheme.secondary}
            config={{
                backgroundColorNotHovered: mainTheme.secondary,
            }}
            customCss={`position: absolute; left: 10%; bottom: -50px;`}/>

            {/* top right big */}
            {/* <HexagonButton
            size={100}
            color={mainTheme.secondary}
            config={{
                backgroundColorNotHovered: mainTheme.secondary,
            }}
            customCss={`position: absolute; right: 5%; top: -100px;`}/> */}
        </>


    return <RecruitProcessRoot>
        <Background full center color={mainTheme.primary} paddingTop={isSmallDevice ? 30 : 70} paddingBottom={70}>
            <Content center column>
                {upperRender}
            </Content>
        </Background>

        <Background full center color={mainTheme.primaryLight} paddingTop={35} paddingBottom={35}>
            <Content center>
                {lowerRender}
            </Content>
        </Background>

        {hexagonsRender}
    </RecruitProcessRoot>
}
