import { useStaticQuery, graphql } from "gatsby"

export default function useContent() {
    const images = useStaticQuery(graphql`
    query {
        deargen_charactor: file(relativePath: { eq: "UnderConstruction/deargen_charactor.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`)

    return { images }
}