import { useStaticQuery, graphql } from "gatsby"
import { useLanguage } from "src/components/utils"

const text = {
    ko: [
        // 문구
        [
            `We make `,
            `it`,
            ` Together`,
            `디어젠과 함께 신약개발의 한계를 뛰어넘을\n디어벤져스를 찾습니다.`,
            `지원하기`,
            `Who is `,
            `Dearvengers`,
            `디어젠은 생물학 생물정보학, 딥러닝, 소프트웨어 전문가가 함께 정밀의료 시대를 만들어 갑니다.\n디어젠의 어벤져스 팀은 누구보다 빠르게 행동하고, 끊임없이 새로운 도전을 향해 움직입니다.`,
        ],
        // 상단 왼쪽 육각형
        [
            `자유와 책임`,
            `주어진 자유를 즐길 수 있으며 자유에 따른 책임을 갖고 일합니다.`,
        ],
        // 상단 오른쪽 육각형
        [
            `배움과 성장`,
            `스스로 동기를 부여하여 다양한 배움을 즐기고, 성장하는 것을 좋아합니다.`,
        ],
        // 하단 육각형
        [
            `존중과 협동`,
            `각 팀원을 존중하고 신뢰하며 함께 성장합니다.`,
        ],
    ],
    en: [
        // 문구
        [
            `We make `,
            `it`,
            ` Together`,
            `We are looking for Dearvaners who will push the boundaries of Drug Discovery together.`,
            `Apply`,
            `Who is `,
            `Dearvengers`,
            `We realize Precision Medicine together with experts in Deep learning, Software, Biology and Bioinformatics.\nOur brilliant Avengers team acts faster than anyone else and consistently moves to face new challenges.`,
        ],
        // 상단 왼쪽 육각형
        [
            `Freedom and Responsibility`,
            `Enjoy your freedom and take responsibility for your freedom.`,
        ],
        // 상단 오른쪽 육각형
        [
            `Learn and Grow`,
            `Motivate yourself, enjoy a variety of learning and love to grow.`,
        ],
        // 하단 육각형
        [
            `Respect and collaboration`,
            `Respect your team members, trust them and grow together.`,
        ],
    ]
}


export default function useContent() {
    const language = useLanguage()
    const 컨텐츠 = text[language]

    const images = useStaticQuery(graphql`
        query {
            # desktop
            balloon: file(relativePath: { eq: "Career/balloon.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            school: file(relativePath: { eq: "Career/school.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            heart: file(relativePath: { eq: "Career/heart.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 90) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            # mobile !TODO: 이런 단순무식한 복붙이 아니라 변수같은거 줘서 코드량 줄일수 있을거같은데 나중에 알아봐야됨
            balloon_mobile: file(relativePath: { eq: "Career/balloon.png" }) {
                childImageSharp {
                    fixed(width: 60, height: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            school_mobile: file(relativePath: { eq: "Career/school.png" }) {
                childImageSharp {
                    fixed(width: 60, height: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            heart_mobile: file(relativePath: { eq: "Career/heart.png" }) {
                childImageSharp {
                    fixed(width: 60, height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)


    return { images, 컨텐츠 }
}