import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import Flex from "styled-flex-component"

const ModalPortalRoot = styled(Flex)`
    position: fixed;
    top: 0;
    left: 0;
    ${/* `re-carousel`'s z-index is 100. so this z-index must be more than that. */""}
    z-index: 110;
    background: rgba(0, 0, 0, 0.9);
`

export default function ModalPortal({ children }) {
    const render = <ModalPortalRoot full center>{children}</ModalPortalRoot>;
    
    return ReactDOM.createPortal(
        render,
        document.getElementById("___modal")
    )
}
