import { useStaticQuery, graphql } from "gatsby"
import { useLanguage } from "src/components/utils"

const text = {
    ko: {
        RecruitBoard: [
            [
                // title
                `Machine Learning Engineer`,
                // content
                `
                주요업무
                • 신약 개발 인공지능 모델 구현 및 관련 데이터 전처리
                • 기 구축 된 인공 지능 모델 튜닝 및 성능 개선
                • 멀티 GPU/TPU를 사용한 딥러닝 모델 최적화
                • 인공지능 최신 논문 서치 및 세미나, 관련 내용 구현
                
                자격요건
                • 빠르게 성장하는 스타트업에서 능동적이고 자기주도적으로 업무를 꼼꼼하게 처리 하시는 분
                • 머신러닝 / 딥러닝 관련 프로젝트를 하나 이상 end-to-end로 수행해 보신분
                • 머신러닝 / 딥러닝 관련 오픈 소스 및 관련 논문 파악에 무리가 없으신 분
                • 기본적인 머신러닝 / 딥러닝 이론을 알고 계신 분
                • 여러 딥러닝 프레임워크 및 python 등 코드 구현에 무리가 없으신 분
                • 문제에 봉착했을 때 이를 끝까지 해결해내는 근성을 가지신 분(Get things done.)
                
                우대사항
                • 머신러닝/딥러닝/컴퓨터 관련 전공 하신 분
                • Custom Deep Learning / Machine Learing model 및 layer  구현해 보신 분
                • 최신 기술에 대한 (영어)논문을 빠르게 찾고/읽고/이해하고/구현하실 수 있는 분
                • 딥러닝 모델을 직접 튜닝 하거나 새로운 도메인에 적용해 보신 분 
                • 제약/바이오/화학/메디컬 필드 지식 소유자 우대
                • 영어 (Written/Verbal) 가능자 우대 / 해외 지사 파견 근무 가능 하신 분
                • CS 탑 논문 발표 경험 (NIPS, ICML, KDD, AAAI, IJCAI, ACL, NAACL, EMNLP 등)
                
                근무지
                • 서울 양재(양재시민의숲 신분당선, 교육개발원 버스 정류장) 또는 실리콘밸리

                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수)
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `Research Scientist`,
                // content
                `
                주요업무
                • 신약 개발 관련 인공지능 모델 개발
                • 인공지능 최신 논문 서치, 세미나 구현
                • 데이터 큐레이션 및 데이터에 맞는 최적의 학습방법 고도화
                • 분산형 학습 모델 및 각종 유전체, 메디컬 데이터 인공지능 모델 개발
                
                자격요건
                • 머신러닝/딥러닝 관련 전공하신 분 (박사이상) 
                • Python을 능숙하게 사용할 수 있는 분
                • 딥러닝 라이브러리 (Tensorflow, Keras, Torch 등) 경험 있으신 분
                • 최신 기술에 대한 (영어)논문을 빠르게 찾고/읽고/이해하고/구현하실 수 있는 분
                • 국내 외 출장 가능하신 분
                
                우대사항
                • 머신러닝/딥러닝 관련 전공 하신 분
                • 여러 Deep Learning / Machine Learing 모델 구현 경험
                • 딥러닝 모델을 직접 튜닝 하거나 새로운 도메인에 적용 경험
                • 제약/바이오/화학/메디컬 백그라운드
                • CS 탑 논문 발표 경험 (NIPS, ICML, KDD, AAAI, IJCAI, ACL, NAACL, EMNLP 등)
                • 영어 (Written/Verbal) 가능자 우대 / 해외 지사 파견 근무 가능 하신 분
                
                근무지
                • 서울 양재(양재시민의숲 신분당선, 교육개발원 버스 정류장) 또는 실리콘밸리
                
                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수)
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `AI Research scientist (전문연구요원, 병역특례, 현역/보충역/전직대상)`,
                // content
                `
                자격요건
                • 채용 대상: 전직 대상자 / 전문연구요원 (석사이상) / 보충역 (학사이상, 사회복무요원소집대상자)

                근무조건
                • 근무형태 : 정규직 (수습기간-3개월) , 병역특례 (전문연구요원)
                • 근무일시 : 주 5일(월-금), 10am-7pm (조정가능)
                • 급여 : 면접 후 결정
                • 근무처: 현재 서울 강남 (2021년 연초 중 판교/광교 등 경기권으로 이전 예정) 

                채용시점
                • 전직 대상자 / 보충역 : 즉시
                • 현역 대상자 : 면접 후 결정

                채용프로세스
                • 서류 -> 실무/임원 면접 -> 최종 결과
                
                주요업무
                • 신약 개발 관련 인공지능 모델 개발
                • 인공지능 최신 논문 서치, 세미나 구현
                • 데이터 큐레이션 및 데이터에 맞는 최적의 학습방법 고도화
                • 분산형 학습 모델 및 각종 유전체, 메디컬 데이터 인공지능 모델 개발
                
                자격요건
                • 머신러닝/딥러닝 관련 전공 하신 분 (보충역-학사이상 / 현역-석사이상)
                • 빠르게 성장하는 스타트업에서 능동적이고 자기주도적으로 업무를 꼼꼼하게 처리 하시는 분
                • Python을 능숙하게 사용할 수 있는 분
                • 딥러닝 라이브러리 (Tensorflow, Keras, Torch 등) 경험 있으신 분
                • 최신 기술에 대한 (영어)논문을 빠르게 찾고/읽고/이해하고/구현하실 수 있는 분
                
                우대사항
                • 박사 학위 우대
                • 여러 Deep Learning / Machine Learing 모델 구현 경험
                • 딥러닝 모델을 직접 튜닝 하거나 새로운 도메인에 적용 경험
                • 제약/바이오/화학/메디컬 백그라운드
                • CS 탑 논문 발표 경험 (NIPS, ICML, KDD, AAAI, IJCAI, ACL, NAACL, EMNLP 등)
                • 영어 (Written/Verbal) 가능자 우대
                
                근무지
                • 서울 양재(양재시민의숲 신분당선, 교육개발원 버스 정류장) 또는 실리콘밸리

                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수)
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `AI Research engineer (전문연구요원, 병역특례, 현역/보충역/전직대상자)`,
                // content
                `
                자격요건
                • 채용 대상: 전직 대상자 / 전문연구요원 (석사이상) / 보충역 (학사이상, 사회복무요원소집대상자)

                근무조건
                • 근무형태 : 정규직 (수습기간-3개월) , 병역특례 (전문연구요원)
                • 근무일시 : 주 5일(월-금), 10am-7pm (조정가능)
                • 급여 : 면접 후 결정
                • 근무처: 서울 양재(양재시민의숲 신분당선, 교육개발원 버스 정류장)
                
                채용시점
                • 전직 대상자 / 보충역 : 즉시
                • 현역 대상자 : 면접 후 결정

                채용프로세스
                • 서류 -> 실무/임원 면접 -> 최종 결과
                
                주요업무
                • 신약 개발 인공지능 모델 개발에 필요한 화학 구조, 단백질 정보 수집 및 전처리
                • 분산형 시스템 처리, 데이터베이스 관리 및 플랫폼 적용
                • 인공지능 최신 논문 서치 및 세미나, 구현

                자격요건
                • 머신러닝/딥러닝 관련 전공 하신 분 (현역- 석사 이상, 보충역 - 학사이상)
                • 빠르게 성장하는 스타트업에서 능동적이고 자기주도적으로 업무를 꼼꼼하게 처리 하시는 분
                • Python을 능숙하게 사용할 수 있는 분
                • 딥러닝 라이브러리 (Tensorflow, Keras, Torch 등) 경험 있으신 분
                • Pandas, scikit-learn등 data science 관련 툴 경험있으신 분
                
                우대사항
                • 박사 학위 우대
                • 여러 Deep Learning / Machine Learing 모델 구현해 보신 분
                • 최신 기술에 대한 (영어)논문을 빠르게 찾고/읽고/이해하고/구현하실 수 있는 분
                • 딥러닝 모델을 직접 튜닝 하거나 새로운 도메인에 적용해 보신 분 
                • 제약/바이오/화학/메디컬 필드 지식 소유자 우대
                • 영어 (Written/Verbal) 가능자 우대

                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수)
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `바이오분석연구원 (Bio informatics)`,
                // content
                `
                채용 안내
                • 근무처 : 서울 양재(양재시민의숲 신분당선, 교육개발원 버스 정류장) 
                • 분야 : 바이오분석연구원 (Bio informatics) 

                주요 업무
                    1. 유전체 데이터 분석
                    • NGS (RNA-seq 혹은 single cell RNA-seq) 데이터 분석 및 리포트 작성.
                    • NGS (RNA-seq 혹은 single cell RNA-seq) 데이터의 custom 분석. 의뢰자의 요구를 바탕으로 데이터 분석 및 결과 도출 필요.
                    • 경우에 따라 논문 작성도 진행

                    2. 암을 비롯한 다양한 질환의 연구
                    • Public data 혹은 공개된 다양한 데이터 베이스에서 정보 습득
                    • 대규모 유전체 데이터를 이용한 질병의 진단 혹은 치료 마커 발굴
                    • 데이터 베이스 및 빅데이터 분석을 통한 약물에 대한 정보 습득 (약물 효능, drug repurposing 여부 파악)

                    3. 실험 설계 및 관리.
                    • 효능 및 기전 연구를 위한 공동 연구 진행.
                    • 공동 연구 진행 시 실험 설계 및 결과 관리를 통해 원활한 프로젝트 추진.

                자격요건 
                • 의/생명 전공자, 박사 이상
                • 면역학 전공 우대
                • Bio informatics 경험자 우대 
                • AI 기술에 관해 배우고자 하는 의지가 있는 분 
                • 스타트업에서 회사, 연구 문화와 체계를 함께 만들어 나가고자 하는 의지가 있는 분 
                • 꼼꼼하고 능동적이며 자기주도적인 업무스타일을 가지신 분 
                • 국내외 출장 가능하신 분

                우대사항
                • 제약업계 기업 경험자 
                • SCI급 논문 4편 이상 (1저자 또는 교신저자 2건 이상)
                • 특허 출원/등록 유 경험자 

                접수 및 문의 
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수)
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `신약개발연구원(합성신약파트)`,
                // content
                `
                주요 업무
                • 합성신약 합성
                • 신규타겟 검토
                (담당 직무에 대한 자세한 사항은 입사 후 담당 리더와 조율 후 진행됩니다.)

                자격요건 
                • 석사 이상(제약 경력 4년 이상)
                • 박사급은 졸업 또는 졸업 예정자
                • 유기화학, 의약화학, 약학 등 관련 전공자

                우대사항
                • 약사, 수의사 등 자격증 소지자 우대
                • 영어 능통자 우대
                • 병역필 또는 면제자로 해외여행에 결격사유가 없을 것
                • 박사의 경우 2021년 8월 졸업예정자도 지원 가능

                채용조건
                • 채용 조건 : 정규직 (수습3개월 포함)
                • 근무지 : 서울 양재 근무 (양재시민의숲 신분당선, 동원 F&B 빌딩)

                채용 프로세스
                • 서류전형 → 실무 면접 → 임원 면접 → 최종 결과 안내

                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수 - 상세 프로젝트 내용 및 성과 포함)
                • 접수 일정 : 2021년 8월 1일 (일) 24:00 접수 마감
                • 서류 결과 안내 : 접수 순으로 상시 진행, 합격자는 개별 통보
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `신약개발연구원(기초약리연구파트)`,
                // content
                `
                주요 업무
                • 기초 약리 연구
                (담당 직무에 대한 자세한 사항은 입사 후 담당 리더와 조율 후 진행됩니다.)

                자격요건
                • 석사 이상(제약 경력 4년 이상)
                • 박사급은 졸업 또는 졸업 예정자
                • 약학, 분자생물학, 생명공학 등 관련 전공자

                우대사항
                • CNS, 면역학 전공자 우대
                • 약사, 수의사 등 자격증 소지자 우대
                • 영어 능통자 우대
                • 병역필 또는 면제자로 해외여행에 결격사유가 없을 것
                • 박사의 경우 2021년 8월 졸업예정자도 지원 가능

                채용조건
                • 채용 조건 : 정규직 (수습3개월 포함)
                • 근무지 : 서울 양재 근무 (양재시민의숲 신분당선, 동원 F&B 빌딩)

                채용 프로세스
                • 서류전형 → 실무 면접 → 임원 면접 → 최종 결과 안내

                접수 및 문의 
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수 - 상세 프로젝트 내용 및 성과 포함)
                • 접수 일정 : 2021년 8월 1일 (일) 24:00 접수 마감 
                • 서류 결과 안내 : 접수 순으로 상시 진행, 합격자는 개별 통보
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `신약개발연구원(합성신약분석파트)`,
                // content
                `
                주요 업무
                • 합성신약 분석
                (담당 직무에 대한 자세한 사항은 입사 후 담당 리더와 조율 후 진행됩니다.)

                자격요건 
                • 석사 이상(제약 경력 4년 이상)
                • 박사급은 졸업 또는 졸업 예정자
                • 화학, 약학, 생명공학 등 관련 전공자

                우대사항
                • NMR 전공자 및 경험자 우대
                • 약사, 수의사 등 자격증 소지자 우대
                • 영어 능통자 우대
                • 병역필 또는 면제자로 해외여행에 결격사유가 없을 것
                • 박사의 경우 2021년 8월 졸업예정자도 지원 가능


                채용조건
                • 채용 조건 : 정규직 (수습3개월 포함)
                • 근무지 : 서울 양재 근무 (양재시민의숲 신분당선, 동원 F&B 빌딩)

                채용 프로세스
                • 서류전형 → 실무 면접 → 임원 면접 → 최종 결과 안내 

                접수 및 문의 
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수 - 상세 프로젝트 내용 및 성과 포함)
                • 접수 일정 : 2021년 8월 1일 (일) 24:00 접수 마감 
                • 서류 결과 안내 : 접수 순으로 상시 진행, 합격자는 개별 통보
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `프론트엔드 개발자`,
                // content
                `
                주요업무
                • 프론트엔드 개발

                자격요건
                • 웹 프론트엔드 개발, 운영 실무 3년 이상 또는 그에 준하는 역량 보유
                • HTML(5), CSS, Javascript(최소 ES5 이상) 지식 보유 
                • React.js 실무 경험 1년 이상
                • 문제에 봉착했을 때 이를 끝까지 해결해내는 근성을 가지신 분(Get things done.)
                • 빠르게 성장하는 스타트업에서 능동적이고 자기주도적으로 업무를 꼼꼼하게 처리 하시는 분

                우대사항
                • Node.js 개발 및 운영 경험
                • AWS를 활용한 설계, 개발, 운용 경험
                • 백엔드 서비스 개발 경험

                근무지
                • 근무처 : 서울 양재 근무 (양재시민의숲 신분당선, 교육개발원 버스 정류장)
                
                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수)
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `백엔드 개발자`,
                // content
                `
                주요업무
                • 디어젠 서비스 백엔드 개발

                자격요건
                • Node.js 기반의 서비스 개발 경험 3년 이상 또는 그에 준하는 역량 보유
                • 웹/모바일 서비스의 일반적인 아키텍쳐에 대한 이해 및 RESTful API 설계 역량 보유
                • MySOL, ElasticSearch, Redis 등의 DB 관련 역량 보유
                • AWS를 활용한 설계, 개발, 운용 경험
                • 문제에 봉착했을 때 이를 끝까지 해결해내는 근성을 가지신 분(Get things done.)
                • 빠르게 성장하는 스타트업에서 능동적이고 자기주도적으로 업무를 꼼꼼하게 처리 하시는 분

                우대사항
                • 프론트엔드 서비스 개발 경험
                • Docker, Kubernentes 기반 서비스 개발 경험

                근무지
                • 근무처 : 서울 양재 근무 (양재시민의숲 신분당선, 교육개발원 버스 정류장)

                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수)
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `재무 회계 담당자(신입)`,
                // content
                `
                주요업무
                • 전표처리 및 법인카드 관리
                • 국책과제 집행 및 운영 관리
                • 매입/매출 관리 및 자금출납
                • 기타 회계관리업무 및 임원 비서 지원
                
                자격요건
                • 경력: 0~2년
                • 학력 : 무관
                
                우대사항
                • 회계관련 자격증 有
                • Excel 활용능력 우수
                • 더존 프로그램 사용경험 有
                • 상장사 근무경력 有
                • 꼼꼼하고 성실한 성격
                • 원활한 커뮤니케이션 능력근무지
                  
                근무처
                • 서울 양재 근무 (양재시민의 숲 신분당선, 동원 F&B건물)
                
                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서
                • 접수처 : career@deargen.me
                `,
                // link
                ``
            ],
            [
                // title
                `경영관리(인턴)`,
                // content
                `
                디어젠의 경영관리부는 디어젠에 적합한 인재분들이 함께 하실 수 있도록 여러 전략을 기획하고 운영하고 있습니다. 
                또한 디어젠만의 문화를 만들어 갈 수 있도록 다방면에서 지원하고 있습니다. 

                주요업무
                • 경영관리 전반적인 업무 지원 (경영기획 및 보고, 인사/총무, 재무, 정부과제 등)
                • 조직문화에 적합한 인사제도 운영 및 임직원 캐어 지원
                • 기타 복리후생 지원 (제도운영, 비품 및 기타 회사운영 관련 사무 지원)
                • 정부과제 및 정부지원제도 운영지원
                
                자격요건
                • 빠르게 성장하는 스타트업에서 능동적이고 자기주도적으로 업무를 꼼꼼하게 처리 하시는 분
                • 경영관리 업무 전반에 대해 배우고 싶으신 분
                • 디어젠의 회사 문화를 함께 만들어 가실 분 
                • 기민한 상황 대처 능력 및 유연한 커뮤니케이션 능력을 가지신 분
                • 피드백을 겸허히 받아들일 줄 아는 분
                • 구성원 지원에 대한 서비스 마인드를 가지신 분
                • 문제에 봉착했을 때 이를 끝까지 해결해내는 근성을 가지신 분(Get things done.)
                • 전공 무관                
                
                우대사항
                • 임직원들의 쾌적한 회사 생활을 위해 제도 및 공간 운영에 관심을 가지고 새로운 아이디어를 도출하고 실행하는 일에서 
                  즐거움을 느끼시는 분 
                • 스타트업에 문화에 대한 경험이 있으신 분
                • 대학 및 대외활동 포트폴리오를 서류와 함께 제출하시는 분 (자유 양식, 자유 내용) 
                • 대학생활 중 대기업 및 국가기관 공모전 참여/수상 경력을 보유하신 분 (분야 무관)
                • 친절함과 활기참으로 직원들의 만족을 이끌어 낼 수 있는 분
                                  
                채용조건
                • 채용 조건 : 인턴 (6개월 근무, 근무기간 : 2021.6월 중순 ~ 2021년 12월 말)
                • 근무지 : 서울 양재 근무 (양재시민의숲 신분당선, 동원 F&B 빌딩)

                채용 프로세스
                서류전형 -> 실무 면접 -> 임원 면접 -> 최종 결과 안내
                                
                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서
                • 접수 일정 : 2021년 6월 11일 (금) 오후 4시 접수 마감
                • 서류 결과 안내 : 2021년 6월 14일 (월) 개별 연락 예정 
                • 접수처 : career@deargen.me
                `,
                // link
                ``
            ]
        ],
        RecruitProcess: [
            [
                `지원서 접수`,
                `서류전형`,
                `실무면접`,
                `임원면접`,
                `최종합격`,
            ],
            [
                `- 개발자 직군의 경우, 면접 시 코딩테스트가 있을 수 있습니다.\n- 면접은 화상 면접으로 진행될 수도 있습니다.\n- 세부 진행 내용은 모집 직군에 따라 다를 수 있으니, 지원하시는 직군의 모집공고를 반드시 확인하시길 바랍니다.\n- 최종 합격 시 처우 협의 및 입사일을 조율합니다.`,
            ],
        ]
    },
    en: {
        RecruitBoard: [
            [
                `Machine Learning Engineer`,
                `We are looking for a great Research Engineer to share the challenge of innovation in new drug development.

                Responsibilities
                • Collecting and preprocessing data of chemical structures, proteins that used in AI model Development.
                • Distributed system handling, database management and platform application
                • Searching latest A.I. papers, prepare seminar and model development
        

                Basic Qualifications
                • Doctor degree required, major in Machine-learning or Deep-learning related
                • Excellent Python skills
                • Experienced deep learining libraries (Tensorflow, Keras, Torch, etc.)
                • Experience with data science, such as Pandas, scikit-lean, etc.
                • Leadership, integrity and accountabilities
                • Possible overseas business trip

                
                Preferred Qualifications (Optional)
                • Experienced in develop Deep-learining, Machine-learning models.
                • Experienced in tuning of deep learning models or applying them to new domains.
                • Pharmaceutical/bio/chemical/medical background
                • Excellent written and spoken English 
                • Possible dispatch to overseas branches
                 

                Work place 
                • Seoul Yang-Jae, or Sillicon Valley
                 
                Apply
                • How to apply : Send your resume and CV to the e-mail below.
                • E-mail : career@deargen.me`,
                ``
            ],
            [
                `Research Scientist`,
                `We are looking for a great Research Scientist to share the challenge of innovation in new drug development.

                Responsibilities
                • Developting A.I. models related to the drug development
                • Searching latest A.I. papers, prepare seminar and model development
                • Data curation, enhance the optimal learning method for data
                • Development of distributed learning models and genetic, medical data A.I. models


                Basic Qualifications
                • Doctor degree required, major in Machine-learning or Deep-learning related
                • Excellent Python skills
                • Experienced deep learining libraries (Tensorflow, Keras, Torch, etc.)
                • Can quickly find/read/understand/implement (English) papers on the latest technology
                • Leadership, integrity and accountabilities
                • Possible overseas business trip

                
                Preferred Qualifications (Optional)
                • Experienced in develop Deep-learining, Machine-learning models.
                • Experienced in tuning of deep learning models or applying them to new domains.
                • Pharmaceutical/bio/chemical/medical background
                • Experiences in presenting CS top papers (NIPS, ICML, KDD, AAAI, IJCAI, ACL, NAACL, EMNLP, etc.)
                • Excellent written and spoken English 
                • Possible dispatch to overseas branches
                 

                Work place 
                • Seoul Yang-Jae, or Sillicon Valley

                Apply
                • How to apply : Send your resume and CV to the e-mail below.
                • E-mail : career@deargen.me`,
                ``
            ],
            [
                // title
                `Research Scientist (Alternative Military Service)`,
                // content
                `
                자격요건
                • 채용 대상: 전직 대상자 / 전문연구요원 (석사이상) / 보충역 (학사이상, 사회복무요원소집대상자)

                근무조건
                • 근무형태 : 정규직 (수습기간-3개월) , 병역특례 (전문연구요원)
                • 근무일시 : 주 5일(월-금), 10am-7pm (조정가능)
                • 급여 : 면접 후 결정
                • 서울 양재(양재시민의숲 신분당선, 교육개발원 버스 정류장) 또는 실리콘밸리

                채용시점
                • 전직 대상자 / 보충역 : 즉시
                • 현역 대상자 : 면접 후 결정

                채용프로세스
                • 서류 -> 실무/임원 면접 -> 최종 결과
                
                주요업무
                • 신약 개발 관련 인공지능 모델 개발
                • 인공지능 최신 논문 서치, 세미나 구현
                • 데이터 큐레이션 및 데이터에 맞는 최적의 학습방법 고도화
                • 분산형 학습 모델 및 각종 유전체, 메디컬 데이터 인공지능 모델 개발
                
                자격요건
                • 머신러닝/딥러닝 관련 전공 하신 분 (보충역-학사이상 / 현역-석사이상)
                • 빠르게 성장하는 스타트업에서 능동적이고 자기주도적으로 업무를 꼼꼼하게 처리 하시는 분
                • Python을 능숙하게 사용할 수 있는 분
                • 딥러닝 라이브러리 (Tensorflow, Keras, Torch 등) 경험 있으신 분
                • 최신 기술에 대한 (영어)논문을 빠르게 찾고/읽고/이해하고/구현하실 수 있는 분
                
                우대사항
                • 박사 학위 우대
                • 여러 Deep Learning / Machine Learing 모델 구현 경험
                • 딥러닝 모델을 직접 튜닝 하거나 새로운 도메인에 적용 경험
                • 제약/바이오/화학/메디컬 백그라운드
                • CS 탑 논문 발표 경험 (NIPS, ICML, KDD, AAAI, IJCAI, ACL, NAACL, EMNLP 등)
                • 영어 (Written/Verbal) 가능자 우대
                
                근무지
                • 서울 강남역근처 또는 실리콘밸리

                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수)
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `Machine Learning Engineer (Alternative Military Service)`,
                // content
                `
                자격요건
                • 채용 대상: 전직 대상자 / 전문연구요원 (석사이상) / 보충역 (학사이상, 사회복무요원소집대상자)

                근무조건
                • 근무형태 : 정규직 (수습기간-3개월) , 병역특례 (전문연구요원)
                • 근무일시 : 주 5일(월-금), 10am-7pm (조정가능)
                • 급여 : 면접 후 결정
                • 서울 양재(양재시민의숲 신분당선, 교육개발원 버스 정류장) 또는 실리콘밸리
                
                채용시점
                • 전직 대상자 / 보충역 : 즉시
                • 현역 대상자 : 면접 후 결정

                채용프로세스
                • 서류 -> 실무/임원 면접 -> 최종 결과
                
                주요업무
                • 신약 개발 인공지능 모델 개발에 필요한 화학 구조, 단백질 정보 수집 및 전처리
                • 분산형 시스템 처리, 데이터베이스 관리 및 플랫폼 적용
                • 인공지능 최신 논문 서치 및 세미나, 구현

                자격요건
                • 머신러닝/딥러닝 관련 전공 하신 분 (현역- 석사 이상, 보충역 - 학사이상)
                • 빠르게 성장하는 스타트업에서 능동적이고 자기주도적으로 업무를 꼼꼼하게 처리 하시는 분
                • Python을 능숙하게 사용할 수 있는 분
                • 딥러닝 라이브러리 (Tensorflow, Keras, Torch 등) 경험 있으신 분
                • Pandas, scikit-learn등 data science 관련 툴 경험있으신 분
                
                우대사항
                • 박사 학위 우대
                • 여러 Deep Learning / Machine Learing 모델 구현해 보신 분
                • 최신 기술에 대한 (영어)논문을 빠르게 찾고/읽고/이해하고/구현하실 수 있는 분
                • 딥러닝 모델을 직접 튜닝 하거나 새로운 도메인에 적용해 보신 분 
                • 제약/바이오/화학/메디컬 필드 지식 소유자 우대
                • 영어 (Written/Verbal) 가능자 우대

                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수)
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `Bio informatics`,
                // content
                `
                We are looking for a great Bio Informatics to share the chalenge of innovation in new drug development.

                Responsibilities
                    1. Genomic Data Analysis
                    • NGS (RNA-seq or singlle cell RNA-seq) data analysis and reporting.
                    • Customizing data analysis of NGS (RNA-seq or singlle cell RNA-seq).
                      It is necessary to analyze data and derive results based on the client’s request.
                    • In some cases, writing a paper(publication)

                    2. Reseach (Immunology, Neurology and Oncology etc.)
                    • Study information from public data or various revealed databases.
                    • Discovery bio markers for diagnosis of diseases or therapeutic using large-scale genomic data.
                    • Study of drug information through database and big data analysis (Identification of drug efficacy, drug repurposing)

                    3. Desing and Management of Experiment
                    • Managing collaborate research about efficacy aand mechanism study.
                    • Managing projects smoothly, through experiment design and result management.

                Basic Qualifications
                • Doctor degree required, major in Medical or Life science.
                • Person who have passion to learn AI technology.
                • Leaderchip, intergrity and accountabilities
                • Possibe overseas business trip

                Preferred Qualifications (Optional)
                • Immunology background
                • Experienced in Bio informatics
                • Experienced in Pharmaceutical company
                • Experienced in patent application/registration 
                • 4 or more SCL-evel papaers (first autor (1 or more) or correspponding autors(2 or more))

                Work Place
                • Seoul Yang-Jae

                Apply
                • How to apply : Send your resume and CV to the e-mail below.
                • E-mail : career@deargen.me
                `,
                // link
                ``
            ],
            [
                // title
                `Drug Discovery Scientist, (Synthetic Drug)`,
                // content
                `
                주요 업무
                • 합성신약 합성
                • 신규타겟 검토
                (담당 직무에 대한 자세한 사항은 입사 후 담당 리더와 조율 후 진행됩니다.)

                자격요건 
                • 석사 이상(제약 경력 4년 이상)
                • 박사급은 졸업 또는 졸업 예정자
                • 유기화학, 의약화학, 약학 등 관련 전공자

                우대사항
                • 약사, 수의사 등 자격증 소지자 우대
                • 영어 능통자 우대
                • 병역필 또는 면제자로 해외여행에 결격사유가 없을 것
                • 박사의 경우 2021년 8월 졸업예정자도 지원 가능

                채용조건
                • 채용 조건 : 정규직 (수습3개월 포함)
                • 근무지 : 서울 양재 근무 (양재시민의숲 신분당선, 동원 F&B 빌딩)

                채용 프로세스
                • 서류전형 → 실무 면접 → 임원 면접 → 최종 결과 안내

                접수 및 문의
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수 - 상세 프로젝트 내용 및 성과 포함)
                • 접수 일정 : 2021년 8월 1일 (일) 24:00 접수 마감
                • 서류 결과 안내 : 접수 순으로 상시 진행, 합격자는 개별 통보
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `Drug Discovery Scientist, (Pharmacology)`,
                // content
                `
                주요 업무
                • 기초 약리 연구
                (담당 직무에 대한 자세한 사항은 입사 후 담당 리더와 조율 후 진행됩니다.)

                자격요건
                • 석사 이상(제약 경력 4년 이상)
                • 박사급은 졸업 또는 졸업 예정자
                • 약학, 분자생물학, 생명공학 등 관련 전공자

                우대사항
                • CNS, 면역학 전공자 우대
                • 약사, 수의사 등 자격증 소지자 우대
                • 영어 능통자 우대
                • 병역필 또는 면제자로 해외여행에 결격사유가 없을 것
                • 박사의 경우 2021년 8월 졸업예정자도 지원 가능

                채용조건
                • 채용 조건 : 정규직 (수습3개월 포함)
                • 근무지 : 서울 양재 근무 (양재시민의숲 신분당선, 동원 F&B 빌딩)

                채용 프로세스
                • 서류전형 → 실무 면접 → 임원 면접 → 최종 결과 안내

                접수 및 문의 
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수 - 상세 프로젝트 내용 및 성과 포함)
                • 접수 일정 : 2021년 8월 1일 (일) 24:00 접수 마감 
                • 서류 결과 안내 : 접수 순으로 상시 진행, 합격자는 개별 통보
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
            [
                // title
                `Drug Discovery Scientist, (Synthetic Drug Analysis)`,
                // content
                `
                주요 업무
                • 합성신약 분석
                (담당 직무에 대한 자세한 사항은 입사 후 담당 리더와 조율 후 진행됩니다.)

                자격요건 
                • 석사 이상(제약 경력 4년 이상)
                • 박사급은 졸업 또는 졸업 예정자
                • 화학, 약학, 생명공학 등 관련 전공자

                우대사항
                • NMR 전공자 및 경험자 우대
                • 약사, 수의사 등 자격증 소지자 우대
                • 영어 능통자 우대
                • 병역필 또는 면제자로 해외여행에 결격사유가 없을 것
                • 박사의 경우 2021년 8월 졸업예정자도 지원 가능


                채용조건
                • 채용 조건 : 정규직 (수습3개월 포함)
                • 근무지 : 서울 양재 근무 (양재시민의숲 신분당선, 동원 F&B 빌딩)

                채용 프로세스
                • 서류전형 → 실무 면접 → 임원 면접 → 최종 결과 안내 

                접수 및 문의 
                • 지원 방법 : 자유 양식 이력서 및 자기소개서 (연구경력 위주의 자유양식 CV 제출 필수 - 상세 프로젝트 내용 및 성과 포함)
                • 접수 일정 : 2021년 8월 1일 (일) 24:00 접수 마감 
                • 서류 결과 안내 : 접수 순으로 상시 진행, 합격자는 개별 통보
                • 접수처 : career@deargen.me`,
                // link
                ``
            ],
        ],
        RecruitProcess: [
            // 5개 면접 절차
            [
                `Send your CV`,
                `Reviewing\nyour CV`,
                `Practical\nInterview`,
                `Executive\nInterview`,
                `Offer`,
            ],
            // 면접관련 주의사항
            [
                `- For developer job, you may have a coding test at interview.\n- The interview can be replaced by a video interview.\n- The details may differ depending on the each job positions, so please check the job postings.\n- After pass the interview you can adjust conditions.\n`,
            ]
        ]
    }
    
}

export default function useContent() {
    const language = useLanguage()
    const 컨텐츠 = text[language]

    const images = useStaticQuery(graphql`
        query {
            laptop_coffee: file(relativePath: { eq: "CareerDetail/laptop_coffee.png" }) {
                childImageSharp {
                    fluid(maxHeight: 220) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            # recruit board
            recruit_board_0: file(relativePath: { eq: "CareerDetail/deargen-recruit.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 5560, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return { images, 컨텐츠 }
}