import React from 'react'
// import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { HexagonField, Img, P } from "src/components"

const SQRT3 = Math.sqrt(3)

const HexagonGridRoot = styled.div`
    position: relative;
    ${props => props.customCss}
`


export default function HexagonGrid({ 
    size=70,
    // if gridColumn is set to 4, this assume that the shape of grid is like 4,5,4,5,... and so on.
    gridColumn=2,
    buttonsProps=[],
    gap=0,
    customCss,
}) {
    // calculate size
    const hexagonWidth = size * SQRT3
    const width = hexagonWidth * (gridColumn + 1) + gap * gridColumn

    const lines = 
        // for example, given gridColumn=3 and the length of buttonsProps is 17, the shape of grid is like 3,4,3,4,3,4
        // so firstly we can count the number of lines up to 4th line with below code. that is, up to 3,4,3,4 
        Math.floor(buttonsProps.length / (2 * gridColumn + 1)) * 2
        // and we add last two lines (if exists) with below code.
        + (buttonsProps.length % (2 * gridColumn + 1) > 0 ? 1 : 0) // first line
        + (buttonsProps.length % (2 * gridColumn + 1) > gridColumn ? 1 : 0) // second line

    
    const hexagonHeight = size * 2
    const height = 
        hexagonHeight // first line
        + (lines - 1) * (3 / 4) * hexagonHeight // left lines
        + (lines - 1) * (gap / SQRT3 * 2) // gap


    // hexagon field
    const hexagonConfigs = buttonsProps.map((buttonProps, index) => {
        if (!buttonProps) return null

        // start from 0. not 1. first line is 0th line
        const line = Math.floor(index / (2 * gridColumn + 1)) * 2 + (index % (2 * gridColumn + 1) < gridColumn ? 0 : 1)
        const isEvenLine = line % 2 === 0
        const nth = isEvenLine
            ? index % (2 * gridColumn + 1) % gridColumn
            : index % (2 * gridColumn + 1) - gridColumn

        const left = 
            (isEvenLine 
                ? hexagonWidth / 2 + gap / 2 
                : 0) 
            + nth * (hexagonWidth + gap)

        const top = 
            line * (3 / 4) * (hexagonHeight 
            + (gap / SQRT3 * 2)) 

        return {
            left,
            top,
            buttonProps: { ...buttonProps, size }
        }
    }).filter(Boolean)

    const render = <HexagonField width={width} height={height} hexagonConfigs={hexagonConfigs} />
    

    return <HexagonGridRoot customCss={customCss}>
        {render}
    </HexagonGridRoot>
}