// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-assembled-updates-article-js": () => import("./../../../src/assembled/Updates/Article.js" /* webpackChunkName: "component---src-assembled-updates-article-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-career-detail-js": () => import("./../../../src/pages/en/career_detail.js" /* webpackChunkName: "component---src-pages-en-career-detail-js" */),
  "component---src-pages-en-career-js": () => import("./../../../src/pages/en/career.js" /* webpackChunkName: "component---src-pages-en-career-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-notice-js": () => import("./../../../src/pages/en/notice.js" /* webpackChunkName: "component---src-pages-en-notice-js" */),
  "component---src-pages-en-pipeline-js": () => import("./../../../src/pages/en/pipeline.js" /* webpackChunkName: "component---src-pages-en-pipeline-js" */),
  "component---src-pages-en-publication-js": () => import("./../../../src/pages/en/publication.js" /* webpackChunkName: "component---src-pages-en-publication-js" */),
  "component---src-pages-en-team-js": () => import("./../../../src/pages/en/team.js" /* webpackChunkName: "component---src-pages-en-team-js" */),
  "component---src-pages-en-technology-drug-design-js": () => import("./../../../src/pages/en/technology/drug_design.js" /* webpackChunkName: "component---src-pages-en-technology-drug-design-js" */),
  "component---src-pages-en-technology-index-js": () => import("./../../../src/pages/en/technology/index.js" /* webpackChunkName: "component---src-pages-en-technology-index-js" */),
  "component---src-pages-en-technology-target-discovery-js": () => import("./../../../src/pages/en/technology/target_discovery.js" /* webpackChunkName: "component---src-pages-en-technology-target-discovery-js" */),
  "component---src-pages-en-updates-js": () => import("./../../../src/pages/en/updates.js" /* webpackChunkName: "component---src-pages-en-updates-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ko-career-detail-js": () => import("./../../../src/pages/ko/career_detail.js" /* webpackChunkName: "component---src-pages-ko-career-detail-js" */),
  "component---src-pages-ko-career-js": () => import("./../../../src/pages/ko/career.js" /* webpackChunkName: "component---src-pages-ko-career-js" */),
  "component---src-pages-ko-index-js": () => import("./../../../src/pages/ko/index.js" /* webpackChunkName: "component---src-pages-ko-index-js" */),
  "component---src-pages-ko-notice-js": () => import("./../../../src/pages/ko/notice.js" /* webpackChunkName: "component---src-pages-ko-notice-js" */),
  "component---src-pages-ko-pipeline-js": () => import("./../../../src/pages/ko/pipeline.js" /* webpackChunkName: "component---src-pages-ko-pipeline-js" */),
  "component---src-pages-ko-publication-js": () => import("./../../../src/pages/ko/publication.js" /* webpackChunkName: "component---src-pages-ko-publication-js" */),
  "component---src-pages-ko-team-js": () => import("./../../../src/pages/ko/team.js" /* webpackChunkName: "component---src-pages-ko-team-js" */),
  "component---src-pages-ko-technology-drug-design-js": () => import("./../../../src/pages/ko/technology/drug_design.js" /* webpackChunkName: "component---src-pages-ko-technology-drug-design-js" */),
  "component---src-pages-ko-technology-index-js": () => import("./../../../src/pages/ko/technology/index.js" /* webpackChunkName: "component---src-pages-ko-technology-index-js" */),
  "component---src-pages-ko-technology-target-discovery-js": () => import("./../../../src/pages/ko/technology/target_discovery.js" /* webpackChunkName: "component---src-pages-ko-technology-target-discovery-js" */),
  "component---src-pages-ko-updates-js": () => import("./../../../src/pages/ko/updates.js" /* webpackChunkName: "component---src-pages-ko-updates-js" */),
  "component---src-pages-under-construction-js": () => import("./../../../src/pages/under_construction.js" /* webpackChunkName: "component---src-pages-under-construction-js" */)
}

