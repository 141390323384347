import React, {  } from 'react'
import styled, {  } from "styled-components"
import Flex, { FlexItem } from "styled-flex-component"
import breakpoint from "styled-components-breakpoint"
import { BsCircleFill } from "react-icons/bs"
import _ from "lodash"
import { H1, P, A, Img } from "src/components"
import {  } from 'src/GlobalStyle'
import useContents from "src/data"

const MainSloganRoot = styled.div`
    padding-top: 50px;
`

const LogoImage = styled(FlexItem)`
    position: absolute;
    top: 25vh;
    left: 5vw;
    width: 300px;
    height: 300px;
    
    ${breakpoint("desktop", "big desktop")`
        opacity: 0.6;
    `}
`
const Text = styled(FlexItem)`
    position: absolute;
    right: 5vw;
    top: 22vh;
    text-align: right;

    ${H1} {
        font-size: 3em;
        font-weight: bold;
    }
    ${P} {
        margin-top: 10px;
        font-size: 1.2em;
        font-weight: 300;
    }

`
const Blur = styled(Flex)`
    position: absolute;
    bottom: 10vh;
    background: rgba(230, 241, 255, 0.5);
    width: 100vw;
    height: 200px;
    backdrop-filter: blur(16px);
    overflow: hidden;
`
const LatestNews = styled(Flex)`
    width: 200px;
    margin-right: 80px;
    
    ${P} {
        text-align: right;
        font-size: 60px;
        font-weight: 300;
        line-height: 0.9;
    }
`
const NewsItems = styled(Flex)`
    margin-left: 80px;
`
const NewsItem = styled(Flex)`
    margin-top: 20px;

    ${A} {
        width: 450px;
        font-size: 16px;
        text-align: left;
    }
`
const GeneImage = styled.div`
    z-index: -1;
    position absolute;
    top: -40px;
    right: 200px;
    width: 240px;
    height: 120%;
`


export default function MainSlogan() {

    // contents
    const { images, 컨텐츠 } = useContents("Home_Slogan")
    const { posts } = useContents("Updates")


    // image
    const imageRender = <LogoImage>
        <Img gatsbyImageSrc={images.home_slogan_logo} />
    </LogoImage>


    // text
    const textRender = <Text>
        <H1>
            {컨텐츠[0][0]}
        </H1>
        <P>{컨텐츠[0][1]}</P>
    </Text>

    // news
    const newsRender = <Blur justifyBetween>
        <GeneImage>
            <Img gatsbyImageSrc={images.gene} />
        </GeneImage>
        <NewsItems center column>
            {posts.slice(0,3).map((post, i) => <NewsItem key={i}>
                <A to={post.to} href={post.href}>
                    <BsCircleFill size={8} />
                    {"  "}{_.truncate(post.title, {length: 50})}
                </A>
                <A to={post.to} href={post.href}>read more</A>
            </NewsItem>)}
        </NewsItems>
        <LatestNews center column>
            <P>{"Latest\nNEWS"}</P>
        </LatestNews>
    </Blur>


    return <MainSloganRoot full column center>
        {imageRender}
        {textRender}
        {newsRender}
    </MainSloganRoot>
}
