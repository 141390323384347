import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { bounce, useIsDeviceWidth } from 'src/components/utils'


const arrowButtonSvg = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="33" viewBox="0 0 22 33">
<g fill="none" fillRule="evenodd" stroke="#1F4D6A" strokeWidth="6">
    <path d="M19.485 20L11 28.485 2.515 20M19.485 3L11 11.485 2.515 3"/>
</g>
</svg>

const mobileArrowButtonSvg = <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
<g fill="none" fillRule="evenodd">
    <path fill="#212529" fillOpacity=".5" d="M8 0h23a8 8 0 0 1 8 8v23a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8z"/>
    <path stroke="#FFF" strokeWidth="2" d="M14.509 17.6l5.091 5.091 5.091-5.091"/>
</g>
</svg>



const ArrowButtonRoot = styled.div`
    position: ${props => props.fixed ? "fixed" : "relative"};
    z-index: 1;
    cursor: pointer;
    left:   ${props => props.left}px;
    right:  ${props => props.right}px;
    top:    ${props => props.top}px;
    bottom: ${props => props.bottom}px;
    animation: ${props => props.play ? bounce({ height: 20, contractBeforeJump: false, callback: props.callback }) : "none"};
    ${props => props.customCss}
`

const Rotate = styled.div`
    transform: ${props => {
        if (props.direction==="left") return `rotateZ(90deg)` 
        if (props.direction==="right") return `rotateZ(-90deg)` 
        if (props.direction==="up") return `rotateZ(180deg)` 
        if (props.direction==="down") return `rotateZ(0deg)` 
    }};
`

export default function ArrowButton({
    onClick,
    position={ left: 20, right: "none", bottom: 20, top: "none" },
    direction,
    fixed=true,
    animationPlay=false,
    customCss
}) {
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })


    // image
    const svg = isSmallDevice ? mobileArrowButtonSvg : arrowButtonSvg


    // animation
    const [play, setPlay] = useState(true);
    useEffect(() => {
        if (!animationPlay) return
        const interval = setInterval(() => setPlay(true), 7000)
        return () => clearInterval(interval)
    }, [])


    return <ArrowButtonRoot
    play={play}
    {...position}
    fixed={fixed}
    callback={() => setPlay(false)}
    onClick={onClick}
    customCss={customCss}>
        <Rotate direction={direction} >
            {svg}
        </Rotate>
    </ArrowButtonRoot>
    
}
