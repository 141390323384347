import { useStaticQuery, graphql } from "gatsby";
import { useLanguage } from "src/components/utils";

const 한글프로필 = [
  {
    koreanName: `강길수`,
    englishName: `Kilsoo Kang`,
    role: `CEO`,
    description: `디어젠의 공동창업자이자 CEO입니다. 디어젠의 경영총괄을 맡아 바이오메디컬 데이터와 인공지능 기술이 질병의 근원적인 치료법 발견으로 이어지도록 제약, 바이오 분야의 전문가들과 디어젠의 협력을 촉진 시키는 일을 하고 있습니다. IT 분야에서 약 15년 이상의 경력을 가진 빅데이터 전문가이자 소프트웨어 아키텍트 입니다. 디어젠 창업 전 네이버에서 빅데이터 플랫폼 개발 리더로 근무했습니다.`,
    letsMake: `Let's make a DREAM`,
    koreanSlogan: "“생명과학 난제해결의 꿈을 함께 실현하고 있습니다.”",
    linkedinHref:
      "https://www.linkedin.com/in/gilsu-kang-16269537/?originalSubdomain=kr",
  },
  {
    koreanName: `박성수`,
    englishName: `Sungsoo Park`,
    role: `CTO`,
    description: `디어젠의 공동창업자이자 CTO입니다. 딥러닝 전문가로서, 디어젠에서 인공지능 기술 개발을 담당하여 디어젠만의 핵심기술을 연구하고 개발하고 있습니다. 딥러닝 분야 다수의 특허와 여러 세계대회 수상경력이 있습니다. 디어젠 이전 DEEPImagine을 창업해 CEO로서 근무하였습니다.`,
    letsMake: `Let's make a WAY`,
    koreanSlogan:
      "“보다 완벽한기술로 신약개발의 새로운 길을 함께 만들고있습니다.”",
    linkedinHref:
      "https://www.linkedin.com/in/%EC%84%B1%EC%88%98-%EB%B0%95-b79ab262/?originalSubdomain=kr",
  },
  {
    koreanName: `강근수`,
    englishName: `Keunsoo Kang`,
    role: `CSO`,
    description: `디어젠의 공동창업자이자 CSO입니다. 생물정보학 박사로서 디어젠 기술의 생물학적 검증 및 연구 개발을 담당하고 있습니다. KAIST 생물정보학 박사학위를 받았으며, 현재 단국대 미생물학과 조교수를 겸하고 있습니다. SCI 논문을 70편 이상 게재했습니다.`,
    letsMake: `Let's make an INNOVATION`,
    koreanSlogan: "“신약개발 분야의 혁신을 통해생명을 전달합니다.”",
    linkedinHref: "https://www.linkedin.com/in/keunsoo-kang-7a000b37/",
  },
  {
    koreanName: `김수현`,
    englishName: `Sean Kim`,
    role: `Head of Strategy & Management `,
    description: `디어젠의 Strategy & Management팀의 Head로서 R&D 전략 및 경영기획 업무 전반을 담당하고 있습니다. 서울대에서 종양생물학 박사과정을 전공하고 한미약품 연구센터에서 약리연구를 담당하였고 국가신약개발재단에서 전문위원으로 500여개 이상의 신약개발과제를 검토하고 50여개 과제를 관리한 경험이 있습니다.`,
    letsMake: `Let’s make the Revolution`,
    koreanSlogan: "“인공지능 기술을 통해 신약개발의 혁명을 함께 만들어갑니다.“",
    linkedinHref: "https://www.linkedin.com/in/suhyeon-sean-kim-375198158/",
  },
  {
    koreanName: `정종영`,
    englishName: `Jong young Joung`,
    role: `Lead of CADD`,
    description: `디어젠 CADD팀의 리더로서, Computational Chemistry 업무를 담당하고 있습니다. 특히, 인공지능과 분자모델링 지식을 접목한 신약 후보물질 발굴의 새로운 방법론을 연구하고 있습니다. 연세대학교에서 분자모델링 및 계산화학 분야로 생명공학 박사 학위를 받았습니다. 고등과학원 (KIAS) 박사후 연구원 과정에서는 단백질 복합체 구조 예측 알고리즘 개발을 진행하였고, 분자동역학을 이용한 생체분자의 열역학적 에너지를 분석하는 연구하였습니다.`,
    letsMake: `Let's make a NOVEL PARADIGM`,
    koreanSlogan:
      "“신약개발의 비전을 이룰 새로운 패러다임을 함께 만들어갑니다”",
    linkedinHref: "https://www.linkedin.com/in/jong-young-joung-5476a620/",
  },
  {
    koreanName: `박지민`,
    englishName: `Jimin Park`,
    role: `Lead of AI`,
    description: `디어젠에서 AI팀 Lead를 맡고 있습니다. 이화여자대학교에서 구조생물학으로 약학박사 학위를 받은 후, 고등과학원(KIAS)에서 단백질 구조 모델링 방법을 연구했습니다. 약학, 구조생물학, 분자 모델링, 딥러닝 등 여러 연구분야를 융합하여 신약 후보물질 발굴을 위한 연구를 하고있습니다.`,
    letsMake: `Let’s make a history`,
    koreanSlogan: "“신약개발의 새로운 역사를 함께 만들어 갑니다.”",
    linkedinHref: "https://www.linkedin.com/in/jimin-park-608338161/",
  },
  {
    koreanName: `이동주 `,
    englishName: `Dongju Lee`,
    role: `Lead of MLOps`,
    description: `디어젠 MLOps팀 Lead로서, AI 플랫폼 및 핀테크 백엔드 개발 경험이 있으며 제품 라이프사이클의 다양한 단계에서 개발에 참여하고 있습니다. MLOps는 머신러닝 시스템의 개발, 배포, 관리 및 확장을 위한 효율적인 운영을 지원하고 있습니다.`,
    letsMake: `Let's make our own story together`,
    koreanSlogan: "“신약개발에 변화를 가져오길 희망합니다.”",
    linkedinHref: "생략",
  },
  {
    koreanName: `Andrew Ha`,
    englishName: `Andrew Ha`,
    role: ` Head of Business Development `,
    description: ` Business Development팀의 Head로서 디어젠이 세계시장으로 진출하여 글로벌 기업으로서의 역량을 갖추어 나가도록 노력하고 있습니다. 스탠포드에서 생물학 박사학위를 받았으며, 디어젠 이전에는 미국 스타트업 제약회사 제넥스에서 신약개발 연구를 하였습니다.`,
    letsMake: `Let’s make advancements`,
    koreanSlogan: "“빠른 성장과 세계시장 진출을 함께 만들어갑니다.“",
    linkedinHref: "https://www.linkedin.com/in/andrew-ha-94a638106",
  },

  /*
    {
        koreanName: `신봉근`,
        englishName: `Bonggun Shin`,
        role: `Representative of Deargen USA`,
        description: `디어젠의 공동창업자이자  Deargen USA의 법인장입니다. 인공지능 분야의 전문가로서 디어젠의  기술 역량을 발전시켜 글로벌 제약 업계의 충족되지 않은 요구를 효과적으로 해결할 수 있도록 끊임없이 노력합니다.  Illinois 공과 대학에서 컴퓨터 공학을 전공하고 KAIST 인공지능 석사를 거쳐 emory 대학에서 AI 기반의 약물 발견에 특화된 박사 학위를 취득하면서 기술적인 기량을 더욱 향상시켰습니다. 이러한 학문적 배경과 제약 분야에서 AI의 경계를 넓히려는 확고한 의지를 통해 디어젠의 소중한 자산으로 만듭니다.`,
        letsMake: `Let's make a BETTER FUTURE`,
        koreanSlogan: "“인공지능 기술을 통한 보다 나은 미래를 함께만들어 갑니다.”",
        linkedinHref: "https://www.linkedin.com/in/bgshin/",
    },
    {
        koreanName: `이보라`,
        englishName: `Violet`,
        role: `Leader`,
        description: `디어젠의 빅데이터 엔지니어로, 생명/의료 빅데이터를 분석 및 가공하여 신약개발을 위한 다양한 기술을 개발하고 있습니다. KAIST에서 생명화학공학을 전공했습니다.`,
        letsMake: `Let's make a NO.1`,
        koreanSlogan: "“신약개발 분야를 선도하는 넘버원 기업을 함께만들어 갑니다.”",
        linkedinHref: "https://www.linkedin.com/in/bora-lee-359a88195/",
    },
    {
        koreanName: `심원상`,
        englishName: `Wonsang Shim`,
        role: `Head of Digital Transformation`,
        description: `디어젠에서 Digital Transformation팀 head를 맡고 있습니다. 소프트웨어 개발 전문가로, 디어젠의 AI기술을 제품화하여 신약개발 분야에서 가치있는 서비스가 되도록 연구하고 있습니다.`,
        letsMake: `Let's make a HEALTHY LIFE`,
        koreanSlogan:
          "“디어젠 플랫폼을 통해 질병 없는 세상을 함께만들고 있습니다.”",
        linkedinHref: "",
    },
    {
        koreanName: `이예브게니`,
        englishName: `Evgenii Li`,
        role: `Leader`,
        description: `디어젠의 Machine Learning Engineer 입니다. Evgenii는 신약개발을 위한 기계 학습 모델의 설계 및 학습을 담당하고 있습니다. 서울대학교에서 컴퓨터공학 학사, 석사를 전공하였습니다. 디어젠 이전에는 국내 인공지능 스타트업에서 환자의 전자의료 데이터로부터 의미있는 패턴을 발견하는 업무를 담당했습니다.`,
        letsMake: `Let's make a CHANGE`,
        koreanSlogan: "“지금 보다 더 나은 신약개발을 위한 변화를 만들어 갑니다”",
        linkedinHref: "https://www.linkedin.com/in/evgenii-li-460b02106/?originalSubdomain=kr",
    },
    {
        koreanName: `김수현`,
        englishName: `Riaa`,
        role: `Researcher`,
        description: `디어젠에서 서비스 기획을 맡고 있습니다. 디어젠의 인공지능 기술에 디어젠의 이야기와 색을 담아 효과적으로 전달하는 법을 연구하고 있습니다. 경희대학교에서 유전공학을 전공했습니다.`,
        letsMake: `Let's make an IMPACT`,
        koreanSlogan: "“세상을 변화시킬 영향력을 함께만들어 갑니다.”",
        linkedinHref: "https://www.linkedin.com/in/kim-riaa-ba9a45115/",
    },
    {
        koreanName: `김대승`,
        englishName: `Daeseung Kim`,
        role: `Researcher`,
        description: `디어젠에서 유전체 데이터 엔지니어를 맡아 생물정보 분석 플랫폼 개발과 신약개발 서비스를 지원하고 있습니다. 단국대학교에서 생물정보학 석사학위를 받았으며 국내 유전체 기업에서 생물정보학 데이터 분석, 파이프라인 및 서비스를 개발하였습니다.`,
        letsMake: `Let's make a HUMAN-CENTERED SERVICE`,
        koreanSlogan: "“모두를 위한 서비스를 만들어갑니다.”",
        linkedinHref: "https://www.linkedin.com/in/%EB%8C%80%EC%8A%B9-%EA%B9%80-7a8607160/?originalSubdomain=kr",
    },
    {
        koreanName: `오동빈`,
        englishName: `Vincent`,
        role: `Researcher`,
        description: `디어젠의 Research scientist 입니다. 신약개발을 위한 기계 학습 및 딥러닝 기술을 개발하고 있습니다. 부산대학교에서 컴퓨터공학 석사학위를 받았습니다.`,
        letsMake: `Let's make a CUTTING-EDGE`,
        koreanSlogan: "“신약개발 분야의 새로운 첨단을 만들어 갑니다”",
        linkedinHref: "https://www.linkedin.com/in/dongpin-oh-ba271b154/",
    },
    {
        koreanName: `김영헌`,
        englishName: `YeongHeon Kim`,
        role: `Researcher`,
        description: `디어젠에서 바이오 분석연구를 수행하고 있으며, 한양대학교에서 생명과학 박사학위를 받았습니다. 디어젠 이전에는 한국원자력의학원에 근무하며, 방사선피폭손상치료 관련 모델 및 치료제를 개발하는 일을 하였습니다. 또한 암세포의 치료와 관련된 세포신호기전을 검증하는 연구를 수행하였습니다.`,
        letsMake: `Let's make a DIFFERENCE`,
        koreanSlogan: "“차별화된 기술로 신약개발에 기여하겠습니다.”",
        linkedinHref: "https://linkedin.com/in/young-heon-kim-191046208",
    },
    {
        koreanName: `신민지`,
        englishName: `MinJi Shin`,
        role: `Manager`,
        description: `디어젠 사업개발부에서 디어젠이 글로벌로 나아갈 수 있도록 새로운 파트너쉽을 발굴하고 협력이 이루어 질 수 있게 이끌고 있습니다. 토론토대학교에서 생리학을 전공하였으며, 바이엘코리아 임상팀에서 근무하며 임상 경력을 쌓았습니다.`,
        letsMake: `Let's make THINGS HAPPEN`,
        koreanSlogan: "신약개발로 모든 질병의 치료가 가능해지는 세상을 함께 만들어 갑니다”",
        linkedinHref: "https://www.linkedin.com/in/minji-shin-76b0b8171",
    },
    {
        koreanName: `남설화`,
        englishName: `Seolhwa Nam`,
        role: `Manager`,
        description: `디어젠 경영관리부에서 인사/총무 업무를 맡고 있습니다.  디어벤져스가 좋은 환경에서 업무할 수 있는 방법이 무엇인지 항상 연구하고 있습니다.`,
        letsMake: `Let's make a Path`,
        koreanSlogan: "“신약 개발의 길잡이가 되겠습니다.“",
        linkedinHref: "https://www.linkedin.com/in/seolhwa-nam-ab4348216/",
    },
    {
        koreanName: `변정현`,
        englishName: `Peter Byun`,
        role: `Researcher`,
        description: `디어젠의 research scientist 입니다. 정현 (Peter)은 University College London에서 Machine Learning 석사 학위를 취득하였고 Knowledge Graph Completion을 중점적으로 연구하였습니다. 디어젠 이전에는 의료 영상 스타트업에서 연구했었고 컴퓨터 비전과 NLP에 능숙한 열정적인 연구자입니다.`,
        letsMake: `Let’s make Disruptive Technologies`,
        koreanSlogan: "“신약개발에서 와해성 혁신을 불러일으킬 기술을 만들어나갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/jeung-hyun-byun-9b7411111/",
    },
    {
        koreanName: `유충현`,
        englishName: `Chunghyeon Yu`,
        role: `Researcher`,
        description: `디어젠에서 합성연구를 수행하고 있으며 한양대학교에서 유기합성을 전공하며 박사학위를 받았습니다. 디어젠 이전에는 연성정밀화학에 근무하며 제네릭 의약품 연구를 통해 대량생산이 가능한 공정을 개발했습니다.`,
        letsMake: `Let’s make imagination into reality`,
        koreanSlogan: "“신약개발이라는 꿈을 현실로 만들겠습니다.“",
        linkedinHref: "https://www.linkedin.com/in/%EC%B6%A9%ED%98%84-%EC%9C%A0-957145196",
    },
    {
        koreanName: `윤지훈`,
        englishName: `JeeHun Yun`,
        role: `Researcher`,
        description: `디어젠의 medicinal chemistry 연구원으로, 약물을 디자인하고 합성하는 업무를 수행하고 있습니다. 영남대학교에서 석사 학위를 받았으며, JW C&C신약연구소에서 10년 동안 항암제, 면역치료제등의 연구 경험이 있습니다.`,
        letsMake: `Let’s make happiness for patients and their families through new drug development`,
        koreanSlogan: "“신약개발로 환자와 가족 모두의 행복을 추구합니다.“",
        linkedinHref: "http://linkedin.com/in/지훈-윤-b02b5220b",
    },
    {
        koreanName: `박헌규`,
        englishName: `Park, HeonKyu`,
        role: `Researcher`,
        description: `디어젠 신약개발부 의약화학팀 책임연구원으로 Drug design 및 합성 업무을 담당하고 있습니다. 충북대학교에서 석사학위를 받았으며 JW C&C 신약연구소에서 14년동안 다수의 항암제 연구를 수행하였습니다.`,
        letsMake: `Let’s make the CHANCE`,
        koreanSlogan: "“신약개발을 통해 난치병 환자들의 치료 기회와 가능성을 함께 만들어갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/heon-kyu-park-80a77a185/",
    },
    {
        koreanName: `조명권`,
        englishName: `Myung Kwon Cho`,
        role: `Researcher`,
        description: `디어젠에서 후보약물 약효 평가를 담당하고 있습니다. 인하대학교에서 생명공학 전공으로 석사학위를 취득하였고, 신경질환 및 염증성 질환 타겟 약리연구를 주로 수행하였습니다. 디어젠 이전에는 종근당과 코오롱 생명과학에 근무하였고, 신경질환 치료제와 항염증제 개발 프로젝트에 참여하여 비임상연구를 진행했습니다.`,
        letsMake: `Let’s make a solution for patient’s unmet needs`,
        koreanSlogan: "“신약개발을 통해 환자에게 필요한 해결책을 함께 만들어 갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/myungkwon-cho-962842210",
    },
    {
        koreanName: `임호랑`,
        englishName: `Horang Lim`,
        role: `Researcher`,
        description: `디어젠의 브랜드디자이너 입니다. 혁신적인 디어젠의 기술과 스토리를 Visualization 하여 전달 합니다.`,
        letsMake: `Let's make Innovative colors`,
        koreanSlogan: "“혁신적인 삶의 가치를 디자인합니다.“",
        linkedinHref: "https://www.linkedin.com/in/horang-lim-729933225/",
    },
    {
        koreanName: `최호재`,
        englishName: `Hojae Choi`,
        role: `Researcher`,
        description: `GIST에서 EECS 학사, KAIST에서 생물정보학 석사학위를 받았으며, 전사체 및 생분자 네트워크 기반 질병 진단, 치료 마커 발굴 기술을 중점적으로 연구했습니다.`,
        letsMake: `Let’s make 'Big first step'`,
        koreanSlogan: "“신약개발을 위한 인공지능 기술로의 큰 첫걸음을 함께 만들어 갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/hojae-choi-163860100/",
    },
    {
        koreanName: `이대석`,
        englishName: `Dae Lee`,
        role: `Researcher`,
        description: `디어젠 신기술 개발팀에서 인공지능을 연구하고 있습니다.  신약개발 관련 다양한 딥러닝 주제에 관심이 있습니다. 수학 전공으로 석사학위를 받았습니다.`,
        letsMake: `Let’s make a better world`,
        koreanSlogan: "“더 살기 좋은 세상을 함께 만들어 갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/dae-lee-531229226/",
    },
    {
        koreanName: `김태묵`,
        englishName: `Taemook Kim`,
        role: `Researcher`,
        description: `디어젠 iDearCenter 에서 타겟 발굴 연구를 수행하고 있습니다.  KAIST에서 후성유전학과 생물정보학으로 박사학위를 받았으며, 다양한 실험모델에서 NGS 데이터를 이용하여 유전체 분석을 주로 수행하였습니다.`,
        letsMake: `Let’s make an innovative medicine`,
        koreanSlogan: "“혁신적인 새로운 신약을 함께 만들어갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/taemook-kim-64501613b/",
    },
    {
        koreanName: `이승규`,
        englishName: `Seungkyu Lee`,
        role: `Researcher`,
        description: `디어젠 iDearCenter 합성신약팀에서 후보물질의 합성가능성을 연구하고 있습니다. 성균관대 약학과에서 의약화학을 공부하며 석사 학위를 받았고, 연성정밀화학에서 근무하며 원료의약품의 공정을 연구했습니다.`,
        letsMake: `Let's make a NEW HOPE`,
        koreanSlogan: "“세상을 변화시킬 새로운 희망을 만듭니다.“",
        linkedinHref: "https://www.linkedin.com/in/chicq",
    },
    {
        koreanName: `장은주`,
        englishName: `Jang Eunju`,
        role: `Leader`,
        description: `디어젠에서 경영기획 및 관리 업무를 맡고 있습니다. 디어젠 이전에는 국내 제약사 기획팀에서 6년간 근무하며 손익 분석, 사업성 검토, 전략 기획 등의 다양한 업무를 수행하였습니다.`,
        letsMake: `Let’s make a NEW WAVE`,
        koreanSlogan: "“신약개발의 새로운 물결을 만들고 관리해 나갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/eunju-jang-74958414b",
    },
    {
        koreanName: `윤현준`,
        englishName: `Yoon Hyunjun`,
        role: `Researcher`,
        description: `디어젠 CADD 팀 소속의 연구원입니다. 건국대학교에서 생명공학 석사 학위를 취득하였으며, 의약화학과 구조생화학이 주 분야입니다. 이전에는 인공지능 신약개발 회사 에일론에서 인공지능을 이용하여 다양한 질병에 대하여 신약 후보물질 개발을 진행하였습니다.`,
        letsMake: `Let’s make a LEAP`,
        koreanSlogan: "“새로운 기술을 통하여 신약개발의 큰 도약을 이룹니다.“",
        linkedinHref: "https://www.linkedin.com/in/hyunjun-yoon-564ab6232/",
    },
    {
        koreanName: `반가히`,
        englishName: `Gahee Bahn`,
        role: `Leader`,
        description: `디어젠 eR&D팀의 리더로서 디어젠 파이프라인에 대한 연구를 수행하고 있습니다. 성균관대 약학과에서 분자신경생물학으로 박사학위를 받았으며, 디어젠 이전에 이수앱지스와 ABLbio에서 퇴행성뇌질환 타겟 신약개발 연구를 수행하였습니다.`,
        letsMake: ` Let’s make a NEW STORY`,
        koreanSlogan: "“신약개발로 세상에 새로운 이야기를 함께 만들어갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/가히-반-4205991b3/",
    },
    {
        koreanName: `조용준`,
        englishName: `Yongjun Cho`,
        role: `Researcher`,
        description: `디어젠의 머신러닝 엔지니어로 강화학습과 딥러닝을 이용하여 신약개발을 위한 기술을 개발하고 있습니다. KAIST 전기및전자공학부에서 석사 학위를 받았으며, 인공지능과 강화학습을 전공하였습니다.`,
        letsMake: ` Let's make a Synergy`,
        koreanSlogan: "“인공지능을 통해 신약개발에 시너지를 일으킵니다.“",
        linkedinHref: "https://www.linkedin.com/in/용준-조-96a96b231/",
    },
    {
        koreanName: `원숙영`,
        englishName: `Suk Young Won`,
        role: `Researcher`,
        description: `합성신약팀에서 신약 후보물질의 합성 프로세스를 연구하고 있습니다. 이화여자대학교에서 유기화학 석사 학위를 취득하였고, 디어젠 이전에는 국내 신약개발 회사에서 근무하며 신약 후보물질 개발을 연구했습니다.`,
        letsMake: ` Let’s make a FRUITION`,
        koreanSlogan: "“끝내 목표를 달성하여 신약개발 성과의 결실을 함께 만들어 갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/suk-young-won-4a1122237/",
    },
    {
        koreanName: `정주희`,
        englishName: `JuHui Jeong`,
        role: `Researcher`,
        description: `디어젠 합성신약팀의 연구원으로 유기합성을 통한 신약개발을 진행하고있습니다.`,
        letsMake: ` Let’s make a miracle`,
        koreanSlogan: "“최상의 신약개발을 통해 기적을 만들어 내고 싶습니다.“",
        linkedinHref: "https://www.linkedin.com/in/주희-정-1b3a19236/",
    },
    {
        koreanName: `엄지`,
        englishName: `Ji Eom`,
        role: `Researcher`,
        description: `iDearCenter의 약리연구팀 연구원입니다. 울산대학교 의학과에서 석사학위를 취득하였고, 수년간 신약개발회사와 제약회사에서 다양한 질환을 대상으로 하는 약물 스크리닝과 약리연구를 진행했습니다. `,
        letsMake: ` Let’s make from question mark to exclamation mark`,
        koreanSlogan: "“신약개발의 물음표를 느낌표로 함께 만들어갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/eomji0918/",
    },
    {
        koreanName: `조소미`,
        englishName: `Somi Cho`,
        role: `Researcher`,
        description: `디어젠의 약리연구팀 소속 연구원입니다. 한양대학교에서 석사학위를 받았으며, 면역학을 전공하였습니다. 약물의 효능평가와 자가면역질환에 대한 연구를 주로 수행하였습니다.`,
        letsMake: ` Let’s make a memory`,
        koreanSlogan: "“신약개발로 모두에게 행복한 기억을 만들어 갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/소미-조-a17a46238/",
    },
    {
        koreanName: `류지혜`,
        englishName: `Jihye Ryu`,
        role: `Researcher`,
        description: `디어젠에서 타겟 발굴을 담당하고 있습니다. 서울대 약학과에서 의약 생명과학 전공으로 박사학위를 취득하였고, 주로 항암 혹은 간대사질환 관련 후보물질 발굴 및 기전연구를 수행하였습니다. 디어젠 이전에는 녹십자에서 근무하였고, 면역항암제와 대사질환 치료제 개발 과제에 참여하여 후보물질 발굴 및 효능평가를 수행하였습니다.`,
        letsMake: ` Let's make a MIRACLE`,
        koreanSlogan: "“세상을 놀라게 할 기적을 함께 만들어 갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/jihye-ryu-130950218/",
    },
    {
        koreanName: `신혜영`,
        englishName: `Haeyoung Shin`,
        role: `Researcher`,
        description: `디어젠에서 책임연구원으로 약효 평가를 담당하고 있습니다. 아주대학교에서 신경과학 박사학위를 취득하였으며, 이후 신약개발 회사에서 신경계 질환 치료제의 약효평가 및 기전연구를 수행하였습니다.`,
        letsMake: ` Let's make harmonious environment`,
        koreanSlogan: "“조화롭고 협동적인 환경에서 안정적으로 신약개발을 만들어나갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/혜영-신-664b494a/",
    },
    {
        koreanName: `이정아`,
        englishName: `Jeong-A Lee`,
        role: `Researcher`,
        description: `서울여자대학교 식품공학과에서 독성학을 전공하여 석사학위를 받았습니다. 이후 수년간 신약개발 회사에서 항암제와 중추신경계 질환 치료제의 약효 평가 및 기전 연구를 수행하였습니다.`,
        letsMake: ` Let's make our miracle`,
        koreanSlogan: "“신약개발의 성공으로 환자들에게 기적을 선물하고 싶습니다.“",
        linkedinHref: "https://www.linkedin.com/in/jeong-a-lee-81b1a2241/",
    },
    {
        koreanName: `손우승`,
        englishName: `Woo Seung Son`,
        role: `Researcher`,
        description: `디어젠에서 의약화학 연구원으로서 신약 합성을 담당하고 있습니다. 연세대에서 유기화학 전공으로 박사학위를 취득하였습니다. KIST에서 자가면역질환, 퇴행성 뇌질환의 신약 개발을 수행하였고 국내 제약사에서 항암제 연구에 참여했었습니다.`,
        letsMake: ` Let’s make a better tomorrow`,
        koreanSlogan: "“신약 개발을 통해 오늘보다 더 나은 내일을 함께 만들어갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/우승-손-496432242/",
    },
    {
        koreanName: `정영경`,
        englishName: `Youngkyung Jung`,
        role: `Researcher`,
        description: `디어젠 iDearCenter 합성신약팀에서 신약 후보물질의 디자인 및 합성 업무를 담당하고 있습니다. 가천대학교 약학과에서 석사학위를 취득하였고, 디어젠 이전에는 국내 신약개발 회사에서 국가신약개발과제 작성 및 신약 개발 연구를 수행하였습니다.`,
        letsMake: ` Let’s make a new paradigm`,
        koreanSlogan: "“신약개발 분야의 새로운 패러다임을 만들어갑니다.“",
        linkedinHref: "https://www.linkedin.com/in/영경-정-372111245/",
    },
    {
        koreanName: `박혜빈`,
        englishName: `Hye Bin Park`,
        role: `Researcher`,
        description: `디어젠에 iDearCenter 합성신약팀 연구원으로서 신약 후보물질의 합성을 담당하고 있습니다. 한국화학연구원과 성균관대에서 유기화학 전공으로 석사학위를 취득하였고, 디어젠 이전에는 국내 신약개발 회사에서 근무하며 다수의 항암제 연구를 수행했습니다.`,
        letsMake: ` Let's make a solution`,
        koreanSlogan: "“신약개발을 통해 난치병 환자들에게 도움이 될 수 있는 해결책을 만들어갑니다.“",
        linkedinHref: "",
    },
    {
        koreanName: `정수민`,
        englishName: `Suemin Jeong`,
        role: `Sr. Manager`,
        description: `사업개발부에서 신규 사업을 발굴하고 세계시장 진출을 리딩하는 업무를 맡고 있습니다. Emory 대학교에서 경제학, 사회학 복수전공으로 졸업 및 서울대학교 MBA 석사 학위를 취득 하였으며, 셀트리온헬스케어 해외사업부에서 해외법인 관리 및 사업개발 업무를 담당했습니다.`,
        letsMake: ` Let's make a new direction`,
        koreanSlogan: "“차별화된 기술로 의료산업의 새로운 방향성을 제시하고자 합니다.“",
        linkedinHref: "https://www.linkedin.com/in/sueminj/",
    },
    */
  //자문위원
  {
    koreanName: `이대엽`,
    englishName: `Daeyeop Lee`,
    role: `기초과학 자문`,
    description: `한국과학기술원(KAIST), Ph.D.\nKAIST 생명과학과교수\nResearch center for cellular identity 단장\n전문분야) 후성유전학, Proteasome`,
    isAdvisor: true,
  },
  {
    koreanName: `김규봉`,
    englishName: `Kyubong Kim`,
    role: `임상/약학/독성학 자문`,
    description: `The University of Georgia, Ph.D.\n단국대학교 약학대학 약학과\n전문분야) 독성 대사체학, 신약개발 및 의약품 허가`,
    isAdvisor: true,
  },
  {
    koreanName: `안영호`,
    englishName: `Yungho Ahn`,
    role: `In-vitro PoC`,
    description: `서울대학교, Ph.D.\n이화여자대학교 의과대학\n전문분야) Oncology 신약 후보 물질 발굴 및 검증`,
    isAdvisor: true,
  },
  {
    koreanName: `한규동`,
    englishName: `Kyudong Han`,
    role: `In-vitro PoC`,
    description: `Louisiana State University, Ph.D.\n단국대학교 나노바이오 의과학과\n전문분야) 비교 유전체학, Next-generation sequencing facility`,
    isAdvisor: true,
  },
  {
    koreanName: `정재훈`,
    englishName: `Jaehoon Chung`,
    role: `유전학 자문`,
    description: `Indiana University, Ph.D.\nKAIST 생명과학과 명예교수\n전) KAIST 생명과학과 교수\n전문분야) 후성유전학, Genomic imprinting`,
    isAdvisor: true,
  },
  {
    koreanName: `유경현`,
    englishName: `Kyunghyun Yoo`,
    role: `전임상 자문`,
    description: `숙명여자대학교, Ph.D.\n숙명여자대학교 생명시스템학부\n전문분야) 간암, 유방암 연구, In-vitro 및 In-vivo, 동물 모델`,
    isAdvisor: true,
  },
  {
    koreanName: `강규호`,
    englishName: `Kyuho Kang`,
    role: `염증질환, In-vitro PoC`,
    description: `코넬의대(Weill Cornell Medicine), Ph.D.\n충북대학교 생물학과\n전문분야) 면역학, 류마티스 관절염`,
    isAdvisor: true,
  },
];

const 영어프로필 = [
  {
    koreanName: ``,
    englishName: `Kilsoo Kang`,
    role: `CEO`,
    description: `Kilsoo is the co-founder and CEO of DEARGEN. By taking charge of the management of DEARGEN, Kilsoo promotes cooperation between DEARGEN and many other experts in pharmaceutical and biology fields so that the biomedical data and AI technology can lead to the discovery of the fundamental treatment of diseases. Also, he is an expert in big data and a software architect who has 15 years’ experience in the IT field. Before establishing DEARGEN, he worked as a leader of big data platform development in NAVER.`,
    letsMake: `Let's make a DREAM`,
    linkedinHref:
      "https://www.linkedin.com/in/gilsu-kang-16269537/?originalSubdomain=kr",
  },
  {
    koreanName: ``,
    englishName: `Sungsoo Park`,
    role: `CTO`,
    description: `Sungsoo is the co-founder and CTO of DEARGEN. As a deep learning specialist, he is in charge of developing AI technology in DEARGEN to research and develop our company’s own core technology. Sungsoo has registered many patents and won awards in many international competitions related to deep learning. Before founding DEARGEN, he was the founder and CEO of DEEPImagine.`,
    letsMake: `Let's make a WAY`,
    linkedinHref:
      "https://www.linkedin.com/in/%EC%84%B1%EC%88%98-%EB%B0%95-b79ab262/?originalSubdomain=kr",
  },
  {
    koreanName: ``,
    englishName: `Keunsoo Kang`,
    role: `CSO`,
    description: `Keunsoo is the co-founder and CSO of DEARGEN. He received his bachelor's degree (double majored in Life Science and Computer Science and Engineering) from Sogang University in 2006 and his Ph.D. in bioinformatics from Korea Advanced Institute of Science and Technology (KAIST) in 2011. As a multidisciplinary researcher, he leads several drug-development pipelines by using DEARGEN's cutting-edge AI technologies. Keunsoo is currently an assistant professor at Dankook University (Department of Microbiology) and has published more than 80 SCI-indexed research papers.`,
    letsMake: `Let's make an INNOVATION`,
    linkedinHref: "https://www.linkedin.com/in/keunsoo-kang-7a000b37/",
  },
  {
    koreanName: ``,
    englishName: `Sean Kim`,
    role: `Head of Strategy & Management`,
    description: `As Deargen's head of the Strategy & Management department, he is in charge of planning R&D strategy and portfolio management. He received Ph.D. in tumor biology at Seoul National University. He has experience in pharmacological research at Hanmi pharmaceutical company, reviewed over 500 drug development projects, and managed more than 50 projects at Korea Drug Development Fund.`,
    letsMake: `Let’s make the Revolution`,
    linkedinHref: "https://www.linkedin.com/in/suhyeon-sean-kim-375198158/",
  },
  {
    koreanName: ``,
    englishName: `Jong young Joung`,
    role: `Lead of CADD`,
    description: `As the leader of the Deargen CADD team, I am in charge of Computational Chemistry. In particular, we are studying a new methodology for discovering new drug candidates that combine artificial intelligence and molecular modeling knowledge. He received a doctorate in biotechnology in molecular modeling and computational chemistry from Yonsei University. In the postdoctoral course of the Institute of Advanced Research (KIAS), he developed an algorithm for predicting the structure of protein complexes and studied the thermodynamic energy of biomolecules using molecular dynamics.`,
    letsMake: `Let's make a NOVEL PARADIGM`,
    linkedinHref: "",
  },
  {
    koreanName: ``,
    englishName: `Jimin Park`,
    role: `Lead of AI`,
    description: `Jimin is in charge of the AI team lead at Deargen. After earning her doctorate in structural biology from Ewha Womans University, she studied protein structure modeling methods at the Advanced Institute of Science (KIAS). She is conducting research to discover new drug candidates by fusing several research fields such as pharmacy, structural biology, molecular modeling, and deep learnings.`,
    letsMake: `Let’s make a history`,
    linkedinHref: "https://www.linkedin.com/in/jimin-park-608338161/",
  },
  {
    koreanName: ``,
    englishName: `Dong ju Lee`,
    role: `Lead of MLOps`,
    description: `As a leader of the Deargen MLOps team, he has experience in developing AI platforms and fintech backend and is involved in development at various stages of the product lifecycle. MLOps supports efficient operation for the development, deployment, management, and expansion of machine learning systems.`,
    letsMake: `Let's make our own story together`,
    linkedinHref: "",
  },
  {
    koreanName: ``,
    englishName: `Andrew Ha`,
    role: `Head of Global Business Development`,
    description: `As the lead of business development at Deargen, Andrew strives to advance Deargen into the international market by accelerating global business plans and spearheading collaborative partnerships internationally. Andrew received his Ph.D. in Biology at Stanford University. Before joining Deargen, he was a scientist at Janux Therapeutics developing innovative immunotherapies for cancer.`,
    letsMake: `Let’s make advancements`,
    linkedinHref: "https://www.linkedin.com/in/andrew-ha-94a638106",
  },
  /*
    {
        koreanName: ``,
        englishName: `Bonggun Shin`,
        role: `Representative of Deargen USA`,
        description: `Bonggun is the co-founder and the representative at Deargen USA. With a profound expertise in the field of artificial intelligence, Bonggun relentlessly endeavors to advance the technological capabilities of DEARGEN, enabling it to effectively address the unmet demands of the global pharmaceutical industry. Having pursued a Bachelor’s degree in computer science from the Illinois Institute of Technology, Bonggun further honed his skills by acquiring a Master’s degree in AI from KAIST, followed by a Ph.D. specializing in AI-driven drug discovery from Emory University. His exceptional academic background and unwavering commitment to pushing the boundaries of AI in the pharmaceutical domain make him an invaluable asset to the Deargen team.`,
        letsMake: `Let's make a BETTER FUTURE`,
        linkedinHref: "https://www.linkedin.com/in/bgshin/",
    },
    {
        koreanName: ``,
        englishName: `Violet`,
        role: `Leader`,
        description: `Violet is a big data engineer at DEARGEN, and she develops diverse technologies for the new drug development through the analysis and processing of big data in life/medical care fields. Violet majored in biochemical engineering at KAIST.`,
        letsMake: `Let's make a NO.1`,
        linkedinHref: "https://www.linkedin.com/in/bora-lee-359a88195/",
    },
    {
        koreanName: ``,
        englishName: `Wonsang Shim`,
        role: `Head of Digital Transformation`,
        description: `Wonsang is the Head of Digital Transformation at DEARGEN. He is a specialist in software development, and he makes products by utilizing the AI technology of DEARGEN and conducts research to ensure these products become valuable services in the new drug development field.`,
        letsMake: `Let's make a HEALTHY LIFE`,
        linkedinHref: "",
    },

    {
        koreanName: ``,
        englishName: `Evgenii Li`,
        role: `Leader`,
        description: `Evgenii is Machine Learning Engineer at DEARGEN. He is in charge of designing and training machine learning models for Drug Development. Previously he had experience in finding meaningful discriminative patterns in Electronic Health Records. Evgenii was majoring in computer science and received his master’s degree in Bio and Health Informatics at Seoul National University.`,
        letsMake: `Let's make a CHANGE`,
        linkedinHref: "https://www.linkedin.com/in/evgenii-li-460b02106/?originalSubdomain=kr",
    },
    {
        koreanName: ``,
        englishName: `Riaa`,
        role: `Researcher`,
        description: `Riaa is in charge of service planning at DEARGEN. Currently, she conducts research on how to effectively deliver DEARGEN’s story and specialty in DEARGEN’s AI technology. Majored in genetic engineering at Kyunghee University.`,
        letsMake: `Let's make an IMPACT`,
        linkedinHref: "https://www.linkedin.com/in/kim-riaa-ba9a45115/",
    },
    {
        koreanName: ``,
        englishName: `Daeseung Kim`,
        role: `Researcher`,
        description: `Daeseung is a genomic data engineer of DEARGEN, and he has been developing a bioinformatic analysis platform and supporting new drug development. He received his master’s degree in bioinformatics at Dankook University. After graduation, Daeseung carried out bioinformatics data analysis along with developing a pipeline and service at a Korean genomics company.`,
        letsMake: `Let's make a HUMAN-CENTERED SERVICE`,
        linkedinHref: "https://www.linkedin.com/in/%EB%8C%80%EC%8A%B9-%EA%B9%80-7a8607160/?originalSubdomain=kr",
    },
    {
        koreanName: ``,
        englishName: `Vincent`,
        role: `Researcher`,
        description: `Vincent is a research scientist at DEARGEN, and he develops machine learning model and deep-learning technology for Drug Discovery. Vincent received his master degree in computer engineering at Pusan National University`,
        letsMake: `Let's make a CUTTING-EDGE`,
        linkedinHref: "https://www.linkedin.com/in/dongpin-oh-ba271b154/",
    },
    {
        koreanName: ``,
        englishName: `YeongHeon Kim`,
        role: `Researcher`,
        description: `Young-heon is a researcher at DEARGEN, and he is in charge of leading some of the 4D programs. He received his Ph.D. in Life Science from Hanyang University. Before Young-heon joined DEARGEN, he worked at the KOREA Institute of Radiological & Medical Sciences and developed models and treatments related to radiation exposure damage. In addition, he studied to verify the cell signalling mechanisms related to cancer cell treatment.`,
        letsMake: `Let's make a DIFFERENCE`,
        linkedinHref: "https://linkedin.com/in/young-heon-kim-191046208",
    },
    {
        koreanName: ``,
        englishName: `MinJi Shin`,
        role: `Manager`,
        description: `As a Business Development Manager at DEARGEN, MinJi explores new partnerships and leads the cooperation between global R&D and DEARGEN. She majored in physiology at the University of Toronto. Before joining DEARGEN, MinJi worked in the Medical department to build a clinical trial career at Bayer Korea.`,
        letsMake: `Let's make THINGS HAPPEN`,
        linkedinHref: "https://www.linkedin.com/in/minji-shin-76b0b8171",
    },
    {
        koreanName: ``,
        englishName: `Seolhwa Nam`,
        role: `Manager`,
        description: `Seolhwa is in charge of the business administration at Deargen. She will support 'Dearvangers' to have a better work environment. Seolhwa has great communication skills and will be a communication channel for all departments in Deargen.`,
        letsMake: `Let's make a path`,
        linkedinHref: "https://www.linkedin.com/in/seolhwa-nam-ab4348216/",
    },
    {
        koreanName: ``,
        englishName: `Peter Byun`,
        role: `Researcher`,
        description: `Peter is a research scientist at DEARGEN. He completed his Master’s degree in Machine Learning at University College London where he specialized in Knowledge Graph Completion. Peter is a passionate researcher who has an experience in both Computer Vision and NLP.`,
        letsMake: `Let’s make Disruptive Technologies`,
        linkedinHref: "https://www.linkedin.com/in/jeung-hyun-byun-9b7411111/",
    },
    {
        koreanName: ``,
        englishName: `Chunghyeon Yu`,
        role: `Researcher`,
        description: `Chunghyeon oversees synthesizing new drugs at DEARGEN. He received a Ph.D. in organic synthesis on the topic of synthetic methods using visible light at the department of BioNano Technology, Hanyang University. Before joining DEARGEN, he studied the process development of generic drugs at Yonsung Fine Chemical.`,
        letsMake: `Let’s make imagination into reality`,
        linkedinHref: "https://www.linkedin.com/in/%EC%B6%A9%ED%98%84-%EC%9C%A0-957145196",
    },
    {
        koreanName: ``,
        englishName: `JeeHun Yun`,
        role: `Researcher`,
        description: `He is a medicinal chemistry researcher at Deargen, responsible for designing and synthesizing drugs. He received his master's degree in chemistry at Yeungnam University and has 10 years of research experiences including anti-cancer drugs and immunotherapeutics at JW C&C Research Laboratories.`,
        letsMake: `Let’s make happiness for patients and their families through new drug development`,
        linkedinHref: "http://linkedin.com/in/지훈-윤-b02b5220b",
    },
    {
        koreanName: ``,
        englishName: `Park, HeonKyu`,
        role: `Researcher`,
        description: `He is a medicinal chemist of Deargen's New Drug Development Department and is in charge of drug design and organic synthesis. He received his master's degree in organic synthesis from Chungbuk National University. He worked at JW C&C Research Laboratories for 14 years in the fields of anticancer drugs.`,
        letsMake: `Let’s make the CHANCE`,
        linkedinHref: "https://www.linkedin.com/in/heon-kyu-park-80a77a185/",
    },
    {
        koreanName: ``,
        englishName: `Myung Kwon Cho`,
        role: `Researcher`,
        description: `Myung Kwon Cho is responsible for evaluating the efficacy of candidate drugs. He graduated from INHA University with a master’s degree in biological engineering and mainly studied pharmacology targeting neurological and inflammatory diseases. Also, before joining Deargen, he worked at CHONG KUN DANG and KOLON life science and was involved in the neurologic disease treatment and anti-inflammatory drug development project for non-clinical studies.`,
        letsMake: `Let’s make a solution for patient’s unmet needs`,
        linkedinHref: "https://www.linkedin.com/in/myungkwon-cho-962842210",
    },
    {
        koreanName: ``,
        englishName: `Horang Lim`,
        role: `Researcher`,
        description: `Horang delivers Deargen’s innovative technology and story by visualization.`,
        letsMake: `Let's make Innovative colors`,
        linkedinHref: "https://www.linkedin.com/in/horang-lim-729933225/",
    },
    {
        koreanName: ``,
        englishName: `Hojae Choi`,
        role: `Researcher`,
        description: `Hojae is a research scientist at DEARGEN, He received a bachelor's degree in EECS from GIST and a master's degree in bioinformatics from KAIST. He focused his research on disease diagnosis and therapeutic marker discovery technology based on transcriptome and biomolecular networks.`,
        letsMake: `Let’s make 'Big first step'`,
        linkedinHref: "https://www.linkedin.com/in/hojae-choi-163860100/",
    },
    {
        koreanName: ``,
        englishName: `Dae Lee`,
        role: `Researcher`,
        description: `Dae is an AI researcher at DEARGEN. His research interests include all aspects of deep learning technologies related to drug discovery. He has a master's degree in mathematics.`,
        letsMake: `Let’s make a better world`,
        linkedinHref: "https://www.linkedin.com/in/dae-lee-531229226/",
    },
    {
        koreanName: ``,
        englishName: `Taemook Kim`,
        role: `Researcher`,
        description: `Taemook is performing target discovery research at DEARGEN's drug development laboratory and was received a doctoral degree in KAIST for epigenetic and bioinformatics. He mainly performed genomic analysis using NGS data in various experimental models.`,
        letsMake: `Let’s make an innovative medicine`,
        linkedinHref: "https://www.linkedin.com/in/taemook-kim-64501613b/",
    },
    {
        koreanName: ``,
        englishName: `Seungkyu Lee`,
        role: `Researcher`,
        description: `Seungkyu is a researcher at iDearcenter studying the possibility of synthesizing drug candidates. He studied medicinal chemistry at SKKU, Department of Pharmacy, and received his master's degree. After graduation, he worked at Yonsung Fine Chemicals, researching API process development.`,
        letsMake: `Let's make a NEW HOPE`,
        linkedinHref: "https://www.linkedin.com/in/chicq",
    },
    {
        koreanName: ``,
        englishName: `Jang Eunju`,
        role: `Leader`,
        description: `Eunju is in charge of management and planning at Deargen. Before Deargen, she worked in a pharmaceutical company's planning and coordination team. She performed various tasks such as profit and loss analysis, business feasibility review, and strategic planning.`,
        letsMake: `Let’s make a NEW WAVE`,
        linkedinHref: "https://www.linkedin.com/in/eunju-jang-74958414b",
    },
    {
        koreanName: ``,
        englishName: `Yoon Hyunjun`,
        role: `Researcher`,
        description: `Hyunjun is a member of the Deargen CADD research team. He has a master's degree in biotechnology from Konkuk University, specializing in medicinal chemistry and structural biochemistry. Hyunjun used to work at an AI drug-discovery company called Ailon, where he utilized AI to develop new drug candidates for various diseases.`,
        letsMake: `Let’s make a LEAP`,
        linkedinHref: "https://www.linkedin.com/in/hyunjun-yoon-564ab6232/",
    },
    {
        koreanName: ``,
        englishName: `Gahee Bahn`,
        role: `Leader`,
        description: `Gahee is a leader at the eR&D team of DEARGEN. She received a doctoral degree in molecular neurobiology from Sungkyunkwan University. Before joining DEARGEN, she worked at ISU ABXIS and ABLbio to develop novel drugs for neurodegenerative diseases.`,
        letsMake: `Let’s make a NEW STORY`,
        linkedinHref: "https://www.linkedin.com/in/가히-반-4205991b3/",
    },
    {
        koreanName: ``,
        englishName: `Yongjun Cho`,
        role: `Researcher`,
        description: `Yongjun is the machine learning engineer of DEARGEN. He develops reinforcement learning and deep learning technologies for the new drug development. He received a Master's degree in electrical engineering at KAIST and majored in artificial intelligence and reinforcement learning.`,
        letsMake: ` Let's make a Synergy`,
        linkedinHref: "https://www.linkedin.com/in/용준-조-96a96b231/",
    },
    {
        koreanName: ``,
        englishName: `Suk Young Won`,
        role: `Researcher`,
        description: `Sukyoung is an organic synthesis researcher at DEARGEN, studying the synthesis process of the new drug candidate. She received a master's degree in organic chemistry from Ewha Womans University. She worked at a domestic drug development company to research a new concept of antibiotics to overcome resistance.`,
        letsMake: ` Let’s make a FRUITION`,
        linkedinHref: "https://www.linkedin.com/in/suk-young-won-4a1122237/",
    },
    {
        koreanName: ``,
        englishName: `JuHui Jeong`,
        role: `Researcher`,
        description: `As a researcher in the Deargen Synthetic New Drug Team, JuHui advances the development of new drugs through organic synthesis.`,
        letsMake: ` Let’s make a miracle`,
        linkedinHref: "https://www.linkedin.com/in/주희-정-1b3a19236/",
    },
    {
        koreanName: ``,
        englishName: `Ji Eom`,
        role: `Researcher`,
        description: `Ji received a master's degree in Department of Medicine at Ulsan University. She conducted drug screening and pharmacological research on various diseases in a new drug development company and pharmaceutical company for several years.`,
        letsMake: ` Let’s make from question mark to exclamation mark`,
        linkedinHref: "https://www.linkedin.com/in/eomji0918/",
    },
    {
        koreanName: ``,
        englishName: `Somi Cho`,
        role: `Researcher`,
        description: `Somi is a researcher at Deargen's Pharmacology Research Team. She received a master's degree from Hanyang University and majored in immunology. She mainly performed drug efficacy evaluations and autoimmune diseases.`,
        letsMake: ` Let’s make a memory`,
        linkedinHref: "https://www.linkedin.com/in/소미-조-a17a46238/",
    },
    {
        koreanName: ``,
        englishName: `Jihye Ryu`,
        role: `Researcher`,
        description: `Jihye is working as a principle researcher at the target discovery team in Deargen. She received her Ph.D. from Seoul National University in pharmacy. She mainly specialized in research on mechanisms related to anti-cancer or liver metabolic diseases. Befor joining Deargen, She worked at GC biopharma and participated in various aspects of the scientific work, including the development of immuno-oncology and metabolic disease drugs, discovering candidate targets and performing efficacy evaluations.`,
        letsMake: ` Let's make a MIRACLE`,
        linkedinHref: "https://www.linkedin.com/in/jihye-ryu-130950218/",
    },
    {
        koreanName: ``,
        englishName: `Haeyoung Shin`,
        role: `Researcher`,
        description: `Haeyoung is in charge of drug efficacy evaluation as a senior researcher at Deargen. she has a doctorate in neuroscience from Ajou University. Haeyoung conducted drug efficacy evaluation and mode of action studies for new drug development for neurological disorders in a pharmaceutical company.`,
        letsMake: ` Let's make harmonious environment`,
        linkedinHref: "https://www.linkedin.com/in/혜영-신-664b494a/",
    },
    {
        koreanName: ``,
        englishName: `Jeong-A Lee`,
        role: `Researcher`,
        description: `Jeong-A obtained a master's degree in toxicology at Seoul Women's University's Department of Food and Microbial Technology. After graduation, she conducted pharmacological research on anticancer drugs and central nervous system disease treatments in pharmaceutical companies.`,
        letsMake: ` Let's make our miracle`,
        linkedinHref: "https://www.linkedin.com/in/jeong-a-lee-81b1a2241/",
    },
    {
        koreanName: ``,
        englishName: `Woo Seung Son`,
        role: `Researcher`,
        description: `Woo Seung is in charge of synthesizing new drugs as a medicinal chemist at Deargen. He received his Ph.D. in organic chemistry from Yonsei University. He developed new drugs for autoimmune diseases and degenerative brain diseases at KIST and worked at a domestic pharmaceutical company for research on anticancer drugs.`,
        letsMake: ` Let’s make a better tomorrow`,
        linkedinHref: "https://www.linkedin.com/in/우승-손-496432242/",
    },
    {
        koreanName: ``,
        englishName: `Youngkyung Jung`,
        role: `Researcher`,
        description: `Youngkyung is a medicinal chemist at Deargen R&D Center, and she is in charge of drug design and organic synthesis. She received a master's degree from Gachon University in pharmacy. She worked at a domestic pharmaceutical company and wrote a National New Drug Development Project, researching new drug development.`,
        letsMake: ` Let’s make a new paradigm`,
        linkedinHref: "https://www.linkedin.com/in/영경-정-372111245/",
    },
    {
        koreanName: ``,
        englishName: `Hye Bin Park`,
        role: `Researcher`,
        description: `Hye Bin is a researcher at Deargen. She received a master's degree in organic chemistry synthesis from KRICT and SKKU. After graduation, she worked for a new drug development company and conducted several anti-cancer drug studies.`,
        letsMake: ` Let’s make a solution`,
        linkedinHref: "",
    },
    {
        koreanName: ``,
        englishName: `Suemin Jeong`,
        role: `Sr. Manager`,
        description: `Suemin is responsible for business development at Deargen, focusing on global market expansion. She received a bachelor's degree at Emory University with Economics and Sociology and an MBA degree at Seoul National University. Prior to joining, she oversaw commercialization of biosimilar products, managing overseas entity operation and global partnership development at Celltrion Healthcare.`,
        letsMake: ` Let’s make a new direction`,
        linkedinHref: "https://www.linkedin.com/in/sueminj/",
    },
    */

  //자문위원
  {
    koreanName: ``,
    englishName: `Yoonho Ko`,
    role: `Clinical Trial Advisor`,
    description: `The Catholic University of Korea, M.D / Ph.D.\n\nDirector of Clinical Oncology Department at the Catholic Univ. of Korea Eunpyeong St. Mary’s Hospital\n\nFormer) Director of the Catholic Univ. of Korea Uijeongbu St. Mary’s Hospital Cancer Center\n\nArea of expertise) Head and neck cancer, Lung cancer, Esophageal cancer, Stomach cancer, Urological cancer`,
    isAdvisor: true,
  },
  {
    koreanName: ``,
    englishName: `Daeyeop Lee`,
    role: `Basic Science Advisor`,
    description: `Korea Advanced Institute of Science and Technology, Ph.D.\n\nProfessor of KAIST Department of Life Science\nHead of the Research Center for Cellular Identity\n\nArea of expertise) Epigenetics, Proteasome`,
    isAdvisor: true,
  },
  {
    koreanName: ``,
    englishName: `Kyubong Kim`,
    role: `Clinical / Pharmaceutical / Toxicology Advisor`,
    description: `The University of Georgia, Ph.D.\n\nDankook University College of Pharmacy Department of Pharmaceutical Sciences\n\nArea of expertise) Toxicity metabolome, Drug development and drug approval `,
    isAdvisor: true,
  },
  {
    koreanName: ``,
    englishName: `Yungho Ahn`,
    role: `In-vitro PoC`,
    description: `Seoul National University, Ph.D.\n\nEwha Womans University College of Medicine\n\nArea of expertise) Oncology, Development and verification of drug candidates`,
    isAdvisor: true,
  },
  {
    koreanName: ``,
    englishName: `Kyudong Han`,
    role: `In-vitro PoC`,
    description: `Louisiana State University, Ph.D.\nDankook University Department of Nano Bio Medical Science \n\nArea of expertise) Comparative genomics, Next-generation sequencing facility`,
    isAdvisor: true,
  },
  {
    koreanName: ``,
    englishName: `Jaehoon Chung`,
    role: `Genetics Advisor`,
    description: `Indiana University, Ph.D.\n\nHonorary professor at KAIST Department of Life Sciences\n\nFormer) Professor at KAIST Department of Life Sciences\n\nArea of expertise) Epigenetics, Genomic imprinting`,
    isAdvisor: true,
  },
  {
    koreanName: ``,
    englishName: `Kyunghyun Yoo`,
    role: `Preclinical Advisor`,
    description: `Sookmyung Women’s University, Ph.D.\n\nSookmyung Women’s University Department of Living System\n\nArea of expertise) Liver cancer and Breast cancer research, In-vitro and In-vivo animal models `,
    isAdvisor: true,
  },
  {
    koreanName: ``,
    englishName: `Kyuho Kang`,
    role: `Inflammatory diseases, In-vitro PoC`,
    description: `Weill Cornell Medicine, Ph.D.\n\nChungbuk National University Department of Biology\n\nArea of expertise) Immunology, Rheumatoid arthritis `,
    isAdvisor: true,
  },
];

export const text = {
  ko: [
    한글프로필,
    [
      ["We make ", "it", " together"],
      "디어젠은 딥러닝, 소프트웨어, 생물학, 생물정보학 전문가가 함께 정밀의료 시대를 만들어 갑니다.\n디어젠의 어벤져스 팀은 누구보다 빠르게 행동하고, 끊임없이 새로운 도전을 향해 움직입니다.",
      `디어젠 멤버소개`,
    ],
  ],
  en: [
    영어프로필,
    [
      ["We make ", "it", " together"],
      "We realize Precision Medicine together with experts in Deep learning, Software, Biology and Bioinformatics. \nOur brilliant Avengers team acts faster than anyone else and consistently moves to face new challenges.",
      "Meet Our Teams",
    ],
  ],
};

export default function useContent() {
  const language = useLanguage();
  const 컨텐츠 = text[language];

  const images = useStaticQuery(graphql`
    query {
      # member
      member_0: file(relativePath: { eq: "Home_Team/kilsoo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_1: file(relativePath: { eq: "Home_Team/sungsoo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_2: file(relativePath: { eq: "Home_Team/keunsoo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_3: file(relativePath: { eq: "Home_Team/seanKim.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_4: file(relativePath: { eq: "Home_Team/jongyoung.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_5: file(relativePath: { eq: "Home_Team/jimin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_6: file(relativePath: { eq: "Home_Team/dongju.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_7: file(relativePath: { eq: "Home_Team/andrew.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_11: file(relativePath: { eq: "Home_Team/sungmin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_12: file(relativePath: { eq: "Home_Team/sojung.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_13: file(relativePath: { eq: "Home_Team/miyonShim.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_14: file(relativePath: { eq: "Home_Team/dongho-lee.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_15: file(relativePath: { eq: "Home_Team/suyeon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_16: file(relativePath: { eq: "Home_Team/Jejin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_17: file(relativePath: { eq: "Home_Team/Kyung_il.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_18: file(relativePath: { eq: "Home_Team/hana.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_19: file(relativePath: { eq: "Home_Team/andrew.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_20: file(relativePath: { eq: "Home_Team/dongpin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_21: file(relativePath: { eq: "Home_Team/youngheon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_22: file(relativePath: { eq: "Home_Team/minji.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_23: file(relativePath: { eq: "Home_Team/seolwha.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_24: file(relativePath: { eq: "Home_Team/byun.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_25: file(relativePath: { eq: "Home_Team/chunghyeon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_26: file(relativePath: { eq: "Home_Team/jeehun.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_27: file(relativePath: { eq: "Home_Team/heonKyu.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_28: file(relativePath: { eq: "Home_Team/myungkwon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_29: file(relativePath: { eq: "Home_Team/horang.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_30: file(relativePath: { eq: "Home_Team/hojae.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_35: file(relativePath: { eq: "Home_Team/andrew.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_36: file(relativePath: { eq: "Home_Team/daesuk.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_37: file(relativePath: { eq: "Home_Team/taemook.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_38: file(relativePath: { eq: "Home_Team/seungkyu.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_39: file(relativePath: { eq: "Home_Team/eunjoo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_40: file(relativePath: { eq: "Home_Team/hyunjoon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_41: file(relativePath: { eq: "Home_Team/gahi.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_42: file(relativePath: { eq: "Home_Team/Jejin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_43: file(relativePath: { eq: "Home_Team/Yongjun.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_44: file(relativePath: { eq: "Home_Team/SukYoung.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_45: file(relativePath: { eq: "Home_Team/JuHui.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_46: file(relativePath: { eq: "Home_Team/EomJi.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_47: file(relativePath: { eq: "Home_Team/Kyung_il.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_48: file(relativePath: { eq: "Home_Team/Somi.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_49: file(relativePath: { eq: "Home_Team/Jihye.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_50: file(relativePath: { eq: "Home_Team/dongho-lee.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_51: file(relativePath: { eq: "Home_Team/hana.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_52: file(relativePath: { eq: "Home_Team/haeyoung_shin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_53: file(relativePath: { eq: "Home_Team/jeong-a-lee.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_54: file(relativePath: { eq: "Home_Team/wooseung.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_55: file(relativePath: { eq: "Home_Team/youngkyung.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_56: file(relativePath: { eq: "Home_Team/hyebin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      member_57: file(relativePath: { eq: "Home_Team/suemin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      # advisor
      advisor_0: file(relativePath: { eq: "Home_Team/advisor_yoonho.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      advisor_1: file(relativePath: { eq: "Home_Team/advisor_daeyeop.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      advisor_2: file(relativePath: { eq: "Home_Team/advisor_kyubong.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      advisor_3: file(relativePath: { eq: "Home_Team/advisor_yungho.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      advisor_4: file(relativePath: { eq: "Home_Team/advisor_kyudong.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      advisor_5: file(relativePath: { eq: "Home_Team/_placeholder.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      advisor_6: file(relativePath: { eq: "Home_Team/advisor_kyunghyun.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      advisor_7: file(relativePath: { eq: "Home_Team/advisor_kyuho.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return { images, 컨텐츠 };
}
