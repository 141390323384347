import React from 'react'
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Flex from "styled-flex-component"
import { BsCircleFill as Icon } from "react-icons/bs"
import { P } from "src/components"


const LegendRoot = styled(Flex)`
    margin-top: 40px;
    background: #f8f8f8;
    border-radius: 10px;
    width: 540px;
    height: 50px;

    ${breakpoint("mobile", "tablet")`
        flex-wrap: wrap;
        width: 100%;
        height: fit-content;
        padding: 10px;
        box-sizing: border-box;
    `}
    
`
const legnedItems = [
    {
        name: "Oncology",
        color: "#80D7FF",
    },
    {
        name: "Neurology",
        color: "#157f58",
    },
    {
        name: "Rare Disease",
        color: "#f59175",
    },
    {
        name: "Dermatology",
        color: "#94e0de",
    },
    {
        name: "Crop Protectant",
        color: "#006797",
    },
]
const LegendItem = styled(Flex)`
    ${P} {
        margin-left: 5px;
        
    }
    ${breakpoint("mobile", "tablet")`
        width: 50%;
    `}
`

export default function Legend() {
    return <LegendRoot center>
        {legnedItems.map(({name, color}, i) => <LegendItem key={i}>
            <Flex center>
                <Icon size={12} color={color} style={{marginLeft: 10}} />
            </Flex>
            <P>{name}</P>
        </LegendItem>)}
    </LegendRoot>
}
