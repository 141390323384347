import { useStaticQuery, graphql } from "gatsby"


export default function useContent() {
    const images = useStaticQuery(graphql`
        query {
            corporate_partnership_0: file(relativePath: { eq: "Home_Partners/Serengen_Logo.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 29) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_1: file(relativePath: { eq: "Home_Partners/Emory_University_Logo.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 26) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_2: file(relativePath: { eq: "Home_Partners/sk_chemicals.png" }) {
                childImageSharp {
                    fixed(width: 110, height: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_3: file(relativePath: { eq: "Home_Partners/LG_HnH_ENG.jpg" }) {
                childImageSharp {
                    fixed(width: 120, height: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_4: file(relativePath: { eq: "Home_Partners/handok.png" }) {
                childImageSharp {
                    fixed(width: 75, height: 44) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_5: file(relativePath: { eq: "Home_Partners/samsung_medical_center.png" }) {
                childImageSharp {
                    fixed(width: 70, height: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_6: file(relativePath: { eq: "Home_Partners/eFlask.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 28) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_7: file(relativePath: { eq: "Home_Partners/uppthera.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 70) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_8: file(relativePath: { eq: "Home_Partners/pharmhannong.png" }) {
                childImageSharp {
                    fixed(width: 110, height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_9: file(relativePath: { eq: "Home_Partners/ms.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_10: file(relativePath: { eq: "Home_Partners/intel.png" }) {
                childImageSharp {
                    fixed(width: 75, height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_11: file(relativePath: { eq: "Home_Partners/nvidia.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 20) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_12: file(relativePath: { eq: "Home_Partners/in_therapeutics.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_13: file(relativePath: { eq: "Home_Partners/amyloid_solution.png" }) {
                childImageSharp {
                    fixed(width: 110, height: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_14: file(relativePath: { eq: "Home_Partners/neogentc.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_15: file(relativePath: { eq: "Home_Partners/nexel.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 21) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_16: file(relativePath: { eq: "Home_Partners/JW_pharma.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            corporate_partnership_17: file(relativePath: { eq: "Home_Partners/K-MEDI-hub.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 46) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            
            scientific_collaboration_0: file(relativePath: { eq: "Home_Partners/kaist.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 70) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            scientific_collaboration_1: file(relativePath: { eq: "Home_Partners/gatholic_university.png" }) {
                childImageSharp {
                    fixed(width: 110, height: 70) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            scientific_collaboration_2: file(relativePath: { eq: "Home_Partners/dankook_university.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 70) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            scientific_collaboration_3: file(relativePath: { eq: "Home_Partners/ewha_women_university.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 70) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            scientific_collaboration_4: file(relativePath: { eq: "Home_Partners/sookmyung_women_university.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            scientific_collaboration_5: file(relativePath: { eq: "Home_Partners/konkuk_university.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 70) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return { images }
}