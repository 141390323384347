import React, {  } from 'react'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { Img, P } from 'src/components';
import { useLanguage } from 'src/components/utils';

const TeamDetailCardRoot = styled.div`
    position: relative;
    height: 350px;
`
const PictureTextRenderRoot = styled.div`
    transition: all 0.2s;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    width: 80px;
`

const ImageWrapper = styled.div`
    position: relative;
    transition: 0.2s;
    filter: grayscale(1) brightness(0.8);
    
    &:hover {
        filter: grayscale(0);
        brightness(1);
    }

    ${breakpoint("mobile", "desktop")`
        width: 100%;
    `}
`

export default function TeamDetailCard({
    koreanName,
    gatsbyImageSrc,
    englishName,
    role,
    customCss 
}) {
    const language = useLanguage()

    // image
    const name = language==="ko"
        ? koreanName
        : englishName
    const imageTextRender = <PictureTextRenderRoot>
        <P size={24} weight={700} color="white">{name}</P>
        {language==="ko" && <P size={14} weight={300} color="white">{englishName}</P>}
        <P size={14} weight={700} color="white">{role}</P>
    </PictureTextRenderRoot>
    
    const imageRender = <ImageWrapper>
        <Img
        gatsbyImageSrc={gatsbyImageSrc}
        height={350}
        gradation={false}
        rounded={8}
        textRender={imageTextRender}
        customCss={`height: 350px;`}/>
    </ImageWrapper>


    return <TeamDetailCardRoot customCss={customCss}>
        {imageRender}
    </TeamDetailCardRoot>   
    
}