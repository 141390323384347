import React, {  } from 'react'
import styled, {  } from "styled-components"
import { HexagonButton, Strong, P } from "src/components"
import {  } from 'src/components/utils';
import { } from 'styled-components-breakpoint';
// assets
import ArkipelagoFont from "./Arkipelago.otf"


const DetailHexagonRoot = styled.div`
    ${props => props.theme.css.full}
`
const LetsMakeA = styled.span`
    display: block;
    @font-face {
        font-family: Arkipelago;
        src: url(${ArkipelagoFont});
    }
    font-family: Arkipelago, cursive;
    font-size: 1.8em;
    transform-origin: 0 0;
    transform: rotate(-10deg);
    color: ${props => props.theme.secondary};
`
const Text = styled.div`
    ${props => props.theme.css.center()}
    top: 450px;
    z-index: 4;
    text-align: center;
    width: fit-content;
    max-width: 400px;

    ${Strong} {
        font-weight: 700;
    }
`

const Gradation = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #000000);
`

export default function DetailHexagon({ gatsbyImageSrc, koreanName, englishName, description, role, letsMake="", size, isAdvisor, ...hexagonButtonProps}) {
    // text
    const sloganRender = letsMake
        ? <LetsMakeA>
            {letsMake}
            {" together"}
        </LetsMakeA>
        : null

    const textRender = <Text>
        {sloganRender}
        <Strong>
            <P customCss={`color: white;`}>{koreanName}</P>
            <P customCss={`color: white;`}>{englishName}</P>
        </Strong>
        <P customCss={`color: white; margin-top: 2em;`}>
            {description}
        </P>
        <P customCss={`color: white; margin-top: 1em;`}>
            {role}
        </P>
    </Text>


    // hexagon button config
    const config = {
        backgroundComponent: <Gradation />,
    }
    const render = <HexagonButton
    gatsbyImageSrc={gatsbyImageSrc}
    {...hexagonButtonProps}
    size={size}
    config={config} 
    customCss={`margin: auto;`} />

    return <DetailHexagonRoot>
        {textRender}
        {render}
    </DetailHexagonRoot>
}
