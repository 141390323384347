import React, {  } from 'react'
import { navigate } from 'gatsby'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Flex, { FlexItem } from 'styled-flex-component';
import { H1, P, Strong, Img, Button, ArrowButton } from 'src/components';
import { GrayBreakLine, useIsDeviceWidth, DeargenLogo, useLanguage } from 'src/components/utils';
import { FiHexagon } from "react-icons/fi"
import { FaAngleLeft } from "react-icons/fa"
import { mainTheme } from "src/GlobalStyle"
import useContents from "src/data"

const DrugDiscoveryRoot = styled.section`

    ${breakpoint("mobile", "desktop")`
        padding: 0 20px;
        margin-bottom: 150px;
    `}
`

const DottedLine = styled.div`
    height: calc(100% - 20px);
    border-left: 2px dotted ${props => props.theme.secondary};
    position: relative;
    left: 8px;
`

export default function TargetDiscovery({ location }) {
    const { images, 컨텐츠 } = useContents("Technology")
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })
    const language = useLanguage()


    // top logo
    const topLogoRender= isSmallDevice
        ? <Flex
        full
        center
        style={{height: 80, position: "relative"}}>
            <DeargenLogo onClick={e => navigate(`/${language}/`)}/>
            <FaAngleLeft size={20} style={{ position: "absolute", left: 0}} onClick={e => navigate(`/${language}/`)}/>
        </Flex>
        : null


    // header
    const headerRender = <>
        <Flex style={{ marginTop: 20 }}>
            <FlexItem style={{ paddingTop: 10, flex: 1 }}>
                <H1 size={20} weight={300}>
                    {컨텐츠.mobile.targetDiscovery[0][0]}
                    <Strong size={34} customCss={`display: block`}>
                        {컨텐츠.mobile.targetDiscovery[0][1]}
                    </Strong>
                </H1>
            </FlexItem>
    
            <Flex style={{ flex: 1 }} center>
                <Img gatsbyImageSrc={images.target_discovery} />
            </Flex>
        </Flex>

        <P size={16} color={mainTheme.secondary} marginTop={50}>{컨텐츠.mobile.targetDiscovery[0][2]}</P>
        <P size={26} color={mainTheme.secondary} weight="bold">{컨텐츠.mobile.targetDiscovery[0][3]}</P>
        <P size={13} marginTop={16}>
            {컨텐츠.mobile.targetDiscovery[0][4]}
        </P>
    </>


    // technologies
    const articlesRender = [컨텐츠.DearTrans, 컨텐츠.WX].map((기술, index) => {
        const [기술이름, 기술설명, buttonProps] = 기술[0]
        const 하위항목들 = 기술[1]

        return <Flex key={index} style={{ marginTop: 50 }}>
            <FlexItem>
                <FiHexagon size={20} color={mainTheme.secondary}/>
                <DottedLine />
            </FlexItem>
            
            <FlexItem style={{ marginLeft: 10 }}>
                <P size={20} weight={900}>{기술이름} </P>
                <P size={13}>{기술설명} </P>

                {buttonProps && <FlexItem style={{ marginTop: 20 }}>
                    <Button {...buttonProps} />
                </FlexItem>}

                {하위항목들.map(하위항목 => {
                    const [title, content, buttonProps2] = 하위항목

                    return <>
                        <P size={16} weight={900} marginTop={30}>{title} </P>
                        <P size={13} marginTop={8}>{content} </P>
                        {buttonProps2 && <Button {...buttonProps2} customCss={`margin-top: 14px;`}/>}
                    </>
                })}
            </FlexItem>
        </Flex>
    })


    // bottom text
    const bottomTextRender = <>
        <GrayBreakLine paddingTop={50} paddingBottom={30}/>
        <P size={13} weight="bold" align="center">
            {컨텐츠.mobile.targetDiscovery[1][0]}
        </P>
        <Button
        to={`/${language}/technology/drug_design`}
        text={컨텐츠.mobile.targetDiscovery[1][1]}
        onClick={e => window.scroll({ top: 0, behavior: "smooth"})}
        filled
        customCss={`width: 100%; margin-top: 20px;`}/>
    </>


    // arrow button render 
    const arrowButtonRender = <>
        <ArrowButton position={{right: 20, bottom: 70}} direction="up" onClick={e => window.scroll({ top: 0, behavior: "smooth" })} />
        <ArrowButton position={{right: 20, bottom: 20}} direction="top"  onClick={e => window.scroll({ top: document.body.clientHeight, behavior: "smooth" })} />
    </>

    // redirect if wrong url
    if (!["", "#deartrans", "#wx", "#deardti", "#moleq"].includes(location.hash)) {
        navigate(location.pathname, { replace: true })
    }

    return <DrugDiscoveryRoot>
        {topLogoRender}
        {headerRender}
        {articlesRender}
        {bottomTextRender}
        {arrowButtonRender}
    </DrugDiscoveryRoot>
}
