import { useStaticQuery, graphql } from "gatsby"
import { useLanguage } from "src/components/utils"

const 육각형 = [
    // left top
    [
        // text when not hovered
        "DearTRANS",
        // text when hovered
        "Genome data Analysis",
        // hash url
        "#deartrans",
    ],
    // left bottom
    [
        "WX",
        "Biomarker\nPrediction",
        "#wx",
    ],
    // left big
    [
        "AI-Powered",
        "Target Discovery",
        "",
    ],
    // right small top
    [
        "DearDTI",
        "Molecule\nSelection",
        "#deardti",
    ],
    // right bottom
    [
        "MolEQ",
        "Lead\nOptimization",
        "#moleq",
    ],
    // right big
    [
        "AI-Powered",
        "Drug Design",
        "#deardti",
    ],
]

const text = {
    ko: {
        육각형,
        Introduce: [
            // header
            [
                "인공지능\n신약발굴 플랫폼  ",
                "Dr.UG",
                "Dr.UG는 유전체 데이터 분석, 바이오마커 예측, 후보물질 발굴 및 최적화에 특화되어 있는 디어젠의 딥러닝 기술 기반 AI 신약발굴 플랫폼입니다. 디어젠은 Dr.UG를 통해 새로운 질병 타겟을 발굴하고, 새로운 신약 후보물질을 디자인함으로서 나아가 정밀의료시대를 실현합니다.",
            ],
            // body
            [
                [
                    "AI-Powered Target Discovery",
                    "Dr.UG를 구성하고 있는 DearTRANS(Genome Data Analysis), WX(Biomarker Prediction)는 질병을 유발하는 타깃을 발굴하는 데 특화된 디어젠의 인공지능 기술입니다. 각각의 인공지능 기술을 기반으로 유전체 데이터를 빠르게 비교･분석하고, 질병 타깃의 MOA(Mode of Action) 및 바이오 마커를 예측합니다.",
                ],
                [
                    "AI-Powered Drug Design",
                    "Dr.UG를 구성하고 있는 DearDTI(Molecule Selection), MolEQ(Lead Optimization)는 타깃에 대한 신약후보 물질을 디자인하는데 특화된 디어젠의 인공지능 기술입니다. Molecule transformer 인공지능 기술을 통해 질병 타깃과 Small molecule의 상호결합에 대해 예측하고, 이를 기반으로 ADMET(흡수, 분포, 대사, 배출, 독성) 최적화를 진행하여 더욱 효과 높은 저분자 화합물 신약후보물질을 디자인합니다.",
                ],
            ],
        ],
        DearTrans: [
            //header
            [
                "DearTRANS",
                "DearTrans는 막대한 양의 유전체 데이터를 빠르게 비교 분석하고 그 결과를 시각화하여 제시해주는 플랫폼 기술입니다.",
                {
                    text: "DearTRANS 사용해보기",
                    href: "https://deartrans1.deargen.me",
                },
            ],
            // body
            [
                [
                    "유전체 데이터 메타분석",
                    "수많은 유전체 연구데이터가 공개되어 있지만, 개별 연구가 갖는 unknown bias에 의한 batch effect 때문에 이 데이터들을 모두 이용하기에는 한계가 있습니다. DearTRANS는 메타분석 기술을 통해 이러한 batch effect 문제를 해결하여 서로 다른 연구 간 데이터를 통합 분석하는 서비스를 제공합니다. 이를 통해 기존 개별 연구가 갖는 한계인 표본 수를 획기적으로 늘릴 수 있습니다.",
                ],
                [
                    "DEG 분석",
                    "동일한 유전자의 평균 발현량이 서로 다른 조건에서 유의하게 다른지를 분석하는 것으로, 바이오/의학/제약 분야에서 기본적으로 많이 쓰이는 방법입니다. DearTRANS는 복잡한 툴이나 프로그래밍을 사용하지 않고 쉽게 DEG 분석하고 시각화 할 수 있는 사용자 친화적인 Web 서비스를 제공합니다.",
                ],
                [
                    "GO 분석",
                    "유전자 온톨로지 (GO) 분석은 유전자 세트와 생물학적 기능들의 상관관계를 평가하기 위한 통계 분석 방법 입니다. 유전자의 기능에 따라 분류된 데이터베이스를 기반으로 한 GO 분석을 통해서 중요한 생물학적 기능과 경로를 해석할 수 있습니다. DearTRNAS는 유전자 세트의 기능적 프로파일을 검색해 줍니다."
                ],
                [
                    "분석 결과 시각화",
                    "연구 가설을 세웠다면, 클릭만으로 가설을 증명하고 이를 시각화 할 수 있습니다. 메타분석을 통해 분석한 유전체 데이터의 샘플을 가상으로 실험군과 대조군으로 지정하면 DearTRANS는 DEG를 비교 분석하여 결과를 도출합니다. DEG 분석 결과는 MA plot, Volcano plot으로 시각화하여 제공됩니다. 또한, 실험군과 대조군 사이의 DEG 결과 중 유의미한 유전자를 선별하여 Heat map과 Bar plot 자료로 나타날 수 있습니다. 이와 같은 시각 자료는 연구자의 이해를 높일 수 있으며, 논문 및 발표자료에 바로 사용할 수도 있습니다.",
                ],
                [
                    "Case Study ∙ Alzheimer's Disease",
                    "디어젠은 DearTRANS를 기반으로 알츠하이머(Alzheimer’s Disease)환자의 뇌 조직에서 과발현되는 핵심 전사인자 단백질인 DDT3(code명)을 발굴했습니다. 또한, DearTRANS를 활용한 교차검증을 통해 DDT3가 알츠하이머의 원인으로 지목되는 Aβ 및 tau 단백질 생성에 관련된 유전자를 조절하고 있음을 밝혔습니다.",
                    {
                        text: "Alzheimer's Disease 자세히 보기",
                        href: "https://deargen.blog/2019/08/05/%EC%95%8C%EC%B8%A0%ED%95%98%EC%9D%B4%EB%A8%B8%EC%84%B1-%EC%B9%98%EB%A7%A4-%ED%83%80%EA%B9%83-ddt3-%EB%8B%A8%EB%B0%B1%EC%A7%88-%EC%A0%80%ED%95%B4-%EC%A0%80%EB%B6%84%EC%9E%90%ED%99%94%ED%95%A9%EB%AC%BC/",                       
                    },
                ],
            ],
        ],
        WX: [
            // header
            [
                "WX",
                "WX는 유전체 발현량 데이터 (Raw transcriptome data)를 바탕으로 바이오마커를 제시합니다. 신규 바이오마커, 예후 관련 바이오마커 및 질병 타겟의 MOA(Mode of Action)까지 예측할 수 있는 인공지능 기술입니다.",
            ],
            //body
            [
                [
                    "유전체 Wide Feature 데이터 분석을 통한 타겟발굴",
                    "유전체 발현량 데이터를 사용해 가장 질병에 specific한 유전자를 선별하여 정확도 높은 바이오마커를 제시합니다. 자체 개발한 GWX(Gene WX) 딥러닝(Deep-learning) 알고리즘을 기반으로, 선택된 실험군과 대조군 사이의 유전체 발현량 차이를 인식하고 중요도 점수를 산정하여 가장 바이오마커로 가능성이 높은 후보 유전자를 제시합니다. 유전체 발현량 차이로 한번 필터링 된 DEG 데이터가 아닌 전체 유전체 데이터를 사용하므로 결과의 정확도를 높였다는 것이 WX의 특징입니다. 현재 치매, 류마티스, ALS, 사코패니아 등 다수의 파이프라인에서 타겟 유전자를 선별했습니다.",
                    {
                        text: "GWX 논문보기",
                        href: "https://www.nature.com/articles/s41598-019-47016-8",
                    },
                ],
                [
                    "질병 예후 관련 바이오마커 예측",
                    "예후 관련 바이오마커는 질병 진단 및 치료를 위한 중요 개념입니다. 예후 관련 바이오마커를 예측하는 것은 질병의 진단, 치료 전략 수립에 있어 더욱 각 환자의 특성에 맞춘 진료를 가능하게 합니다. 디어젠은 자체개발 딥러닝 알고리즘인 CWX(Casecaded WX)를 통해 해당질병 환자군 중 고위험군과 저위험군을 더 정확하게 분류하고, 유전체 발현량의 중요도를 산정하여 정확도 높은 예후 관련 바이오 마커를 제시합니다.",
                    {
                        text: "CWX 논문보기",
                        href: "https://www.frontiersin.org/articles/10.3389/fgene.2019.00662/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Genetics&id=444920",
                    },
                    
                ],
                [
                    "MOA (Mode Of Action) 예측",
                    "신약개발에서 약의 MOA (Mode Of Action)를 밝히는 것은 중요합니다. MOA가 불명확한 신약개발 파이프라인은 임상 비용이 많이 필요할 뿐 아니라, 신약 허가를 받는 것 또한 쉽지 않습니다. 디어젠은 GO(Gene Ontology) term 기반의 Neural Network 모델을 자체 구축하여 중요도가 높은 GO term을 예측하고, Neural Network의 weight를 분석하여 MOA를 예측합니다.",
                ],
                [
                    "Case Study ∙ 폐암",
                    "WX를 이용해, 폐암 예후와 관련성이 있는 전이유발 바이오마커 2종 발견했습니다. 이를 실제로 임상데이터를 통해 분석한 결과 유의미한 상관관계가 있음을 확인했습니다. 이후 성모병원과의 공동 연구를 통한 세포주 실험에서 WX를 이용해 발견한 폐암 예후관련 바이오마커 2종인 miR-XXXa,b에 의해 폐암세포의 전이성이 높아지는 것을 A549 폐암세포에서 확인했습니다. 또한, 실제 환자들의 조직 시료에서 miR-XXXa,b의 발현양에 따라 그룹을 지어 생존율(OS)를 분석하였고, 그 결과 miR-XXXa,b의 발현양이 높으면 예후가 악화되는 것이 유의미한 상관관계를 나타내었습니다.",
                ],
            ],
        ],
        DearDTI: [
            // header
            [
                "DearDTI",
                "DearDTI는 화합물-단백질 간의 상호작용에 대한 정보를 기반으로 결합도 (Binding Affinity)를 예측하여 효능이 좋은 신약후보물질을 제시하는 인공지능 기술입니다.",
            ],
            // body
            [
                [
                    "신약후보 물질 발굴",
                    "DearDTI는 화합물과 단백질 간의 결합도 (Drug-Target Binding Affinity)를 예측합니다. 특정 저분자 화합물이 어느 타겟에 결합 할 수 있는지 또는 원하는 질병 타겟에 어떤 저분자 화합물이 잘 결합할 수 있을지 예측하여 신약후보 물질로 가능성이 높은 저분자 화합물을 발굴합니다. DearDTI는 Self-Attention 메커니즘을 기반으로 한 Transformer 기술을 도입하여 Global context를 효과적으로 이용할 수 있게 했습니다. 이에 따라 화합물 학습패턴에 대한 지식을 효율적으로 모델에 반영하여 모델의 정밀도를 높이고, 더 정확도 높은 후보물질을 제시합니다.",
                ],
                [
                    "Drug Repurposing",
                    "DearDTI의 특정 저분자 화합물에 결합하는 질병 타깃을 예측하는 기술은 새로운 신약후보물질을 발굴하는 것에서 나아가 Drug Repurposing으로의 신약개발 파이프라인을 가능하게 합니다. 효과가 부족하여 임상 단계를 통과하지 못한 후보물질이 더 나은 효과를 보일 수 있는 타깃을 예측하거나, 기존 승인된 의약물질의 새로운 적응증을 탐색하여 새로운 신약개발 파이프라인을 제시합니다.",
                ],
                [
                    "관련 논문",
                    "DearDTI모델은 인공지능 최고 수준의 저널인 JMLR (Journal of Machine Learning Research)에 개제 되었으며, 의료 빅데이터를 활용한 인공지능 기술 분야 최대 학회인 MLHC (Machine Learning for Healthcare)2019에서 발표되었습니다.",
                    {
                        text: "DearDTI 논문보기",
                        href: "https://arxiv.org/abs/1908.06760",
                    },
                ],
                [
                    "Case Study ∙ 고형암",
                    "DearDTI가 실제로 얼마나 신약개발 과정에서 의미 있게 사용될 수 있을지를 실험을 통해 살펴보았습니다. Drugbank에서 FDA 승인된 1,794개의 약물과 EGFR (Cancer Biomarker) 단백질 데이터를 입력값으로 넣고 30개의 EGFR 타깃 약물 후보군을 추려보는 실험을 했습니다. 그 결과 Drugbank에 존재하는 실제 EGFR 타겟 약물 8개가 모두 후보군 중에 있었음을 발견했습니다. 이는 DearDTI의 유용성을 증명하는 동시에 8개의 FDA 승인된 약물 외 제안된 22개의 약물 후보들도 잠재적 항암제로 검증해 볼 필요가 있음을 의미하고 있습니다.",
                    {
                        text: "고형암 자세히 보기",
                        href: "https://deargen.blog/2019/10/10/%ea%b3%a0%ed%98%95%ec%95%94-%ec%9c%a0%eb%b0%9c-%ec%a0%84%ec%82%ac%ec%9d%b8%ec%9e%90-%eb%8b%a8%eb%b0%b1%ec%a7%88-%ec%84%a0%ed%83%9d%ec%a0%81-%ec%a0%9c%ea%b1%b0-first-in-class-%ec%8b%a0%ec%95%bd/",
                    },  
                ],
                [
                    "Case Study ∙ ALS",
                    "DearDTI를 이용하여 ALS 환자에서 발견되는 TDP-43 단백질의 비정상적인 응집을 조절할 수 있는 small molecule을 찾아냈습니다. 그 후 단국대학교와의 공동연구를 결과 세포주 실험에서 small molecule의 TDP-43 단백질 응집 해소 효과를 확인했습니다. 이를 통해 16개월 이상 소요되던 전통적인 연구 방법을 통한 ALS초기 연구(후보물질 발굴) 기간을 디어젠의 인공지능 기술을 이용하여 약 2개월의 내로 단축이 가능함을 보여주었습니다.", 
                    {
                        text: "ALS 자세히 보기",
                        href: "https://deargen.blog/2019/08/05/als%eb%a3%a8%ea%b2%8c%eb%a6%ad-%ea%b7%bc%eb%b3%b8%ec%a0%81-%ec%b9%98%eb%a3%8c%ec%97%90-%eb%8c%80%ed%95%9c-%eb%8f%84%ec%a0%84/",           

                    },    
                ],
                [
                    "Case Study ∙ COVID-19",
                    "디어젠은 DearDTI 기술을 이용하여 COVID-19의 환자에게 즉시 적용할 수 있는 효과적인 치료 전략을 수립하기 위한 테스트를 진행했습니다. 항바이러스제를 대상으로 알고리즘 분석을 진행했으며, 그 결과 이미 시판된 약물 및 에볼라 치료제로 개발되었던 약물 등이 COVID-19에 효과적일 것으로 예측되었습니다. 그중 일부는 실제로 각 나라 및 기업에서 임상 중입니다.", 
                    {
                        text: "COVID-19 자세히 보기",
                        href: "https://deargen.blog/2020/02/06/디어젠주-ai-모델-이용해-코로나바이러스-치료제-예측/",           

                    },    
                ],
            ],
        ],
        MOL_EQ: [
            // header
            [
                "MolEQ",
                "MolEQ는 선도물질의 효능 및 독성 등을 최적화하여 새로운 화합물을 디자인하는 디어젠의 인공지능 기술입니다.",
            ],
            // body
            [
                [
                    "ADMET 예측",
                    "신약개발 과정에서 후보물질의 대사적 특성을 초기에 연구하는 것은 신약개발의 비용과 시간을 단축하는 주요 전략으로 자리 잡고 있습니다. MolEQ는 화합물의 ADMET(흡수, 분포, 대사, 배출, 독성)을 원하는 질병 타겟에 최적화하여 새로운 신약후보 물질을 디자인하는 기술입니다. \n\nMolEQ 기술의 특징은 DearDTI의 affinity 수치 정보를 기반으로 한 효능, 독성, Synthetic Accessibility (SA), Quantitative Estimate of Drug-likeness (QED) 등 물질의 다중 특성을 molecule design 목표에 따라 가중치(Weight)를 다르게 주어 최적화할 수 있다는 것입니다. 타 모델들은 일반적으로 QED와 같은 한 가지 물질의 속성만을 학습 시켜 최적화를 진행합니다. \n\n결과적으로 MolEQ는 물질의 여러 가지 특성들을 한 번에 최적화했기 때문에 그 결과를 실제로 신약개발 연구에 바로 적용할 수 있는 장점이 있습니다.",
                ],
                [
                    "Case Study ∙ Iniparib / Brivanib / Rebimastat",
                    "Iniparib은 임상 3상에서 효능이 입증되지 않아 실패했습니다. 디어젠은 Iniparib의 affinity에 높은 가중치를 주고 약물 최적화를 시행하였고, 그 결과 affinity score가 유의미하게 개선된 결과가 도출되었습니다. 또한 최적화 진행 시 Sythetic Accessibility에도 가중치를 주었기 때문에 최적화된 물질 후보군 2개는 SA가 3.5 이하로 물질 합성도 용이함이 예측되었습니다.\n\nBrivanib은 입증되지 못한 효능과 예측하지 못한 독성으로 임상에서 실패했습니다. MolEQ를 통해 hERG (심장독성)을 수치를 낮추는 최적화를 진행했고, 그 결과 Brivanib의 핵심 구조를 유지하면서 독성이 낮아지고, affinity 수치가 개선된 결과가 도출되었습니다. \n\n마지막으로 Rebimastat은 Skin hypersensitivity의 문제로 임상 3상에서 실패한 약물입니다. 이에 디어젠은 MolEQ를 통해 Skin Toxicity Score (STS) 의 가중치를 높게 두고 약물 최적화를 진행했습니다. 그 결과 STS가 낮으면서도 AS는 높고 합성이 용이할 것으로 예측되는 구조를 다수 예측했습니다.\n\n이러한 예측 결과를 바탕으로 AACR drug discovery workshop에서 포스터 발표를 진행했습니다(2020년 1월).",
                    {
                        text: "AACR 학회발표 자세히 보기",
                        href: "https://deargen.blog/2020/02/05/moleq-다중-속성을-동시에-조절하여-저분자화합물-선도/",           

                    },
                ],
            ],
        ],
        mobile: {
            targetDiscovery: [
                // header
                [
                    "AI 신약발굴 플랫폼",
                    "Dr.UG",
                    "AI-Powered",
                    "Target Discovery",
                    "Dr.UG를 구성하고 있는 DearTRANS(Genome Data Analysis), WX(Biomarker Prediction)는 질병을 유발하는 타깃을 발굴하는 데 특화된 디어젠의 인공지능 기술입니다. 각각의 인공지능 기술을 기반으로 유전체 데이터를 빠르게 비교･분석하고, 질병 타깃의 MOA(Mode of Action) 및 바이오 마커를 예측합니다.",
                ],
                // bottom text
                [
                    "“Dr.UG 플랫폼을 통해 발굴한 Target을 기반으로, \n새로운 Drug Design을 시도해 볼수 있습니다.”",
                    "Drug Design 기술소개 보기 →",
                ],
            ],
            drugDesign: [
                // header
                [
                    "AI 신약발굴 플랫폼",
                    "Dr.UG",
                    "AI- Powered",
                    "Drug Design",
                    "Dr.UG를 구성하고 있는 DearDTI(Molecule Selection), MolEQ(Lead Optimization)는 타깃에 대한 신약후보 물질을 디자인하는데 특화된 디어젠의 인공지능 기술입니다. Molecule transformer 인공지능 기술을 통해 질병 타깃과 Small molecule의 상호결합에 대해 예측하고, 이를 기반으로 ADMET(흡수, 분포, 대사, 배출, 독성) 최적화를 진행하여 더욱 효과 높은 저분자 화합물 신약후보물질을 디자인합니다.",
                ],
                // bottom text
                [
                    "“새로운 Drug Design을 위한 Target Discovery를 \n지금 바로 Dr.UG 플랫폼에서 시작 할 수 있습니다.”",
                    "Target Discovery 기술소개 보기 →",
                ],
            ],
        },
    },
    // English
    en: {
        육각형,
        Introduce: [
            // header
            [
                "The AI platform for drug discovery",
                "Dr.UG",
                "Dr. UG is an AI platform for developing new drugs that is based on DEARGEN’s deep learning technology. It specializes in genome data analysis, biomarker prediction, molecule selection and optimization. DEARGEN discovers new disease targets with Dr.UG and further realizes precision medicine by designing small molecules for new drugs.",
            ],
            // body
            [

                [
                    "AI-Powered Target Discovery",
                    "The ‘DearTRANS’ (Genome Data Analysis) and ‘WX’(Biomarker Prediction) consisting of Dr.UG are DEARGEN's artificial intelligence technologies that specialize in identifying disease-causing targets. Based on each AI technology, genomic data can be quickly compared and analyzed. Also, the disease target's mode of action (MOA) and biomarkers can be predicted.",
                ],
                [
                    "AI-Powered Drug Design",
                    "The DearDTI (Molecule Screening) and MolEQ (Lead Optimization) consisting of Dr.UG are DEARGEN's artificial intelligence technologies that specialize in designing drug candidates for targets. Through the Molecule transformer technology, the affinity score between a target and a small molecule can be predicted. Based on this, small molecule drug candidates with higher efficacy can be designed by ADMET (absorption, distribution, metabolism, excretion, toxicity) optimization.",
                ],
            ],
        ],
        DearTrans: [
            // header
            [
                "DearTrans",
                "DearTRANS is a platform that quickly compares and analyzes huge amounts of genomic data and presents the results visually.",
                {
                    text: "TRY TO USE DearTrans",
                    href: "https://deartrans1.deargen.me",
                },
                
            ],
            // body
            [
                [
                    "Genomic Data Meta-Analysis",
                    "Numerous genome research data has been published, but there is a limit in using all of these data due to the batch effect caused by unknown bias in individual studies. DearTRANS provides services of integrating and analyzing data between disparate studies by solving this batch effect problem through meta-analysis technology. This can overcome the limitations of existing individual studies and dramatically increase the number of research data samples.",
                ],
                [
                    "Differentially Expressed Gene(DEG) analysis",
                    "DearTRANS analyzes whether the average expression level of the same gene is significantly different under different conditions. It is a widely used method in bio, medical, and pharmaceutical fields. DearTRANS provides a user-friendly web service that makes DEG analysis and visualization easy without the use of complex tools or programming.",
                ],
                [
                    "Gene Ontology(GO) Analysis",
                    "Gene ontology (GO) analysis is a statistical analysis method for assessing the correlation between gene sets and biological functions. GO analysis based on a database categorized by gene function can be used to interpret important biological functions and pathways. DearTRNAS searches for the functional profile of gene sets.",
                ],
                [
                    "Visualization of Analysis Results",
                    "Once you’ve created a research hypothesis, you can prove and visualize it with just a click. According to your hypothesis, if you specified samples of genomic data analyzed through meta-analysis as an experimental group and a control group, DearTRANS will show results by comparing and analyzing DEG. DEG analysis results will be provided by visualizing into MA plot and Volcano plot. \n\nBesides, significant genes from the DEG results between the experimental and control group can be selected and displayed as a heat map and a bar plot. These visual aids can enhance the understanding of researchers and can be directly used in research papers and presentation materials.",
                ],
                [
                    "Case Study ∙ Alzheimer's Disease",
                    "Based on the DearTRANS, DEARGEN discovered DDT3 (code name) that is a key transcription factor protein that is overexpressed in the brain tissue of Alzheimer's Disease patients. Also, through cross-validation using DearTRANS, it has confirmed that DDT3 regulates genes involved in the production of Aβ and tau proteins that are regarded as the cause of Alzheimer's disease.",
                ],
            ],
        ],
        WX: [
            // header
            [
                "WX",
                "WX presents biomarkers, based on raw transcriptome data. It is an artificial intelligence technology that can predict biomarkers, prognostic biomarkers and even the mode of action (MOA) of disease targets.",
            ],
            // body
            [
                [
                    "Target Discovery through Genome Wide Feature Data Analysis",
                    "WX presents highly accurate biomarkers by selecting a succinct set of genes that are most specific to disease from raw transcriptome data. Based on GWX (Gene WX) that is our own deep-learning algorithm, WX presents candidate genes that are the most likely to be chosen as biomarkers by recognizing differences in gene expression between a selected experimental and control group and calculating the scores of the importance. WX features highly accurate results increased by using the entire genome data instead of DEG data that is filtered by the difference in gene expression.\n\nBy using the WX, target genes were currently selected from many pipelines such as Alzheimer ’s Disease, Rheumatism, Amyotrophic lateral sclerosis (ALS), and sarcopenia, etc. ",
                    {
                        text: "VIEW GWX PAPERS",
                        href: "https://www.nature.com/articles/s41598-019-47016-8",
                    }

                ],
                [
                    "Prognostic Biomarkers Prediction",
                    "Prognostic biomarkers are an important concept for diagnosing and treating disease. Predicting prognostic biomarkers enables medical treatments personalized to each patient's characteristics more in the strategy establishment for diagnosis and treatment of disease. We present a highly accurate prognostic biomarker by more accurately categorizing a high-risk and low-risk group among the relevant disease patients and calculating the importance of genome expression through Casexed Wx (CWx) that is own deep learning algorithm.",
                    {
                        text: "VIEW CWX PAPERS",
                        href: "https://www.frontiersin.org/articles/10.3389/fgene.2019.00662/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Genetics&id=444920",
                    }
                ],
                [
                    "MOA (Mode Of Action) Prediction",
                    "In drug development, it is important to identify the mode of action (MOA). Drug development pipelines with an unclear MOA are not only costly in clinical trials but are also difficult to obtain approvals. DEARGEN predicts GO terms with a high priority by building Gene Ontology(GO) terms-based Neural Network Model and MOA by analyzing the weight of the Neural Network.",
                ],
                [
                    "Case Study ∙ Lung Cancer",
                    "Two types of metastasis-induced biomarkers related to lung cancer prognosis have been found by using WX, and these biomarkers are shown to be significantly correlated to the prognosis through the analysis of actual clinical data. In a cell line experiment through the collaborative research with St. Mary's Hospital, it is confirmed that the metastasis of lung cancer cells has increased by miR-XXXa, b, which are two types of biomarkers related to lung cancer prognosis discovered by using WX. Also, the overall survival (OS) was analyzed by grouping according to the expression level of miR-XXXa, b in real patients' tissue samples. This result showed a significant correlation if the expression level of miR-XXXa, b is high, the prognosis worsens.\n\n",
                ],
            ]
        ],
        DearDTI: [
            // header
            [
                "DearDTI",
                "DearDTI presents drug candidates with a good efficacy by predicting the binding affinity, based on information about the compound-protein interactions.",
            ],
            // body
            [
                [
                    "Drug Candidate Discovery",
                    "DearDTI predicts a binding affinity between compounds and proteins(Drug-Target Binding Affinity). It discovers small molecules with high potential as drug candidates by predicting that particular small molecule can be bound to which target or which small-molecule compounds will bind well to the desired disease target. DearDTI has made global context be used effectively by introducing Transformer technology based on the Self-Attention mechanism. Accordingly, DearDTI increases the precision of the model and suggests more accurate candidates by effectively reflecting the knowledge of compound learning patterns in the model.",
                ],
                [
                    "Drug Repurposing",
                    "The technology of predicting disease targets that bind to specific small molecule in the DearDTI enables drug development Pipeline for drug repurposing.DearDTI presents a drug development pipeline by predicting targets that candidates (fail to pass the clinical phase due to its low efficacy) can have a better effect or exploring new indications for existing approved pharmaceuticals.",
                ],
                [
                    "Related (Research) Papers",
                    "The DearDTI model was published in the Journal of Machine Learning Research (JMLR) which is a journal with the highest level in artificial intelligence. In addition, it is presented at Machine Learning for Healthcare (MLHC) that is the largest conference on artificial intelligence technology using medical big data.",
                    {
                        text: "VIEW DearDTI PAPERS",
                        href: "https://arxiv.org/abs/1908.06760",
                    }
                ],
                [
                    "Case Study ∙ Solid Cancer",
                    "We have examined how the DearDTI can significantly be used in the actual process of drug development through experiments. We conducted an experiment to extract 30 EGFR Target Drug Candidates by setting the data of 1,794 FDA approved drugs from Drugbank and EGFR(Cancer Biomarker) protein as input data. As a result, we found that all eight actual EGFR-targeted drugs existed in the Drugbank were among the candidates. This not only demonstrates the usefulness of the DearDTI but also means that in addition to eight FDA-approved drugs, 22 proposed drug candidates also need to be tested as potential anticancer drugs.",
                    {
                        text: "FIND OUT MORE",
                        href: "https://deargen.blog/2019/08/05/colorectal-cancer-mertk-and-small-molecular/",
                    }
                ],
                [
                    "Case Study ∙ ALS",
                    "Using the DearDTI, we have found a small molecule that can control abnormal aggregation of the TDP-43 protein found in ALS patients. After that, we confirmed the effects of a small molecule in decreasing the aggregation of TDP-43 protein in cell line experiments through a collaborative research result with Dankook University. This has shown that it is possible to shorten the ALS initial research period (for drug candidate discovery) through traditional research methods that take more than 16 months into within 2 months using DEARGEN's artificial intelligence technology.",
                    {
                        text: "FIND OUT MORE",
                        href: "https://deargen.blog/2019/08/05/als-lou-gehrig-a-challenge-to-radical-cure/",
                    }
                ],
                [
                    "Case Study ∙ COVID-19",
                    "We used DearDTI to identify commercially available drugs that could act on viral proteins of COVID-19. We conducted an analyze by antiviral drugs data. As a result, we predicted that drugs which have already been marketed and drugs which are developed as Ebloa treatments etc. are effecive for COVID-19. Some of them are actually in clinical trial in some countries and companies.", 
                    {
                        text: "FIND OUT MORE",
                        href: "https://deargen.blog/2020/02/07/deargen-predicting-coronavirus-treatment-using-ai/",           
                    },    
                ],
            ],
        ],
        MOL_EQ: [
            // header
            [
                "MolEQ",
                "MolEQ designs novel compounds by optimizing multiple properties such as efficacy and toxicity of a lead compound.",
            ],
            // body
            [
                [
                    "ADMET Prediction",
                    "Early research into the metabolic properties of candidates in the process of drug development is becoming a key strategy to reduce the cost and time of drug development. MolEQ designs novel drug candidates by optimizing ADMET (absorption, distribution, metabolism, excretion, toxicity) of compounds to the desired disease target.\n\nMolEQ technology has a feature that multiple properties of a substance can be optimized by giving a weight differently according to the molecular design goals, based on affinity numerical information from the DearDTI. The optimized features are shown as efficacy, toxicity, Synthetic Accessibility (SA), and Quantitative Estimate of Drug-likeness (QED), etc. Other models typically proceed with optimization by learning only the properties of one substance such as QED.\n\nAs a result, MolEQ has optimized several properties of the compound at once, so it has an advantage that the results can be directly applied to a drug development research.",
                ],
                [
                    "Case Study ∙ Iniparib / Brivanib / Rebimastat",
                    "Iniparib is an inhibitor of DNA repair essential for cancer cell survival and it was developed up to Phase III clinical trials, but it failed to be developed into a drug due to a lack of its efficacy. Thus, we conducted a drug optimization by giving high weight to AS by using MolEQ, which showed a finding that AS was variously improved. Among them, two candidates were predicted to be easy to synthesize materials with SA 3.5 and below because we also gave weight to Synthetic Accessibility (SA) during the optimization process.\n\nbrivanib, the toxicity was evaluated by DearADMET based on the FDA report that reports low efficacy and unexpected toxicity. The result showed that brivanib has high hERG (cardiac toxicity) rate, so we selected brivanib as an optimization case to lower hERG values. As a result, the affinity was improved and the toxicity was lower while maintaining the original core structure.\n\nRebimastat is a drug candidate that failed in Phase III clinical trials due to side effects such as skin hypersensitivity, etc. Thus, DEARGEN conducted a drug optimization by giving high weight to Skin Toxicity Score (STS) through MolEQ. As a result, we predicted a large number of structures that were expected to have low STS but high AS and to be synthesized easily.\n\nBased on these prediction results, posters were presented at the AACR drug discovery workshop (January 2020).",
                    {
                        text: "FIND OUT MORE",
                        href: "https://deargen.blog/2020/02/05/moleq-an-artificial-intelligence-model-that-adjusts-multiple-property-at-the-same-time-and-optimizes-small-molecule-lead-compounds/",           
                    },
                ],
            ],
        ],
        mobile: {
            targetDiscovery: [
                // header
                [
                    "The AI platform for drug discovery",
                    "Dr.UG",
                    "AI-Powered",
                    "Target Discovery",
                    "The ‘DearTRANS’ (Genome Data Analysis) and ‘WX’(Biomarker Prediction) consisting of Dr.UG are DEARGEN's artificial intelligence technologies that specialize in identifying disease-causing targets. Based on each AI technology, genomic data can be quickly compared and analyzed. Also, the disease target's mode of action (MOA) and biomarkers can be predicted.",
                ],
                // bottom text
                [
                    "“Based on targets from our Dr.UG flatform, \nYou can try out the new Drug Design here.”",
                    "Drug Design Technology →",
                ],
            ],
            drugDesign: [
                // header
                [
                    "The AI platform for drug discovery",
                    "Dr.UG",
                    "AI - Powered",
                    "Drug Design",
                    "The DearDTI (Molecule Screening) and MolEQ (Lead Optimization) consisting of Dr.UG are DEARGEN's artificial intelligence technologies that specialize in designing drug candidates for targets. Through the Molecule transformer technology, the affinity score between a target and a small molecule can be predicted. Based on this, small molecule drug candidates with higher efficacy can be designed by ADMET (absorption, distribution, metabolism, excretion, toxicity) optimization.",
                ],
                // bottom text
                [
                    "“For new drug design with our Dr.UG flatform, \nYou can start Target Discovery here.”",
                    "Target Discovery Technology →",
                ],
            ],
        },
    },
}

export default function useContent() {
    const language = useLanguage()
    const 컨텐츠 = text[language]

    const images = useStaticQuery(graphql`
        query {
            target_discovery: file(relativePath: { eq: "Home_OurTechnology/target_discovery.png" }) {
                childImageSharp {
                    fixed(width: 130, height: 110) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            drug_design: file(relativePath: { eq: "Home_OurTechnology/drug_design.png" }) {
                childImageSharp {
                    fixed(width: 140, height: 85) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return { images, 컨텐츠 }
}