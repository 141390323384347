import React, { useState, useMemo } from 'react'
import styled from "styled-components"
import Flex, { FlexItem } from "styled-flex-component"
import breakpoints from "styled-components-breakpoint"
import { useWindowScroll, useIsDeviceWidth } from "src/components/utils"
import {  } from 'src/GlobalStyle'
import NavItem from "./_NavItem"
import MainLogo from "./_MainLogo"
import BurgerButton from "./_BurgerButton"
import LanguageButton from "./_LanguageButton"

const NavigationRoot = styled.header`
    position: ${props => props.scrollFollowMode ? "fixed" : "absolute"};
    box-shadow: ${props => props.scrollFollowMode ? "0 2px 10px 0 #ccd0d9;" : "none"};
    transform: ${props => props.hide ? "translateY(-200%)" : "translateY(0)"};

    padding: ${props => props.scrollFollowMode ? "20px 0" : "40px 0 0"};
    ${props => props.isSmallDevice ? "padding: 0;" : ""};
    
    background: ${props => props.isNotFoundPage ? "transparent": "white"};
    transition: all 0.3s;
    ${/* ModalPortal's z-index is 110. so this z-index must be higher than that */""}
    z-index: 111;
    width: 100%;

    ${breakpoints("mobile", "desktop")`
        padding-top: 0;
        height: 60px;
        ${props => props.mobileMenuOpen 
            ? `
                background: transparent;
                box-shadow: none;
            ` 
            : ""};
    `}
`
const NavItems = styled.nav`
    width: 950px;
    padding-left: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${breakpoints("mobile", "desktop")`
        padding: 0 10px;
    `}
`


export default function Navigation({ config=[], location }) {
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })

    // render
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const navItemsRender = !isSmallDevice 
        ? config.map((config, index) => <NavItem key={index} {...config} />)
        : <BurgerButton navItemsConfig={config} onChange={open => setMobileMenuOpen(open)}/>
        
    const languageButton = !isSmallDevice && <FlexItem style={{paddingRight: 40}}>
        <LanguageButton location={location} />
    </FlexItem>

    const navigationRender = <Flex full justifyBetween style={{width: "100vw"}}>
        <NavItems justifyBetween alignCenter>
            <MainLogo />
            {navItemsRender}
        </NavItems>

        {languageButton}
    </Flex>
    

    // menu should hides when scrolled down, showed when scrolled up 
    const { y } = useWindowScroll()
    const [pageY, setPageY] = useState(0)
    const isScrolledDown = useMemo(() => {
        setPageY(y)
        return (y - pageY) > 0
    }, [y])


    // windowScroll-Y must be more than 0. otherwise it acts weird in safari
    const scrollFollowMode = y > 10
    const hide = scrollFollowMode && isScrolledDown


    // navigation should be transparent in 404 page
    const isNotFoundPage = location && location.pathname.startsWith("/404")

    return <NavigationRoot
    scrollFollowMode={scrollFollowMode}
    isSmallDevice={isSmallDevice}
    hide={hide}
    mobileMenuOpen={mobileMenuOpen}
    isNotFoundPage={isNotFoundPage}> 
        {navigationRender}
    </NavigationRoot>

}
