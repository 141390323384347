import React, { useReducer, useMemo } from 'react'
import styled, {  } from "styled-components"
import Flex from "styled-flex-component"
import breakpoint from "styled-components-breakpoint"
import _ from "lodash"
import { Img, H1, U } from "src/components"
import { fadeIn, bounce, CapsuleText, useIsDeviceWidth, useInView } from "src/components/utils"
import useContents from "src/data"

const PartnersRoot = styled(Flex)`
    ${breakpoint("mobile", "desktop")`
        margin: 50px 0 100px;
        box-sizing: border-box;
        padding: 0 20px;
    `}
`

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${H1} {
        font-size: 24px;
        font-weight: bold;
        margin-top: 20px;
        text-align: center;
        
        ${breakpoint("mobile", "desktop")`
            margin-top: 50px;
            font-size: 16px;
        `}  
    }
`
const Grid = styled.div`
    flex: 4;
    max-width: 1600px;
    display: grid;
    grid-template-columns: repeat(${props => props.numColumn}, 1fr);
    grid-auto-rows: 120px;
    grid-gap: 0px 10px;
    justify-items: center;
    align-items: center;
    margin-top: 30px;

    ${breakpoint("tablet", "desktop")`
        margin-top: 50px;
        grid-template-columns: repeat(3, 1fr);
    `}
    ${breakpoint("mobile", "tablet")`
        margin-top: 30px;
        grid-auto-rows: 80px;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px 20px; 
    `}
`
const Item = styled.div`
    opacity: ${props => props.show ? 1 : 0};
    animation: ${props => {
        return props.show
            ? fadeIn({ mode: "fromLeft", delay: 0.3 + props.delay, speed: 1.5 })
            : "none"
    }};     
`
const Bounce = styled.div`
    width: 100%;
    animation: ${props => props.play ? bounce({ height: 20, callback: props.callback }) : "none"};
`

function LogoGrid({ title, numColumn, images, inView }) {
    // play mouse hover animation
    const imageKeys = _.keys(images)
    const imageValues = _.values(images)
    const [playIndex, dispatch] = useReducer((state, action) => {
        const { index, play } = action
        const newState = Array.from(state)
        newState[index] = play
        return newState
    }, Array(imageKeys.length).fill(false))

    return <>
        <H1>
            <U>{title}</U>
        </H1>
        <Grid numColumn={numColumn}>
            {_.map(imageValues, (gatsbyImageSrc, index) => {
                // calculate initial animation delay
                const row = index%numColumn
                const col = Math.floor(index/numColumn)
                const delay = (row + col) / 6 + 0.3
                
                return <Item
                key={index}
                show={inView}
                delay={delay}>
                    <Bounce
                    onMouseEnter={e => dispatch({ index, play: true })}
                    onTouchStart={e => dispatch({ index, play: true })}
                    play={playIndex[index]===true}
                    callback={() => dispatch({ index, play: false})}>
                        <Img
                        gatsbyImageSrc={gatsbyImageSrc}
                        objectFit="contain"
                        alt={`${title} of Deargen's partner ${index}`} />
                    </Bounce>
                </Item>
            })}
        </Grid>
    </>
}

export default function Partners() {
    const { images } = useContents("Home_Partners")
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })

    // initial animation
    const [ref, inView] = useInView({ threshold: 0.2, triggerOnce: true })
    
    // title in mobile
    const mobileTitleRender = isSmallDevice
        ? <CapsuleText>
            {`Partners`}
        </CapsuleText>
        : null

    // logo render
    const logoRender1 = useMemo(() => {
        const filtered_images = _.pickBy(images, (value, key) => key.includes("corporate_partnership"))
        return <LogoGrid
            inView={inView}
            title="Corporate Partnership"
            images={filtered_images}
            numColumn={6} />
    }, [inView, images])

    const logoRender2 = useMemo(() => {
        const filtered_images = _.pickBy(images, (value, key) => key.includes("scientific_collaboration"))
        return <LogoGrid
            inView={inView}
            title="Scientific Collaboration"
            images={filtered_images}
            numColumn={6} />
    }, [inView, images])


    return <PartnersRoot ref={ref} full center column>
        {mobileTitleRender}
        
        <LayoutContainer>
            {logoRender1}
            {logoRender2}
        </LayoutContainer>
    </PartnersRoot>
}