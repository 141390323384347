import { useStaticQuery, graphql } from "gatsby"
import { useLanguage } from "src/components/utils"

const text = {   
    ko: [
        // header
        [
            // big text bold
            "\n",            
            // big text bold
            "Our Pipeline",
            // small text
            "디어젠은 신약개발 초기 R&D 단계를 AI로 효율화 합니다.\n디어젠의 각 프로그램은 항암제, 심혈관•대사질환, 신경질환, 면역질환과 같은\n의학적 요구가 높은 질병영역에 초점을 맞추고 있습니다."
        ],
        // first pipeline
        [
            // title
            "4D Program",
            // small text under title
            "4D (Deargen-Driven Drug Develpment) 프로그램은 Hit 물질 발굴부터 디어젠이 주도하여 진행하는 신약개발 파이프라인입니다.\nAI를 활용하여 예측/합성/검증/재학습의 반복과정을 통해 최적의 신약 후보물질을 개발합니다.",
            // pipeline graph
            [
                {programName: "DGD-201 | DLBCL", progress: 6, type: "oncology"},
                {programName: "DGP-101 | Immuno-oncology", progress: 6, type: "oncology"},
                {programName: "DGP-108 | NSCLC", progress: 5, type: "oncology"},
                {programName: "DGP-113 | TNBC", progress: 3, type: "oncology"},
                {programName: "DGP-114 | Ovarian  Cancer", progress: 3, type: "oncology"},
            ],
        ],
        // second pipeline
        [
            "Co-Developed Program",
            "국내외 신약개발사와 다양한 협력을 통해 신약을 개발하고 있습니다.",
            // pipeline graph
            [
                // sk_chemicals                
                {programName: "DGC610 | Rare Disease", progress: 3, type: "neurology", partner: "sk_chemicals"},
                // uppthera
                {programName: "DGC803 | Herbicide", progress: 4, type: "confidential", partner: "uppthera"},
                {programName: "DGC804 | Fungicide", progress: 4, type: "confidential", partner: "uppthera"},
                {programName: "DGC805 | Herbicide", progress: 5, type: "confidential", partner: "uppthera"},
                // amyloid solution
                {programName: "DGC601 | Neurology", progress: 7, type: "cvrm", partner: "amyloid_solution"},
                // dl_pharma
                {programName: "DGC810 | Dermatology", serviceAgreement: true, type: "immunology", partner: "dl_pharma"},
                {programName: "DGC811 | Dermatology", serviceAgreement: true, type: "immunology", partner: "dl_pharma"},                
            ],
        ],
    ],
    en: [
        // header
        [
            // big text bold
            "\n",
            // big text bold
            "Our Pipeline",
            // small text
            "Deargen streamlines the initial R&D stage of new drug development with A.I.\nEach of Deargen's programs focuses on diseases with high medical demands,\nsuch as anticancer drugs, cardiovascular and metabolic diseases, neurological diseases, and immune diseases."
        ],
        // first pipeline
        [
            // title
            "4D Program",
            // small text under title
            "The 4D (Deargen-Driven Drug Development) program is a new drug development pipeline led by Deargen from the discovery of Hit substances.\nUsing A.I, we develop optimal new drug candidates through the iterative process of prediction, synthesis, validation, and re-learning.",
            // pipeline graph
            [
                {programName: "DGD-201 | DLBCL", progress: 6, type: "oncology"},
                {programName: "DGP-101 | Immuno-oncology", progress: 6, type: "oncology"},
                {programName: "DGP-108 | NSCLC", progress: 5, type: "oncology"},
                {programName: "DGP-113 | TNBC", progress: 3, type: "oncology"},
                {programName: "DGP-114 | Ovarian  Cancer", progress: 3, type: "oncology"},
            ],
        ],
        // second pipeline
        [
            "Co-Developed Program",
            "Deargen is developing new drugs through various cooperation with domestic and overseas drug developers.",
            // pipeline graph
            [
                // sk_chemicals                
                {programName: "DGC610 | Rare Disease", progress: 3, type: "neurology", partner: "sk_chemicals"},
                // uppthera
                {programName: "DGC803 | Herbicide", progress: 4, type: "confidential", partner: "uppthera"},
                {programName: "DGC804 | Fungicide", progress: 4, type: "confidential", partner: "uppthera"},
                {programName: "DGC805 | Herbicide", progress: 5, type: "confidential", partner: "uppthera"},
                // amyloid solution
                {programName: "DGC601 | Neurology", progress: 7, type: "cvrm", partner: "amyloid_solution"},
                // dl_pharma
                {programName: "DGC810 | Dermatology", serviceAgreement: true, type: "immunology", partner: "dl_pharma"},
                {programName: "DGC811 | Dermatology", serviceAgreement: true, type: "immunology", partner: "dl_pharma"},                
            ],
        ],
    ],
}


export default function useContent() {
    const language = useLanguage()
    const 컨텐츠 = text[language]

    const images = useStaticQuery(graphql`
        query {
            big_capsule: file(relativePath: { eq: "Pipeline/big_capsule.png" }) {
                childImageSharp {
                    fixed(width: 420, height: 240) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            deargen_logo_colorful: file(relativePath: { eq: "Pipeline/deargen_logo_colorful.png" }) {
                childImageSharp {
                    fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            co: file(relativePath: { eq: "Pipeline/co.png" }) {
                childImageSharp {
                    fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            sk_chemicals: file(relativePath: { eq: "Pipeline/jw-pharma.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            uppthera: file(relativePath: { eq: "Pipeline/farmhannong.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            amyloid_solution: file(relativePath: { eq: "Pipeline/amyloid_solution.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            dl_pharma: file(relativePath: { eq: "Pipeline/lghnh.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return { images, 컨텐츠 }
}