import React from 'react'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Flex, {  } from "styled-flex-component"
import { H1, U, PublicationCard as Card } from 'src/components';
import { BreakLine, GrayBreakLine, useIsDeviceWidth } from 'src/components/utils'
import {  } from "src/GlobalStyle"
import useContents from "src/data"


const PublicationRoot = styled.section`
    position: relative;
    max-width: 800px;
    margin: auto;
    padding: 200px 20px 50px;
    margin-bottom: 150px;

    ${breakpoint("mobile", "desktop")`
        padding-top: 100px;
        margin-bottom: 50px;
    `}
`

export default function Publication({  }) {
    const { images, 컨텐츠 } = useContents("Home_Publication")
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })

    
    // header
    const headerRender = <H1 size={24} weight="bold">
        <U>{`Publications`}</U>
    </H1>


    // cards
    const cardsRender = <Flex column style={{marginTop: 70, marginBottom: 40}}>
        {컨텐츠[0].map((pub, index) => {
            const { label, channel, title, member, content, buttonProps } = pub
            const gatsbyImageSrc = images[`publication_paperPatent_${index}`]
            

            return <React.Fragment key={index}>
                <Card
                gatsbyImageSrc={gatsbyImageSrc}
                label={label}
                upperSubtitle={channel}
                title={title}
                subtitle={member}
                content={content}
                buttonProps={buttonProps} />

                {index < 컨텐츠[0].length-1
                    ? isSmallDevice 
                        ? <GrayBreakLine paddingTop={50} paddingBottom={40} /> 
                        : <BreakLine paddingTop={70} paddingBottom={60} />
                    : null
                }
                
            </React.Fragment>
        })}
    </Flex>


    return<PublicationRoot customCss={`padding: 100px 20px 50px;`}>
        {headerRender}
        {cardsRender}
    </PublicationRoot>   
}