import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { navigate } from "gatsby"
import styled from "styled-components"
import _ from "lodash"
import { Carousel, ArrowButton } from "src/components"
import { useKeypress, useMouseWheelHandler } from 'src/components/utils'
// slides
import slide1 from "./MainSlogan"
// import slide2 from "./Approach"
import slide3 from "./OurTechnology"
import slide4 from "./Pipeline"
import slide5 from "./Publication"
import slide6 from "./Team"
import slide7 from "./Partners"
import slide8 from "./Contact"

const DURATION = 800

export const slides = [ 
    slide1,
    // slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
]

const Opacity = styled.div`
    ${props => props.theme.css.full}
    opacity: ${props => props.hide ? 0 : 1};
    transition: all ${DURATION / 1000 / 2}s;
`

export default function HomeDesktop({ location }) {
    const [beforeCurrent, setBeforeCurrent] = useState({ before: null, current: null })


    const updateUrlHash = (index, location) => {
        const urlHash = slides[index].urlHash
        navigate(`${location.pathname}${urlHash}`, { replace: true })
    }

    const toPrev = useCallback(
        _.debounce(
            () => {
                setBeforeCurrent(({ before, current }) => {
                    const newCurrent = Math.max(current-1, 0)
                    updateUrlHash(newCurrent, location)
                    return { before: current, current: newCurrent }
                })
            },
            600,
            { leading: true, trailing: false }
        )
    , [location])

    const toNext = useCallback(
        _.debounce(
            () => {
                setBeforeCurrent(({ before, current }) => {
                    const newCurrent = Math.min(current+1, slides.length-1)
                    updateUrlHash(newCurrent, location)
                    return { before: current, current: newCurrent }
                })
            },
            600,
            { leading: true, trailing: false }
        )
    , [location])


    // decide current slide according to hashUrl
    useEffect(() => {
        const newCurrent = _.findIndex(slides, ["urlHash", location.hash])

        setBeforeCurrent(({ before, current }) => { 
            if (newCurrent===current) return { before, current}

            return { before: current, current: newCurrent }
        })
    }, [location])
    

    // interactions
    useKeypress({
        keycode: "ArrowDown",
        callback: toNext,
    })
    useKeypress({
        keycode: "ArrowUp",
        callback: toPrev,
    })

    useMouseWheelHandler({ 
        onWheelUp() { toPrev(); },
        onWheelDown() { toNext(); },
        wait: DURATION,
    })


    // handle carousel change
    const [moving, setMoving] = useState(false)

    const onStart = index => {
        setMoving(true)
    }
    const onEnd = index => {
        setMoving(false)
    }


    // if we pass Carousel null with `current`, it makes bug action. so we wait `current` to be decided by urlHash.
    const render = useMemo(() => {
        if (beforeCurrent.current === null) return null

        return <Carousel
        onStart={onStart}
        onEnd={onEnd}
        disableSwipe={true}
        current={beforeCurrent.current}
        axis="y"
        width="100vw"
        height="100vh"
        duration={DURATION}>
            {slides.map((slide, index) => {
                const { before, current } = beforeCurrent
                const toIn = index === current
                const toOut = index === before
                let slidePhase = null
                
                if (moving && toIn) slidePhase = "movingIn"
                if (moving && toOut) slidePhase = "movingOut"
                
                return <Opacity key={index} hide={current !== index}>
                    <slide.component slidePhase={slidePhase} window={window} />
                </Opacity>
            })}
        </Carousel>
    }, [beforeCurrent, moving])

    
    // ArrowButton render
    const isLastPage = beforeCurrent.current === slides.length - 1
    const arrowButtonRender = <ArrowButton
        position={{ left: 30, bottom: 30 }}
        direction="down"
        onClick={e => toNext()} 
        style={{opacity: isLastPage ? 0 : 1}} />


    return <>
        {render}
        {arrowButtonRender}
    </>
}

