import React, { useState } from 'react'
import { navigate }  from "gatsby"
import styled, { } from "styled-components"
import Flex, {  } from "styled-flex-component"
import _ from "lodash"
import { Img } from "src/components"

const SQRT3 = Math.sqrt(3)

const HexagonButtonRoot = styled.div`
    width: ${props => props.direction==="vertical" ? props.size * SQRT3 : props.size * 2}px;
    padding-bottom: ${props => props.direction==="vertical" ? props.size * 2 : props.size * SQRT3}px;
    transform: ${props => props.direction==="vertical" ? `rotate(60deg) skewY(-30deg)` : `rotate(-60deg) skewX(-30deg)`};
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 4px 0 4px 0px;
    ${props => props.customCss};
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
`

const HexagonIn = styled.div`
    ${/* 위에서 rotate먼저하고 skew했으니까 여기서는 skew먼저하고 rotate해서 원상복귀시켜야됨 */""}
    transform: ${props => props.direction==="vertical" ? `skewY(30deg) rotate(-60deg)` : `skewX(30deg) rotate(60deg)`};
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const Border = styled.div`
    z-index: 1;
    position: absolute;
    top: ${props => props.direction==="vertical" ? "25%" : "none"};
    left: ${props => props.direction==="vertical" ? "none" : "25%"};
    width: ${props => props.direction==="vertical" ? "100%" : "50%"};
    height: ${props => props.direction==="vertical" ? "50%" : "100%"};
    transform: rotate(${props => props.rotateDegree}deg);
    box-sizing: border-box;
    border-left: ${props => props.direction==="vertical" ? props.config.border : "transparent"};
    border-right: ${props => props.direction==="vertical" ? props.config.border : "transparent"};
    border-top: ${props => props.direction==="vertical" ? "transparent" : props.config.border};
    border-bottom: ${props => props.direction==="vertical" ? "transparent" : props.config.border}};
    border-radius: 4px;
    transition: all 0.2s;
    background: ${props => props.active
        ? props.config.backgroundColorHovered
        : props.config.backgroundColorNotHovered
    };
`
const Opacity = styled.div`
    ${props => props.theme.css.full}
    z-index: 1;
    position: absolute;
    transition: all 0.2s;
    opacity: ${props => props.opacity};
`
const BackgroundComponentWrapper = styled(Flex)`
    position: absolute;
`
const BackgroundImageWrapper = styled.div`
    ${props => props.theme.css.full}
    position: absolute;
    top: 0;
    left: 0;
`
const Center = styled.div`
    ${props => props.theme.css.center()}
`

export default function NewHexagonButton({ 
    size=70, 
    color="black", 
    imgSrc,
    gatsbyImageSrc,
    config={}, 
    to,
    innerLinkOption={},
    href,
    eventHandlers={},
    onClick=()=>{},
    direction="vertical",
    activeFromOutside=false,
    customCss,
}) {
    
    // config
    const defaultConfig = { 
        border: gatsbyImageSrc ? "none" : `1px solid ${color}`,
        componentHovered: null,
        componentNotHovered: null,
        backgroundColorNotHovered: "transparent",
        backgroundColorHovered: gatsbyImageSrc 
            ? "transparent" 
            : config.backgroundColorHovered
                ? config.backgroundColorHovered
                : color,
        backgroundComponent: null,
    }
    config = { ...defaultConfig, ...config }

    // background image
    const backgroundImageRender = <BackgroundImageWrapper>
        {gatsbyImageSrc && <Img imgSrc={imgSrc} gatsbyImageSrc={gatsbyImageSrc} customCss={`height: 100%;`}/>}
    </BackgroundImageWrapper>


    // background
    const backgroundComponentRender = <BackgroundComponentWrapper center full>
        {config.backgroundComponent}
    </BackgroundComponentWrapper>


    // interaction
    const [active, setActive] = useState(false)
    
    if (!_.has(eventHandlers, "onMouseEnter")) eventHandlers.onMouseEnter = () => setActive(true)
    if (!_.has(eventHandlers, "onMouseLeave")) eventHandlers.onMouseLeave = () => setActive(false)
    // if (!_.has(eventHandlers, "onTouchStart")) eventHandlers.onTouchStart = () => {setActive(true); console.log(3)}
    // if (!_.has(eventHandlers, "onTouchEnd")) eventHandlers.onTouchEnd = () => {setActive(false); console.log(4)}
    // if (!_.has(eventHandlers, "onTouchCancel")) eventHandlers.onTouchEnd = () => {setActive(false); console.log(5)}
    if (!_.has(eventHandlers, "onTouchStart")) {
        eventHandlers.onClick = e => {
            if(to) navigate(to, innerLinkOption)
            if(href) window.open(href, "_blank")
            setActive(active => !active)
            onClick()
        }
    }


    // active
    const isActive = active || activeFromOutside

   return <HexagonButtonRoot
    size={size}
    direction={direction}
    customCss={customCss}
    {...eventHandlers}>
        <HexagonIn direction={direction}>
            <Border rotateDegree={0} active={isActive} direction={direction} size={size} config={config} />
            <Border rotateDegree={60} active={isActive} direction={direction} size={size} config={config} />
            <Border rotateDegree={-60} active={isActive} direction={direction} size={size} config={config} />

            {backgroundImageRender}
            {backgroundComponentRender}

            {/* show this when not hovered */}
            <Opacity 
            opacity={isActive ? 0 : 1}>
                <Center>{config.componentNotHovered}</Center>
            </Opacity>
            
            {/* show this when hovered */}
            <Opacity opacity={isActive ? 1 : 0}>
                <Center>{config.componentHovered}</Center>
            </Opacity>

        </HexagonIn>
    </HexagonButtonRoot>
}
