import React from 'react'
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { H2, P } from 'src/components';
import { blur } from 'src/components/utils';
import { FiHexagon } from "react-icons/fi"
import { mainTheme } from "src/GlobalStyle"
import useContents from "src/data"

const Item = styled.div`
    margin-bottom: 1rem;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    animation: ${props => blur({ speed: 1, delay: props.delay })}

`
const Title = styled.div`
    margin-top: 1rem;
    ${H2} {
        font-size: 1.5em;
    }
`
const Content = styled.div`
    margin-top: 1rem;
`
        
function AboutDrugBody() {
    const { 컨텐츠 } = useContents("Technology")

    
    return <>
        <Item delay={0.1}>
            <Title>
                <H2 customCss={`color: ${mainTheme.secondary};`}>
                    <FiHexagon />{컨텐츠.Introduce[1][0][0]}
                </H2>
            </Title>
            <Content>
                <P>{컨텐츠.Introduce[1][0][1]}</P>
            </Content>
        </Item>    

        <Item delay={0.3}>
            <Title>
                <H2>
                    <FiHexagon />{컨텐츠.Introduce[1][1][0]}
                </H2>
            </Title>
            <Content>
                <P>{컨텐츠.Introduce[1][1][1]}</P>
            </Content>
        </Item>    
    </>
}

export default <AboutDrugBody />