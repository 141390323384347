import React, { useState, useEffect } from 'react'
import { InView } from "react-intersection-observer"
import _ from "lodash"

const THRESH_HOLD = 0.3

export default function useInViews(components=[]) {
    const [render, setRender] = useState(null)
    const [inViews, setInViews] = useState(Array(components.length).fill(false))
    const [elements, setElements] = useState(Array(components.length).fill(null))
    
    useEffect(() => {
        setRender(components.map((component, index) => {
            const setRef = ref => {
                if (!ref) return
                setElements(elements => {
                    const newRefs = Array.from(elements)
                    newRefs[index] = ref.node
                    return newRefs
                })
            }

            return <InView
            ref={setRef}
            threshold={THRESH_HOLD}
            onChange={inView => setInViews(inViews => {
                const newState = [...inViews]
                newState[index] = inView
                return newState
            }) }>
                {component}
            </InView>
        }))
    }, [components])

    return [render, inViews, elements]
}
