import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import Flex from "styled-flex-component"
import useContents from "src/data"
import { H1, H2, Strong, P, Img } from "src/components"
import Legend from "./_Legend"
import PipelineGraph from "./_PipelineGraph"
import breakpoint from 'styled-components-breakpoint'


const Layout = styled(Flex)`
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    padding-bottom: 250px;
    ${breakpoint("mobile", "desktop")`
        padding-bottom: 100px;
    `}
`
const Header = styled(Flex)`
    width: 100%;
    padding-top: 150px;

    ${breakpoint("mobile", "desktop")`
        padding-top: 50px;
        figure {
                display: none;
            }
    `}
`
const PipelineTitle = styled(Flex)`
    margin-top: 100px;
`

export default function Pipeline() {
    const { images, 컨텐츠 } = useContents("Pipeline")

    const HeaderRender = <Header justifyBetween>
        <Flex column>
            <H1 weight={300}>
                {컨텐츠[0][0]}
                <Strong>{컨텐츠[0][1]}</Strong>
            </H1>
            <P marginTop={20}>
                {컨텐츠[0][2]}
            </P>
            <Legend />
        </Flex>
        <div>
            <Img gatsbyImageSrc={images["big_capsule"]} />
        </div>
    </Header>

    const FirstPipelineRender = <>
        <PipelineTitle column center>
            <Img gatsbyImageSrc={images["deargen_logo_colorful"]} />
            <H2 size={24} marginTop={10}>
                {컨텐츠[1][0]}
            </H2>
            <P marginTop={20} align="center">
                {컨텐츠[1][1]}
            </P>
        </PipelineTitle>
        <PipelineGraph items={컨텐츠[1][2]} />
    </>

    const SecondPipelineRender = <>
        <PipelineTitle column center>
            <Img gatsbyImageSrc={images["co"]} />
            <H2 size={24} marginTop={10}>
                {컨텐츠[2][0]}
            </H2>
            <P marginTop={20}>
                {컨텐츠[2][1]}
            </P>
        </PipelineTitle>
        <PipelineGraph items={컨텐츠[2][2]} />
    </>

    return <Layout center column>
        {HeaderRender}
        {FirstPipelineRender}
        {SecondPipelineRender}
    </Layout>
}
