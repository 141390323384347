import React, {  } from 'react'
import styled, {  } from "styled-components"
import Flex, { FlexItem } from "styled-flex-component"
import { H1, U, P, Strong, Button, HexagonField } from "src/components"
import { useWindowSize, CapsuleText, useLanguage } from "src/components/utils";
import { mainTheme } from "src/GlobalStyle"
import useContents from "src/data"

const SQRT3 = Math.sqrt(3)

const TeamMobileRoot = styled(Flex)`
    margin-top: 50px;
    box-sizing: border-box;
    padding: 0 20px;
`

const Hexagon = styled(FlexItem)`
    position: relative;
    left: -20px;
    top: -60px;
`

const SloganP = styled(P)`
    background: ${props => props.theme.secondary};
    font-size: 0.9em;
    padding: 2px 10px;
`

const HexagonText = styled(FlexItem)`
    position: absolute;
    left: 20px;
    ${props => props.customCss}
`

export default function TeamMobile() {
    const language = useLanguage()
    const { images, 컨텐츠 } = useContents("Home_Team")

    // text
    const textRender = <>
        <H1 size={26} weight={800} customCss={`margin-top: 30px;`}>
            {컨텐츠[1][0][0]}
            <U>{컨텐츠[1][0][1]}</U>
            {컨텐츠[1][0][2]}
        </H1>

        <P size={16} customCss={`margin-top: 10px;`}>{컨텐츠[1][1]}</P>

        <Button
        text={컨텐츠[1][2]}
        to={`/${language}/team`}
        customCss={`margin-top: 24px; position: relative; z-index: 1;`}/>
    </>


    // pre-calculate value    
    const { innerWidth } = useWindowSize()
    const size = innerWidth / 1.5 / SQRT3
    const hexagonHeight = size * 2
    const hexagonWidth = size * SQRT3
    
    // hexagon configs
    const ceoProfile = 컨텐츠[0].find(profile => profile.role==="CEO")
    const ceoImage = images.member_0
    const hexagonConfigs = [
        // left navy
        {
            left: 0,
            bottom: 0,
            buttonProps: {
                size,
                config: {
                    backgroundColorNotHovered: mainTheme.primary,
                },
            }
        },
        // right green
        {
            left: hexagonWidth,
            bottom: hexagonHeight / 2,
            buttonProps: {
                color: mainTheme.secondary,
                size,
                config: {
                    backgroundColorNotHovered: mainTheme.secondary,
                },
            }
        },
        // mid picture
        {
            left: hexagonWidth / 2,
            bottom: hexagonHeight / 4,
            buttonProps: {
                size,
                color: mainTheme.primary,
                gatsbyImageSrc: ceoImage,
            }
        },
    ]

    // hexagon field size
    const width = innerWidth
    const height = size * 3
    const ceoName = language==="ko" ? ceoProfile.koreanName : ceoProfile.englishName
    const hexagonRender = <Hexagon>
        <HexagonField
        width={width}
        height={height}
        hexagonConfigs={hexagonConfigs} />
        <HexagonText customCss={`bottom: ${hexagonHeight/3}px;`}>
            <SloganP>
                {`"Let's make a `}
                <Strong>{ceoProfile.letsMakeA}</Strong>
                {` together"`}
            </SloganP>
            <P size="0.8em" weight={400} color="white" customCss={`margin-top: 5px;`}>{ceoName} / {ceoProfile.role}</P>
        </HexagonText>
    </Hexagon>


    // title in mobile
    const mobileTitleRender = <CapsuleText>
        {`Our Team`}
    </CapsuleText>


    return <TeamMobileRoot column>
        {mobileTitleRender}
        {textRender}
        {hexagonRender}
    </TeamMobileRoot>
}
