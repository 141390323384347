import React, { useState } from 'react'
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { mainTheme } from "src/GlobalStyle"
import _ from "lodash"
import { OutboundLink } from "gatsby-plugin-google-gtag"


const filledStyleCss = css`
    background: ${props => props.color};
    color: white;
`
const emptyStyleCss = css`
    background: transparent;
    color: ${props => props.color};
`
const ButtonRoot = styled.button`
    ${props => props.filled ? filledStyleCss : emptyStyleCss }
    display: block;
    width: fit-content;
    height: 50px;
    box-sizing: border-box;
    padding: 15px;
    cursor: pointer;
    border: 1px solid ${props => props.color};
    border-radius: 5px;
    transition: all 0.15s;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent; 

    :hover {
        ${props => props.filled ? emptyStyleCss : filledStyleCss }
    }

    ${breakpoint("mobile", "tablet")`
        height: 40px;
        padding: 10px 18px;
        font-size: 14px;
        font-weight: 400;
    `}
    
    ${props => props.customCss}
`

export default function Button({ text, color=mainTheme.primary, filled=false, customCss, to, href, eventHandlers={}, ...props }) {
    // `to` is for inner-link. `href` is for outer-link
    const innerLinkProps = { as: Link, to }
    const outerLinkProps = { as: OutboundLink, href, target: "_blank" }
    const linkProps = to 
        ? innerLinkProps 
        : href
            ? outerLinkProps
            : {}
    

    // interaction
    const [active, setActive] = useState(false)
    // if (!_.has(eventHandlers, "onTouchStart")) eventHandlers.onTouchStart = () => setActive(true)
    // if (!_.has(eventHandlers, "onTouchEnd")) eventHandlers.onTouchEnd = () => setActive(false)
    // if (!_.has(eventHandlers, "onTouchCancel")) eventHandlers.onTouchEnd = () => setActive(false)


    // active
    const isActive = filled || active

    return <ButtonRoot
    color={color}
    filled={isActive}
    customCss={customCss}
    onClick={e => e.stopPropagation()}
    {...eventHandlers}
    {...linkProps}
    {...props} >
        {text}
    </ButtonRoot>
}