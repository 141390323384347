import React from "react"
import styled from "styled-components"

const defaultSize = "1.1em"

const H2 = styled(
    ({ size, color, weight, align, marginTop, whiteSpace, lineHeight, hoverColor, lineLimit, children, ...props }) => <h2 {...props}>{children}</h2>
)`
    font-size: ${props => props.size 
        ? Number.isInteger(props.size)
            ? `${props.size}px`
            : props.size
        : `${defaultSize}`};

    color: ${props => { 
        if (props.color==="primary") return  props.theme.primary
        if (props.color==="secondary") return  props.theme.secondary

        const defaultColor = props.theme.primary
        return props.color 
            ? props.color
            : defaultColor
    }};

    font-weight: ${props => props.weight 
        ? props.weight 
        : 900
    };

    text-align: ${props => props.align 
        ? props.align 
        : "inherit"
    };
    
    margin-top: ${props => props.marginTop 
        ? props.marginTop 
        : 0
    }px;

    white-space: ${props => props.whiteSpace
        ? props.whiteSpace
        : "pre-wrap"
    };

    line-height: ${props => props.lineHeight
        ? props.lineHeight
        : "1.5"
    };

    transition: all 0.2s;
    &:hover { 
        color: ${props => props.hoverColor
            ? props.hoverColor
            : props.color
        }
    };

    transition: all 0.2s;
    &:hover {
        color: ${props => {
            if (props.hoverColor==="primary") return  props.theme.primary
            if (props.hoverColor==="secondary") return  props.theme.secondary

            const defaultHoverColor = props.color
            return props.hoverColor 
                ? props.hoverColor
                : defaultHoverColor
        }}
    };

    line-height: 1.3;
    ${props => props.customCss};
`

export default H2