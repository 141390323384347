import React, { useState } from 'react'
import styled from "styled-components"
import Flex from "styled-flex-component"
import breakpoint from "styled-components-breakpoint"
import { Link } from "gatsby"
import { FaAngleDown } from "react-icons/fa"
import { U, P, Strong } from "src/components"
import { useIsDeviceWidth } from 'src/components/utils'
import { mainTheme } from 'src/GlobalStyle'
import { OutboundLink } from "gatsby-plugin-google-gtag"


const NavItemRoot = styled.a`
    color: ${props => props.theme.primary};
    transition: color 0.3s;
    -webkit-tap-highlight-color: transparent; 

    :hover {
        color: ${props => props.theme.primary};
    }

    ${breakpoint("mobile", "desktop")`
        border-bottom: 1px solid rgba(6, 55, 79, 0.2);
        padding: 16px 0px;
        margin-left: 20px;
    `}
    
    ${props => props.customCss}
`
const SubMenu = styled(Flex)`
    display: ${props => !props.show && "none"};
    padding: 5px;
    position: absolute;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0 2px 5px gray;
    width: 120px;
    background: white;
    margin-top: 20px;
    &::before {
        // 서브메뉴를 margin-top 으로 띄워놓으면 중간에 비어있기때문에 마우스를 옮기다가 onMouseLeave 가 발동해버림. 따라서 보이지 않는 ::before 엘리먼트로 공백을 메워준다
        content: "";
        position: absolute;
        width: 100%;
        height: 40px;
        left: 0;
        top: -20px;
    }
`
const SubMenuItem = styled.div`
    border-radius: 10px;
    padding: 10px;
    transition: 0.2s;
    &:hover {
        background: rgba(230, 241, 255);
    }
`

export default function NavItem({ text, to, href, isActive, fontWeight, mobileSubtitle, subMenuItems=[] }) {
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800})
    const [hovered, setHovered] = useState(false)

    // `to` is for inner-link. `href` is for outer-link
    const innerLinkProps = to ? { as: Link, to } : {}
    const outerLinkProps = href ? { as: OutboundLink, href, target: "_blank" } : {}

    // render
    const underlineColor = isSmallDevice ? "white" : mainTheme.secondary
    const subMenuRender = <>
        {subMenuItems.length > 0 && <>
            <FaAngleDown size={12} />
            <SubMenu column show={hovered} onClick={e => console.log("subMenu")}>
                {subMenuItems.map(item => <SubMenuItem as={(item.href ? OutboundLink : Link)} to={item.to} href={item.href} onClick={e => e.stopPropagation()}>
                        {item.text}
                </SubMenuItem>)}
            </SubMenu>
        </>}
    </>

    const render = <P

    onClick={e => console.log("P")}

    size={isSmallDevice ? 22 : 16}
    weight={fontWeight || "normal"}
    onMouseEnter={e => setHovered(true)}
    onMouseLeave={e => setHovered(false)}
    style={{position: "relative"}} >
        {isActive ? <Strong><U color={underlineColor}>{text}</U></Strong> : text}
        {subMenuRender}
    </P>


    // mobileSubtitle
    const mobileSubtitleRender = mobileSubtitle 
        ? <P size={12} color="rgba(6, 55, 79, 0.5)">{mobileSubtitle}</P>
        : null

        
    return <NavItemRoot
    {...innerLinkProps}
    {...outerLinkProps}>
        {mobileSubtitleRender}
        {render}
    </NavItemRoot>
}
