import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import styled from "styled-components"
import { useLanguage } from 'src/components/utils'

const LanguageButtonRoot = styled.div`
    display: flex;
    width: 80px;
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
`
const Part = styled.div`
    flex: 1;
    position: relative;
    background: ${props => props.selected ? props.theme.secondary : `white`};
    color: ${props => props.theme.primary};
    text-align: center;
`
const Center = styled.div`
    ${props => props.theme.css.center}
`

export default function SlideButton({ location }) {
    const language = useLanguage()
    const [selected, setSelected] = useState(language==="ko" ? "left" : "right")

    useEffect(() => {
        setSelected(language==="ko" ? "left" : "right")
    }, [language])

    
    return <LanguageButtonRoot>
        <Part
        selected={selected==="left"}
        onClick={e => {
            setSelected("left")
            // english to korean
            if (language!=="ko") {
                const { pathname, hash } = location
                const to = `${pathname==="/" ? "/ko" : ""}${pathname.replace("/en", "/ko")}${hash}`
                navigate(to)
            }
        }}>
            <Center>
                {`KOR`}
            </Center>
        </Part>
        <Part
        selected={selected==="right"}
        onClick={e => {
            setSelected("right")

            // korean to english
            if (language!=="en") {
                const { pathname, hash } = location
                const to = `${pathname==="/" ? "/en" : ""}${pathname.replace("/ko", "/en")}${hash}`
                navigate(to)
            }
        }}>
            <Center>
                {`EN`}
            </Center>
        </Part>
    </LanguageButtonRoot>
}
