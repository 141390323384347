// // https://www.gatsbyjs.org/docs/ssr-apis/
import React, {  } from "react"
import { Redirect } from "@reach/router"
import { ThemeProvider } from "styled-components"
import { mainTheme, GlobalStyle } from "src/GlobalStyle"
import { Navigation } from "src/assembled"
import { detect } from 'detect-browser';
const browser = detect()

export const wrapRootElement = ({ element }) => {
    return <>
        <div id="___modal"></div>
        <ThemeProvider theme={mainTheme}>
            <GlobalStyle />
            {element}
        </ThemeProvider>
    </>
}

export const wrapPageElement = ({ element, props }) => {
    const { location } = props

    // navigation
    const navigationRender = <Navigation location={location} />
    // redirect if IE
    if (browser.name==="ie" && window.location.pathname !== "/under_construction") {
        window.location.href = "https://deargen.me/under_construction"
        return
    }

    return <>
        {navigationRender}
        {element}
    </>
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    // currently this app's UI is deeply depends on `window.innerWidth` and `window.innerWidth` is only updated when `resize` event triggered, not initially rendered.
    // so we should explicitly trigger `resize` event.
    setTimeout(() => {
        // 왠지는 모르겠는데 `resize` 2번이상 호출해야 완전히 window.innerWidth가 업데이트되고 UI가 올바르게 변경된다. 1번만 호출하면 부분적으로 밖에 업데이트가 안됨. 원인 불명. 디버깅 불가능
        window.dispatchEvent(new Event('resize'))
        window.dispatchEvent(new Event('resize'))
        window.dispatchEvent(new Event('resize'))
    }, 100)

    // fade animation
    if (prevLocation !==null && location.pathname !== prevLocation.pathname) {
        document.body.style.opacity = 0
        const duration = 400
        setTimeout(() => {
            document.body.style.transitionDuration = duration + 'ms'
            document.body.style.opacity = 1
        }, 100)
        setTimeout(() => {
            document.body.style.transitionDuration = ""
        }, duration)
    
        // 아래 코드는 원래 anchor-link 라고 부르는 기능인데 gatsby 에서는 안되길래 직접 구현
        if (location.pathname.match(/[en|ko]\/technology/) ) {
            setTimeout(() => {
                const element = window.document.getElementById(location.hash)
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" })
                }
            }, 200)
        }
    }
    if (location.pathname.match(/[en|ko]/) ) { 
        window.scrollTo({ top: 0 });
    }
}

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
    const { location: { pathname, hash} } = routerProps
    if (pathname.match(/[en|ko]\/technology/) ) {
        return hash
    }
    return true
}