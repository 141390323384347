import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Flex from "styled-flex-component"
import breakpoint from 'styled-components-breakpoint'
import { navigate } from "gatsby"
import { FaArrowRight } from "react-icons/fa"
import { GrClose } from "react-icons/gr"
import { H1, H2, P, U } from "src/components"  
import { useLanguage, useIsDeviceWidth, CapsuleText } from "src/components/utils"  
import { mainTheme } from "src/GlobalStyle"
import useContents from "src/data"
import Card from "./_Card"

const PipelineRoot = styled(Flex)`
    padding: 50px 15px 100px;
    box-sizing: border-box;
    flex-direction: column;
    background: ${props => props.theme.primary};
    margin-top: 100px;
    
    ${breakpoint("desktop")`
        flex-direction: row;
        margin: 0;
        background: transparent;
        padding: 0;
    `}
`
const LeftTextRoot = styled.div`
    padding-top: 50px;
    text-align: left;
    width: 100%;
    
    ${H1} {
        color: white;
        font-size: 26px;
        ${breakpoint("desktop")`
            font-size: 48px;
            color: ${props => props.theme.primary};
        `}
    }
    ${P} {
        color: white;
        margin-top: 20px;
        ${breakpoint("desktop")`
            color: ${props => props.theme.primary};
        `}
    }

    ${breakpoint("desktop")`
        padding-top: 0;
        margin-right: 30px;
        text-align: left;
        width: initial;
    `}

    ${breakpoint("big desktop")`
        margin-right: 100px;
    `}
`
const RightCardsRoot = styled.div`
    position: relative;
    margin-top: 40px;
    width: 100%;

    ${breakpoint("desktop")`
        margin-top: 0px;
        width: 600px;
    `}
    
`
const RightCardsWrapper = styled(Flex)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    ${breakpoint("desktop")`
        width: 100%;
    `}
`
const RightCardsPlaceholderWrapper = styled(Flex)`
    flex-wrap: wrap;

    ${breakpoint("desktop")`
        width: 600px;
    `}
`
const CardPlaceholder = styled.div`
    margin: 5px;
    box-sizing: border-box;
    height: 180px;
    width: calc(50% - 10px);
    
    ${breakpoint("desktop")`
        height: 200px;
        width: calc(33% - 10px);
        background: rgba(230, 241, 255, 0.5);
    `}
`
const LearnMore = styled(CardPlaceholder)`
    padding: 20px;
    z-index: 1;
    transition: 0.2s;
    box-sizing: borde-box;
    border-radius: 5px;
    background: #003657;
    border: 1px solid transparent;

    &:hover {
        border: 1px solid ${props => props.theme.secondary};
        cursor: pointer;
    }

    ${H2} {
        color: white;
        font-size: 22px;
        ${breakpoint("desktop")`
            color: ${props => props.theme.primary};
        `}
    }

    ${breakpoint("desktop")`
        background: rgba(230, 241, 255, 0.5);
    `}
`
const Detail = styled.div`
    // when some card is selected, non-selected cards's z-index is 0, blur background is 1, selected card is 2
    z-index: ${props => props.active ? 1 : 0};
    opacity: ${props => props.active ? 1 : 0};
    background: white;
    position: absolute;
    box-sizing: border-box;
    border-radius: 10px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: 0.4s;
    backdrop-filter: blur(16px);

    // white blur
    ::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0;
        border-radius: 10px;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0));
    }

    ${breakpoint("desktop")`
        background: rgba(230, 241, 255, 0.5);
        ::after {
            display: none;
        }
    `}

`
const DetailText = styled(Flex)`
    position: absolute;
    box-sizing: border-box;
    margin: 20px 0px;
    width: 100%;
    // 100% - CardHeight
    height: calc(100% - 200px);
    bottom: 0;
    right: 0px;
    padding: 40px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    ${P} {
        height: 100%;
        color: black;
        & div {
            height: fit-content;
            padding-bottom: 80px;
        }
    }

    ${breakpoint("desktop")`
        border-top: none;
        border-left: 1px solid ${props => props.theme.secondary};
        // 100% - CardWidth
        width: calc(100% - 200px);
        // 100% - DetailTextMargin*2
        height: calc(100% - 40px);

        ::-webkit-scrollbar {
            display: inherit;
        }
    `}
`


export default function Pipeline() {
    const { images, 컨텐츠 } = useContents("Home_Pipeline")
    const language = useLanguage()
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800})

    const [selected, setSelected] = useState(null)
    const cardsRootRef = useRef(null)
    const [cardRefs, setCardRefs] = useState(Array(컨텐츠[1].length).fill(null))
    const [leftTops, setLeftTops] = useState({ lefts: [], tops: [] })
    
    useEffect(() => {
        if (!cardsRootRef) return
        if (!cardRefs.every(Boolean)) return

        const { left: rootLeft, top: rootTop } = cardsRootRef.current.getBoundingClientRect()
        const lefts = cardRefs.map(ref => ref.getBoundingClientRect().left - rootLeft)
        const tops = cardRefs.map(ref => ref.getBoundingClientRect().top - rootTop)
        setLeftTops({ lefts, tops })
    }, [cardsRootRef, cardRefs])

    // title width capsule shape icon in mobile
    const mobileTitleRender = isSmallDevice && <CapsuleText
    textColor="white"
    leftColor="white"
    customCss={`margin-left: 20px;`}>
        {`Pipeline`}
    </CapsuleText>

    // header
    const leftTextRender = <LeftTextRoot>
        <H1 size={48} weight="bold">
            {컨텐츠[0][0]}<U>{컨텐츠[0][1]}</U>
        </H1>
        <P>{컨텐츠[0][2]}</P>
    </LeftTextRoot>

    // card detail
    const detailRef = useRef(null)
    const detailRender = <Detail
    ref={detailRef}
    active={selected!==null}>
        <DetailText center>
            <P>
                <div>
                    {selected!==null && 컨텐츠[1][selected][1]}
                </div>
            </P>
        </DetailText>
        <GrClose
        size={30}
        onClick={e => setSelected(null)}
        style={{position:"absolute", top: 20, right: 20, cursor: "pointer" }}/>
    </Detail>

    // when user do wheeling <Detail/>, the wheel event must not be propagated, otherwise slide will be changed unexpectedly.
    // <Carousel/> is using some libarary `react-swipeable`, which uses uses original native DOM-event, NOT React's synthetic-event.
    // so we should deal with native-DOM-eventlistner. 
    useEffect(() => {
        if (!detailRef) return
        const handler1 = detailRef.current.addEventListener("touchend", e => e.stopPropagation())   
        const handler2 = detailRef.current.addEventListener("wheel", e => e.stopPropagation())   
        return () => {
            window.removeEventListener("resize", handler1)
            window.removeEventListener("resize", handler2)
        }
    }, [detailRef])

    // cards
    const cardsRender = <RightCardsWrapper>
        {컨텐츠[1].map(([techName], i) => {
            return <Card
            text={techName}
            gatsbyImageSrc={images[`pipeline_${i}`]}
            onClick={e => setSelected(i)}
            active={selected===i}
            hide={selected!==i && selected!==null}
            top={selected===i ? 0 : leftTops.tops[i]}
            left={selected===i ? 0 : leftTops.lefts[i]} />
        })}
    </RightCardsWrapper>

    
    //cards placeholder
    const cardsPlaceholderRender = <RightCardsPlaceholderWrapper>
        {컨텐츠[1].map((__notuse__, i) => (
            <CardPlaceholder
            key={i}
            ref={ref => {
                if (ref!==null && cardRefs[i]===null) {
                    setCardRefs(refs => {
                        const newRefs = Array.from(refs)
                        newRefs[i] = ref
                        return newRefs
                    })
                }
            }} />
        ))}
        <LearnMore onClick={e => navigate(`/${language}/pipeline`)}>
            <H2>{컨텐츠[2]}</H2>
            <FaArrowRight color={isSmallDevice ? "white" : mainTheme.primary} style={{ position: "absolute", right: 20, bottom: 20}}/>
        </LearnMore>
    </RightCardsPlaceholderWrapper>

    // cards & cards placeholder
    const rightRender = <RightCardsRoot ref={cardsRootRef} onClick={e => {
        // 모바일에서는 X 버튼이 가려서 클릭이 안된다. 그래서 그냥 아래와 같이 처리
        if (isSmallDevice && selected!==null) setSelected(null)
    }}>
        {cardsPlaceholderRender}
        {detailRender}
        {cardsRender}
    </RightCardsRoot>



    return <PipelineRoot full center>
        {mobileTitleRender}
        {leftTextRender}
        {rightRender}
    </PipelineRoot> 
    
}


