import React, { useState } from 'react'
import styled from "styled-components"
import _ from "lodash"
import Flex from "styled-flex-component"
import { Img, P } from "src/components"
import { useIsDeviceWidth } from "src/components/utils"
import useContents from "src/data"
import VisibilitySensor from "react-visibility-sensor"


const COLOR = {
    oncology: "#80D7FF",
    cvrm: "#157f58",
    neurology: "#f59175",
    immunology: "#94e0de",
    confidential: "#006797",
}

const PipelineGraphRoot = styled.div`
    margin-top: 60px;
    width: 100%;
`
const Heads = styled(Flex)`
    width: 100%;
`
const Cell = styled(Flex)`
    display: flex;
    flex: ${props => props.short ? 4 : 5};
    justify-content: ${props => props.align==="left" ? "flex-start" : "center"};
    height: ${props => props.full ? "500px" : `${props.height}px`};
    background: ${props => props.color};
    border-top: 1px ${props => props.borderTopDotted ? "dotted" : "solid"} ${props => props.borderTop || props.borderTopDotted ? "#d2d2d2" : "transparent"};
    border-left: 1px ${props => props.borderLeftDotted ? "dotted" : "solid"} ${props => props.borderLeft || props.borderLeftDotted ? "#d2d2d2" : "transparent"};
    border-right: 1px ${props => props.borderRightDotted ? "dotted" : "solid"} ${props => props.borderRight || props.borderRightDotted ? "#d2d2d2" : "transparent"};
    border-bottom: 1px ${props => props.borderBottomDotted ? "dotted" : "solid"} ${props => props.borderBottom || props.borderBottomDotted ? "#d2d2d2" : "transparent"};
    padding-top: ${props => props.paddingTop ? `${props.height / 2}px` : 0};
    padding-bottom: ${props => props.paddingBottom ? `${props.height / 2}px` : 0};
    position: relative;
    color: #333333;
    font-size: 14px;
    font-weight: ${props => props.bold ? "bold" : "inherit"};
    text-align: center;
`
// bar
const BarLine = styled.div`
    z-index: 1;
    position: absolute;
    transition: width 1s;
    // 이유는 모르겠는데 width 를 100%라고 써놔도 Cell의 왼쪽,오른쪽의 border 두께로 추정되는 2px 이 포함이 안되는거같다
    width: ${props => props.show ? `calc(${props.length * 100}% + ${props.length * 2.6}px)` : "1px"};
    background: ${props => props.show ? props.color : "transparent"};
    border-radius: 0 ${props => props.rightRadius && "10px 10px"} 0;
    opacity: ${props => props.opacity};
    border-radius: 0 ${props => props.rightRadius ? "10px 10px" : "0 0"} 0;
    ${props => {
        if (props.paddingTop && props.paddingBottom) return "top: 50%"
        if (props.paddingTop) return "top: calc(50% + 10px)"
        if (props.paddingBottom) return "top: calc(50% - 10px)"
        return "top: 50%"
    }};
    left: -2px;

    transform: translateY(-50%);
    height: 10px;
    box-sizing: border-box;
`
function Bar({ color, length, opacity=1, show, rightRadius, paddingTop=false, paddingBottom=false }) {
    const [visible, setVisible] = useState(false)

    return <VisibilitySensor onChange={visible => { if (visible) setVisible(true) }}>
        <BarLine
        color={color}
        length={length}
        show={(visible) || show}
        opacity={opacity}
        rightRadius={rightRadius} 
        paddingTop={paddingTop}
        paddingBottom={paddingBottom} />
    </VisibilitySensor>
}

const ServiceAgreement = styled(Flex)`
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 1200%;
    height: 100%;
    background: white;
`

export default function PipelineGraph({
    items,
    headNames=["Target ID", "Hit Discovery", "Lead Optimization", "Preclinical"],
}) {
    const { images } = useContents("Pipeline")
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800})
    const groupedItems = _.groupBy(items, "partner")
    if (!isSmallDevice) {
        headNames.unshift("Program Name")
    }
    if (!isSmallDevice && _.keys(groupedItems).some(type => type!=="undefined")) {
        headNames.unshift("Partner")
    }

    // Head
    const headRender = <Heads>
        {headNames.map((name, i) => <Cell
        key={name}
        short={(name==="Partner" || name==="Program Name")}
        align={(name==="Partner" || name==="Program Name") ? "left" : "center"}
        height={50}
        center
        borderTop={!isSmallDevice}
        borderBottom={!isSmallDevice}
        borderRight={!isSmallDevice && name!=="Partner" && name!=="Preclinical"}
        bold 
        style={{minWidth: name==="Partner" ? 120 : "none"}}>
            {name}
        </Cell>)}
    </Heads>

    // ServiceAgreement
    const serviceAgreement = <ServiceAgreement center>
        <P size={16}>Service Agreement</P>
    </ServiceAgreement>

    // Body
    const bodyRender = _.zip(_.keys(groupedItems), _.values(groupedItems)).map(([partner, items]) => <>
        {isSmallDevice && <Cell style={{flex:1, marginTop: 30, marginBottom: 10}}>
            <Img gatsbyImageSrc={images[partner]} />
        </Cell>}
        <Flex>
            {/* Partner */}
            {headNames.includes("Partner") && <Cell
            short
            center
            align="left"
            borderBottom
            style={{minWidth: 120}}>
                <Img gatsbyImageSrc={images[partner]} />
            </Cell>}
            {/* Progran Name */}
            {!isSmallDevice && <Flex column style={{flex:4}}>
                {items.map(({ programName }, itemIndex) => (
                    <Cell
                    key={itemIndex}
                    height={43}
                    align="left"
                    borderRight
                    borderBottom={itemIndex===items.length-1}
                    paddingTop={itemIndex===0}
                    paddingBottom={itemIndex===items.length-1}>
                        <Flex center>
                            {programName}
                        </Flex>
                    </Cell>
                ))}
            </Flex>}
            {/* progress bar */}
            <Flex style={{flex: 20}}>
                {_.range(4).map(progressStepIndex => (
                    <Flex style={{flex: 5}} column>
                        {items.map((item, itemIndex) => (
                            <Cell
                            key={itemIndex}
                            style={{flex: 1}}
                            borderRight={progressStepIndex<3}>
                                <Cell
                                height={43}
                                borderBottom={!isSmallDevice && itemIndex===items.length-1}
                                paddingTop={!isSmallDevice && itemIndex===0}
                                borderRightDotted={!isSmallDevice}
                                paddingBottom={itemIndex===items.length-1} >
                                    {isSmallDevice && progressStepIndex===0 && <P
                                    size={12}
                                    align="left"
                                    style={{position: "absolute", left:0, top: 0, width: "max-content", background: "rgba(255,255,255,0.4"}}>
                                        {item.programName}
                                    </P>}
                                    {progressStepIndex===0 && <>
                                        {item.serviceAgreement 
                                            ? serviceAgreement
                                            : <>
                                                <Bar
                                                color={COLOR[item.type]}
                                                length={3 * 4}
                                                opacity={0.3}
                                                paddingTop={itemIndex===0}
                                                paddingBottom={itemIndex===items.length-1}
                                                show />
                                                <Bar
                                                color={COLOR[item.type]}
                                                length={item.progress}
                                                paddingTop={itemIndex===0}
                                                paddingBottom={itemIndex===items.length-1}
                                                rightRadius />
                                            </>
                                        }
                                    </>}
                                </Cell>
                                <Cell
                                height={43}
                                borderRightDotted={!isSmallDevice}
                                borderBottom={!isSmallDevice && itemIndex===items.length-1}
                                paddingTop={!isSmallDevice && itemIndex===0}
                                paddingBottom={itemIndex===items.length-1} />
                                <Cell
                                height={43}
                                borderBottom={!isSmallDevice && itemIndex===items.length-1}
                                paddingTop={!isSmallDevice && itemIndex===0}
                                paddingBottom={itemIndex===items.length-1} />
                            </Cell>
                        ))}
                    </Flex>
                ))}
            </Flex>
        </Flex>
    </>)

    return <PipelineGraphRoot>
        {headRender}
        {bodyRender}
    </PipelineGraphRoot>
}
