import { useStaticQuery, graphql } from "gatsby";
import { useLanguage } from "src/components/utils";

const text = {
  ko: [
    // paper & patent
    [
      {
        label: "paper",
        title: `Drug–Target Interaction Deep Learning-Based Model Identifies the Flavonoid Troxerutin as a Candidate TRPV1 Antagonist`,
        channel: `applied sciences, 2023`,
        member: `Bo Ram Beck, Hyunjun Yoon, Gahee Bahn, Young-heon Kim`,
        content: `Abstract\nBioactive compounds are often used as initial substances for many therapeutic agents. In recent years, both theoretical and practical innovations in hardware-assisted and fast-evolving machine learning (ML) have made it possible to identify desired bioactive compounds in chemical spaces, such as those in natural products (NPs). This review introduces how machine learning approaches can be used for the identification and evaluation of bioactive compounds. It also provides an overview of recent research trends in machine learning-based prediction and the evaluation of bioactive compounds by listing real-world examples along with various input data. In addition, several ML-based approaches to identify specific bioactive compounds for cardiovascular and metabolic diseases are described. Overall, these approaches are important for the discovery of novel bioactive compounds and provide new insights into the machine learning basis for various traditional applications of bioactive compound-related research.`,
        href: "https://www.mdpi.com/2076-3417/13/9/5617",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2076-3417/13/9/5617",
          },
        ],
      },
      {
        label: "paper",
        title: `A Brief Review of Machine Learning-Based Bioactive Compound Research`,
        channel: `applied sciences, 2022`,
        member: `Bo Ram Beck, Keunsoo Kang`,
        content: `Abstract\nBioactive compounds are often used as initial substances for many therapeutic agents. In recent years, both theoretical and practical innovations in hardware-assisted and fast-evolving machine learning (ML) have made it possible to identify desired bioactive compounds in chemical spaces, such as those in natural products (NPs). This review introduces how machine learning approaches can be used for the identification and evaluation of bioactive compounds. It also provides an overview of recent research trends in machine learning-based prediction and the evaluation of bioactive compounds by listing real-world examples along with various input data. In addition, several ML-based approaches to identify specific bioactive compounds for cardiovascular and metabolic diseases are described. Overall, these approaches are important for the discovery of novel bioactive compounds and provide new insights into the machine learning basis for various traditional applications of bioactive compound-related research.`,
        href: "https://www.mdpi.com/2076-3417/12/6/2906",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2076-3417/12/6/2906",
          },
        ],
      },
      {
        label: "paper",
        title: `Improving Evidential Deep Learning via Multi-task Learning`,
        channel: `AAAI, 2022`,
        member: `Dongpin Oh,  Bonggun Shin`,
        content: `Abstract\nThe Evidential regression network (ENet) estimates a con- tinuous target and its predictive uncertainty without costly Bayesian model averaging. However, it is possible that the target is inaccurately predicted due to the gradient shrinkage problem of the original loss function of the ENet, the negative log marginal likelihood (NLL) loss. In this paper, the objec- tive is to improve the prediction accuracy of the ENet while maintaining its efficient uncertainty estimation by resolving the gradient shrinkage problem. A multi-task learning (MTL) framework, referred to as MT-ENet, is proposed to accom- plish this aim. In the MTL, we define the Lipschitz modi- fied mean squared error (MSE) loss function as another loss and add it to the existing NLL loss. The Lipschitz modified MSE loss is designed to mitigate the gradient conflict with the NLL loss by dynamically adjusting its Lipschitz constant. By doing so, the Lipschitz MSE loss does not disturb the un- certainty estimation of the NLL loss. The MT-ENet enhances the predictive accuracy of the ENet without losing uncertainty estimation capability on the synthetic dataset and real-world benchmarks, including drug-target affinity (DTA) regression. Furthermore, the MT-ENet shows remarkable calibration and out-of-distribution detection capability on the DTA bench- marks.`,
        href: "https://www.aaai.org/AAAI22Papers/AAAI-2489.OhD.pdf",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.aaai.org/AAAI22Papers/AAAI-2489.OhD.pdf",
          },
        ],
      },
      {
        label: "paper",
        title: `An Interpretable Framework for Drug-Target Interaction with Gated Cross Attention`,
        channel: `Machine Learning For Health Care, 2021`,
        member: `YeachanKim, Bonggun Shin`,
        content: `Abstract\nIn silico prediction of drug-target interactions (DTI) is significant for drug discovery because it can largely reduce timelines and costs in the drug development process. Specifically, deep learning-based DTI approaches have been shown promising results in terms of accuracy and low cost for the prediction. However, they pay little attention to the interpretability of their prediction results and feature-level interactions between a drug and a target. In this study, we propose a novel interpretable framework that can provide reasonable cues for the interaction sites. To this end, we elaborately design a gated cross-attention mechanism that crossly attends drug and target features by constructing explicit interactions between these features. The gating function in the method enables neural models to focus on salient regions over entire sequences of drugs and proteins, and the byproduct from the function, which is the attention map, could serve as interpretable factors. The experimental results show the efficacy of the proposed method in two DTI datasets. Additionally, we show that gated cross-attention can sensitively react to the mutation, and this result could provide insights into the identification of novel drugs targeting mutant proteins.`,
        href:
          "https://static1.squarespace.com/static/59d5ac1780bd5ef9c396eda6/t/60fb3ab269a91023398a0e44/1627077298810/mlhc_2021_final.pdf",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://static1.squarespace.com/static/59d5ac1780bd5ef9c396eda6/t/60fb3ab269a91023398a0e44/1627077298810/mlhc_2021_final.pdf",
          },
        ],
      },
      {
        label: "paper",
        title: `Prediction of Diabetic Sensorimotor Polyneuropathy Using Machine Learning Techniques`,
        channel: `Clinical Medicine, 2021`,
        member: `Dae Youp Shin, Bora Lee, Won Sang Yoo, Joon Won Park and Jung Keun Hyun`,
        content: `Abstract\nDiabetic sensorimotor polyneuropathy (DSPN) is a major complication in patients with diabetes mellitus (DM), and early detection or prediction of DSPN is important for preventing or managing neuropathic pain and foot ulcer. Our aim is to delineate whether machine learning techniques are more useful than traditional statistical methods for predicting DSPN in DM patients. Four hundred seventy DM patients were classified into four groups (normal, possible, probable, and confirmed) based on clinical and electrophysiological findings of suspected DSPN. Three ML methods, XGBoost (XGB), support vector machine (SVM), and random forest (RF), and their combinations were used for analysis. RF showed the best area under the receiver operator characteristic curve (AUC, 0.8250) for differentiating between two categories—criteria by clinical findings (normal, possible, and probable groups) and those by electrophysiological findings (confirmed group)—and the result was superior to that of linear regression analysis (AUC = 0.6620). Average values of serum glucose, International Federation of Clinical Chemistry (IFCC), HbA1c, and albumin levels were identified as the four most important predictors of DSPN. In conclusion, machine learning techniques, especially RF, can predict DSPN in DM patients effectively, and electrophysiological analysis is important for identifying DSPN.`,
        href: "https://www.mdpi.com/2077-0383/10/19/4576",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2077-0383/10/19/4576",
          },
        ],
      },
      {
        label: "paper",
        title: `DeepCUBIT: Predicting Lymphovascular Invasion or Pathological Lymph Node Involvement of Clinical T1 Stage Non-Small Cell Lung Cancer on Chest CT Scan Using Deep Cubical Nodule Transfer Learning Algorithm`,
        channel: `Frontiers in Oncology, 2021`,
        member: `Kyongmin Sarah Beck, Bomi Gil, Sae Jung Na, Ji Hyung Hong, Sang Hoon Chun, Ho Jung An, Jae Jun Kim, Soon Auck Hong, Bora Lee, Won Sang Shim, Sungsoo Park and Yoon Ho Ko`,
        content: `Abstract\nThe prediction of lymphovascular invasion (LVI) or pathological nodal involvement of tumor cells is critical for successful treatment in early stage non-small cell lung cancer (NSCLC). We developed and validated a Deep Cubical Nodule Transfer Learning Algorithm (DeepCUBIT) using transfer learning and 3D Convolutional Neural Network (CNN) to predict LVI or pathological nodal involvement on chest CT images. A total of 695 preoperative CT images of resected NSCLC with tumor size of less than or equal to 3 cm from 2008 to 2015 were used to train and validate the DeepCUBIT model using five-fold cross-validation method. We also used tumor size and consolidation to tumor ratio (C/T ratio) to build a support vector machine (SVM) classifier. Two-hundred and fifty-four out of 695 samples (36.5%) had LVI or nodal involvement. An integrated model (3D CNN + Tumor size + C/T ratio) showed sensitivity of 31.8%, specificity of 89.8%, accuracy of 76.4%, and AUC of 0.759 on external validation cohort. Three single SVM models, using 3D CNN (DeepCUBIT), tumor size or C/T ratio, showed AUCs of 0.717, 0.630 and 0.683, respectively on external validation cohort. DeepCUBIT showed the best single model compared to the models using only C/T ratio or tumor size. In addition, the DeepCUBIT model could significantly identify the prognosis of resected NSCLC patients even in stage I. DeepCUBIT using transfer learning and 3D CNN can accurately predict LVI or nodal involvement in cT1 size NSCLC on CT images. Thus, it can provide a more accurate selection of candidates who will benefit from limited surgery without increasing the risk of recurrence.`,
        href:
          "https://www.frontiersin.org/articles/10.3389/fonc.2021.661244/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Oncology&id=661244",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://www.frontiersin.org/articles/10.3389/fonc.2021.661244/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Oncology&id=661244",
          },
        ],
      },
      {
        label: "paper",
        title: `DeepRePath: Identifying the Prognostic Features of Early-Stage Lung Adenocarcinoma Using Multi-Scale Pathology Images and Deep Convolutional Neural Networks`,
        channel: `Cancers, 2021`,
        member: `Won Sang Shim, Kwangil Yim, Tae-Jung Kim,Yeoun Eun Sung, Gyeongyun Lee, Ji Hyung Hong, Sang Hoon Chun, Seoree Kim, Ho Jung An, Sae Jung Na, Jae Jun Kim, Mi Hyoung Moon, Seok Whan Moon, Sungsoo Park, Soon Auck Hong, Yoon Ho Ko`,
        content: `Abstract\nThe prognosis of patients with lung adenocarcinoma (LUAD), especially early-stage LUAD, is dependent on clinicopathological features. However, its predictive utility is limited. In this study, we developed and trained a DeepRePath model based on a deep convolutional neural network (CNN) using multi-scale pathology images to predict the prognosis of patients with early-stage LUAD. DeepRePath was pre-trained with 1067 hematoxylin and eosin-stained whole-slide images of LUAD from the Cancer Genome Atlas. DeepRePath was further trained and validated using two separate CNNs and multi-scale pathology images of 393 resected lung cancer specimens from patients with stage I and II LUAD. Of the 393 patients, 95 patients developed recurrence after surgical resection. The DeepRePath model showed average area under the curve (AUC) scores of 0.77 and 0.76 in cohort I and cohort II (external validation set), respectively. Owing to low performance, DeepRePath cannot be used as an automated tool in a clinical setting. When gradient-weighted class activation mapping was used, DeepRePath indicated the association between atypical nuclei, discohesive tumor cells, and tumor necrosis in pathology images showing recurrence. Despite the limitations associated with a relatively small number of patients, the DeepRePath model based on CNNs with transfer learning could predict recurrence after the curative resection of early-stage LUAD using multi-scale pathology images.`,
        href: "https://www.mdpi.com/2072-6694/13/13/3308/htm",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2072-6694/13/13/3308/htm",
          },
        ],
      },
      {
        label: "paper",
        title: `Controlled molecule generator for optimizing multiple chemical properties`,
        channel: `ACM, 2021`,
        member: `Bonggun Shin, Sungsoo Park, JinYeong Bak, Joyce C. Ho`,
        content: `Abstract\nGenerating a novel and optimized molecule with desired chemical properties is an essential part of the drug discovery process. Failure to meet one of the required properties can frequently lead to failure in a clinical test which is costly. In addition, optimizing these multiple properties is a challenging task because the optimization of one property is prone to changing other properties. In this paper, we pose this multi-property optimization problem as a sequence translation process and propose a new optimized molecule generator model based on the Transformer with two constraint networks: property prediction and similarity prediction. We further improve the model by incorporating score predictions from these constraint networks in a modified beam search algorithm. The experiments demonstrate that our proposed model, Controlled Molecule Generator (CMG), outperforms state-of-the-art models by a significant margin for optimizing multiple properties simultaneously.`,
        href: "https://dl.acm.org/doi/10.1145/3450439.3451879",
        buttonProps: [
          {
            text: "Publication",
            href: "https://dl.acm.org/doi/10.1145/3450439.3451879",
          },
        ],
      },
      {
        label: "paper",
        title: `Target-Centered Drug Repurposing Predictions of Human 'ACE2' and 'TMPRSS2' Interacting Approved Drugs for Coronavirus Disease 2019 (COVID-19) Treatment`,
        channel: `Viruses, 2020`,
        member: `Yoonjung Choi, Bonggun Shin, Keunsoo Kang, Sungsoo Park, Bo Ram Beck`,
        content: `Abstract\nPreviously, our group predicted commercially available Food and Drug Administration (FDA) approved drugs that can inhibit each step of the replication of severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2) using a deep learning-based drug-target interaction model called Molecule Transformer-Drug Target Interaction (MT-DTI). Unfortunately, additional clinically significant treatment options since the approval of remdesivir are scarce. To overcome the current coronavirus disease 2019 (COVID-19) more efficiently, a treatment strategy that controls not only SARS-CoV-2 replication but also the host entry step should be considered. In this study, we used MT-DTI to predict FDA approved drugs that may have strong affinities for the angiotensin-converting enzyme 2 (ACE2) receptor and the transmembrane protease serine 2 (TMPRSS2) which are essential for viral entry to the host cell. Of the 460 drugs with Kd of less than 100 nM for the ACE2 receptor, 17 drugs overlapped with drugs that inhibit the interaction of ACE2 and SARS-CoV-2 spike reported in the NCATS OpenData portal. Among them, enalaprilat, an ACE inhibitor, showed a Kd value of 1.5 nM against the ACE2. Furthermore, three of the top 30 drugs with strong affinity prediction for the TMPRSS2 are anti-hepatitis C virus (HCV) drugs, including ombitasvir, daclatasvir, and paritaprevir. Notably, of the top 30 drugs, AT1R blocker eprosartan and neuropsychiatric drug lisuride showed similar gene expression profiles to potential TMPRSS2 inhibitors. Collectively, we suggest that drugs predicted to have strong inhibitory potencies to ACE2 and TMPRSS2 through the DTI model should be considered as potential drug repurposing candidates for COVID-19.`,
        href: "https://www.mdpi.com/1999-4915/12/11/1325/htm",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/1999-4915/12/11/1325/htm",
          },
        ],
      },
      {
        label: "paper",
        title: `Predicting FOXM1-Mediated Gene Regulation through the Analysis of Genome-Wide FOXM1 Binding Sites in MCF-7, K562, SK-N-SH, GM12878 and ECC-1 Cell Lines`,
        channel: `Molecular Sciences, 2020`,
        member: `Keunsoo Kang, Yoonjung Choi, Hoo Hyun Kim, Kyung Hyun Yoo,Sungryul Yu`,
        content: `Abstract\nForkhead box protein M1 (FOXM1) is a key transcription factor (TF) that regulates a common set of genes related to the cell cycle in various cell types. However, the mechanism by which FOXM1 controls the common gene set in different cellular contexts is unclear. In this study, a comprehensive meta-analysis of genome-wide FOXM1 binding sites in ECC-1, GM12878, K562, MCF-7, and SK-N-SH cell lines was conducted to predict FOXM1-driven gene regulation. Consistent with previous studies, different TF binding motifs were identified at FOXM1 binding sites, while the NFY binding motif was found at 81% of common FOXM1 binding sites in promoters of cell cycle-related genes. The results indicated that FOXM1 might control the gene set through interaction with the NFY proteins, while cell type-specific genes were predicted to be regulated by enhancers with FOXM1 and cell type-specific TFs. We also found that the high expression level of FOXM1 was significantly associated with poor prognosis in nine types of cancer. Overall, these results suggest that FOXM1 is predicted to function as a master regulator of the cell cycle through the interaction of NFY-family proteins, and therefore the inhibition of FOXM1 could be an attractive strategy for cancer therapy.`,
        href: "https://www.mdpi.com/1422-0067/21/17/6141/htm",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/1422-0067/21/17/6141/htm",
          },
        ],
      },
      {
        label: "paper",
        title: `Community Assessment of the Predictability of Cancer Protein and Phosphoprotein Levels from Genomics and Transcriptomics`,
        channel: `Cell Systems, 2020`,
        member: `Mi Yang, Francesca Petralia, Zhi Li, Hongyang Li, Weiping Ma, Xiaoyu Song, Sunkyu Kim, Heewon Lee, Han Yu, Bora Lee, Seohui Bae, Eunji Heo, Jan Kaczmarczyk, Piotr Stępniak, Michał Warchoł, Thomas Yu, Anna P. Calinawan, Paul C. Boutros, Samuel H. Payne, Boris Reva, NCI-CPTAC-DREAM Consortium, Emily Boja, Henry Rodriguez, Gustavo Stolovitzky, Yuanfang Guan, Jaewoo Kang, Pei Wang, David Fenyö, Julio Saez-Rodriguez`,
        content: `Abstract\nCancer is driven by genomic alterations, but the processes causing this disease are largely performed by proteins. However, proteins are harder and more expensive to measure than genes and transcripts. To catalyze developments of methods to infer protein levels from other omics measurements, we leveraged crowdsourcing via the NCI-CPTAC DREAM proteogenomic challenge. We asked for methods to predict protein and phosphorylation levels from genomic and transcriptomic data in cancer patients. The best performance was achieved by an ensemble of models, including as predictors transcript level of the corresponding genes, interaction between genes, conservation across tumor types, and phosphosite proximity for phosphorylation prediction. Proteins from metabolic pathways and complexes were the best and worst predicted, respectively. The performance of even the best-performing model was modest, suggesting that many proteins are strongly regulated through translational control and degradation. Our results set a reference for the limitations of computational inference in proteogenomics.`,
        href:
          "https://www.cell.com/cell-systems/fulltext/S2405-4712(20)30242-8?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS2405471220302428%3Fshowall%3Dtrue",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://www.cell.com/cell-systems/fulltext/S2405-4712(20)30242-8?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS2405471220302428%3Fshowall%3Dtrue",
          },
        ],
      },
      {
        label: "paper",
        title: `Identification of Novel microRNA Prognostic Markers Using Cascaded Wx, a Neural Network-Based Framework, in Lung Adenocarcinoma Patients`,
        channel: `Cancers, 2020`,
        member:
          "Jeong Seon Kim, Sang Hoon Chun, Sungsoo Park, Sieun Lee, Sae Eun Kim, Ji Hyung Hong, Keunsoo Kang, Yoon Ho Ko, Young-Ho Ahn",
        content: `Abstract\nThe evolution of next-generation sequencing technology has resulted in a generation of large amounts of cancer genomic data. Therefore, increasingly complex techniques are required to appropriately analyze this data in order to determine its clinical relevance. In this study, we applied a neural network-based technique to analyze data from The Cancer Genome Atlas and extract useful microRNA (miRNA) features for predicting the prognosis of patients with lung adenocarcinomas (LUAD). Using the Cascaded Wx platform, we identified and ranked miRNAs that affected LUAD patient survival and selected the two top-ranked miRNAs (miR-374a and miR-374b) for measurement of their expression levels in patient tumor tissues and in lung cancer cells exhibiting an altered epithelial-to-mesenchymal transition (EMT) status. Analysis of miRNA expression from tumor samples revealed that high miR-374a/b expression was associated with poor patient survival rates. In lung cancer cells, the EMT signal induced miR-374a/b expression, which, in turn, promoted EMT and invasiveness. These findings demonstrated that this approach enabled effective identification and validation of prognostic miRNA markers in LUAD, suggesting its potential efficacy for clinical use.`,
        href: "https://www.mdpi.com/2072-6694/12/7/1890/htm",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2072-6694/12/7/1890/htm",
          },
        ],
      },
      {
        label: "Poster",
        title: `Molecular optimization of phase III trial failed anticancer drugs using target affinity and toxicity-centered multiple properties reinforcement learning`,
        channel: `Clinical Cancer Research, 2020`,
        member:
          "Sungsoo Park, Yoon Ho Ko, Bora Lee, Bonggun Shin and Bo Ram Beck",
        content: `Abstract\nDespite the potential of anticancer drugs in the early stages of drug development, many clinical trials have failed at phase III because of low efficacy and/or high toxicity profiles. To improve such issues, effective lead optimization based on machine learning (ML) is needed. Many ML-based lead optimization models have been proposed, but they are capable of optimizing only a single property of a drug; therefore, multiple models are required and would likely be significantly different from the original drug as processed by multiple models. Our contributions are as follows: 1) We devised a new deep learning architecture based on a long short-term memory on a convolutional neural network for predicting binding affinity score (AS) and absorption, distribution, metabolism, excretion, and toxicity (ADMET) prediction. 2) We adapted the Molecule Deep Q-Networks (MoleDQN) to our new reward combinations so that a single model can consider multiple properties such as quantitative estimate of drug-likeness (QED), AS, synthetic accessibility scores (SAS), and toxicity scores. 3) Binding affinity estimator and ADMET estimator are used to indicate drug efficacy and toxicities. Iniparib, brivanib, and rebimastat, which failed in phase III clinical trials, were used for this analysis, and the top two optimized candidates of each drug with an improved score were selected. Iniparib was developed as a PARP inhibitor, but QED and AS were not predicted as a potential drug in our model (QED, 0.51; SAS, 2.23; AS, 5.02). This may be related to the low efficacy reported in clinical trials. Thus, iniparib was optimized for improved QED and AS to increase efficacy. `,
        href:
          "https://clincancerres.aacrjournals.org/content/26/12_Supplement_1/35.abstract",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://clincancerres.aacrjournals.org/content/26/12_Supplement_1/35.abstract",
          },
        ],
      },
      {
        label: "paper",
        title: `Tiotropium Is Predicted to Be a Promising Drug for COVID-19 Through Transcriptome-Based Comprehensive Molecular Pathway Analysis`,
        channel: `Viruses, 2020`,
        member: "Keunsoo Kang, Hoo Hyun Kim, Yoonjung Choi",
        content: `Abstract\nThe coronavirus disease 2019 (COVID-19) outbreak caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2) affects almost everyone in the world in many ways. We previously predicted antivirals (atazanavir, remdesivir and lopinavir/ritonavir) and non-antiviral drugs (tiotropium and rapamycin) that may inhibit the replication complex of SARS-CoV-2 using our molecular transformer–drug target interaction (MT–DTI) deep-learning-based drug–target affinity prediction model. In this study, we dissected molecular pathways upregulated in SARS-CoV-2-infected normal human bronchial epithelial (NHBE) cells by analyzing an RNA-seq data set with various bioinformatics approaches, such as gene ontology, protein–protein interaction-based network and gene set enrichment analyses. The results indicated that the SARS-CoV-2 infection strongly activates TNF and NFκB-signaling pathways through significant upregulation of the TNF, IL1B, IL6, IL8, NFKB1, NFKB2 and RELB genes. In addition to these pathways, lung fibrosis, keratinization/cornification, rheumatoid arthritis, and negative regulation of interferon-gamma production pathways were also significantly upregulated. We observed that these pathologic features of SARS-CoV-2 are similar to those observed in patients with chronic obstructive pulmonary disease (COPD). Intriguingly, tiotropium, as predicted by MT–DTI, is currently used as a therapeutic intervention in COPD patients. Treatment with tiotropium has been shown to improve pulmonary function by alleviating airway inflammation. Accordingly, a literature search summarized that tiotropium reduced expressions of IL1B, IL6, IL8, RELA, NFKB1 and TNF in vitro or in vivo, and many of them have been known to be deregulated in COPD patients. These results suggest that COVID-19 is similar to an acute mode of COPD caused by the SARS-CoV-2 infection, and therefore tiotropium may be effective for COVID-19 patients.`,
        href: "https://www.mdpi.com/1999-4915/12/7/776/htm",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/1999-4915/12/7/776/htm",
          },
        ],
      },
      {
        label: "paper",
        title: `Predicting commercially available antiviral drugs that may act on the novel coronavirus (SARS-CoV-2) through a drug-target interaction deep learning model`,
        channel: `Computational and Structural Biotechnology Journal, 2020`,
        member:
          "Bo Ram Beck, Bong gun Shin, Yoonjung Choi, Sungsoo Park, Keunsoo Kang",
        content: `Abstract\nThe infection of a novel coronavirus found in Wuhan of China (SARS-CoV-2) is rapidly spreading, and the incidence rate is increasing worldwide. Due to the lack of effective treatment options for SARS-CoV-2, various strategies are being tested in China, including drug repurposing. In this study, we used our pre-trained deep learning-based drug-target interaction model called Molecule Transformer-Drug Target Interaction (MT-DTI) to identify commercially available drugs that could act on viral proteins of SARS-CoV-2. The result showed that atazanavir, an antiretroviral medication used to treat and prevent the human immunodeficiency virus (HIV), is the best chemical compound, showing an inhibitory potency with Kd of 94.94 nM against the SARS-CoV-2 3C-like proteinase, followed by remdesivir (113.13 nM), efavirenz (199.17 nM), ritonavir (204.05 nM), and dolutegravir (336.91 nM). Interestingly, lopinavir, ritonavir, and darunavir are all designed to target viral proteinases. However, in our prediction, they may also bind to the replication complex components of SARS-CoV-2 with an inhibitory potency with Kd < 1000 nM. In addition, we also found that several antiviral agents, such as Kaletra (lopinavir/ritonavir), could be used for the treatment of SARS-CoV-2. Overall, we suggest that the list of antiviral drugs identified by the MT-DTI model should be considered, when establishing effective treatment strategies for SARS-CoV-2.`,
        href:
          "https://www.sciencedirect.com/science/article/pii/S2001037020300490?via%3Dihub",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://www.sciencedirect.com/science/article/pii/S2001037020300490?via%3Dihub",
          },
        ],
      },
      {
        label: "paper",
        title: `DeepBTS: Prediction of Recurrence-free Survival of Non-small Cell Lung Cancer Using a Time-binned Deep Neural Network`,
        channel: `Nature Scientific Report, 2019`,
        member:
          "Bora Lee, Sang Hoon Chun, Ji Hyung Hong, In Sook Woo, Seoree Kim, Joon Won Jeong, Jae Jun Kim, Hyun Woo Lee, Sae Jung Na, Kyongmin Sarah Beck, Bomi Gil, Sungsoo Park, Ho Jung An & Yoon Ho Ko",
        content: `Abstract\nAccurate prediction of non-small cell lung cancer (NSCLC) prognosis after surgery remains challenging. The Cox proportional hazard (PH) model is widely used, however, there are some limitations associated with it. In this study, we developed novel neural network models called binned time survival analysis (DeepBTS) models using 30 clinico-pathological features of surgically resected NSCLC patients (training cohort, n = 1,022; external validation cohort, n = 298). We employed the root-mean-square error (in the supervised learning model, s- DeepBTS) or negative log-likelihood (in the semi-unsupervised learning model, su-DeepBTS) as the loss function. The su-DeepBTS algorithm achieved better performance (C-index = 0.7306; AUC = 0.7677) than the other models (Cox PH: C-index = 0.7048 and AUC = 0.7390; s-DeepBTS: C-index = 0.7126 and AUC = 0.7420). The top 14 features were selected using su-DeepBTS model as a selector and could distinguish the low- and high-risk groups in the training cohort (p = 1.86 × 10−11) and validation cohort (p = 1.04 × 10−10). When trained with the optimal feature set for each model, the su-DeepBTS model could predict the prognoses of NSCLC better than the traditional model, especially in stage I patients. Follow-up studies using combined radiological, pathological imaging, and genomic data to enhance the performance of our model are ongoing.`,
        href: "https://www.nature.com/articles/s41598-020-58722-z",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.nature.com/articles/s41598-020-58722-z",
          },
        ],
      },
      {
        label: "patent",
        title: `피쳐 영향 판단 방법 및 그 시스템`,
        channel: `특허번호 10-207302-000-00`,
        member: "디어젠 주식회사",
        content: ``,
        href:
          "https://patentscope.wipo.int/search/ko/detail.jsf?docId=WO2019088759",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://patentscope.wipo.int/search/ko/detail.jsf?docId=WO2019088759",
          },
        ],
      },
      {
        label: "paper",
        title: `Expression of EEF1A1 Is Associated with Prognosis of Patients with Colon Adenocarcinoma\n`,
        channel: `Journal of Clinical Medicine, 2019`,
        member:
          "Eun kyo Joung, Jiyoung Kim,Nara Yoon, Lee-so Maeng, Ji Hoon Kim, Sungsoo Park, Keunsoo Kang, Jeong Seon Kim, Young Ho Ahn,Yoon Ho Ko, Jae Ho Byun, Ji Hyung Hong",
        content: `Abstract\nBackground: The prognostic role of the translational factor, elongation factor-1 alpha 1 (EEF1A1), in colon cancer is unclear. Objectives: The present study aimed to investigate the expression of EEF1A in tissues obtained from patients with stage II and III colon cancer and analyze its association with patient prognosis. Methods: A total of 281 patients with colon cancer who underwent curative resection were analyzed according to EEF1A1 expression. Results: The five-year overall survival in the high-EEF1A1 group was 87.7%, whereas it was 65.6% in the low-EEF1A1 expression group (hazard ratio (HR) 2.47, 95% confidence interval (CI) 1.38–4.44, p = 0.002). The five-year disease-free survival of patients with high EEF1A1 expression was 82.5%, which was longer than the rate of 55.4% observed for patients with low EEF1A1 expression (HR 2.94, 95% CI 1.72–5.04, p < 0.001). Univariate Cox regression analysis indicated that age, preoperative carcinoembryonic antigen level, adjuvant treatment, total number of metastatic lymph nodes, and EEF1A1 expression level were significant prognostic factors for death. In multivariate analysis, expression of EEF1A1 was an independent prognostic factor associated with death (HR 3.01, 95% CI 1.636–5.543, p < 0.001). EEF1A1 expression was also an independent prognostic factor for disease-free survival in multivariate analysis (HR 2.54, 95% CI 1.459–4.434, p < 0.001). Conclusions: Our study demonstrated that high expression of EEF1A1 has a favorable prognostic effect on patients with colon adenocarcinoma.`,
        href: "https://www.mdpi.com/2077-0383/8/11/1903",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2077-0383/8/11/1903",
          },
        ],
      },
      {
        label: "paper",
        title: `Self-Attention Based Molecule Representation for Predicting Drug-Target Interaction`,
        channel: `Journal of Machine Learning Research, 2019`,
        member: "Bonggun Shin, Sungsoo Park, Keunsoo Kang, Joyce C. Ho",
        content: `Abstract\nPredicting drug-target interactions (DTI) is an essential part of the drug discovery process, which is an expensive process in terms of time and cost. Therefore, reducing DTI cost could lead to reduced healthcare costs for a patient. In addition, a precisely learned molecule representation in a DTI model could contribute to developing personalized medicine, which will help many patient cohorts. In this paper, we propose a new molecule representation based on the self-attention mechanism, and a new DTI model using our molecule representation. The experiments show that our DTI model outperforms the state of the art by up to 4.9% points in terms of area under the precision-recall curve. Moreover, a study using the DrugBank database proves that our model effectively lists all known drugs targeting a specific cancer biomarker in the top-30 candidate list.`,
        href: "https://arxiv.org/abs/1908.06760",
        buttonProps: [
          { text: "Publication", href: "https://arxiv.org/abs/1908.06760" },
          {
            text: "Review",
            href:
              "https://deargen.blog/2019/08/14/mt-dti-%ec%84%b8%ea%b3%84-%ec%b5%9c%ea%b3%a0-%ec%84%b1%eb%8a%a5%ec%9d%98-%ec%95%bd%eb%ac%bc-%eb%8b%a8%eb%b0%b1%ec%a7%88-%ec%83%81%ed%98%b8%ec%9e%91%ec%9a%a9-%ec%98%88%ec%b8%a1/",
          },
          { text: "Demo", href: "https://mt-dti.deargendev.me/" },
        ],
      },
      {
        label: "paper",
        title: `Wx: a neural network-based feature selection algorithm for next-generation sequencing data`,
        channel: `Nature Scientific Report, 2019`,
        member: "Bonggun Shin, Sungsoo Park, Keunsoo Kang, Joyce C. Ho",
        content: `Abstract\nNext-generation sequencing (NGS), which allows the simultaneous sequencing of billions of DNA fragments simultaneously, has revolutionized how we study genomics and molecular biology by generating genome-wide molecular maps of molecules of interest. However, the amount of information produced by NGS has made it difficult for researchers to choose the optimal set of genes. We have sought to resolve this issue by developing a neural network-based feature (gene) selection algorithm called Wx. the Wx algorithm ranks genes based on the discriminative index (DI) score that represents the classification power for distinguishing given groups. With a gene list ranked by DI score, researchers can institutively select the optimal set of genes from the highest-ranking ones. We applied the Wx algorithm to a TCGA pan-cancer gene-expression cohort to identify an optimal set of gene-expression biomarker candidates that can distinguish cancer samples from normal samples for 12 different types of cancer. The 14 gene-expression biomarker candidates identified by Wx were comparable to or outperformed previously reported universal gene expression biomarkers, highlighting the usefulness of the Wx algorithm for next-generation sequencing data. thus, we anticipate that the Wx algorithm can complement current state-of-the-art analytical applications for the identification of biomarker candidates as an alternative method.`,
        href: "https://www.nature.com/articles/s41598-019-47016-8",
        buttonProps: [
          {
            text: "Publication",
            href: " https://www.nature.com/articles/s41598-019-47016-8",
          },
          {
            text: "Review",
            href:
              "https://deargen.blog/2019/07/19/wx-novel-%eb%b0%94%ec%9d%b4%ec%98%a4%eb%a7%88%ec%bb%a4-%eb%b0%9c%ea%b5%b4-%ea%b8%b0%ec%88%a0/",
          },
        ],
      },
      {
        label: "paper",
        title: `Cascaded Wx: a novel prognosis-related feature selection framework in human lung adenocarcinoma transcriptomes`,
        channel: `Frontiers in Genetics, 2019`,
        member:
          "Bonggun Shin, Sungsoo Park, Ji Hyung Hong, Ho Jung An, Sang Hoon Chun, Kilsoo Kang, Young-Ho Ahn, Yoon Ho Ko, Keunsoo Kang",
        content: `Abstract\nArtificial neural network-based analysis has recently been used to predict clinical dc.pngoutcomes in patients with solid cancers, including lung cancer. However, the majority of algorithms were not originally developed to identify genes associated with patients’ prognoses. To address this issue, we developed a novel prognosis-related feature selection framework called Cascaded Wx (CWx). The CWx framework ranks features according to the survival of a given cohort by training neural networks with three different high- and low-risk groups in a cascaded fashion. We showed that this approach accurately identified features that best identify the patients’ prognoses, compared to other feature selection algorithms, including the Cox proportional hazards and Coxnet models, when applied to The Cancer Genome Atlas lung adenocarcinoma (LUAD) transcriptome data. The prognostic potential of the top 100 genes identified by CWx outperformed or was comparable to those identified by the other methods as assessed by the concordance index (c-index). In addition, the top 100 genes identified by CWx were found to be associated with the Wnt signaling pathway, providing biologically relevant evidence for the value of these genes in predicting the prognosis of patients with LUAD. Further analyses of other cancer types showed that the genes identified by CWx had the highest prognostic values according to the c-index. Collectively, the CWx framework will potentially be of great use to prognosis-related biomarker discoveries in a variety of diseases.\n`,
        href:
          "https://www.frontiersin.org/articles/10.3389/fgene.2019.00662/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Genetics&id=444920",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://www.frontiersin.org/articles/10.3389/fgene.2019.00662/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Genetics&id=444920",
          },
          {
            text: "Review",
            href:
              "https://deargen.blog/2019/07/19/cwxcascaded-wx-%ec%98%88%ed%9b%84-%eb%b0%94%ec%9d%b4%ec%98%a4%eb%a7%88%ec%bb%a4-%eb%b0%9c%ea%b5%b4-%ea%b8%b0%ec%88%a0/",
          },
        ],
      },
      {
        label: "paper",
        title: `Octopus-toolkit: a workflow to automate mining of public epigenomic and transcriptomic next-generation sequencing data`,
        channel: `Nucleic Acids Research, 2018`,
        member:
          "Keunsoo Kang, Taemook Kim, Hogyu David Seo, Lothar Hennighausen, Daeyoup Lee",
        content: `Abstract\nBackground: The prognostic role of the translational factor, elongation factor-1 alpha 1 (EEF1A1), in colon cancer is unclear. Objectives: The present study aimed to investigate the expression of EEF1A in tissues obtained from patients with stage II and III colon cancer and analyze its association with patient prognosis. Methods: A total of 281 patients with colon cancer who underwent curative resection were analyzed according to EEF1A1 expression. Results: The five-year overall survival in the high-EEF1A1 group was 87.7%, whereas it was 65.6% in the low-EEF1A1 expression group (hazard ratio (HR) 2.47, 95% confidence interval (CI) 1.38–4.44, p = 0.002). The five-year disease-free survival of patients with high EEF1A1 expression was 82.5%, which was longer than the rate of 55.4% observed for patients with low EEF1A1 expression (HR 2.94, 95% CI 1.72–5.04, p < 0.001). Univariate Cox regression analysis indicated that age, preoperative carcinoembryonic antigen level, adjuvant treatment, total number of metastatic lymph nodes, and EEF1A1 expression level were significant prognostic factors for death. In multivariate analysis, expression of EEF1A1 was an independent prognostic factor associated with death (HR 3.01, 95% CI 1.636–5.543, p < 0.001). EEF1A1 expression was also an independent prognostic factor for disease-free survival in multivariate analysis (HR 2.54, 95% CI 1.459–4.434, p < 0.001). Conclusions: Our study demonstrated that high expression of EEF1A1 has a favorable prognostic effect on patients with colon adenocarcinoma.`,
        href: "https://academic.oup.com/nar/article/46/9/e53/4840238",
        buttonProps: [
          {
            text: "Publication",
            href: "https://academic.oup.com/nar/article/46/9/e53/4840238",
          },
        ],
      },
    ],
    // award
    [
      {
        href: `https://deargen.blog/2016/12/23/%eb%94%94%ec%96%b4%ec%a0%a0-ilsvrc-2016-%eb%8c%80%ed%9a%8c-%ec%9d%b4%eb%af%b8%ec%a7%80%eb%84%b7-%ec%9d%b4%eb%af%b8%ec%a7%80-%ec%9d%b8%ec%8b%9d-%eb%8c%80%ed%9a%8c-%ec%88%98%ec%83%81/`,
        label: "award",
      },
      {
        href: `https://deargen.blog/2017/01/20/%eb%94%94%ec%96%b4%ec%a0%a0-nci-cptac-%eb%93%9c%eb%a6%bc%ec%b1%8c%eb%a6%b0%ec%a7%80-%eb%8c%80%ed%9a%8c-top-performers-%ec%84%a0%ec%a0%95/`,
        label: "award",
      },
      {
        href: ``,
        label: "award",
      },
    ],
  ],
  en: [
    // paper & patent
    [
      {
        label: "paper",
        title: `Drug–Target Interaction Deep Learning-Based Model Identifies the Flavonoid Troxerutin as a Candidate TRPV1 Antagonist`,
        channel: `applied sciences, 2023`,
        member: `Bo Ram Beck, Hyunjun Yoon, Gahee Bahn, Young-heon Kim`,
        content: `Abstract\nBioactive compounds are often used as initial substances for many therapeutic agents. In recent years, both theoretical and practical innovations in hardware-assisted and fast-evolving machine learning (ML) have made it possible to identify desired bioactive compounds in chemical spaces, such as those in natural products (NPs). This review introduces how machine learning approaches can be used for the identification and evaluation of bioactive compounds. It also provides an overview of recent research trends in machine learning-based prediction and the evaluation of bioactive compounds by listing real-world examples along with various input data. In addition, several ML-based approaches to identify specific bioactive compounds for cardiovascular and metabolic diseases are described. Overall, these approaches are important for the discovery of novel bioactive compounds and provide new insights into the machine learning basis for various traditional applications of bioactive compound-related research.`,
        href: "https://www.mdpi.com/2076-3417/13/9/5617",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2076-3417/13/9/5617",
          },
        ],
      },
      {
        label: "paper",
        title: `A Brief Review of Machine Learning-Based Bioactive Compound Research`,
        channel: `applied sciences, 2022`,
        member: `Bo Ram Beck, Keunsoo Kang`,
        content: `Abstract\nBioactive compounds are often used as initial substances for many therapeutic agents. In recent years, both theoretical and practical innovations in hardware-assisted and fast-evolving machine learning (ML) have made it possible to identify desired bioactive compounds in chemical spaces, such as those in natural products (NPs). This review introduces how machine learning approaches can be used for the identification and evaluation of bioactive compounds. It also provides an overview of recent research trends in machine learning-based prediction and the evaluation of bioactive compounds by listing real-world examples along with various input data. In addition, several ML-based approaches to identify specific bioactive compounds for cardiovascular and metabolic diseases are described. Overall, these approaches are important for the discovery of novel bioactive compounds and provide new insights into the machine learning basis for various traditional applications of bioactive compound-related research.`,
        href: "https://www.mdpi.com/2076-3417/12/6/2906",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2076-3417/12/6/2906",
          },
        ],
      },
      {
        label: "paper",
        title: `Improving Evidential Deep Learning via Multi-task Learning`,
        channel: `AAAI, 2022`,
        member: `Dongpin Oh,  Bonggun Shin`,
        content: `Abstract\nThe Evidential regression network (ENet) estimates a con- tinuous target and its predictive uncertainty without costly Bayesian model averaging. However, it is possible that the target is inaccurately predicted due to the gradient shrinkage problem of the original loss function of the ENet, the negative log marginal likelihood (NLL) loss. In this paper, the objec- tive is to improve the prediction accuracy of the ENet while maintaining its efficient uncertainty estimation by resolving the gradient shrinkage problem. A multi-task learning (MTL) framework, referred to as MT-ENet, is proposed to accom- plish this aim. In the MTL, we define the Lipschitz modi- fied mean squared error (MSE) loss function as another loss and add it to the existing NLL loss. The Lipschitz modified MSE loss is designed to mitigate the gradient conflict with the NLL loss by dynamically adjusting its Lipschitz constant. By doing so, the Lipschitz MSE loss does not disturb the un- certainty estimation of the NLL loss. The MT-ENet enhances the predictive accuracy of the ENet without losing uncertainty estimation capability on the synthetic dataset and real-world benchmarks, including drug-target affinity (DTA) regression. Furthermore, the MT-ENet shows remarkable calibration and out-of-distribution detection capability on the DTA bench- marks.`,
        href: "https://www.aaai.org/AAAI22Papers/AAAI-2489.OhD.pdf",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.aaai.org/AAAI22Papers/AAAI-2489.OhD.pdf",
          },
        ],
      },
      {
        label: "paper",
        title: `An Interpretable Framework for Drug-Target Interaction with Gated Cross Attention`,
        channel: `Machine Learning For Health Care, 2021`,
        member: `YeachanKim, Bonggun Shin`,
        content: `Abstract\nIn silico prediction of drug-target interactions (DTI) is significant for drug discovery because it can largely reduce timelines and costs in the drug development process. Specifically, deep learning-based DTI approaches have been shown promising results in terms of accuracy and low cost for the prediction. However, they pay little attention to the interpretability of their prediction results and feature-level interactions between a drug and a target. In this study, we propose a novel interpretable framework that can provide reasonable cues for the interaction sites. To this end, we elaborately design a gated cross-attention mechanism that crossly attends drug and target features by constructing explicit interactions between these features. The gating function in the method enables neural models to focus on salient regions over entire sequences of drugs and proteins, and the byproduct from the function, which is the attention map, could serve as interpretable factors. The experimental results show the efficacy of the proposed method in two DTI datasets. Additionally, we show that gated cross-attention can sensitively react to the mutation, and this result could provide insights into the identification of novel drugs targeting mutant proteins.`,
        href:
          "https://static1.squarespace.com/static/59d5ac1780bd5ef9c396eda6/t/60fb3ab269a91023398a0e44/1627077298810/mlhc_2021_final.pdf",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://static1.squarespace.com/static/59d5ac1780bd5ef9c396eda6/t/60fb3ab269a91023398a0e44/1627077298810/mlhc_2021_final.pdf",
          },
        ],
      },
      {
        label: "paper",
        title: `Prediction of Diabetic Sensorimotor Polyneuropathy Using Machine Learning Techniques`,
        channel: `Clinical Medicine, 2021`,
        member: `Dae Youp Shin, Bora Lee, Won Sang Yoo, Joon Won Park and Jung Keun Hyun`,
        content: `Abstract\nDiabetic sensorimotor polyneuropathy (DSPN) is a major complication in patients with diabetes mellitus (DM), and early detection or prediction of DSPN is important for preventing or managing neuropathic pain and foot ulcer. Our aim is to delineate whether machine learning techniques are more useful than traditional statistical methods for predicting DSPN in DM patients. Four hundred seventy DM patients were classified into four groups (normal, possible, probable, and confirmed) based on clinical and electrophysiological findings of suspected DSPN. Three ML methods, XGBoost (XGB), support vector machine (SVM), and random forest (RF), and their combinations were used for analysis. RF showed the best area under the receiver operator characteristic curve (AUC, 0.8250) for differentiating between two categories—criteria by clinical findings (normal, possible, and probable groups) and those by electrophysiological findings (confirmed group)—and the result was superior to that of linear regression analysis (AUC = 0.6620). Average values of serum glucose, International Federation of Clinical Chemistry (IFCC), HbA1c, and albumin levels were identified as the four most important predictors of DSPN. In conclusion, machine learning techniques, especially RF, can predict DSPN in DM patients effectively, and electrophysiological analysis is important for identifying DSPN.`,
        href: "https://www.mdpi.com/2077-0383/10/19/4576",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2077-0383/10/19/4576",
          },
        ],
      },
      {
        label: "paper",
        title: `DeepCUBIT: Predicting Lymphovascular Invasion or Pathological Lymph Node Involvement of Clinical T1 Stage Non-Small Cell Lung Cancer on Chest CT Scan Using Deep Cubical Nodule Transfer Learning Algorithm`,
        channel: `Frontiers in Oncology, 2021`,
        member: `Kyongmin Sarah Beck, Bomi Gil, Sae Jung Na, Ji Hyung Hong, Sang Hoon Chun, Ho Jung An, Jae Jun Kim, Soon Auck Hong, Bora Lee, Won Sang Shim, Sungsoo Park and Yoon Ho Ko`,
        content: `Abstract\nThe prediction of lymphovascular invasion (LVI) or pathological nodal involvement of tumor cells is critical for successful treatment in early stage non-small cell lung cancer (NSCLC). We developed and validated a Deep Cubical Nodule Transfer Learning Algorithm (DeepCUBIT) using transfer learning and 3D Convolutional Neural Network (CNN) to predict LVI or pathological nodal involvement on chest CT images. A total of 695 preoperative CT images of resected NSCLC with tumor size of less than or equal to 3 cm from 2008 to 2015 were used to train and validate the DeepCUBIT model using five-fold cross-validation method. We also used tumor size and consolidation to tumor ratio (C/T ratio) to build a support vector machine (SVM) classifier. Two-hundred and fifty-four out of 695 samples (36.5%) had LVI or nodal involvement. An integrated model (3D CNN + Tumor size + C/T ratio) showed sensitivity of 31.8%, specificity of 89.8%, accuracy of 76.4%, and AUC of 0.759 on external validation cohort. Three single SVM models, using 3D CNN (DeepCUBIT), tumor size or C/T ratio, showed AUCs of 0.717, 0.630 and 0.683, respectively on external validation cohort. DeepCUBIT showed the best single model compared to the models using only C/T ratio or tumor size. In addition, the DeepCUBIT model could significantly identify the prognosis of resected NSCLC patients even in stage I. DeepCUBIT using transfer learning and 3D CNN can accurately predict LVI or nodal involvement in cT1 size NSCLC on CT images. Thus, it can provide a more accurate selection of candidates who will benefit from limited surgery without increasing the risk of recurrence.`,
        href:
          "https://www.frontiersin.org/articles/10.3389/fonc.2021.661244/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Oncology&id=661244",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://www.frontiersin.org/articles/10.3389/fonc.2021.661244/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Oncology&id=661244",
          },
        ],
      },
      {
        label: "paper",
        title: `DeepRePath: Identifying the Prognostic Features of Early-Stage Lung Adenocarcinoma Using Multi-Scale Pathology Images and Deep Convolutional Neural Networks`,
        channel: `Cancers, 2021`,
        member: `Won Sang Shim, Kwangil Yim, Tae-Jung Kim,Yeoun Eun Sung, Gyeongyun Lee, Ji Hyung Hong, Sang Hoon Chun, Seoree Kim, Ho Jung An, Sae Jung Na, Jae Jun Kim, Mi Hyoung Moon, Seok Whan Moon, Sungsoo Park, Soon Auck Hong, Yoon Ho Ko`,
        content: `Abstract\nThe prognosis of patients with lung adenocarcinoma (LUAD), especially early-stage LUAD, is dependent on clinicopathological features. However, its predictive utility is limited. In this study, we developed and trained a DeepRePath model based on a deep convolutional neural network (CNN) using multi-scale pathology images to predict the prognosis of patients with early-stage LUAD. DeepRePath was pre-trained with 1067 hematoxylin and eosin-stained whole-slide images of LUAD from the Cancer Genome Atlas. DeepRePath was further trained and validated using two separate CNNs and multi-scale pathology images of 393 resected lung cancer specimens from patients with stage I and II LUAD. Of the 393 patients, 95 patients developed recurrence after surgical resection. The DeepRePath model showed average area under the curve (AUC) scores of 0.77 and 0.76 in cohort I and cohort II (external validation set), respectively. Owing to low performance, DeepRePath cannot be used as an automated tool in a clinical setting. When gradient-weighted class activation mapping was used, DeepRePath indicated the association between atypical nuclei, discohesive tumor cells, and tumor necrosis in pathology images showing recurrence. Despite the limitations associated with a relatively small number of patients, the DeepRePath model based on CNNs with transfer learning could predict recurrence after the curative resection of early-stage LUAD using multi-scale pathology images.`,
        href: "https://www.mdpi.com/2072-6694/13/13/3308/htm",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2072-6694/13/13/3308/htm",
          },
        ],
      },
      {
        label: "paper",
        title: `Controlled molecule generator for optimizing multiple chemical properties`,
        channel: `ACM, 2021`,
        member: `Bonggun Shin, Sungsoo Park, JinYeong Bak, Joyce C. Ho`,
        content: `Abstract\nGenerating a novel and optimized molecule with desired chemical properties is an essential part of the drug discovery process. Failure to meet one of the required properties can frequently lead to failure in a clinical test which is costly. In addition, optimizing these multiple properties is a challenging task because the optimization of one property is prone to changing other properties. In this paper, we pose this multi-property optimization problem as a sequence translation process and propose a new optimized molecule generator model based on the Transformer with two constraint networks: property prediction and similarity prediction. We further improve the model by incorporating score predictions from these constraint networks in a modified beam search algorithm. The experiments demonstrate that our proposed model, Controlled Molecule Generator (CMG), outperforms state-of-the-art models by a significant margin for optimizing multiple properties simultaneously.`,
        href: "https://dl.acm.org/doi/10.1145/3450439.3451879",
        buttonProps: [
          {
            text: "Publication",
            href: "https://dl.acm.org/doi/10.1145/3450439.3451879",
          },
        ],
      },
      {
        label: "paper",
        title: `Target-Centered Drug Repurposing Predictions of Human 'ACE2' and 'TMPRSS2' Interacting Approved Drugs for Coronavirus Disease 2019 (COVID-19) Treatment`,
        channel: `Viruses, 2020`,
        member: `Yoonjung Choi, Bonggun Shin, Keunsoo Kang, Sungsoo Park, Bo Ram Beck`,
        content: `Abstract\nPreviously, our group predicted commercially available Food and Drug Administration (FDA) approved drugs that can inhibit each step of the replication of severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2) using a deep learning-based drug-target interaction model called Molecule Transformer-Drug Target Interaction (MT-DTI). Unfortunately, additional clinically significant treatment options since the approval of remdesivir are scarce. To overcome the current coronavirus disease 2019 (COVID-19) more efficiently, a treatment strategy that controls not only SARS-CoV-2 replication but also the host entry step should be considered. In this study, we used MT-DTI to predict FDA approved drugs that may have strong affinities for the angiotensin-converting enzyme 2 (ACE2) receptor and the transmembrane protease serine 2 (TMPRSS2) which are essential for viral entry to the host cell. Of the 460 drugs with Kd of less than 100 nM for the ACE2 receptor, 17 drugs overlapped with drugs that inhibit the interaction of ACE2 and SARS-CoV-2 spike reported in the NCATS OpenData portal. Among them, enalaprilat, an ACE inhibitor, showed a Kd value of 1.5 nM against the ACE2. Furthermore, three of the top 30 drugs with strong affinity prediction for the TMPRSS2 are anti-hepatitis C virus (HCV) drugs, including ombitasvir, daclatasvir, and paritaprevir. Notably, of the top 30 drugs, AT1R blocker eprosartan and neuropsychiatric drug lisuride showed similar gene expression profiles to potential TMPRSS2 inhibitors. Collectively, we suggest that drugs predicted to have strong inhibitory potencies to ACE2 and TMPRSS2 through the DTI model should be considered as potential drug repurposing candidates for COVID-19.`,
        href: "https://www.mdpi.com/1999-4915/12/11/1325/htm",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/1999-4915/12/11/1325/htm",
          },
        ],
      },
      {
        label: "paper",
        title: `Predicting FOXM1-Mediated Gene Regulation through the Analysis of Genome-Wide FOXM1 Binding Sites in MCF-7, K562, SK-N-SH, GM12878 and ECC-1 Cell Lines`,
        channel: `Molecular Sciences, 2020`,
        member: `Keunsoo Kang, Yoonjung Choi, Hoo Hyun Kim, Kyung Hyun Yoo,Sungryul Yu`,
        content: `Abstract\nForkhead box protein M1 (FOXM1) is a key transcription factor (TF) that regulates a common set of genes related to the cell cycle in various cell types. However, the mechanism by which FOXM1 controls the common gene set in different cellular contexts is unclear. In this study, a comprehensive meta-analysis of genome-wide FOXM1 binding sites in ECC-1, GM12878, K562, MCF-7, and SK-N-SH cell lines was conducted to predict FOXM1-driven gene regulation. Consistent with previous studies, different TF binding motifs were identified at FOXM1 binding sites, while the NFY binding motif was found at 81% of common FOXM1 binding sites in promoters of cell cycle-related genes. The results indicated that FOXM1 might control the gene set through interaction with the NFY proteins, while cell type-specific genes were predicted to be regulated by enhancers with FOXM1 and cell type-specific TFs. We also found that the high expression level of FOXM1 was significantly associated with poor prognosis in nine types of cancer. Overall, these results suggest that FOXM1 is predicted to function as a master regulator of the cell cycle through the interaction of NFY-family proteins, and therefore the inhibition of FOXM1 could be an attractive strategy for cancer therapy.`,
        href: "https://www.mdpi.com/1422-0067/21/17/6141/htm",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/1422-0067/21/17/6141/htm",
          },
        ],
      },
      {
        label: "paper",
        title: `Community Assessment of the Predictability of Cancer Protein and Phosphoprotein Levels from Genomics and Transcriptomics`,
        channel: `Cell Systems, 2020`,
        member: `Mi Yang, Francesca Petralia, Zhi Li, Hongyang Li, Weiping Ma, Xiaoyu Song, Sunkyu Kim, Heewon Lee, Han Yu, Bora Lee, Seohui Bae, Eunji Heo, Jan Kaczmarczyk, Piotr Stępniak, Michał Warchoł, Thomas Yu, Anna P. Calinawan, Paul C. Boutros, Samuel H. Payne, Boris Reva, NCI-CPTAC-DREAM Consortium, Emily Boja, Henry Rodriguez, Gustavo Stolovitzky, Yuanfang Guan, Jaewoo Kang, Pei Wang, David Fenyö, Julio Saez-Rodriguez`,
        content: `Abstract\nCancer is driven by genomic alterations, but the processes causing this disease are largely performed by proteins. However, proteins are harder and more expensive to measure than genes and transcripts. To catalyze developments of methods to infer protein levels from other omics measurements, we leveraged crowdsourcing via the NCI-CPTAC DREAM proteogenomic challenge. We asked for methods to predict protein and phosphorylation levels from genomic and transcriptomic data in cancer patients. The best performance was achieved by an ensemble of models, including as predictors transcript level of the corresponding genes, interaction between genes, conservation across tumor types, and phosphosite proximity for phosphorylation prediction. Proteins from metabolic pathways and complexes were the best and worst predicted, respectively. The performance of even the best-performing model was modest, suggesting that many proteins are strongly regulated through translational control and degradation. Our results set a reference for the limitations of computational inference in proteogenomics.`,
        href:
          "https://www.cell.com/cell-systems/fulltext/S2405-4712(20)30242-8?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS2405471220302428%3Fshowall%3Dtrue",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://www.cell.com/cell-systems/fulltext/S2405-4712(20)30242-8?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS2405471220302428%3Fshowall%3Dtrue",
          },
        ],
      },
      {
        label: "paper",
        title: `Identification of Novel microRNA Prognostic Markers Using Cascaded Wx, a Neural Network-Based Framework, in Lung Adenocarcinoma Patients`,
        channel: `Cancers, 2020`,
        member:
          "Jeong Seon Kim, Sang Hoon Chun, Sungsoo Park, Sieun Lee, Sae Eun Kim, Ji Hyung Hong, Keunsoo Kang, Yoon Ho Ko, Young-Ho Ahn",
        content: `Abstract\nThe evolution of next-generation sequencing technology has resulted in a generation of large amounts of cancer genomic data. Therefore, increasingly complex techniques are required to appropriately analyze this data in order to determine its clinical relevance. In this study, we applied a neural network-based technique to analyze data from The Cancer Genome Atlas and extract useful microRNA (miRNA) features for predicting the prognosis of patients with lung adenocarcinomas (LUAD). Using the Cascaded Wx platform, we identified and ranked miRNAs that affected LUAD patient survival and selected the two top-ranked miRNAs (miR-374a and miR-374b) for measurement of their expression levels in patient tumor tissues and in lung cancer cells exhibiting an altered epithelial-to-mesenchymal transition (EMT) status. Analysis of miRNA expression from tumor samples revealed that high miR-374a/b expression was associated with poor patient survival rates. In lung cancer cells, the EMT signal induced miR-374a/b expression, which, in turn, promoted EMT and invasiveness. These findings demonstrated that this approach enabled effective identification and validation of prognostic miRNA markers in LUAD, suggesting its potential efficacy for clinical use.`,
        href: "https://www.mdpi.com/2072-6694/12/7/1890/htm",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2072-6694/12/7/1890/htm",
          },
        ],
      },
      {
        label: "Poster",
        title: `Molecular optimization of phase III trial failed anticancer drugs using target affinity and toxicity-centered multiple properties reinforcement learning`,
        channel: `Clinical Cancer Research, 2020`,
        member:
          "Sungsoo Park, Yoon Ho Ko, Bora Lee, Bonggun Shin and Bo Ram Beck",
        content: `Abstract\nDespite the potential of anticancer drugs in the early stages of drug development, many clinical trials have failed at phase III because of low efficacy and/or high toxicity profiles. To improve such issues, effective lead optimization based on machine learning (ML) is needed. Many ML-based lead optimization models have been proposed, but they are capable of optimizing only a single property of a drug; therefore, multiple models are required and would likely be significantly different from the original drug as processed by multiple models. Our contributions are as follows: 1) We devised a new deep learning architecture based on a long short-term memory on a convolutional neural network for predicting binding affinity score (AS) and absorption, distribution, metabolism, excretion, and toxicity (ADMET) prediction. 2) We adapted the Molecule Deep Q-Networks (MoleDQN) to our new reward combinations so that a single model can consider multiple properties such as quantitative estimate of drug-likeness (QED), AS, synthetic accessibility scores (SAS), and toxicity scores. 3) Binding affinity estimator and ADMET estimator are used to indicate drug efficacy and toxicities. Iniparib, brivanib, and rebimastat, which failed in phase III clinical trials, were used for this analysis, and the top two optimized candidates of each drug with an improved score were selected. Iniparib was developed as a PARP inhibitor, but QED and AS were not predicted as a potential drug in our model (QED, 0.51; SAS, 2.23; AS, 5.02). This may be related to the low efficacy reported in clinical trials. Thus, iniparib was optimized for improved QED and AS to increase efficacy. `,
        href:
          "https://clincancerres.aacrjournals.org/content/26/12_Supplement_1/35.abstract",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://clincancerres.aacrjournals.org/content/26/12_Supplement_1/35.abstract",
          },
        ],
      },
      {
        label: "paper",
        title: `Tiotropium Is Predicted to Be a Promising Drug for COVID-19 Through Transcriptome-Based Comprehensive Molecular Pathway Analysis`,
        channel: `Viruses, 2020`,
        member: "Keunsoo Kang, Hoo Hyun Kim, Yoonjung Choi ",
        content: `Abstract\nThe coronavirus disease 2019 (COVID-19) outbreak caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2) affects almost everyone in the world in many ways. We previously predicted antivirals (atazanavir, remdesivir and lopinavir/ritonavir) and non-antiviral drugs (tiotropium and rapamycin) that may inhibit the replication complex of SARS-CoV-2 using our molecular transformer–drug target interaction (MT–DTI) deep-learning-based drug–target affinity prediction model. In this study, we dissected molecular pathways upregulated in SARS-CoV-2-infected normal human bronchial epithelial (NHBE) cells by analyzing an RNA-seq data set with various bioinformatics approaches, such as gene ontology, protein–protein interaction-based network and gene set enrichment analyses. The results indicated that the SARS-CoV-2 infection strongly activates TNF and NFκB-signaling pathways through significant upregulation of the TNF, IL1B, IL6, IL8, NFKB1, NFKB2 and RELB genes. In addition to these pathways, lung fibrosis, keratinization/cornification, rheumatoid arthritis, and negative regulation of interferon-gamma production pathways were also significantly upregulated. We observed that these pathologic features of SARS-CoV-2 are similar to those observed in patients with chronic obstructive pulmonary disease (COPD). Intriguingly, tiotropium, as predicted by MT–DTI, is currently used as a therapeutic intervention in COPD patients. Treatment with tiotropium has been shown to improve pulmonary function by alleviating airway inflammation. Accordingly, a literature search summarized that tiotropium reduced expressions of IL1B, IL6, IL8, RELA, NFKB1 and TNF in vitro or in vivo, and many of them have been known to be deregulated in COPD patients. These results suggest that COVID-19 is similar to an acute mode of COPD caused by the SARS-CoV-2 infection, and therefore tiotropium may be effective for COVID-19 patients.`,
        href: "https://www.mdpi.com/1999-4915/12/7/776/htm",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/1999-4915/12/7/776/htm",
          },
        ],
      },
      {
        label: "paper",
        title: `Predicting commercially available antiviral drugs that may act on the novel coronavirus (SARS-CoV-2) through a drug-target interaction deep learning model`,
        channel: `Computational and Structural Biotechnology Journal, 2020`,
        member:
          "Bo Ram Beck, Bong gun Shin, Yoonjung Choi, Sungsoo Park, Keunsoo Kang",
        content: `Abstract\nThe infection of a novel coronavirus found in Wuhan of China (SARS-CoV-2) is rapidly spreading, and the incidence rate is increasing worldwide. Due to the lack of effective treatment options for SARS-CoV-2, various strategies are being tested in China, including drug repurposing. In this study, we used our pre-trained deep learning-based drug-target interaction model called Molecule Transformer-Drug Target Interaction (MT-DTI) to identify commercially available drugs that could act on viral proteins of SARS-CoV-2. The result showed that atazanavir, an antiretroviral medication used to treat and prevent the human immunodeficiency virus (HIV), is the best chemical compound, showing an inhibitory potency with Kd of 94.94 nM against the SARS-CoV-2 3C-like proteinase, followed by remdesivir (113.13 nM), efavirenz (199.17 nM), ritonavir (204.05 nM), and dolutegravir (336.91 nM). Interestingly, lopinavir, ritonavir, and darunavir are all designed to target viral proteinases. However, in our prediction, they may also bind to the replication complex components of SARS-CoV-2 with an inhibitory potency with Kd < 1000 nM. In addition, we also found that several antiviral agents, such as Kaletra (lopinavir/ritonavir), could be used for the treatment of SARS-CoV-2. Overall, we suggest that the list of antiviral drugs identified by the MT-DTI model should be considered, when establishing effective treatment strategies for SARS-CoV-2.`,
        href:
          "https://www.sciencedirect.com/science/article/pii/S2001037020300490?via%3Dihub",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://www.sciencedirect.com/science/article/pii/S2001037020300490?via%3Dihub",
          },
        ],
      },
      {
        label: "paper",
        title: `DeepBTS: Prediction of Recurrence-free Survival of Non-small Cell Lung Cancer Using a Time-binned Deep Neural Network`,
        channel: `Nature Scientific Report, 2019`,
        member:
          "Bora Lee, Sang Hoon Chun, Ji Hyung Hong, In Sook Woo, Seoree Kim, Joon Won Jeong, Jae Jun Kim, Hyun Woo Lee, Sae Jung Na, Kyongmin Sarah Beck, Bomi Gil, Sungsoo Park, Ho Jung An & Yoon Ho Ko",
        content: `Abstract\nAccurate prediction of non-small cell lung cancer (NSCLC) prognosis after surgery remains challenging. The Cox proportional hazard (PH) model is widely used, however, there are some limitations associated with it. In this study, we developed novel neural network models called binned time survival analysis (DeepBTS) models using 30 clinico-pathological features of surgically resected NSCLC patients (training cohort, n = 1,022; external validation cohort, n = 298). We employed the root-mean-square error (in the supervised learning model, s- DeepBTS) or negative log-likelihood (in the semi-unsupervised learning model, su-DeepBTS) as the loss function. The su-DeepBTS algorithm achieved better performance (C-index = 0.7306; AUC = 0.7677) than the other models (Cox PH: C-index = 0.7048 and AUC = 0.7390; s-DeepBTS: C-index = 0.7126 and AUC = 0.7420). The top 14 features were selected using su-DeepBTS model as a selector and could distinguish the low- and high-risk groups in the training cohort (p = 1.86 × 10−11) and validation cohort (p = 1.04 × 10−10). When trained with the optimal feature set for each model, the su-DeepBTS model could predict the prognoses of NSCLC better than the traditional model, especially in stage I patients. Follow-up studies using combined radiological, pathological imaging, and genomic data to enhance the performance of our model are ongoing.`,
        href: "https://www.nature.com/articles/s41598-020-58722-z",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.nature.com/articles/s41598-020-58722-z",
          },
        ],
      },
      {
        label: "patent",
        title: `METHOD AND SYSTEM FOR DETERMINING FEATURE INFLUENCE`,
        channel: `Application number 10-207302-000-00`,
        member: "Deargen Inc.",
        content: ``,
        href:
          "https://patentscope.wipo.int/search/ko/detail.jsf?docId=WO2019088759",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://patentscope.wipo.int/search/ko/detail.jsf?docId=WO2019088759",
          },
        ],
      },
      {
        label: "paper",
        title: `Expression of EEF1A1 Is Associated with Prognosis of Patients with Colon Adenocarcinoma\n`,
        channel: `Journal of Clinical Medicine, 2019`,
        member:
          "Eun kyo Joung, Jiyoung Kim,Nara Yoon, Lee-so Maeng, Ji Hoon Kim, Sungsoo Park, Keunsoo Kang, Jeong Seon Kim, Young Ho Ahn,Yoon Ho Ko, Jae Ho Byun, Ji Hyung Hong",
        content: `Abstract\nBackground: The prognostic role of the translational factor, elongation factor-1 alpha 1 (EEF1A1), in colon cancer is unclear. Objectives: The present study aimed to investigate the expression of EEF1A in tissues obtained from patients with stage II and III colon cancer and analyze its association with patient prognosis. Methods: A total of 281 patients with colon cancer who underwent curative resection were analyzed according to EEF1A1 expression. Results: The five-year overall survival in the high-EEF1A1 group was 87.7%, whereas it was 65.6% in the low-EEF1A1 expression group (hazard ratio (HR) 2.47, 95% confidence interval (CI) 1.38–4.44, p = 0.002). The five-year disease-free survival of patients with high EEF1A1 expression was 82.5%, which was longer than the rate of 55.4% observed for patients with low EEF1A1 expression (HR 2.94, 95% CI 1.72–5.04, p < 0.001). Univariate Cox regression analysis indicated that age, preoperative carcinoembryonic antigen level, adjuvant treatment, total number of metastatic lymph nodes, and EEF1A1 expression level were significant prognostic factors for death. In multivariate analysis, expression of EEF1A1 was an independent prognostic factor associated with death (HR 3.01, 95% CI 1.636–5.543, p < 0.001). EEF1A1 expression was also an independent prognostic factor for disease-free survival in multivariate analysis (HR 2.54, 95% CI 1.459–4.434, p < 0.001). Conclusions: Our study demonstrated that high expression of EEF1A1 has a favorable prognostic effect on patients with colon adenocarcinoma.`,
        href: "https://www.mdpi.com/2077-0383/8/11/1903",
        buttonProps: [
          {
            text: "Publication",
            href: "https://www.mdpi.com/2077-0383/8/11/1903",
          },
        ],
      },
      {
        label: "paper",
        title: `Self-Attention Based Molecule Representation for Predicting Drug-Target Interaction`,
        channel: `Journal of Machine Learning Research, 2019`,
        member: "Bonggun Shin, Sungsoo Park, Keunsoo Kang, Joyce C. Ho",
        content: `Abstract\nPredicting drug-target interactions (DTI) is an essential part of the drug discovery process, which is an expensive process in terms of time and cost. Therefore, reducing DTI cost could lead to reduced healthcare costs for a patient. In addition, a precisely learned molecule representation in a DTI model could contribute to developing personalized medicine, which will help many patient cohorts. In this paper, we propose a new molecule representation based on the self-attention mechanism, and a new DTI model using our molecule representation. The experiments show that our DTI model outperforms the state of the art by up to 4.9% points in terms of area under the precision-recall curve. Moreover, a study using the DrugBank database proves that our model effectively lists all known drugs targeting a specific cancer biomarker in the top-30 candidate list.`,
        href: "https://arxiv.org/abs/1908.06760",
        buttonProps: [
          { text: "Publication", href: "https://arxiv.org/abs/1908.06760" },
          {
            text: "Review",
            href:
              "https://deargen.blog/2019/08/14/a-new-state-of-the-art-drug-target-interaction-model-molecule-transformer-based-drug-target-interaction-mt-dti-model/",
          },
          { text: "Demo", href: "https://mt-dti.deargendev.me/" },
        ],
      },
      {
        label: "paper",
        title: `Wx: a neural network-based feature selection algorithm for next-generation sequencing data`,
        channel: `Nature Scientific Report, 2019`,
        member: "Bonggun Shin, Sungsoo Park, Keunsoo Kang, Joyce C. Ho",
        content: `Abstract\nNext-generation sequencing (NGS), which allows the simultaneous sequencing of billions of DNA fragments simultaneously, has revolutionized how we study genomics and molecular biology by generating genome-wide molecular maps of molecules of interest. However, the amount of information produced by NGS has made it difficult for researchers to choose the optimal set of genes. We have sought to resolve this issue by developing a neural network-based feature (gene) selection algorithm called Wx. the Wx algorithm ranks genes based on the discriminative index (DI) score that represents the classification power for distinguishing given groups. With a gene list ranked by DI score, researchers can institutively select the optimal set of genes from the highest-ranking ones. We applied the Wx algorithm to a TCGA pan-cancer gene-expression cohort to identify an optimal set of gene-expression biomarker candidates that can distinguish cancer samples from normal samples for 12 different types of cancer. The 14 gene-expression biomarker candidates identified by Wx were comparable to or outperformed previously reported universal gene expression biomarkers, highlighting the usefulness of the Wx algorithm for next-generation sequencing data. thus, we anticipate that the Wx algorithm can complement current state-of-the-art analytical applications for the identification of biomarker candidates as an alternative method.`,
        href: " https://www.nature.com/articles/s41598-019-47016-8",
        buttonProps: [
          {
            text: "Publication",
            href: " https://www.nature.com/articles/s41598-019-47016-8",
          },
          {
            text: "Review",
            href:
              "https://deargen.blog/2019/07/19/wx-technology-for-novel-biomarker-discovery/",
          },
        ],
      },
      {
        label: "paper",
        title: `Cascaded Wx: a novel prognosis-related feature selection framework in human lung adenocarcinoma transcriptomes`,
        channel: `Frontiers in Genetics, 2019`,
        member:
          "Bonggun Shin, Sungsoo Park, Ji Hyung Hong, Ho Jung An, Sang Hoon Chun, Kilsoo Kang, Young-Ho Ahn, Yoon Ho Ko, Keunsoo Kang",
        content: `Abstract\nArtificial neural network-based analysis has recently been used to predict clinical dc.pngoutcomes in patients with solid cancers, including lung cancer. However, the majority of algorithms were not originally developed to identify genes associated with patients’ prognoses. To address this issue, we developed a novel prognosis-related feature selection framework called Cascaded Wx (CWx). The CWx framework ranks features according to the survival of a given cohort by training neural networks with three different high- and low-risk groups in a cascaded fashion. We showed that this approach accurately identified features that best identify the patients’ prognoses, compared to other feature selection algorithms, including the Cox proportional hazards and Coxnet models, when applied to The Cancer Genome Atlas lung adenocarcinoma (LUAD) transcriptome data. The prognostic potential of the top 100 genes identified by CWx outperformed or was comparable to those identified by the other methods as assessed by the concordance index (c-index). In addition, the top 100 genes identified by CWx were found to be associated with the Wnt signaling pathway, providing biologically relevant evidence for the value of these genes in predicting the prognosis of patients with LUAD. Further analyses of other cancer types showed that the genes identified by CWx had the highest prognostic values according to the c-index. Collectively, the CWx framework will potentially be of great use to prognosis-related biomarker discoveries in a variety of diseases.\n`,
        href:
          "https://www.frontiersin.org/articles/10.3389/fgene.2019.00662/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Genetics&id=444920",
        buttonProps: [
          {
            text: "Publication",
            href:
              "https://www.frontiersin.org/articles/10.3389/fgene.2019.00662/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Genetics&id=444920",
          },
          {
            text: "Review",
            href:
              "https://deargen.blog/2019/07/19/cwx-cascaded-wx-technology-for-prognosis-biomarker-discovery/",
          },
        ],
      },
      {
        label: "paper",
        title: `Octopus-toolkit: a workflow to automate mining of public epigenomic and transcriptomic next-generation sequencing data`,
        channel: `Nucleic Acids Research, 2018`,
        member:
          "Keunsoo Kang, Taemook Kim, Hogyu David Seo, Lothar Hennighausen, Daeyoup Lee",
        content: `Abstract\nBackground: The prognostic role of the translational factor, elongation factor-1 alpha 1 (EEF1A1), in colon cancer is unclear. Objectives: The present study aimed to investigate the expression of EEF1A in tissues obtained from patients with stage II and III colon cancer and analyze its association with patient prognosis. Methods: A total of 281 patients with colon cancer who underwent curative resection were analyzed according to EEF1A1 expression. Results: The five-year overall survival in the high-EEF1A1 group was 87.7%, whereas it was 65.6% in the low-EEF1A1 expression group (hazard ratio (HR) 2.47, 95% confidence interval (CI) 1.38–4.44, p = 0.002). The five-year disease-free survival of patients with high EEF1A1 expression was 82.5%, which was longer than the rate of 55.4% observed for patients with low EEF1A1 expression (HR 2.94, 95% CI 1.72–5.04, p < 0.001). Univariate Cox regression analysis indicated that age, preoperative carcinoembryonic antigen level, adjuvant treatment, total number of metastatic lymph nodes, and EEF1A1 expression level were significant prognostic factors for death. In multivariate analysis, expression of EEF1A1 was an independent prognostic factor associated with death (HR 3.01, 95% CI 1.636–5.543, p < 0.001). EEF1A1 expression was also an independent prognostic factor for disease-free survival in multivariate analysis (HR 2.54, 95% CI 1.459–4.434, p < 0.001). Conclusions: Our study demonstrated that high expression of EEF1A1 has a favorable prognostic effect on patients with colon adenocarcinoma.`,
        href: "https://academic.oup.com/nar/article/46/9/e53/4840238",
        buttonProps: [
          {
            text: "Publication",
            href: "https://academic.oup.com/nar/article/46/9/e53/4840238",
          },
        ],
      },
    ],
    // award
    [
      {
        href: `https://deargen.blog/2016/12/23/deargen-won-at-ilsvrc-2016-imagenet-large-scale-visual-recognition-challenge/`,
        label: "award",
      },
      {
        href: `https://deargen.blog/2017/01/20/deargen-was-selected-as-top-performers-of-the-nci-cptac-dream-challenge/`,
        label: "award",
      },
      {
        href: ``,
        label: "award",
      },
    ],
  ],
};

export default function useContent() {
  const language = useLanguage();
  const 컨텐츠 = text[language];

  const images = useStaticQuery(graphql`
    query {
      publication_paperPatent_0: file(
        relativePath: { eq: "Home_Publication/applsci-logo.png" }
      ) {
        childImageSharp {
          fixed(width: 220, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_1: file(
        relativePath: { eq: "Home_Publication/applsci-logo.png" }
      ) {
        childImageSharp {
          fixed(width: 220, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_2: file(
        relativePath: { eq: "Home_Publication/AAAI.jpg" }
      ) {
        childImageSharp {
          fixed(width: 220, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_3: file(
        relativePath: { eq: "Home_Publication/mlhc.png" }
      ) {
        childImageSharp {
          fixed(width: 154, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_4: file(
        relativePath: { eq: "Home_Publication/cm.png" }
      ) {
        childImageSharp {
          fixed(width: 154, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_5: file(
        relativePath: { eq: "Home_Publication/frontiers.png" }
      ) {
        childImageSharp {
          fixed(width: 154, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_6: file(
        relativePath: { eq: "Home_Publication/cancers.png" }
      ) {
        childImageSharp {
          fixed(width: 110, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_7: file(
        relativePath: { eq: "Home_Publication/acm.png" }
      ) {
        childImageSharp {
          fixed(width: 154, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_8: file(
        relativePath: { eq: "Home_Publication/viruses.png" }
      ) {
        childImageSharp {
          fixed(width: 220, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_9: file(
        relativePath: { eq: "Home_Publication/molecular_sciences.webp" }
      ) {
        childImageSharp {
          fixed(width: 220, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_10: file(
        relativePath: { eq: "Home_Publication/cell_systems.png" }
      ) {
        childImageSharp {
          fixed(width: 220, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_11: file(
        relativePath: { eq: "Home_Publication/cancers.png" }
      ) {
        childImageSharp {
          fixed(width: 210, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_12: file(
        relativePath: { eq: "Home_Publication/clinical_cancer_research.jpg" }
      ) {
        childImageSharp {
          fixed(width: 154, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_13: file(
        relativePath: { eq: "Home_Publication/viruses.png" }
      ) {
        childImageSharp {
          fixed(width: 270, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_14: file(
        relativePath: { eq: "Home_Publication/csbj.png" }
      ) {
        childImageSharp {
          fixed(width: 154, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_15: file(
        relativePath: { eq: "Home_Publication/nature.png" }
      ) {
        childImageSharp {
          fixed(width: 154, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_16: file(
        relativePath: { eq: "Home_Publication/patent_logo.png" }
      ) {
        childImageSharp {
          fixed(width: 90, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_17: file(
        relativePath: { eq: "Home_Publication/cm.png" }
      ) {
        childImageSharp {
          fixed(width: 85, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_18: file(
        relativePath: { eq: "Home_Publication/jmlr.png" }
      ) {
        childImageSharp {
          fixed(width: 154, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_19: file(
        relativePath: { eq: "Home_Publication/nature.png" }
      ) {
        childImageSharp {
          fixed(width: 110, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_20: file(
        relativePath: { eq: "Home_Publication/frontiers.png" }
      ) {
        childImageSharp {
          fixed(width: 90, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_paperPatent_21: file(
        relativePath: { eq: "Home_Publication/nar.png" }
      ) {
        childImageSharp {
          fixed(width: 110, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_award_0: file(
        relativePath: { eq: "Home_Publication/imagenet.png" }
      ) {
        childImageSharp {
          fixed(width: 120, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_award_1: file(
        relativePath: { eq: "Home_Publication/dc.png" }
      ) {
        childImageSharp {
          fixed(width: 130, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publication_award_2: file(
        relativePath: { eq: "Home_Publication/kaggle.png" }
      ) {
        childImageSharp {
          fixed(width: 120, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return { images, 컨텐츠 };
}
