import React, {  } from 'react'
import _ from "lodash"
import { ArrowButton } from "src/components"
import { Section, FadeInView } from 'src/components/utils'
import { slides } from "./HomeDesktop"

export default function HomeMobile() {

    // render
    const render = slides
    .filter(slide => _.has(slide, "mobile"))
    .map((slide, index) => {
        // if not define overfloww:hidden, critical error is occurred in mobile. don't know reason why
        return <Section>
            <FadeInView key={index} threshold={0.2}>
                <slide.mobile />
            </FadeInView>
        </Section>
    })


    // arrow button render 
    const arrowButtonRender = <>
        <ArrowButton position={{right: 20, bottom: 70}} direction="up" onClick={e => window.scroll({ top: 0, behavior: "smooth" })} />
        <ArrowButton position={{right: 20, bottom: 20}} direction="top"  onClick={e => window.scroll({ top: document.body.clientHeight, behavior: "smooth" })} />
    </>


    return <>
        {render}
        {arrowButtonRender}
    </>
}

