import React, { useState } from 'react'
import styled, { css } from "styled-components"
import Flex from "styled-flex-component"
import breakpoint from "styled-components-breakpoint"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Img, P, H1, Button } from 'src/components';
import { useIsDeviceWidth } from 'src/components/utils';
import { mainTheme } from "src/GlobalStyle"


const PublicationCardRoot = styled.article`
    position: relative;
    padding: 0 12px;
    
    ${breakpoint("mobile", "desktop")`
        padding: 0;
    `}

    ${props => props.customCss}
`
const Label = styled(P)`
    background: ${props => props.color};
    position: absolute;
    right: 0px;
    width: fit-content;
    color: white;
    font-weight: bold;
    padding: 6px 20px;
    border-radius: 5px;

    ${breakpoint("mobile", "desktop")`
        position: relative;
        padding: 3px 10px;
    `}

    ${props => props.customCss}
`

const UpperSubtitle = styled(P)`
    font-size: 20px;
    font-weight: lighter;
    margin-top: 30px;

    ${breakpoint("mobile", "desktop")`
        font-size: 12px;
        margin-top: 12px;
    `}
`

const Title = styled(H1)`
    font-size: 24px;
    font-weight: bold;
    line-height: 1.3;
    margin-top: 30px;
    
    ${breakpoint("mobile", "desktop")`
        font-size: 18px;
        font-weight: bold;
        margin-top: 8px;
        text-align: start;
    `}

    ${props => props.customCss};
`

const Subtitle = styled(P)`
    margin-top: 30px;
    font-size: 16px;
    font-weight: lighter;
    
    ${breakpoint("mobile", "desktop")`
        font-weight: normal;
        font-size: 12px;
        margin-top: 8px;
    `}
`

const Content = styled(P)`
    font-size: 18px;
    margin-top: 50px;
    
    ${breakpoint("mobile", "desktop")`
        font-size: 14px;
        margin-top: 8px;
    `}
`

const ReadMore = styled.span`
    font-size: 13px;
    margin-top: 8px;
    text-decoration: underline;
`

const Buttons = styled(Flex)`
    margin-top: 50px;

    ${breakpoint("mobile", "desktop")`
        margin-top: 20px;
    `}
`

export default function PublicationDetailCard({
    gatsbyImageSrc,
    label,
    upperSubtitle,
    title,
    subtitle,
    content,
    buttonProps=[],
    href,
    customCss,
    card=false,
}) {    
    const [open, setOpen] = useState(false)
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })
    
    // styling according to label
    const color = ((label==="paper"||label==="Poster") && mainTheme.secondary) || (label==="patent" && "orange") || "transparent"
    const cardModeRootCss = css`
        cursor: ${href ? "pointer" : "initial"};
        border: 2px solid ${color};
        border-radius: 15px;
        box-sizing: border-box;
        width: 300px;
        padding: 30px 20px 0px;
        flex: 1;
    `
    const cardModeLabelCss = css`
        right: -2px;
        top: -18px;
    `
    const defaultImageCss = css`
        margin-top: 15px;
    `
    const cardModeImageCss = css`
        margin: auto;
    `
    const cardModeTitleCss = css`
        font-size: 16px;
        margin-top: 10px;
        text-align: center;
    `


    // render
    const render = <>
        <Label customCss={card && cardModeLabelCss} color={color}>{label}</Label>    

        <Img
        href={href}
        gatsbyImageSrc={gatsbyImageSrc}
        rounded={4}
        customCss={card ? cardModeImageCss : defaultImageCss}/>

        {upperSubtitle && <UpperSubtitle>{upperSubtitle}</UpperSubtitle>}
        
        {title && <Title customCss={card && cardModeTitleCss}>{title}</Title>}

        {subtitle && <Subtitle>{subtitle}</Subtitle>}

        {content && <Content>{open && content}</Content>}

        {content && <ReadMore onClick={e => setOpen(o => !o)}>
            {open 
                ? "Close" 
                : "Read more"
            }
        </ReadMore>}

        {buttonProps.length > 0 && <Buttons>
            {buttonProps.map((props, index) => <Button key={index} {...props} customCss={`margin-right: 8px;`}/>)}
        </Buttons>}
    </>

    // when href is passed, acts as outer-link
    const outerLinkProps = href 
        ? { as: OutboundLink, href, target: "_blank" } 
        : {}


    return <PublicationCardRoot
    customCss={card ? cardModeRootCss : customCss}
    {...outerLinkProps}>
        {render}
    </PublicationCardRoot>   
    
}