import React from 'react'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { H1, P, U, Strong } from 'src/components';
import useContents from "src/data"

const AboutDrugHeaderRoot = styled.div`
    ${H1} {
        font-size: 4.5em;
        ${breakpoint("mobile", "desktop")`
            font-size: 2em;
        `}
    }
    ${P} {
        margin-top: 20px;
    }
`
        
function AboutDrugHeader() {
    // get contents
    const { 컨텐츠 } = useContents("Technology")
    

    return  <AboutDrugHeaderRoot>
        <H1 weight={200} customCss={`margin: 20px 0;`}>
            <P>{컨텐츠.Introduce[0][0]}</P>
            <Strong>
                <U>
                    {컨텐츠.Introduce[0][1]}
                </U>
            </Strong>
        </H1>
        <P>
            {컨텐츠.Introduce[0][2]}
        </P>
    </AboutDrugHeaderRoot> 
}

export default <AboutDrugHeader />