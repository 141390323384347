import React, {  } from 'react'
import styled, {  } from "styled-components"
import { H2, P, Button } from "src/components"
import useContents from "src/data"

const FooterRoot = styled.div`
    background: #f8f9fa;
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    
    ${H2} {
        text-align: center;
        font-size: 1.2rem;
    }
    ${P} {
        text-align: center;
        font-weight: 800;
        font-size: 0.8rem;
    }
`
const Text = styled.div`
    ${props => props.theme.css.center()}
`


export default function Footer() {
    const { 컨텐츠 } = useContents("Home_Contact")

    return <FooterRoot>
        <Text>
            <H2>{컨텐츠[1][0]}</H2>
            <Button text={컨텐츠[1][1]} customCss={`margin: 10px;`}/>
            <P>{컨텐츠[1][2]}</P>

        </Text>
    </FooterRoot>
}
