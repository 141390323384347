import React, { useMemo } from 'react'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Flex, {  } from 'styled-flex-component'
import { HexagonField, P as _P, H2, Img } from "src/components"
import { useLanguage } from "src/components/utils"
import { mainTheme } from 'src/GlobalStyle'
import { MdArrowForward } from "react-icons/md"

const HexagonTocRoot = styled.div`
    height: fit-content;
    ${props => props.customCss};
`

const SQRT3 = Math.sqrt(3)

const P = styled(_P)`
    color: ${props => props.theme.secondary};
    font-size: 0.8em;
    font-weight: 700;
    text-align: center;
    ${props => props.customCss}
`
const BigHexagonBackgroundComponentRoot = styled(Flex)`
    ${H2} {
        font-size: 1.2em;
        font-weight: 700;
        ${breakpoint("mobile", "desktop")`
            font-size: 1.1em;
        `}
    }

    img {
        object-fit: contain;
        height: 70px;
    }
`

export default function HexagonToc({ inViews, 컨텐츠, images, customCss }) {
    // url prefix
    const language = useLanguage()
    const urlPrefix = `/${language}/technology/`
 

    const bigHexagonLargeCharactorSize = "1.2em"
    const leftBigHexagonBackgroundComponent = useMemo(() => (
        <BigHexagonBackgroundComponentRoot column center full>
            <Img gatsbyImageSrc={images.target_discovery} />
            <P customCss={`color: ${mainTheme.primary}; margin-top: 10px;`}>{컨텐츠.육각형[2][0]}</P>
            <H2 customCss={`color: ${mainTheme.primary}; font-size: ${bigHexagonLargeCharactorSize};`}>{컨텐츠.육각형[2][1]}</H2>
            <MdArrowForward size={30} color={mainTheme.primary} style={{marginTop: 10}}/>
        </BigHexagonBackgroundComponentRoot>
    ), [])

    const rightBigHexagonBackgroundComponent = useMemo(() => (
        <BigHexagonBackgroundComponentRoot column center full>
            <Img gatsbyImageSrc={images.drug_design} />
            <P customCss={`color: ${mainTheme.primary}; margin-top: 10px;`}>{컨텐츠.육각형[5][0]}</P>
            <H2 customCss={`color: ${mainTheme.primary}; font-size: ${bigHexagonLargeCharactorSize};`}>{컨텐츠.육각형[5][1]}</H2>
            <MdArrowForward size={30} color={mainTheme.primary} style={{marginTop: 10}}/>
        </BigHexagonBackgroundComponentRoot>
    ), [])

    const smallHexagonLineLength = 50
    const smallHexagonWidth = smallHexagonLineLength * 2
    const smallHexagonHeight = smallHexagonLineLength * SQRT3
    const bigHexagonLineLength = smallHexagonLineLength * 2.7
    const bigHexagonWidth = bigHexagonLineLength * 2
    const bigHexagonHeight = bigHexagonLineLength * SQRT3

    // how many push down right HexagonField
    const rightDown = bigHexagonHeight / 2 + 40
    // how many push left right HexagonField
    const rightRight = bigHexagonWidth * 3/5
    const gap = 10;
    const hexagonConfigs = [
        // left small top
        {
            top: (bigHexagonHeight / 2) - (gap / 2) - smallHexagonHeight,
            left: 0,
            buttonProps: {
                color: mainTheme.secondary,
                size: smallHexagonLineLength,
                direction: "horizontal",
                config: {
                    componentNotHovered: <P>{컨텐츠.육각형[0][0]}</P>,
                    componentHovered: <P customCss={`color: white;`}>{컨텐츠.육각형[0][1]}</P>,
                },
                to: `${urlPrefix}${컨텐츠.육각형[0][2]}`,
                innerLinkOption: { replace: true },
                activeFromOutside: inViews[1],
                onClick() {
                    document.getElementById(컨텐츠.육각형[0][2]).scrollIntoView()
                }
            },
        },
        // left small bottom
        {
            top: (bigHexagonHeight / 2) + (gap / 2),
            left: 0,
            buttonProps: {
                color: mainTheme.secondary,
                size: smallHexagonLineLength,
                direction: "horizontal",
                config: {
                    componentNotHovered: <P>{컨텐츠.육각형[1][0]}</P>,
                    componentHovered: <P customCss={`color: white;`}>{컨텐츠.육각형[1][1]}</P>,
                },
                to: `${urlPrefix}${컨텐츠.육각형[1][2]}`,
                innerLinkOption: { replace: true },
                activeFromOutside: inViews[2],
                onClick() {
                    document.getElementById(컨텐츠.육각형[1][2]).scrollIntoView()
                }
            }
        },
        // left big
        {
            top: 0,
            left: (smallHexagonLineLength * 1.5) + gap,
            buttonProps: {
                color: mainTheme.secondary,
                size: bigHexagonLineLength,
                direction: "horizontal",
                config: {
                    backgroundComponent: leftBigHexagonBackgroundComponent,
                    backgroundColorHovered: "transparent",
                },
                to: `${urlPrefix}${컨텐츠.육각형[2][2]}`,
                innerLinkOption: { replace: true },
                activeFromOutside: inViews[0],
                onClick() {
                    document.getElementById(컨텐츠.육각형[2][2]).scrollIntoView()
                }
            }
        },
        // right small top
        {
            top: rightDown + (bigHexagonHeight / 2) - (gap / 2) - smallHexagonHeight,
            right: 0,
            buttonProps: {
                color: mainTheme.secondary,
                size: smallHexagonLineLength,
                direction: "horizontal",
                config: {
                    componentNotHovered: <P>{컨텐츠.육각형[3][0]}</P>,
                    componentHovered: <P customCss={`color: white;`}>{컨텐츠.육각형[3][1]}</P>,
                },
                to: `${urlPrefix}${컨텐츠.육각형[3][2]}`,
                innerLinkOption: { replace: true },
                activeFromOutside: inViews[3],
                onClick() {
                    document.getElementById(컨텐츠.육각형[3][2]).scrollIntoView()
                }
            }
        },
        // right small bottom
        {
            top: rightDown + (bigHexagonHeight / 2) + (gap / 2),
            right: 0,
            buttonProps: {
                color: mainTheme.secondary,
                size: smallHexagonLineLength,
                direction: "horizontal",
                config: {
                    componentNotHovered: <P>{컨텐츠.육각형[4][0]}</P>,
                    componentHovered: <P customCss={`color: white;`}>{컨텐츠.육각형[4][1]}</P>,
                },
                to: `${urlPrefix}${컨텐츠.육각형[4][2]}`,
                innerLinkOption: { replace: true },
                activeFromOutside: inViews[4],
                onClick() {
                    document.getElementById(컨텐츠.육각형[4][2]).scrollIntoView()
                }
            }
        },
        // right big
        {
            top: rightDown,
            right: (smallHexagonLineLength * 1.5) + gap,
            buttonProps: {
                color: mainTheme.secondary,
                size: bigHexagonLineLength,
                direction: "horizontal",
                config: {
                    backgroundComponent: rightBigHexagonBackgroundComponent,
                    backgroundColorHovered: "transparent",
                },
                to: `${urlPrefix}${컨텐츠.육각형[5][2]}`,
                innerLinkOption: { replace: true },
                activeFromOutside: inViews[0],
                onClick() {
                    document.getElementById(컨텐츠.육각형[5][2]).scrollIntoView()
                }
            }
        },
    ]
    
    // HexagonFeld size
    const width = ((smallHexagonLineLength * 1.5) + gap + bigHexagonWidth) * 2 - rightRight 
    const height = bigHexagonHeight + rightDown

    return <HexagonTocRoot customCss={customCss}>
        <HexagonField width={width} height={height} hexagonConfigs={hexagonConfigs} />
    </HexagonTocRoot>
}
