import React, { useState } from 'react'
import styled from 'styled-components'
import Flex, {  } from 'styled-flex-component'
import { H2, U, PublicationCard as Card, Button } from "src/components"  
import { BreakLine, useLanguage } from "src/components/utils"  
import useContents from "src/data"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

const PublicationRoot = styled.div`
    ${props => props.theme.css.full}
    position: relative;

    ${H2} {
        text-align: center;
        padding: 1em 0;
        font-size: 1.5em;
        font-weight: 800;
    }
`
const LayoutContainer = styled.div`
    ${props => props.theme.css.center()}
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    box-sizing: border-box;
    padding: 40px 1em 0;
`

const Carousel = styled(Flex)`
    position: relative;
    // card*3 (900) + margin*4 (40) + halfcard*2 (300)
    width: 1240px;
    min-height: 260px;
    margin: auto;
    overflow-x: hidden;
`
const Cards = styled(Flex)`
    transition: 0.2s;
    transform: translateX(${props => -props.index*310 + 160}px);
`
const CarouselButton = styled.div`
    ${props => {
        if (props.left) return "left: 50px"
        if (props.right) return "right: 50px"
    }};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 50px;
    height: 50px;
    border: 1px solid aquamarine;
    border-radius: 100%;
    background: white;
    box-shadow: 0 2px 5px gray;
    top: 50%;
    transform: translateY(-25px);
    cursor: pointer;
`

export default function Publication() {
    const { images, 컨텐츠 } = useContents("Home_Publication")
    const language = useLanguage()
    const [carouselIndex, setCarouselIndex] = useState(0)

    // paper category
    const paperCategoryRender = <H2><U>{`PAPER&PATENT`}</U></H2>


    // paper cards
    const paperCardsRender = <Carousel alignCenter>
        <Cards index={carouselIndex}>
            {컨텐츠[0].map((pub, index) => {
                const { title, label, href } = pub
                const gatsbyImageSrc = images[`publication_paperPatent_${index}`]
                const opacity = (index < carouselIndex) || (index >= carouselIndex+3) ? 0.3 : 1

                return <Flex
                key={index}
                column
                center
                style={{ opacity, marginTop:20, marginRight:10 }}>
                    <Card
                    card
                    label={label}
                    title={title}
                    href={href}
                    gatsbyImageSrc={gatsbyImageSrc} />
                </Flex>
            })}
        </Cards>

        <CarouselButton center left onClick={e => setCarouselIndex(i => Math.max(0, i-1))}>
            <FaChevronLeft />
        </CarouselButton>

        <CarouselButton center right onClick={e => setCarouselIndex(i => Math.min(컨텐츠[0].length-3, i+1))}>
            <FaChevronRight />
        </CarouselButton>
    </Carousel>


    // contest category
    const contestCategoryRender = <H2><U>{`AWARD`}</U></H2>


    // award cards
    const contestCardsRender = <Cards>
        <div style={{flex: 1}}/>
        {컨텐츠[1].map((pub, index) => {
            const { title, href, label } = pub
            const gatsbyImageSrc = images[`publication_award_${index}`]

            return <Card
            key={index}
            card
            label={label}
            title={title}
            href={href}
            gatsbyImageSrc={gatsbyImageSrc} />
        })}
        <div style={{flex: 1}}/>
    </Cards>


    // render
    const render = <LayoutContainer>
        {paperCategoryRender}
        {paperCardsRender}
        <Button text={language==="ko" ? "Publication 한번에 보기" : "ALL PUBLICATIONS"} to={`/${language}/publication`} customCss={`margin-top: 30px;`}/>

        <BreakLine paddingTop={50} paddingBottom={20} />

        {contestCategoryRender}
        {contestCardsRender}
    </LayoutContainer>


    return <PublicationRoot>
        {render}
    </PublicationRoot>
}
