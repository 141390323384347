import React, {  } from 'react'
import { Link } from "gatsby"
import styled, {  } from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { P, A, Img } from 'src/components';
import { useLanguage } from 'src/components/utils';


const UpdatesCardRoot = styled.div`
    border-radius: 20px;
`

export default function UpdatesMobileCard({ imgSrc, title, date, content, category, href, to }) {
    const language = useLanguage()

    // `to` is for inner-link. `href` is for outer-link
    const innerLinkProps = { as: Link, to }
    const outerLinkProps = { as: OutboundLink, href, target: "_blank" }
    const linkProps = to 
        ? innerLinkProps 
        : href
            ? outerLinkProps
            : {}

    // category
    const categoryRender = <P size={12} weight="bold" align="right">{category}</P>

    // title
    const cardTextRender = <P size={20} weight={600} color="white" customCss={`position: absolute; left: 0; bottom: 0; padding: 10px;`}>
        {title}
    </P>

    // image
    const imageRender = <Img
    {...linkProps}
    imgSrc={imgSrc}
    height={170}
    gradation
    rounded={10}
    border={2} 
    textRender={cardTextRender}
    customCss={`margin-top: 8px;`}/>


    // text
    const textRender =  <>
        <P size={12} lineLimit={3} marginTop={12} customCss={`padding: 0 10px;`}>
            {date}
        </P>
        <P size={12} lineLimit={3} marginTop={12} customCss={`padding: 0 10px;`}>
            {content}
        </P>
        <A {...linkProps} weight="bold" marginTop={12} customCss={`padding: 0 10px;`}>
            {language==="ko" ? "자세히 보기" : "Read more"}
        </A>
    </>

    return <UpdatesCardRoot>
        {categoryRender}
        {imageRender}
        {textRender}  
    </UpdatesCardRoot>
}