import { useStaticQuery, graphql } from "gatsby"
import { useLanguage } from "src/components/utils"

const text = {   
    ko: [
        // 육각형
        [
            [
                "DearTRANS",
                "Genome data\nAnalysis",
            ],
            [
                "WX",
                "Biomarker\nPrediction",
            ],
            [
                "AI-Powered",
                "Target Discovery",
            ],
            [
                "DearDTI",
                "Molecule\nSelection",
            ],
            [
                "MolEQ",
                "Lead\nOptimization",
            ],
            [
                "AI-Powered",
                "Drug Design",
            ],
        ],
        // 메인 문구
        [
            "인공지능 신약발굴 플랫폼",
            "Dr.UG",
            "Dr.UG는 유전체 데이터 분석, 바이오마커 예측, 후보물질 발굴 및 최적화에 특화된 디어젠의 딥러닝 기술 기반 AI 신약발굴 플랫폼입니다. \n디어젠은 Dr.UG를 통해 새로운 질병 타겟을 발굴하고, 새로운 신약 후보물질을 디자인함으로서 나아가 정밀의료시대를 실현합니다.",
        ],
    ],
    en: [
        // 육각형
        [
            [
                "DearTRANS",
                "Genome data\nAnalysis",
            ],
            [
                "WX",
                "Biomarker\nPrediction",
            ],
            [
                "AI-Powered",
                "Target Discovery",
            ],
            [
                "DearDTI",
                "Molecule\nSelection",
            ],
            [
                "MolEQ",
                "Lead\nOptimization",
            ],
            [
                "AI-Powered",
                "Drug Design",
            ],
        ],
        // 메인 문구
        [
            "The AI Platform For Drug Discovery",
            "Dr.UG",
            "Dr. UG is an AI platform for developing new drugs that is based on DEARGEN’s deep learning technology.\nIt specializes in genome data analysis, biomarker prediction, molecule selection and optimization.\nDEARGEN discovers new disease targets with Dr.UG and further realizes precision medicine by designing small molecules for new drugs.",
        ],
    ]
}

export default function useContent() {
    const language = useLanguage()
    const 컨텐츠 = text[language]

    const images = useStaticQuery(graphql`
        query {

            # desktop
            target_discovery: file(relativePath: { eq: "Home_OurTechnology/target_discovery.png" }) {
                childImageSharp {
                    fixed(width: 120, height: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            drug_design: file(relativePath: { eq: "Home_OurTechnology/drug_design.png" }) {
                childImageSharp {
                    fixed(width: 140, height: 90) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            # mobile
            target_discovery_mobile: file(relativePath: { eq: "Home_OurTechnology/target_discovery.png" }) {
                childImageSharp {
                    fixed(width: 80, height: 70) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            drug_design_mobile: file(relativePath: { eq: "Home_OurTechnology/drug_design.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return { images, 컨텐츠 }
}