import React, { useState, useEffect } from 'react'
import styled, { css } from "styled-components"
import Flex from "styled-flex-component"
import { ModalPortal, P } from "src/components"
import NavItem from "./_NavItem"

const sideDot = css`
  // side dot
  position: absolute;
  content: "";
  width: 4px; 
  height: 4px;
  border-radius: 10px;
  background: ${props => props.theme.primary};
`

const BurgerButtonRoot = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 24px;
    height: 2px;
    background: ${props => props.theme.primary};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 3px;

    // first bar
    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
      ::before {
        ${sideDot}
        right: -2px;
        top: -1px;
      }
    }

    // second bar
    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
      ::before {
        ${sideDot}
        left: -2px;
        top: -1px;
      }
    }

    // third bar
    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
      ::before {
        ${sideDot}
        right: -2px;
        top: -1px;
      }
    }
  }
`
const Burger = ({ open }) => {
    return <BurgerButtonRoot open={open}>
        <div />
        <div />
        <div />
    </BurgerButtonRoot>
}

const MobileNavigationRoot = styled(Flex)`
  background: ${props => props.theme.secondary};
`
const CopyrightP = styled(P)`
    position: absolute;
    font-size: 12px;
    font-weight: 900;
    color: white;
    bottom: 24px;
    left: 20px;
`

export default function BurgerButton({ navItemsConfig, onChange }) {
    const [open, setOpen] = useState(false);

    // notify whether open or not to outside
    useEffect(() => {
      onChange(open)
    }, [open])


    // render
    const render = <ModalPortal>
        <MobileNavigationRoot full column justifyCenter alignStart>
            {navItemsConfig.map((config, index) => <NavItem key={index} {...config}/> )}
        </MobileNavigationRoot>
        <CopyrightP>{`ⓒ 2019 DEARGEN Inc.`}</CopyrightP>
    </ModalPortal>
        
    
    return <BurgerButtonRoot
    open={open}
    onClick={e => setOpen(open => !open)}>
        <Burger />
        {open && render}       
    </BurgerButtonRoot>
}


