import React, { useMemo } from 'react'
import { navigate } from "gatsby"
import styled from 'styled-components'
import Flex, {  } from 'styled-flex-component'
import _ from "lodash"
import { ArrowButton, HexagonToc } from "src/components"
import { useIsDeviceWidth, useLanguage } from 'src/components/utils'
import useInViews from "src/components/Toc/useInViews"
import useContents from "src/data"
// sections
import { AboutDrugHeader, AboutDrugBody } from "./AboutDrug"
import TechnologySection from "./_TechnologySection"


const TechnologyDesktopRoot = styled(Flex)`
`

export default function TechnologySections({ location }) {
    const { images, 컨텐츠 } = useContents("Technology")
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })
    const language = useLanguage()


    // sections
    const components = useMemo(() => [
        <TechnologySection header={AboutDrugHeader}   body={AboutDrugBody} />,
        <TechnologySection header={컨텐츠.DearTrans[0]} body={컨텐츠.DearTrans[1]}  id="#deartrans" />,
        <TechnologySection header={컨텐츠.WX[0]}        body={컨텐츠.WX[1]}         id="#wx" />,
        <TechnologySection header={컨텐츠.DearDTI[0]}   body={컨텐츠.DearDTI[1]}    id="#deardti"/>,
        <TechnologySection header={컨텐츠.MOL_EQ[0]}    body={컨텐츠.MOL_EQ[1]}     id="#moleq" customCss={`padding-bottom: 200px`} />
    ], [컨텐츠])
    const [desktopRender, inViews, elements] = useInViews(components)


    // toc
    const tocRender = isSmallDevice
        ? null
        : <HexagonToc inViews={inViews} refs={elements} 컨텐츠={컨텐츠} images={images} customCss={`position: fixed; top: 30%; left: calc(150px + 40%);`} />


    // arrow button
    const arrowButtonRender = <>
        <ArrowButton position={{right: 40, bottom: 40}} direction="up" onClick={e => window.scroll({ top: 0, behavior: "smooth" })} />
        <ArrowButton position={{left: 30, top: 30}} direction="left" onClick={e => navigate(`/${language}/#technology`)} />
    </>


    // redirect if wrong url
    if (!["", "#deartrans", "#wx", "#deardti", "#moleq"].includes(location.hash)) {
        navigate(location.pathname, { replace: true })
    }


    return <TechnologyDesktopRoot>
        {/* left content */}
        <Flex column center style={{width: "50%", minWidth: 550}}>
            {desktopRender}
        </Flex>
        
        {tocRender}
        {arrowButtonRender}

    </TechnologyDesktopRoot>
}