import React, {  } from 'react'
import Flex, { FlexItem } from 'styled-flex-component';
import { Footer } from 'src/components';
import { useIsDeviceWidth } from 'src/components/utils';
import RecruitBoard from "./RecruitBoard"
import RecruitProcess from "./RecruitProcess"


export default function CareerSections() {
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })

    return <>
        <Flex column>
            <FlexItem order={isSmallDevice ? 3  : 1}>
                <RecruitBoard />
            </FlexItem>

            {/* <FlexItem order={2}>
                <RecruitProcess />
            </FlexItem>
             */}
        </Flex>
    </>
}