import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }
`


const SEO = ({ title, description, image, pathname, article, noindex=false }) => (
    <StaticQuery
        query={query}
        render={({
            site: {
                siteMetadata: {
                    defaultTitle,
                    titleTemplate,
                    defaultDescription,
                    siteUrl,
                    defaultImage,
                },
            },
        }) => {
            const seo = {
                title: title || defaultTitle,
                description: description || defaultDescription,
                image: `${siteUrl}${image || defaultImage}`,
                url: `${siteUrl}${pathname || ""}`,
            }

            return (
                <>
                    <Helmet title={seo.title} titleTemplate={titleTemplate}>
                        <meta name="description" content={seo.description} />
                        <meta name="image" content={seo.image} />
                        {seo.url && <meta property="og:url" content={seo.url} />}
                        {(article ? true : null) && (
                            <meta property="og:type" content="article" />
                        )}
                        {seo.title && <meta property="og:title" content={seo.title} />}
                        {seo.description && (
                            <meta property="og:description" content={seo.description} />
                        )}
                        {seo.image && <meta property="og:image" content={seo.image} />}
                        <meta name="twitter:card" content="summary_large_image" />
                        {seo.title && <meta name="twitter:title" content={seo.title} />}
                        {seo.description && (
                            <meta name="twitter:description" content={seo.description} />
                        )}
                        {seo.image && <meta name="twitter:image" content={seo.image} />}
                        {noindex && <meta name="robots" content="noindex" />}

                        {/* 다국어 지원 https://support.google.com/webmasters/answer/189077#language-codes */}
                        {/* TODO: hreflang 태그 제대로 적용하기. 현재 제대로 적용안됬다고 구글에서 메일왔음. 메일내용: 
                            받는사람: deargen.me 웹마스터님
                            귀하의 사이트에 있는 일부 페이지에서 rel-alternate-hreflang 태그가 잘못 구현된 것으로 확인되었습니다. 특히 잘못된 언어 및 지역 코드 또는 잘못된 양방향 연결(페이지 A가 hreflang을 통해 페이지 B에 연결되는 경우 B에서 A로 다시 연결되는 링크도 있어야 함)과 관련된 문제인 것 같습니다. Google은 hreflang 속성을 사용하여 검색결과에서 올바른 사용자에게 연결되는 적절한 언어 또는 지역 URL을 제공합니다.
                            hreflang 링크의 오류를 수정하기 전까지는 귀하의 웹사이트에서 추가 언어 또는 지역 타겟팅을 이용할 수 없습니다. */}
                        {pathname && <link rel="alternate" href={`https://deargen.me/${pathname}`.replace("/ko", "/en")} hreflang="en" />}
                        {pathname && <link rel="alternate" href={`https://deargen.me/${pathname}`.replace("/en", "/ko")} hreflang="ko" />}
                        {pathname && <link rel="alternate" href={`https://deargen.me/${pathname}`.replace("/ko", "/en")} hreflang="x-default" />}
                        
                    </Helmet>
                </>
            )
        }}
    />
)
export default SEO