import { useLanguage } from "src/components/utils"

const text = {   
    ko: [
        // map
        [
            `대전 본사`,
            `대전광역시 서구 청사로 136, 대전무역회관 18층 R1846호(월평동)`,
            `서울 연구소`,
            `서울특별시 서초구 마방로 60, 동원F&B 15층\n `,
            `iDear Center`,
            `경기도 화성시 동탄첨단산업1로 27, 금강펜테리움 IX타워 A동 11층\n `,
            `미국 지사`,
            `4185 SILVER PEAK PKWY, SUITE A, SUWANEE, GA, 30024, USA\n `,
        ],
        // footer
        [
            `CONTACT`,
            `contact@deargen.me`,
            `ⓒ 2021 DEARGEN Inc.`,
        ],
    ],    
    en: [
        // map
        [
            `Daejeon Office`,
            `R1846, 18F, 136, Cheongsa-ro, Seo-gu, \nDaejeon, Korea`,
            `Seoul Research Center`,
            `Deargen, Dongwon F&B, 60, Mabang-ro, \nSeocho-gu, Seoul, Korea`,
            `iDear Center`,
            `11F, 27, Dongtancheomdansaneop 1-ro, Hwaseong-si, Gyeonggi-do, Korea`,
            `U.S. Office`,
            `4185 SILVER PEAK PKWY, SUITE A, SUWANEE, GA, 30024, USA`,
        ],
        // footer
        [
            `CONTACT`,
            `contact@deargen.me`,
            `ⓒ 2021 DEARGEN Inc.`,
        ],
    ]
}


export default function useContent() {
    const language = useLanguage()
    const 컨텐츠 = text[language]
    return { 컨텐츠 }
}