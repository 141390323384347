import React from "react"
import styled from "styled-components"
import { createAnimation, useInView } from "src/components/utils"
import { mainTheme } from "src/GlobalStyle"

const U = styled.u`
    text-decoration: none;

    &::before {
        z-index: 0;
        background: ${props => props.underlineColor};
        width: ${props => props.noPlay 
            ? "100%" 
            : 0
        };
        content: "";
        position: absolute;
        height: 0.3em;
        border-radius: 0.2em;
        bottom: 0.2em;
        justify-self: center;
        animation: ${props => {
            if (!props.inView) return

            return props.noPlay 
                ? ""
                : createAnimation({
                    from: { width: "0" },
                    to: { width: "100%" },
                    speed: 0.7,
                    delay: props.delay,
                })
            }
        };
    }
    ${props => props.customCss};
`

export default function ({ children, color=mainTheme.secondary, delay=0.6, noPlay }) {  
    const [ref, inView] = useInView({ threshold: 1 })

    return (
        // pseudo element need parent element which css is not "position: static"
        <span ref={ref} css={`position: relative; z-index: 2;`}>
            <U underlineColor={color} delay={delay} noPlay={noPlay} inView={inView}>
                <span css={`position: relative; z-index: 1;`} >{children}</span>
            </U>
        </span>
    )
}