import { createGlobalStyle, css } from "styled-components"

export const mainTheme = {
    primary: "rgb(31, 77, 106)",
    primaryLight: "#315d78",
    secondary: "rgb(52, 225, 164)",
    secondaryLight: "#a6ffe4",
    shade: "#f1f3f5",
    navigationFontColor: "#aaa", // TODO: 색깔바꿔야됨 색깔안맞음
    // 0~576: mobile, 576~800: tablet  ...... 1600~else: big big desktop
    breakpoints: {
        mobile: 0,
        tablet: 576,
        desktop: 800,
        "big desktop": 1200,
        "big big desktop": 1600,
    },
    css: {
        full: `width: 100%; height: 100%;`,
        flexCenter(direction="row") {
            return css`
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: ${direction};
            `
        },
        center() {
            return css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            `
        },
        pseudo({ content="", position="absolute", customCss, ...props }) {
            return css`
                content: "${content}";
                position: ${position};
                ${Object.keys(props).map(key => 
                    `${key} : ${props[key]};` 
                )};
                ${customCss}
            `
        },
        // make inline-style css width javascript object syntax to remove wasted new line.
        makeCss({ customCss, ...props }) {
            return css`
                ${Object.keys(props).map(key => 
                    `${key} : ${props[key]};` 
                )};
                ${customCss}
            `
        }
    }
}

// reference: https://meyerweb.com/eric/tools/css/reset/
const defaultStyle = `
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
`

export const GlobalStyle = createGlobalStyle`
    ${defaultStyle}

    body {
        padding: 0;
        margin: 0;
        font-family: "Apple SD Gothic Neo", 'Noto Sans KR';
        overflow-x: hidden;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    li {
        list-style-type: none;
    }

    // scroll bar style
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background: rgb(52,225,164); 
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(32,205,144); 
    }
`