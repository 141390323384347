import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import styled from "styled-components"
import {  } from 'src/components/utils';
import Script from "react-load-script"

const GoogleMapRoot = styled.div`
    height: 300px;
    width: 100%;
    opacity: ${props => props.loaded ? 1 : 0};
    transition: all 1s;
`

// this component can be mounted more than once. so we should respond to that situation.
function GoogleMap({ where }, forwardedRef) {
    // the `styled-components` DO NOT support `useRef` so we use `useState` instead
    const [root, setRoot] = useState(null);
    const [loaded, setLoaded] = useState(false)
    

    // disable draggable feature in mobile enviroment
    const option = {
        "seoulOffice": {
            zoom: 17,
            center: {lat: 37.477194, lng: 127.043685},
            disableDefaultUI: true,
        },
        "kaist": {
            zoom: 16,
            center: {lat: 36.3594, lng: 127.379},
            disableDefaultUI: true,
        },
        "iDearCenter": {
            zoom: 16,
            center: {lat: 37.210221466767315, lng: 127.08943736484795},
            disableDefaultUI: true,
        },
        "usOffice": {
            zoom: 15,
            center: {lat: 34.043979510520145, lng: -83.96237394407301},
            disableDefaultUI: true,
        },

    }


    // attach marker
    const marker = useRef(null)
    const attachMarker = googleMapInstance => {
        if (!loaded) return

        const newMarker = new window.google.maps.Marker({
            position: option[where].center,
            map: googleMapInstance,
            animation: window.google.maps.Animation.DROP,
        })

        marker.current = newMarker
    }


    // attach map
    const [googleMapInstance, setGoogleMapInstance] = useState(null)
    useEffect(() => {
        if (!loaded) return

        // animate marker https://developers.google.com/maps/documentation/javascript/markers#animate
        const map = new window.google.maps.Map(root, option[where]);
        setGoogleMapInstance(map)
    }, [loaded])    


    function clearMarker() {
        if (!marker.current) return null
        marker.current.setMap(null)
    }


    // enable control animaation from outside
    const imperativeHandlers = {
        attachMarker() {
            attachMarker(googleMapInstance)
        },
        clearMarker,
    }
    useImperativeHandle(
        forwardedRef, 
        () => imperativeHandlers,
        [loaded, googleMapInstance]
    )


    // when user do wheeling on googlemap, the wheel event must not be propagated, otherwise slide will be changed unexpectedly.
    // <Carousel/> is using some libarary `react-swipeable`, which uses uses original native DOM-event, NOT React's synthetic-event.
    // so we should deal with native-DOM-eventlistner. 
    useEffect(() => {
        if (!root) return
        const handler1 = root.addEventListener("touchend", e => e.stopPropagation())   
        const handler2 = root.addEventListener("wheel", e => e.stopPropagation())   
        return () => {
            window.removeEventListener("resize", handler1)
            window.removeEventListener("resize", handler2)
        }
    }, [root])


    // handle script onLoad
    const onLoad = () => {
        setTimeout(() => {
            setLoaded(true)
        }, 300)
    }


    return <>
        <GoogleMapRoot
        ref={el => setRoot(el)}
        loaded={loaded}
        onWheel={e => e.stopPropagation()} />
        {console.log(process.env)}
        <Script
        // url={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`}
        url={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCJWEhKE5c5ksIvZqnnpmQKi4XS8FQ43_E`}
        type='text/javascript'
        onLoad={onLoad}
        async />
    </>
}
  
export default forwardRef(GoogleMap)