import React, {  } from 'react'
import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Flex, { FlexItem as _FlexItem } from "styled-flex-component"
import { DeargenLogo } from "src/components/utils"
import { P as _P } from "src/components"
import { FaMapMarkerAlt } from "react-icons/fa"
import {  } from "src/GlobalStyle"
import useContents from "src/data"

const FooterRoot = styled.footer`
    display: flex;
    padding: 20px 0;
    background: #1f4d6a;
`

const P = styled(_P)`
    color: #9ec7db;
    margin: 0 0 10px;
    ${props => props.customCss}
`

const FlexItem = styled(_FlexItem)`
    white-space: pre-wrap;
    ${props => props.customCss}
`

export default function Footer () {
    const { 컨텐츠 } = useContents("Home_Footer")

    return <FooterRoot>
        <FlexItem 
        customCss={css`
            margin-bottom: 20px; 
            padding: 0 20px;
            ${breakpoint("mobile", "desktop")`
                margin-left: 35px;
            `}
        `}>
            <DeargenLogo color="#5d8aa0"/>
        </FlexItem>

        <FlexItem>
            <Flex>
                <FlexItem customCss={`margin: 0 10px 0 30px;`}>
                    <FaMapMarkerAlt size={20} color={"#5d8aa0"} />
                </FlexItem>
                <FlexItem>
                    <P>{컨텐츠[0][0]}</P>
                    <P customCss={`color: #5d8aa0`}>{컨텐츠[0][1]}</P>
                    <P>{컨텐츠[0][2]}</P>
                </FlexItem>
            </Flex>
        </FlexItem>

        <FlexItem>
            <Flex>
                <FlexItem customCss={`margin-right: 10px; margin-left: 30px;`}>
                    <FaMapMarkerAlt size={20} color={"#5d8aa0"}/>
                </FlexItem>
                <FlexItem>
                    <P>{컨텐츠[0][3]}</P>
                    <P customCss={`color: #5d8aa0`}>{컨텐츠[0][4]}</P>
                </FlexItem>
            </Flex>
        </FlexItem>
        <FlexItem>
            <Flex>
                <FlexItem customCss={`margin-right: 10px; margin-left: 30px;`}>
                    <FaMapMarkerAlt size={20} color={"#5d8aa0"}/>
                </FlexItem>
                <FlexItem>
                    <P>{컨텐츠[0][5]}</P>
                    <P customCss={`color: #5d8aa0`}>{컨텐츠[0][6]}</P>
                </FlexItem>
            </Flex>
        </FlexItem>
        <FlexItem>
            <Flex>
                <FlexItem customCss={`margin-right: 10px; margin-left: 30px;`}>
                    <FaMapMarkerAlt size={20} color={"#5d8aa0"}/>
                </FlexItem>
                <FlexItem>
                    <P>{컨텐츠[0][7]}</P>
                    <P customCss={`color: #5d8aa0`}>{컨텐츠[0][8]}</P>
                </FlexItem>
            </Flex>
        </FlexItem>

    </FooterRoot>
}
