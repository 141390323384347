import { keyframes, css } from 'styled-components'
import isEmpty from "lodash/isEmpty"
import mapKeys from "lodash/mapKeys"

// imitate API of 'react-spring/useSpring' at https://www.react-spring.io/docs/hooks/use-spring
export function createAnimation({
    from={},
    to={},
    percentageKeyFrames,
    speed=0.3,
    delay=0,
    timingFunction="cubic-bezier(0,0,0,1)",
    reverse=false,
    loop=false,
    callback
}) {
    const animationKeyframes = createKeyframes({ from, to, percentageKeyFrames, reverse })
    if (callback) setTimeout(callback, (delay+speed) * 1000)
    
    return css`${animationKeyframes} ${speed}s ${timingFunction} ${delay}s ${loop && "infinite"} both;`
}

export function createKeyframes({
    from={},
    to={},
    percentageKeyFrames={},
    reverse=false,
}) {
    // In case of using `from` and `to` in keyframes
    if (isEmpty(percentageKeyFrames)) {
        if (reverse) {
            const temp = from;
            from = to;
            to = temp;
        }
        return keyframes`
            from {
                ${Object.keys(from).map(cssProperty => `${cssProperty} : ${from[cssProperty]}`).join(";")};
            }
            to {
                ${Object.keys(to).map(cssProperty => `${cssProperty} : ${to[cssProperty]}`).join(";")};
            }
        `
    }
    // In case of using a percentage notation in keyframes
    else {
        if (reverse) {
            percentageKeyFrames = mapKeys(percentageKeyFrames, (value, key) => 100-Number(key) )
        }
        return keyframes`
            ${Object.keys(percentageKeyFrames).map(percent => {
                const cssRule = percentageKeyFrames[percent]
                return `
                    ${percent}% {
                        ${Object.keys(cssRule).map(cssProperty => `${cssProperty} : ${cssRule[cssProperty]}`).join(";")}
                    }`
            })}
        `
    }
}

export function fadeIn({ mode="fromLeft", length="10%", speed=0.5, withBlur=false, callback, ...props }) {
    const x = {fromright: 0, fromBottom: 0, fromLeft: `-${length}`, fromRight: `${length}`, center: 0, plain: 0, }[mode];
    const y = {fromright: `-${length}`, fromBottom: `${length}`,  fromLeft: 0, fromRight: 0, center: 0, plain: 0, }[mode];
    const scale = {fromLeft: 1, fromRight: 1, fromright: 1, fromBottom: 1, center: 1.5, plain: 1 }[mode];
    const filter = withBlur ? "blur(12px)" : "none"

    return createAnimation({
        from: { 
            transform: `translate(${x}, ${y}) scale(${scale});`, 
            opacity: 0, 
            filter,
        },
        to: {
            transform: "translate(0,0) scale(1);",
            opacity: 1 
        },
        speed,
        callback,
        ...props,
    })
}

export function slideIn({ mode, speed=0.6, ...props }) {
    const
    startY = {fromBottom: "100vh", fromTop: "-100vh" }[mode],
    endY   = {fromBottom: 0, fromTop: 0, }[mode],
    startX = 0,
    endX   = 0;
    return createAnimation({
        from: { 
            transform: `translate(${startX}, ${startY})`, 
            opacity: 0 
        },
        to: {
            transform: `translate(${startX}, ${endY})`,
            opacity: 1 
        },
        speed,
        ...props
    })
}

export function bounce({ height=20, contractBeforeJump=true, speed=1, ...props }) {
    return createAnimation({
        percentageKeyFrames: {
            0: { transform: `translateY(0)` }, 
            20: { transform: `translateY(0) ${contractBeforeJump ? "scale(1.2, 0.8)" : ""}` }, 
            40: { transform: `translateY( -${height}px )` },
            50: { transform: `translateY(0)` }, 
            60: { transform: `translateY( -${height/2}px )` },
            80: { transform: `translateY(0)` }, 
            100: { transform: `translateY(0)` }, 
        },
        speed,
        ...props
    })
}

// see https://codepen.io/lbebber/pen/XJRdrV
export function tvTurnOff({ axis="x", speed=0.7, ...props }) {
    const scale_60 = { x: `1, 0.01`, y: `0.01, 1`}[axis]
    const scale_100 = { x: `0, 0.01`, y: `0.01, 0`}[axis]
    return createAnimation({
        percentageKeyFrames: {
            0: {
                opacity: 1,
                transform: "translate3d(0, 0, 0);",
            },
            60: {
                opacity: 1,
                transform: `scale(${scale_60}) translate3d(0,0,0);`,
            },
            100: {
                opacity: 1,
                "animation-timimg-function:": "cubic-bezier(0.755, 0.050, 0.855, 0.060)",
                transform: `scale(${scale_100}) translate3d(0,0,0);`,
            },
        },
        timingFunction: "cubic-bezier(0.230, 1.000, 0.320, 1.000)",
        speed,
        ...props,
    })  
}

// see https://codepen.io/anatravas/pen/qqbBeJ?editors=0110
export function flip({ mode="top", speed=0.8, ...props }) {
    const
    rotateX = { top: "180deg", left: "-180deg" }[mode];

    return createAnimation({
        from: {
            "transform": `rotateX(0)`,
        },
        to: {
            "transform": `rotateX(${rotateX}) scale(1)`,
        },
        speed,
        timingFunction: "cubic-bezier(0.680, -0.550, 0.265, 1.550)",
        ...props
    })
}

// see http://animista.net/play/attention/jello
export function jello({ mode="top", fadeIn=false, speed=0.9, ...props }) {
    return createAnimation({
        percentageKeyFrames: {
            0: {
                opacity: fadeIn ? 0 : 1,
                transform: `scale3d(1, 1, 1)`
            },
            30: {
                transform: `scale3d(1.25, 0.75, 1)`
            },
            40: {
                transform: `scale3d(0.75, 1.25, 1)`
            },
            50: {
                transform: `scale3d(1.15, 0.85, 1)`
            },
            65: {
                transform: `scale3d(0.95, 1.05, 1)`
            },
            75: {
                transform: `scale3d(1.05, 0.95, 1)`
            },
            100: {
                transform: `scale3d(1, 1, 1)`
            },
        },
        speed,
        ...props
    })
}
// see http://animista.net/play/text/focus-in/text-focus-in
export function blur({ speed=0.9, ...props }) {
    return createAnimation({
        from: {
            filter: "blur(12px)",
            opacity: 0,
        },
        to: {
            filter: "blur(0px)",
            opacity: 1,
        },
        speed,
        timingFunction: "cubic-bezier(0.550, 0.085, 0.680, 0.530)",
        ...props
    })
}

// see http://animista.net/play/text/tracking-in/tracking-in-contract
export function tracking({ speed=0.8, ...props }) {
    return createAnimation({
        percentageKeyFrames: {
            0: {
                "letter-spacing": "-0.5em",
                opacity: 0,
            },
            40: {
                opacity: 0.6,
            },
            100: {
                "letter-spacing": "normal",
                opacity: 1,
            },
        },
        speed,
        timingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)",
        ...props
    })
}

export function floating({ speed=2, timingFunction="linear", ...props }) {
    return createAnimation({
        percentageKeyFrames: {
            0: { transform: "translateY(0)" },
            50: { transform: "translateY(-20px)" },
            100: { transform: "translateY(0)" },
        },
        loop: true,
        speed,
        timingFunction,
        ...props
    })
}

// http://animista.net/play/text/shadow-drop/text-shadow-drop-bottom
export function textShadow({ speed=0.6, timingFunction="linear", ...props }) {
    return createAnimation({
        from: {
            "text-shadow": "0 0 0 rgba(0, 0, 0, 0)"
        },
        to: {
            "text-shadow": "0 6px 18px rgba(0, 0, 0, 0.35)"
        },
        speed,
        timingFunction,
        ...props
    })
}

