import React, {  } from 'react'
import Navigation from "./Navigation"
import {  } from 'src/components'
import { useIsDeviceWidth, useLanguage } from 'src/components/utils'


export default function NavigationIndex({ location }) {

    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800})
    const language = useLanguage()

    // hide navigation at some path
    if (!location) return null
    if (location.pathname.match(/[en|ko]\/technology/)) return null
    if (location.pathname.match(/[en|ko]\/updates\/.+/)) return null
    if (location.pathname.match(/[en|ko]\/notice\/.+/)) return null

    // navigation configs
    const technologySubMenuItems = [
        {
            text: "DearTrans",
            to: `/${language}/technology/#deartrans`
        },
        {
            text: "WX",
            to: `/${language}/technology/#wx`
        },
        {
            text: "DearDTI",
            to: `/${language}/technology/#deardti`
        },
        {
            text: "MolEQ",
            to: `/${language}/technology/#moleq`
        },
    ]
    const teamSubMenuItems = [
        {
            text: "Deargen",
            to: `/${language}/team/#deargen`
        },
        // {
        //     text: "Advisors",
        //     to: `/${language}/team/#advisors`
        // },
    ]
    const careerSubMenuItems = [
        {
            text: "Career",
            to: `/${language}/career`
        },
        {
            text: "Recruit",
            href: `https://deargen.oopy.io/d090bbbd-2cd3-4f17-8fda-41e770d6c22c`
        },
    ]
    const navigationConfig = [
        {text: "Technology",to: `/${language}/#technology`,  isActive: location.hash==="#technology", subMenuItems: technologySubMenuItems },
        {text: "Pipeline",      to: `/${language}/#pipeline`, isActive: location.hash==="#pipeline" },
        {text: "Publication",   to: `/${language}/#publication`, isActive: location.hash==="#publication" },
        {text: "Team",          to: `/${language}/#team`, isActive: location.hash==="#team", subMenuItems: teamSubMenuItems },
        {text: "Partners",      to: `/${language}/#partners`, isActive: location.hash==="#partners" },
        {text: "Contact",       to: `/${language}/#contact`, isActive: location.hash==="#contact" },
        {text: "Updates",       to: `/${language}/updates/`, fontWeight: 900 },
        {text: "Notice",        to: `/${language}/notice/`, fontWeight: 900 },
        {text: "Career",        to: `/${language}/career/`, fontWeight: 900, subMenuItems: careerSubMenuItems },
    ] 

    const mobileNavigationConfig = [
        {text: "Home",              to: `/${language}`,                              isActive: location.pathname === `/${language}` },
        {text: "Target Discovery",  to: `/${language}/technology/target_discovery/`, isActive: location.pathname.includes(`/technology/target_discovery`), mobileSubtitle: "Technology"},
        {text: "Drug Design",       to: `/${language}/technology/drug_design/`,      isActive: location.pathname.includes(`/technology/drug_design`), mobileSubtitle: "Technology"},
        {text: "Pipeline",          to: `/${language}/pipeline/`,                    isActive: location.pathname.includes(`/${language}/pipeline`) },
        {text: "Publications",      to: `/${language}/publication/`,                 isActive: location.pathname.includes(`/${language}/publication`) },
        {text: "Team",              to: `/${language}/team/`,                        isActive: location.pathname.includes(`/${language}/team`) },
        {text: "Updates",           to: `/${language}/updates/`,                     isActive: location.pathname.includes(`/${language}/updates`) },
        {text: "Notice",            to: `/${language}/notice/`,                      isActive: location.pathname.includes(`/${language}/notice`) },
        {text: "Career",            to: `/${language}/career/`,                      isActive: location.pathname.includes(`/${language}/career`) },
    ] 

    // render
    const render = <Navigation
    config={ isSmallDevice ? mobileNavigationConfig : navigationConfig } 
    location={location} />

    return <>
        {render}
    </>
}
