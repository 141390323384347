import _ from "lodash"
import fp from "lodash/fp"
import { useStaticQuery, graphql } from "gatsby"
import striptags from "striptags"
import { useLanguage } from "src/components/utils"


export default function useContent() {
    const language = useLanguage()

    const { noticePosts } = useStaticQuery(graphql`
        query getNoticePosts {
            noticePosts: allWordpressPost(filter: {tags: {elemMatch: {name: {eq: "@Notice"}}}}, sort: {fields: date, order: DESC}) {
                edges {
                    node {
                        ...article
                    }
                }
            }
        }
        fragment article on wordpress__POST {
            slug
            title
            excerpt
            date(formatString: "YYYY.MM.DD")
            category: categories {
                name
                description
            }
            tags {
                name
            }
            imgSrc: jetpack_featured_media_url
        }
    `)
    
    const posts = fp.flow(
        fp.map("node"),
        // flatten tags
        fp.map(post => ({ 
            ...post,
            tags: post.tags.map(tag => tag.name)
        })),
        // filter by language
        fp.filter(post => post.tags.some(fp.includes(language))),
        // strip HTML tag in excerpt
        fp.map(post => ({
            ...post,
            excerpt: striptags(post.excerpt)
        })),
        // flatten category
        fp.map(post => ({ 
            ...post,
            category: post.category[0].name
        })),
        // add `to` property using slug
        fp.map(post => ({
            ...post,
            to: `/${language}/notice/${post.slug}/`
        }))
    )(noticePosts.edges)

    const categories = ["NOTICE"]
    return { categories, posts }
}