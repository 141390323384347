import React, {  } from 'react'
import { Link } from "gatsby"
import styled, {  } from "styled-components"
import Flex, { FlexItem } from "styled-flex-component"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Img, H1, P, Button } from 'src/components';
import {  } from 'src/components/utils';
import { mainTheme } from 'src/GlobalStyle'

const ImageWrapper = styled(Flex)`
    height: 100%;
    position: relative;
    cursor: pointer;

    ${/* `...` text when hovered */ ""}
    ::after {
        content: "...";
        font-size: 2em;
        ${props => props.theme.css.center()}
        color: white;
        transition: all 0.2s;
        opacity: 0;
    }

    :hover {
        ::after {
            opacity: 1;
        }
    }
`
const UpdatesCardRoot = styled.article`
    display: flex;
    flex-direction: ${props => props.isHeadArticle ? "row" : "column"};
    border-radois: 20px;
`


export default function UpdatesDesktopCard({ imgSrc, category, date, title, content, href, to, buttonProps, isHeadArticle=false }) {
    // `to` is for inner-link. `href` is for outer-link
    const innerLinkProps = { as: Link, to }
    const outerLinkProps = { as: OutboundLink, href, target: "_blank" }
    const linkProps = to 
        ? innerLinkProps 
        : href
            ? outerLinkProps
            : {}

    // image
    const image = <ImageWrapper center>
        <Img
        imgSrc={imgSrc}
        {...linkProps}
        height={isHeadArticle ? 300 : 200}
        width={isHeadArticle ? 300 : undefined}
        rounded={8}
        border={2}
        customCss={`
            transition: all 0.2s;
            &:hover {
                filter: blur(5px) brightness(40%);
            }`
        }/>
    </ImageWrapper>

    const imageRender = isHeadArticle
        ? <FlexItem style={{ padding: "0 30px" }}>
            {image}
        </FlexItem>
        : image


    // text
    const textRender = <Flex column justifyCenter>
        <P
        size={13}
        weight={900}
        marginTop={10}
        color={isHeadArticle ? "secondary" : "primary"}
        customCss={`
            width: fit-content;
            padding: 5px;
            border: 1px solid ${isHeadArticle ? "transparent" : mainTheme.primary};
            border-radius: 10px;
        `}>
            {category}
        </P>
        <P marginTop={10}>
            {date}
        </P>

        <H1
        {...linkProps}
        size={isHeadArticle ? 28 : 22}
        marginTop={16}
        weight={700}
        hoverColor="secondary"
        lineLimit={3}
        customCss={`cursor: pointer;`}>
            {title}
        </H1>

        <P size={14} lineLimit={3} marginTop={10}>
            {content}
        </P>
        
        {buttonProps && <Button {...buttonProps} customCss={`margin-top: 20px;`}/>}
    </Flex>

    return <UpdatesCardRoot isHeadArticle={isHeadArticle}>
        {imageRender}
        {textRender}
    </UpdatesCardRoot>
}