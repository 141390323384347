import React, {  } from 'react'
import styled, {  } from "styled-components"
import Flex, { FlexItem as _FlexItem } from "styled-flex-component"
import breakpoint from "styled-components-breakpoint"
import { P as _P, Strong } from "src/components"
import { CapsuleText, useLanguage } from "src/components/utils"
import {  } from "src/GlobalStyle"
import LanguageButton from "./_LanguageButton"
import useContents from "src/data"

const MobileFooterRoot = styled.footer`
    ${props => props.theme.css.full}
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    
    position: relative;
    background: #005284;
    height: 100vh;
    
    ${breakpoint("mobile", "desktop")`
        box-sizing: border-box;
        padding: 0 20px;
    `}
    
    ${props => props.customCss};
`

const P = styled(_P)`
    color: white;
    font-size: 16px;
    font-weight: 300;
    ${props => props.customCss}

    ${Strong} {
        font-weight: 600;
    }
`

const FlexItem = styled(_FlexItem)`
    white-space: pre;
    ${props => props.customCss}
`

const CopyrightP = styled(P)`
    font-size: 12px;
    font-weight: 900;
    color: white;
`

export default function MobileFooter ({ location }) {
    const { 컨텐츠 } = useContents("Home_Footer")
    const language = useLanguage()

    return <>
        {/* <FooterNavigation center full>
            <DeargenLogo />
            <BurgerButton />
        </FooterNavigation> */}

        <MobileFooterRoot column full justifyBetween >
            
            <Flex column>
                <FlexItem style={{marginTop: 40}}>
                    <CapsuleText
                    textColor="white"
                    leftColor="white">
                        {`Contact`}
                    </CapsuleText>
                </FlexItem>

                <Flex style={{marginTop: 40}}>
                    <FlexItem style={{width: 100}}>
                        <P>{language==="ko" ? "이메일" : "Email"}</P>
                    </FlexItem>
                    <FlexItem>
                        <P as="address">{컨텐츠[1][0]}</P>
                    </FlexItem>
                </Flex>

                <Flex style={{marginTop: 10}}>
                    <FlexItem style={{width: 100}}>
                        <P>{language==="ko" ? "전화번호" : "Phone"}</P>
                    </FlexItem>
                    <FlexItem>
                        <P as="address">{컨텐츠[1][1]}</P>
                    </FlexItem>
                </Flex>

                <Flex style={{marginTop: 10}}>
                    <FlexItem style={{width: 100}}>
                        <P>{language==="ko" ? "FAX" : "FAX"}</P>
                    </FlexItem>
                    <FlexItem>
                        <P as="address">{컨텐츠[1][2]}</P>
                    </FlexItem>
                </Flex>

                <Flex style={{marginTop: 10}}>
                    <FlexItem>
                        <P customCss={`width: 100px;`}>{language==="ko" ? "주소" : "Address"}</P>
                    </FlexItem>
                    <FlexItem>
                        <P><Strong>{컨텐츠[1][3]}</Strong></P>
                        <P>{컨텐츠[1][4]}</P>
                        <P customCss={`margin-top: 20px;`}><Strong>{컨텐츠[1][5]}</Strong></P>
                        <P>{컨텐츠[1][6]}</P>
                        <P customCss={`margin-top: 20px;`}><Strong>{컨텐츠[1][7]}</Strong></P>
                        <P>{컨텐츠[1][8]}</P>
                        <P customCss={`margin-top: 20px;`}><Strong>{컨텐츠[1][9]}</Strong></P>
                        <P>{컨텐츠[1][10]}</P>
                    </FlexItem>
                </Flex>
            </Flex>

            <FlexItem style={{marginBottom: 40}}>
                <LanguageButton location={location} />
                <CopyrightP customCss={`margin-top: 10px; padding: 0;`}>{컨텐츠[1][11]}</CopyrightP>
            </FlexItem>

        </MobileFooterRoot>
    </>
}
