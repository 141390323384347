import React, {  } from 'react'
import {  } from "src/components"
import {  } from 'src/components/utils'
// sections
import Section1 from "./Career"


export default function CareerSection() {


    return <>
        <Section1 />
    </>
}