import _ from "lodash"
import Career from "./Career"
import CareerDetail from "./CareerDetail"
import Technology from "./Technology"
import Team from "./Team"
import Home_Slogan from "./Home_Slogan"
import Home_OurTechnology from "./Home_OurTechnology"
import Home_OurWork from "./Home_OurWork"
import Home_Pipeline from "./Home_Pipeline"
import Home_Publication from "./Home_Publication"
import Home_Team from "./Home_Team"
import Home_Partners from "./Home_Partners"
import Home_Contact from "./Home_Contact"
import Home_Footer from "./Home_Footer"
import UnderConstruction from "./UnderConstruction"
import Updates from "./Updates"
import Notice from "./Notice"
import Pipeline from "./Pipeline"


const dataNameToUseContent = {
    Career,
    CareerDetail,
    Technology,
    Team,
    Home_Slogan,
    Home_OurTechnology,
    Home_OurWork,
    Home_Pipeline,
    Home_Publication,
    Home_Team,
    Home_Partners,
    Home_Contact,
    Home_Footer,
    UnderConstruction,
    Updates,
    Notice,
    Pipeline,
}


export default function useContents(dataName) {
    if (!(dataName in dataNameToUseContent)) {
        throw Error(`There is no contents '${dataName}'`)
    }
    const useContent = dataNameToUseContent[dataName]
    const data = useContent()
    
    if (_.has(data, "images")) {
        data.images = _.mapValues(
            data.images,
            object => {
                if (_.has(object, "childImageSharp.fluid")) {
                    return { fluid: _.get(object, "childImageSharp.fluid") }
                }
                if (_.has(object, "childImageSharp.fixed")) {
                    return { fixed: _.get(object, "childImageSharp.fixed") }
                }
            }
        )
    }

    return data
} 