import React, {  } from 'react'
import styled from "styled-components"
import { HexagonButton } from 'src/components'

const HexagonFieldRoot = styled.div`
    position: relative;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    ${props => props.customCss}
`
const HexagonButtonWrapper = styled.div`
    position: absolute;
    top: ${props => props.top}px;
    bottom: ${props => props.bottom}px;
    left: ${props => props.left}px;
    right: ${props => props.right}px;
`

export default function HexagonField({ width, height, hexagonConfigs=[], customCss }) {

    const buttonsRender = hexagonConfigs.map((config, index) => {
        const { top, bottom, left, right, buttonProps } = config

        return <HexagonButtonWrapper key={index} top={top} bottom={bottom} left={left} right={right}>
            <HexagonButton {...buttonProps} />
        </HexagonButtonWrapper>
    })


    return <HexagonFieldRoot width={width} height={height} customCss={customCss}>
        {buttonsRender}
    </HexagonFieldRoot>
    
}
