import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import styled from "styled-components"
import { useLanguage } from 'src/components/utils'

const FooterLanguageButtonRoot = styled.div`
    display: flex;
    width: 100px;
    height: 35px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.css.primary};
`
const Part = styled.div`
    flex: 1;
    position: relative;
    background: ${props => props.selected ? props.theme.primary : props.theme.shade};
    border-radius: 4px;
    color: ${props => props.selected ? props.theme.shade : props.theme.primary};
    text-align: center;
`
const Center = styled.div`
    ${props => props.theme.css.center}
`

export default function FooterLanguageButton({ location }) {
    const language = useLanguage()
    const [selected, setSelected] = useState(language==="ko" ? "left" : "right")

    useEffect(() => {
        setSelected(language==="ko" ? "left" : "right")
    }, [language])


    return <FooterLanguageButtonRoot>
        <Part
        selected={selected==="left"}
        onClick={e => {
            setSelected("left")
            // english to korean
            if (language!=="ko") {
                const { pathname, hash } = location
                const to = `${pathname==="/" ? "/ko" : ""}${pathname.replace("/en", "/ko")}${hash}`
                navigate(to)
            }
        }}>
            <Center>
                {`KOR`}
            </Center>
        </Part>
        <Part
        selected={selected==="right"}
        onClick={e => {
            setSelected("right")

            // korean to english
            if (language!=="en") {
                const { pathname, hash } = location
                const to = `${pathname==="/" ? "/en" : ""}${pathname.replace("/ko", "/en")}${hash}`
                navigate(to)
            }
        }}>
            <Center>
                {`EN`}
            </Center>
        </Part>
    </FooterLanguageButtonRoot>
}
