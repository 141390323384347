import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import styled from 'styled-components'
import Flex, {  } from 'styled-flex-component'
import {  } from "src/components"  
import {  } from "src/components/utils"  


const CarouselRoot = styled.div`
    ${props => props.theme.css.full}
    width: ${props => props.width 
        ? Number.isInteger(props.width)
            ? `${props.width}px`
            : props.width
        : ``
    };
    height: ${props => props.height 
        ? Number.isInteger(props.height)
            ? `${props.height}px`
            : props.height
        : ``
    };

    position: relative;
    overflow: hidden; 

    ${props => props.customCss}
`
const Items = styled(Flex)`
    position: relative;
    ${props => props.theme.css.full}
    transition: 0.2s;
`
const Item = styled(Flex)`
    position: absolute;
    margin: auto;
`

// reference https://github.com/amio/re-carousel/blob/master/src/carousel.js
function Carousel({
    axis="x",
    width,
    height,
    current=0,
    onStart=function(){},
    onEnd=function(){},
    disableSwipe=false,
    duration=600,
    loop=false,
    minMove=50,
    gap=0,
    customCss,
    children=[],
}) {

    // siblings 
    const [siblings, setSiblings] = useState({
        prev:       current ? current-1 : -1,
        current:    current ? current   : 0,
        next:       current ? current+1 : 1,
    })

    function getSiblingFrames({ prev, current, next }) {
        return [
            refs.current[prev],
            refs.current[current],
            refs.current[next]
        ]
    }

    
    // get all ref of items
    const refs = useRef(Array(children.length).fill(null))
    const itemsRender = useMemo(() => {
        // in case that just one or none children is passed
        if (!Array.isArray(children)) children = [children]

        const wrappedChildren = children.map((child, index) => {
            return <Item
            key={index}
            full
            center
            ref={el => {
                if (!el) return

                // set ref
                refs.current[index] = el

                // init position
                switch (axis) {
                    case "x":
                        if      (index < siblings.current) el.style.left = "-100vw" // to left
                        else if (index > siblings.current) el.style.left = "100vw" // to right
                        break
                    case "y":
                        if      (index < siblings.current) el.style.top = "-100vh" // to up
                        else if (index > siblings.current) el.style.top = "100vh" // to down
                        break
                }
            }}>
                {child}
            </Item>
        })

        return <Items center>
            {wrappedChildren}
        </Items>
    }, [children])


    // frame
    const [root, setRoot] = useState(null)
    const [rootWidth, setrootWidth] = useState(0)
    const [rootHeight, setRootHeight] = useState(0)


    // handle resize
    const updateRootSize = useCallback(() => {
        if (!root) return
        let { width, height } = window.getComputedStyle(root)

        // the result of getComputedStyle is string like '1234px', not a number like 1234
        width = Number(width.slice(0, width.length-2))
        height = Number(height.slice(0, height.length-2))

        setrootWidth(width)
        setRootHeight(height)
    }, [root])

    useEffect(() => {
        updateRootSize()
        const handler = e => { updateRootSize() }
        
        window.addEventListener("resize", handler)
        return () => {
            window.removeEventListener("resize", handler)
        }
    }, [updateRootSize])

    useEffect(() => {
        prepareSiblingFrames()
    }, [rootWidth, rootHeight])


    // place sibling frames to correct place before moving
    function prepareSiblingFrames(duration=0) {
        const [prevFrame, currentFrame, nextFrame] = getSiblingFrames(siblings)

        translateXY(currentFrame, 0, 0, duration)
        if (axis === 'x') {
            translateXY(prevFrame, -rootWidth - gap, 0)
            translateXY(nextFrame, rootWidth + gap, 0)
        }
        else if(axis === 'y') {
            translateXY(prevFrame, 0, -rootHeight - gap)
            translateXY(nextFrame, 0, rootHeight + gap)
        }
    }


    // move frames
    function translateXY(el, x, y, duration=0) {        
        if (!el) return
        
        // animation
        el.style.transitionDuration = duration + 'ms'
        el.style.webkitTransitionDuration = duration + 'ms'
        
        el.style.left = `${x}px`
        el.style.top = `${y}px`
    }

    function slideTo(to) {
        prepareSiblingFrames()
        const [prevFrame, currentFrame, nextFrame] = getSiblingFrames(siblings)
        
        switch (to) {
            case 'up':
                translateXY(currentFrame, 0, -rootHeight - gap, duration)
                translateXY(nextFrame, 0, 0, duration)
                break
            case 'down':
                translateXY(currentFrame, 0, rootHeight + gap, duration)
                translateXY(prevFrame, 0, 0, duration)
                break
            case 'left':
                translateXY(currentFrame, -rootWidth - gap, 0, duration)
                translateXY(nextFrame, 0, 0, duration)
                break
            case 'right':
                translateXY(currentFrame, rootWidth + gap, 0, duration)
                translateXY(prevFrame, 0, 0, duration)
                break
            default: // back to origin
                prepareSiblingFrames(duration)
                return
        }
    }

    function toNext() {
        const isCurrentLast = siblings.current === refs.current.length-1

        if (isCurrentLast) return slideTo("origin")
        else if (axis==="x") slideTo("left")
        else if (axis==="y") slideTo("up")

        const isNextLast = siblings.next === refs.current.length-1

        setSiblings({
            prev: siblings.next - 1,
            current: siblings.next,
            next: isNextLast ? null : siblings.next + 1,
        })
    }

    function toPrev() {
        const isCurrentFirst = siblings.current === 0

        if (isCurrentFirst) return slideTo("origin")
        if      (axis==="x") slideTo("right")
        else if (axis==="y") slideTo("down")

        const isPrevFirst = siblings.prev === 0

        setSiblings({
            prev: isPrevFirst ? null : siblings.prev-1,
            current: siblings.prev,
            next: siblings.prev + 1,
        })
    }


    // user interaction
    const [touch, setTouch] = useState(false)
    const [startX, setStartX] = useState(null)
    const [startY, setStartY] = useState(null)
    const [deltaX, setDeltaX] = useState(0)
    const [deltaY, setDeltaY] = useState(0)

    function onTouchStart (e) {
        if (refs.current.length < 2) return
        prepareSiblingFrames()
    
        const { pageX, pageY } = (e.touches && e.touches[0]) || e
        setStartX(pageX)
        setStartY(pageY)
        setDeltaX(0)
        setDeltaY(0)

        setTouch(true)
    }

    function onTouchMove(e) {
        if (e.touches && e.touches.length > 1) return
    
        const { pageX, pageY } = (e.touches && e.touches[0]) || e
        let deltaX = pageX - startX
        let deltaY = pageY - startY
        setDeltaX(deltaX)
        setDeltaY(deltaY)

        // when arrived edge in non-loop mode, reduce drag effect.
        if (!loop) {
            const isCurrentLast = siblings.current === refs.current.length-1
            const isCurrentFirst = siblings.current === 0
            if (isCurrentLast) {
                deltaX < 0 && (deltaX /= 3)
                deltaY < 0 && (deltaY /= 3)
            }
            if (isCurrentFirst) {
                deltaX > 0 && (deltaX /= 3)
                deltaY > 0 && (deltaY /= 3)
            }
        }

        moveFramesBy(deltaX, deltaY)
    }

    function moveFramesBy (deltaX, deltaY) {
        const [prevFrame, currentFrame, nextFrame] = getSiblingFrames(siblings)
    
        switch (axis) {
            case 'x':
                translateXY(currentFrame, deltaX, 0)
                translateXY(prevFrame, deltaX - rootWidth - gap, 0)
                translateXY(nextFrame, deltaX + rootWidth + gap, 0)
                break
            case 'y':
                translateXY(currentFrame, 0, deltaY)
                translateXY(prevFrame, 0, deltaY - rootHeight)
                translateXY(nextFrame, 0, deltaY + rootHeight)
                break
        }
    }

    const onTouchEnd = e => {
        const to = decideWhereToSlide(siblings)
        if      (to==="next") toNext()
        else if (to==="prev") toPrev()
        else if (to==="origin") slideTo("origin")

        // trun back to initial state
        setStartX(null)
        setStartY(null)
        setDeltaX(0)
        setDeltaY(0)
        setTouch(false)
    }

    function decideWhereToSlide(siblings) {
        switch (axis) {
            
            case 'x':
                if (loop === false) {
                    if (siblings.current === 0 && deltaX > 0) return 'origin'
                    if (siblings.current === refs.current.length - 1 && deltaX < 0) return 'origin'
                }
                if (Math.abs(deltaX) < minMove) return 'origin'
                return deltaX < 0 ? 'next' : 'prev'
                break

            case 'y':
                if (loop === false) {
                    if (siblings.current === 0 && deltaY > 0) return 'origin'
                    if (siblings.current === refs.current.length - 1 && deltaY < 0) return 'origin'
                }
                if (Math.abs(deltaY) < minMove) return 'origin'
                return deltaY < 0 ? 'next' : 'prev'
                break

            default:
                return "origin"
        }
    }

    const eventHandlers = disableSwipe
        ? {}
        : {
            onTouchStart,
            onTouchMove: touch ? onTouchMove : () => {},
            onTouchEnd,
            onMouseDown: onTouchStart,
            onMouseMove: touch ? onTouchMove : () => {},
            onMouseUp: onTouchEnd,
            onMouseLeave: onTouchEnd,
        }


    // deal with change comming from outside
    // this effect must be executed only `current` is changed. but there are too many dependecy so that this makes bugs.
    // so we should distinguish wherher "Is `current` changed from outside?" by `isCurrentChangedFromOutside`
    const [isCurrentChangedFromOutside, setIsChangeFromOutsideOcurred] = useState(false)
    
    
    // skip running effect in first render https://stackoverflow.com/questions/53351517/react-hooks-skip-first-run-in-useeffect/53351556
    const isFirstUpdate = useRef(true)
    useEffect(() => {
        if (isFirstUpdate.current===true) {
            isFirstUpdate.current = false
            return
        }

        setIsChangeFromOutsideOcurred(true)
    }, [current])

    useEffect(() => {
        if (!isCurrentChangedFromOutside) return
        if (!Number.isInteger(current)) return
        
        if (current < siblings.current) {
            setSiblings(siblings => ({ ...siblings, prev: current}))
            if (siblings.prev === current) {
                toPrev()
                setIsChangeFromOutsideOcurred(false)
            }
        }
        else if (current > siblings.current) {
            setSiblings(siblings => ({ ...siblings, next: current}))
            if (siblings.next === current) {
                toNext()
                setIsChangeFromOutsideOcurred(false)
            }
        }
    }, [isCurrentChangedFromOutside, current, siblings])
    
    
    // handle callbacks from outside.
    useEffect(() => {
        onStart(siblings.current)
        setTimeout(() => {
            onEnd(siblings.current)
        }, duration)
    }, [siblings])


    return <CarouselRoot
    ref={el => setRoot(el)}
    width={width}
    height={height}
    customCss={customCss}
    {...eventHandlers}>
        {itemsRender}
    </CarouselRoot>     
}

export default Carousel