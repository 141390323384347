import React, { } from 'react'
import styled, {  } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Flex, { FlexItem } from 'styled-flex-component'
import { HexagonField, H1, H2, Strong, U, P, Img } from "src/components"
import { useWindowSize, useLanguage, CapsuleText, useIsDeviceWidth } from "src/components/utils"
import { mainTheme } from 'src/GlobalStyle'
import { MdArrowForward } from "react-icons/md"
import useContents from "src/data"

const SQRT3 = Math.sqrt(3)

const OurTechnologyRoot = styled(Flex)`
    width: 100%;
    height: 100vh;
    padding-top: 50px;

    ${breakpoint("mobile", "desktop")`
        box-sizing: border-box;
        padding: 0 20px;
        height: initial;
    `}
`
const BigHexagonBackgroundComponentRoot = styled(Flex)`
    ${H2} {
        font-size: 1.5em;
        font-weight: 700;
        ${breakpoint("mobile", "desktop")`
            font-size: 1.2em;
        `}
    }
`
const HexagonWrapper = styled(FlexItem)`
    order: 1;
    ${breakpoint("mobile", "desktop")`
        margin-top: 40px;
        order: 2;
    `}
`
const Text = styled(FlexItem)`
    text-align: center;
    max-width: 800px;
    order: 2;

    ${breakpoint("mobile", "desktop")`
        order: 1;
        text-align: start;
    `}

    ${H1} {
        margin-top: 18px;
        font-weight: 300;
        font-size: 2.5em;

        ${breakpoint("mobile", "desktop")`
            font-size: 26px;
        `}
        
        ${Strong} {
            display: block;
            font-size: 3.5rem;
            margin-top: 10px;
        }
    }
    ${P} {
        font-size: 14px;
        margin-top: 12px;

        ${breakpoint("mobile", "desktop")`
            font-size: 16px;
        `}  
    }
    ${Strong} {
        ${breakpoint("mobile", "desktop")`
            font-size: 2em;
        `}        
    }
`
const ButtonP = styled(P)`
    color: ${props => props.theme.secondary};
    text-align: center;
    ${props => props.customCss};
`

export default function OurTechnology() {
    const { images, 컨텐츠 } = useContents("Home_OurTechnology")
    const { 컨텐츠: 컨텐츠_Technology } = useContents("Technology")
    const language = useLanguage()
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })
    
    
    // calculate size
    const { innerWidth } = useWindowSize()
    const smallHexagonLineLength = isSmallDevice
        ? 45
        : 70
    const smallHexagonHeight = smallHexagonLineLength * SQRT3
    const bigHexagonLineLength = smallHexagonLineLength * 2.7
    const bigHexagonWidth = bigHexagonLineLength * 2
    const bigHexagonHeight = bigHexagonLineLength * SQRT3
    const gap = 10;


    // hexagon field size
    const padding = 40
    const width = isSmallDevice
        ? innerWidth - padding
        : ((smallHexagonLineLength * 1.5) + gap + bigHexagonWidth) * 2
    const height = isSmallDevice
        ? bigHexagonHeight * 2 + gap
        : bigHexagonHeight


    // hexagon configs
    const LeftBigHexagonBackgroundComponent = <BigHexagonBackgroundComponentRoot column center full>
        <Img gatsbyImageSrc={isSmallDevice 
            ? images.target_discovery_mobile
            : images.target_discovery
        } />
        <P customCss={`margin-top: 20px;`}>{컨텐츠_Technology.육각형[2][0]}</P>
        <H2 whiteSpace="nowrap">{컨텐츠_Technology.육각형[2][1]}</H2>
        <MdArrowForward size={30} color={mainTheme.primary} style={{marginTop: 10}}/>
    </BigHexagonBackgroundComponentRoot>

    const RightBigHexagonBackgroundComponent = <BigHexagonBackgroundComponentRoot column center full>
        <Img gatsbyImageSrc={isSmallDevice 
            ? images.drug_design_mobile
            : images.drug_design
        } />
        <P customCss={`margin-top: 20px;`}>{컨텐츠_Technology.육각형[5][0]}</P>
        <H2 whiteSpace="nowrap">{컨텐츠_Technology.육각형[5][1]}</H2>
        <MdArrowForward size={30} color={mainTheme.primary} style={{marginTop: 10}}/>
    </BigHexagonBackgroundComponentRoot>
    

    // how many push down right HexagonField
    const rightDown = isSmallDevice
        ? bigHexagonHeight + gap
        : 0

    const hexagonConfigs = [
        // left small top
        {
            top: (bigHexagonHeight / 2) - smallHexagonHeight - (gap / 2),
            left: 0,
            buttonProps: {
                color: mainTheme.secondary,
                size: smallHexagonLineLength,
                direction: "horizontal",
                config: {
                    componentNotHovered: <ButtonP>{컨텐츠_Technology.육각형[0][0]}</ButtonP>,
                    componentHovered: <ButtonP customCss={`color: white;`}>{컨텐츠_Technology.육각형[0][1]}</ButtonP>,
                },
                to: isSmallDevice 
                    ? undefined
                    : `/${language}/technology/${컨텐츠_Technology.육각형[0][2]}`,
            }
        },
        // left small bottom
        {
            top: (bigHexagonHeight / 2) + (gap / 2),
            left: 0,
            buttonProps: {
                color: mainTheme.secondary,
                size: smallHexagonLineLength,
                direction: "horizontal",
                config: {
                    componentNotHovered: <ButtonP>{컨텐츠_Technology.육각형[1][0]}</ButtonP>,
                    componentHovered: <ButtonP customCss={`color: white;`}>{컨텐츠_Technology.육각형[1][1]}</ButtonP>,
                },
                to: isSmallDevice 
                    ? undefined
                    : `/${language}/technology/${컨텐츠_Technology.육각형[1][2]}`,
                
            }
        },
        // left big
        {
            top: 0,
            left: (smallHexagonLineLength * 1.5) + gap,
            buttonProps: {
                color: mainTheme.secondary,
                size: bigHexagonLineLength,
                direction: "horizontal",
                config: {
                    backgroundComponent: LeftBigHexagonBackgroundComponent,
                    backgroundColorHovered: "transparent",
                },
                to: isSmallDevice 
                    ? `/${language}/technology/${컨텐츠_Technology.육각형[2][2]}`
                    : `/${language}/technology`,
            }
        },
        // right small top
        {
            top: rightDown + (bigHexagonHeight / 2) - smallHexagonHeight - (gap / 2),
            right: 0,
            buttonProps: {
                color: mainTheme.secondary,
                size: smallHexagonLineLength,
                direction: "horizontal",
                config: {
                    componentNotHovered: <ButtonP>{컨텐츠_Technology.육각형[3][0]}</ButtonP>,
                    componentHovered: <ButtonP customCss={`color: white;`}>{컨텐츠_Technology.육각형[3][1]}</ButtonP>,
                },
                to: isSmallDevice 
                    ? undefined
                    : `/${language}/technology/${컨텐츠_Technology.육각형[3][2]}`,
            }
        },
        // right small bottom
        {
            top: rightDown + (bigHexagonHeight / 2) + (gap / 2),
            right: 0,
            buttonProps: {
                color: mainTheme.secondary,
                size: smallHexagonLineLength,
                direction: "horizontal",
                config: {
                    componentNotHovered: <ButtonP>{컨텐츠_Technology.육각형[4][0]}</ButtonP>,
                    componentHovered: <ButtonP customCss={`color: white;`}>{컨텐츠_Technology.육각형[4][1]}</ButtonP>,
                },
                to: isSmallDevice 
                    ? undefined
                    : `/${language}/technology/${컨텐츠_Technology.육각형[4][2]}`,
            }
        },
        // right big
        {
            top: rightDown,
            right: (smallHexagonLineLength * 1.5) + gap,
            buttonProps: {
                color: mainTheme.secondary,
                size: bigHexagonLineLength,
                direction: "horizontal",
                config: {
                    backgroundComponent: RightBigHexagonBackgroundComponent,
                    backgroundColorHovered: "transparent",
                },
                to: isSmallDevice 
                    ? `/${language}/technology/drug_design`
                    : `/${language}/technology/${컨텐츠_Technology.육각형[3][2]}`,
            }
        },
    ]


    // hexagon render
    const hexagonRender = <HexagonWrapper>
        <HexagonField
        width={width}
        height={height}
        hexagonConfigs={hexagonConfigs} />
    </HexagonWrapper>

    // text
    const underline = isSmallDevice
        ? null
        : <Strong><U>{컨텐츠[1][1]}</U></Strong>
    const textRender = <Text>
        <H1>
            {컨텐츠[1][0]}
            {underline}
        </H1>
        <P>{컨텐츠[1][2]}</P>
    </Text>


    // title in mobile
    const mobileTitleRender = isSmallDevice
        ? <CapsuleText>
            {"Our Technology "}
            <Strong>
                <U>{`Dr.UG`}</U>
            </Strong>
        </CapsuleText>
        : null

        
    return <OurTechnologyRoot column center full>
        {hexagonRender}
        {textRender}
        {mobileTitleRender}
    </OurTechnologyRoot>
}
