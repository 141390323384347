import { useLanguage } from "src/components/utils"

const text = {   
    ko: [
        [
            // header
            `Our Team`,
            // member cards
            `디어젠 멤버소개`,
            // bottom text
            `Join Us`
        ],
    ],
    en: [
        [
            `Our Team`,
            "Meet Our Teams",  
            `Join Us`,
        ]
    ],
}


export default function useContents() {
    const language = useLanguage()
    const 컨텐츠 = text[language]
    const images = {}

    return { images, 컨텐츠 }
}