import React, { useEffect, useRef } from 'react'
import styled, {  } from "styled-components"
import _ from "lodash"
import breakpoint from "styled-components-breakpoint"
import { GrClose as CloseIcon } from "react-icons/gr"
import { AiFillLinkedin as LinkedInIcon } from "react-icons/ai"
import { H1, P, Img } from "src/components";
import { useLanguage, useKeypress, useWindowSize } from "src/components/utils";
import { mainTheme } from "src/GlobalStyle"


const RightModalRoot = styled.div`
    display: ${props => props.show ? "block" : "none"};
    position: fixed;
    overflow: scroll;
    overflow-x: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
`
const RightModalContent = styled.section`
    position: absolute;
    top: 0;
    right: 0;
    width: 450px;
    min-height: 100%;
    box-shadow: -6px 0px 8px rgba(0,0,0,0.3);
    background: white;

    animation-duration: 0.25s;
    animation-name: slidein;

    @keyframes slidein {
        from {
            transform: translateX(100%);
        }
        
        to {
            transform: translateX(0);
        }
    }

    ${breakpoint("mobile", "desktop")`
        width: 100%;
    `}

`
const ProfileText = styled.div`
    ${props => props.theme.css.full}
    box-sizing: border-box;
    padding: 30px;
`
const Role = styled.span`
    font-size: 12px;
    font-weight: lighter;
    margin-left: 5px;
`
const LinkedInButton = styled(
    ({ href, size=40, color="navy", marginTop=20, children, ...props }) => <a href={href} target="_blink" style={{display:"inline-block", marginTop}}>
        <LinkedInIcon size={size} color={color} {...props} />
    </a>
)``


export default function RightModal({
    show,
    profile={},
    gatsbyImageSrc,
    onClose,
}) {
    const language = useLanguage()
    const { offsetHeight } = useWindowSize()


    // move scroll to top on every click
    const ref = useRef()
    useEffect(() => {
        ref.current.scroll({ top: 0 })
    }, [ref, show])
    

    // right modal content
    const slogan = profile.letsMake && <P size={16} marginTop={10}>
        {profile.letsMake}
        {" together"}
    </P>
    const koreanSloganRender = language==="ko"
        ? <P size={13} weight={700} marginTop={10}>{profile.koreanSlogan}</P>
        : null
    const profileTextRender = <ProfileText> 
        <H1 size={16} weight={700}>
            {language==="ko" ? profile.koreanName : profile.englishName} <Role>{profile.role}</Role>
        </H1>
        {slogan}
        {koreanSloganRender}
        <P size={14} weight={300} marginTop={10}>{profile.description}</P>
        {profile.linkedinHref && <LinkedInButton href={profile.linkedinHref} />}
    </ProfileText>

    const contentRender = <RightModalContent show={show} onClick={e => e.stopPropagation()}>
        <Img gatsbyImageSrc={gatsbyImageSrc} />
        {profileTextRender}
        <CloseIcon
        size={30}
        onClick={onClose}
        style={{
            top: 20,
            right: 20,
            cursor:"pointer",
            position:"absolute"
        }} 
        />
    </RightModalContent>


    // // close on ESC keypress
    // useKeypress({
    //     keycode: "Escape",
    //     callback: onClose,
    // })


    return <RightModalRoot
    ref={ref}
    show={show}
    height={offsetHeight}
    onClick={onClose}>
        {contentRender}
    </RightModalRoot>   
    
}