import React, {  } from 'react'
import { Link } from "gatsby"
import styled, {  } from "styled-components"
import _ from "lodash"
import GatsbyImg from "gatsby-image"
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const Gradation = styled.div`
    ${props => props.theme.css.full};
    position: absolute;
    left: 0;
    top: 0;
    background:  linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.7));
`

const ImgRoot = styled.figure`
    width: ${props => Number.isInteger(props.width) 
        ? `${props.width}px` 
        : `${props.isFixed ? "fit-content" : ""}`
    };
    height: ${props => Number.isInteger(props.height) 
        ? `${props.height}px` 
        : `${props.isFixed ? "fit-content" : ""}`
    };
    border: ${props => props.border
        ? `${props.border}px solid ${props.theme.secondary};`
        : "none"
    };
    border-radius: ${props => props.rounded 
        ? props.rounded 
        : 0
    }px;

    display: block;
    position: relative;
    box-sizing: border-box;
    cursor: ${props => props.cursor};

    img {
        border-radius: ${props => props.rounded - props.border}px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


    ${Gradation} {
        border-radius: ${props => props.rounded - props.border}px;
    }

    ${props => props.customCss}
`

export default function Img({
    height,
    width,
    imgSrc,
    gatsbyImageSrc,
    gatsbyImageWrapperStyle={},
    gatsbyImageImgStyle={},
    textRender,
    rounded=0,
    border=0,
    gradation=false,
    to,
    href,
    alt="",
    eventHandlers,
    customCss,
    ...props 
}) {
    

    // `to` is for inner-link. `href` is for outer-link
    const innerLinkProps = { as: Link, to }
    const outerLinkProps = { as: OutboundLink, href, target: "_blank" }
    const linkProps = to 
        ? innerLinkProps 
        : href
            ? outerLinkProps
            : {}


    // img
    const isFluid = gatsbyImageSrc && _.has(gatsbyImageSrc, "fluid")
    const isFixed = gatsbyImageSrc && _.has(gatsbyImageSrc, "fixed")
    if (isFluid) _.set(gatsbyImageWrapperStyle, "borderRadius", rounded-border)
    if (isFluid) _.set(gatsbyImageWrapperStyle, "height", "100%")
    
    const imgRender = gatsbyImageSrc
        ? <GatsbyImg 
        {...gatsbyImageSrc} 
        style={gatsbyImageWrapperStyle}
        imgStyle={gatsbyImageImgStyle}
        alt={alt} />
        : imgSrc
            ? <img src={imgSrc} alt={alt} />
            : null


    return <ImgRoot
    width={width}
    height={height}
    border={border}
    rounded={rounded}
    cursor={(to || href) ? "pointer" : "initial"}
    isFixed={isFixed}
    customCss={customCss}
    {...eventHandlers}
    {...linkProps}
    {...props}>
        
        {imgRender}
        {gradation && <Gradation />}
        {textRender}

    </ImgRoot>
}